import React, { ChangeEvent, useEffect, useState } from 'react';
import { MdAddAPhoto, MdDelete } from 'react-icons/md';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import Select, { ValueType } from 'react-select';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';

import { PageHeaderAction } from 'modules/app-ui/models';
import {
  Badge,
  BadgeDetailsCardItem,
  BadgeLevel,
  BadgeThunks,
} from 'modules/badges';
import { RoutePath } from 'modules/navigation';
import { ApplicationState } from 'modules/app-state';
import {
  PointOptions,
  PointAction,
} from 'modules/gamification/const/PointAction';
import { useFormatMessage } from 'modules/localisation';
import {
  EmptyState,
  Loading,
  PageHeader,
  PageGuard,
  useModal,
  usePageModified,
} from 'modules/app-ui';

interface DispatchProps {
  getAll: VoidFunction;
  saveBadge: (
    levelValues: BadgeLevel[],
    oldlevelValues: BadgeLevel[],
    id: string,
    title: string,
    actionName: PointAction,
  ) => void;
  removeBadge: (badgeId: string) => void;
}

interface ReduxProps {
  badge: Badge;
  badgesAreChanging: boolean;
}

type Props = DispatchProps & ReduxProps & RouteComponentProps<any>;

const BadgeDetails: React.FC<Props> = ({
  getAll,
  badge,
  saveBadge,
  removeBadge,
  badgesAreChanging,
  history,
  match,
}) => {
  const onRedirect = () => history.push(RoutePath.Badges);
  const [toggleModal, Modal] = useModal(removeBadge, onRedirect);
  const [titleValue, setTitleValue] = useState(badge ? badge.title : '');
  const [actionValue, setActionValue] = useState(
    badge && badge.actionName
      ? {
          value: badge.actionName,
          label: badge.actionName,
        }
      : undefined,
  );

  const [levelValues, setLevelValues] = useState<BadgeLevel[]>(
    badge && badge.levels.map(level => ({ ...level })),
  );

  const { id, levels } = badge;

  const [error, setError] = useState<JSX.Element>();
  const [formatMessage] = useFormatMessage();

  const [pageModified, setPageModified] = usePageModified('badges-contentarea');

  const onRemoveLevel = (badgeLevel: BadgeLevel) => {
    const filteredLevelValues = levelValues.filter(
      item => item.level !== badgeLevel.level,
    );

    setLevelValues(filteredLevelValues);
    setPageModified(true);
  };

  const isFormValid = () => {
    if (
      !titleValue ||
      !actionValue ||
      !PointOptions.some(option => option.value === actionValue.value)
    ) {
      setError(
        <>
          <strong>{formatMessage('error.missing')}:</strong>
          {!titleValue && <p>{formatMessage('form.title')}</p>}
          {(!actionValue ||
            !PointOptions.some(
              option => option.value === actionValue.value,
            )) && <p>{formatMessage('form.action-name')}</p>}
        </>,
      );
      return false;
    }

    return true;
  };

  const onLevelUpdate = (currentLevel: number, badgeLevel: BadgeLevel) => {
    const tempLevels = [...levelValues];
    tempLevels[currentLevel] = badgeLevel;

    setLevelValues(tempLevels);
    setPageModified(true);
  };

  const renderBadgeItems = () => {
    return levelValues.map((item, key: number) => (
      <BadgeDetailsCardItem
        key={key}
        id={key}
        badgeLevel={item}
        onUpdate={onLevelUpdate}
        onRemove={onRemoveLevel}
      />
    ));
  };

  const handleSelectChange = (
    value: ValueType<{
      value: string;
      label: string;
    }>,
  ) => {
    setActionValue(value as any);
    setPageModified(true);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setTitleValue(value);
  };

  const saveBadgeCard = () => {
    if (!isFormValid() || !actionValue) {
      return;
    }

    setPageModified(false);

    saveBadge(
      levelValues,
      levels,
      id,
      titleValue.toLowerCase(),
      actionValue.value,
    );

    if (
      !navigator.onLine &&
      levelValues.some(level => Boolean(level.imageFile))
    ) {
      toast.error('Images can not be updated in offline mode');
    }

    const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);
    toast.success(
      id
        ? formatMessage('toast.badges.update', capitalize(titleValue))
        : formatMessage('toast.badges.save', capitalize(titleValue)),
    );

    onRedirect();
  };

  const pushNewElement = () => {
    setLevelValues([
      ...levelValues,
      { imageUrl: '', level: levelValues.length },
    ]);

    setPageModified(true);
  };

  useEffect(() => {
    if (!badge) {
      getAll();
    }
  }, [getAll, badge]);

  useEffect(() => {
    if (!badge) {
      return;
    }

    setTitleValue(badge.title || '');
    if (badge.actionName) {
      setActionValue({ value: badge.actionName, label: badge.actionName });
    }
    setLevelValues(badge.levels.map(level => ({ ...level })));
  }, [badge, setTitleValue, setActionValue, setLevelValues]);

  if (!levelValues) {
    if (!badgesAreChanging) {
      return <EmptyState showImage />;
    }

    return <Loading isLoading fullPage />;
  }

  return (
    <section className="contentarea v-badges" id="badges-contentarea">
      <PageHeader
        title={
          <FormattedMessage
            id={
              match.params.id === 'new'
                ? 'badges.create.new'
                : 'badges.details.title'
            }
          />
        }
        backAction={
          new PageHeaderAction(
            (<FormattedMessage id="badges.back-to-list" />),
            RoutePath.Badges,
          )
        }
      />

      {error && (
        <div className="alert alert--warning s-bottom--lrg">{error}</div>
      )}

      <div className="card">
        <div className="field">
          <label htmlFor="titleValue" className="field__lbl t-mandatory">
            <FormattedMessage id="form.title" />
          </label>
          <FormattedMessage id="form.title.placeholder">
            {placeholder => (
              <input
                name="titleValue"
                id="titleValue"
                type="text"
                className="input input--med t-upper"
                placeholder={placeholder as string}
                value={titleValue}
                onChange={handleChange}
              />
            )}
          </FormattedMessage>
        </div>

        <div>
          <label htmlFor="actionValue" className="field__lbl t-mandatory">
            <FormattedMessage id="form.action-name" />
          </label>
          <FormattedMessage id="form.action-name.placeholder">
            {placeholder => (
              <Select
                id="actionValue"
                value={actionValue}
                onChange={handleSelectChange}
                options={PointOptions}
                className="input--select"
                placeholder={placeholder as string}
              />
            )}
          </FormattedMessage>
        </div>

        <div className="v-badges__list s-bottom--lrg s-top--lrg">
          {renderBadgeItems()}
          <button
            className="btn v-badges__list__item v-badges__list__item__add"
            onClick={pushNewElement}
          >
            <MdAddAPhoto size={40} />
          </button>
        </div>

        <footer className="card__footer">
          <div className="btn-group">
            <button
              className="btn btn--primary btn--med"
              onClick={saveBadgeCard}
              disabled={!pageModified}
            >
              <FormattedMessage id={id ? 'button.save' : 'button.add'} />
            </button>

            {id && (
              <button
                data-id={id}
                data-title={titleValue}
                className="btn btn--ghost btn--med"
                onClick={toggleModal}
              >
                <MdDelete size={16} />
              </button>
            )}
          </div>
        </footer>

        <Modal />

        <PageGuard when={pageModified} />
      </div>
    </section>
  );
};

export default connect<ReduxProps, DispatchProps, Props, ApplicationState>(
  (state, ownProps) => {
    const id = ownProps.match.params.id;

    return {
      badge:
        id === 'new'
          ? new Badge()
          : state.badges.items.filter(item => item.id === id)[0],
      badgesAreChanging: state.badges.loading,
    };
  },
  {
    getAll: BadgeThunks.getAllAsync,
    saveBadge: BadgeThunks.saveBadge,
    removeBadge: BadgeThunks.removeBadge,
  },
)(BadgeDetails);
