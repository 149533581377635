export interface Product {
  id: string;
  actionPoints: number | string;
  barcode: string;
  description: string;
  imageUrl: string;
  leadText: string;
  name: string;
  scanned: boolean;
  url: string;
  activeDate: number;
}

export class Product {
  constructor(data?: Partial<Product>) {
    if (data && data.id) {
      this.id = data.id;
    }

    this.actionPoints = (data && data.actionPoints) || 0;
    this.barcode = (data && data.barcode) || '';
    this.description = (data && data.description) || '';
    this.imageUrl = (data && data.imageUrl) || '';
    this.leadText = (data && data.leadText) || '';
    this.name = (data && data.name) || '';
    this.scanned = (data && data.scanned) || false;
    this.url = (data && data.url) || '';
    this.activeDate = (data && data.activeDate) || Date.now();
  }
}
