import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

export const OfflineAlert: React.FC = () => {
  const [online, setOnlineStatus] = useState(navigator.onLine);
  useEffect(() => {
    window.addEventListener('online', () => setOnlineStatus(true));
    window.addEventListener('offline', () => setOnlineStatus(false));

    return () => {
      window.removeEventListener('online', () => setOnlineStatus(true));
      window.removeEventListener('offline', () => setOnlineStatus(false));
    };
  }, []);

  return online ? null : (
    <div className="alert alert--warning t-center">
      <p>
        <FormattedMessage id="alert.offline" />
      </p>
      <p>
        <FormattedMessage id="alert.offline.features" />
      </p>
    </div>
  );
};
