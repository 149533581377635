import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';

import { ApplicationState } from 'modules/app-state';
import { PageHeaderAction } from 'modules/app-ui/models';
import { useFormatMessage } from 'modules/localisation';
import { PageHeader, Loading, EmptyState, PageGuard } from 'modules/app-ui';

import { UserLevelsItem } from '../components';
import { UserLevel } from '../models';
import { UserThunks } from '../redux';

interface DispatchProps {
  getAll: VoidFunction;
  saveAll: (entity: { id: string; levels: UserLevel[] }) => void;
}

interface ReduxProps {
  userLevels?: { id: string; levels: UserLevel[] };
  error?: string;
  isChanging: boolean;
}

type Props = DispatchProps & ReduxProps;

const UserLevelsList: React.FC<Props> = ({
  getAll,
  userLevels,
  error,
  isChanging,
  saveAll,
}) => {
  const [userlevelsDirty, setUserLevelsDirty] = useState(false);
  const [formatMessage] = useFormatMessage();

  const addNewUserLevel = () => {
    // addLevel(new UserLevel('', 0));
    setUserLevelsDirty(true);
  };

  const save = () => {
    setUserLevelsDirty(false);
    if (userLevels) {
      saveAll(userLevels);
    }
    toast.success(formatMessage('toast.user-levels.save'));
  };

  useEffect(() => {
    getAll();
  }, [getAll]);

  return (
    <section className="contentarea v-levels">
      <PageHeader
        title={<FormattedMessage id="user-levels" />}
        actions={[
          new PageHeaderAction(
            (<FormattedMessage id="user-levels.btn.add" />),
            addNewUserLevel,
          ),
        ]}
      />

      {error && <p className="alert alert--warning">{error}</p>}

      <Loading fullPage isLoading={isChanging}>
        {(!userLevels || !userLevels.levels) && <EmptyState showImage />}

        {userLevels?.levels && (
          <>
            <div className="v-levels__list">
              {userLevels.levels.map((level, i) => (
                <UserLevelsItem
                  {...level}
                  id={i}
                  key={`${level.level}_${i}`}
                  // removeLevel={removeLevel}
                  // updateLevel={updateLevel}
                  setUserLevelsDirty={setUserLevelsDirty}
                />
              ))}
            </div>

            <div className="contentarea__actionbar">
              <button
                className="btn btn--primary btn--med"
                onClick={save}
                disabled={!userlevelsDirty}
              >
                <FormattedMessage id="button.save" />
              </button>
            </div>
          </>
        )}
      </Loading>

      <PageGuard when={userlevelsDirty} />
    </section>
  );
};

export default connect<ReduxProps, DispatchProps, null, ApplicationState>(
  state => ({
    userLevels: state.users.userLevels,
    isChanging: state.users.loading,
    error: state.users.error,
  }),
  {
    getAll: UserThunks.getAllGamificationLevels,
    saveAll: UserThunks.saveAllGamificationLevels,
  },
)(UserLevelsList);
