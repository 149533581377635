import React, { useState, useEffect } from 'react';
import Select, { ValueType } from 'react-select';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';

import { ApplicationState } from 'modules/app-state';
import { RoleOptions, UserThunks } from 'modules/users';
import {
  PageHeaderAction,
  usePageModified,
  PageHeader,
  Loading,
  PageGuard,
} from 'modules/app-ui';
import { RoutePath } from 'modules/navigation';
import { useFormatMessage } from 'modules/localisation';

interface ReduxProps {
  error?: string;
}
interface DispatchProps {
  inviteUser: (
    firstName: string,
    lastName: string,
    email: string,
    role: string,
  ) => void;
}

type Props = ReduxProps & DispatchProps & RouteComponentProps<any>;

const UserInvite: React.FC<Props> = ({ inviteUser, history, error }) => {
  const [state, setState] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });
  const [role, setRole] = useState(RoleOptions[0]);
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState<React.ReactElement | null>(null);
  const [formatMessage] = useFormatMessage();
  const [pageModified, setPageModified] = usePageModified(
    'user-invite-contentarea',
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleRoleChange = (
    value: ValueType<{
      value: string;
      label: string;
    }>,
  ) => {
    setRole(value as any);

    setPageModified(true);
  };

  const emailRegex = new RegExp(
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
  );

  const validateForm = () => {
    if (state.email.match(emailRegex) && state.firstName && state.lastName) {
      return true;
    }

    setFormError(
      <div>
        {(!state.firstName || !state.lastName) && (
          <div>
            <strong>
              <FormattedMessage id="error.missing" />:
            </strong>
          </div>
        )}
        {!state.firstName && (
          <div>
            <FormattedMessage id="form.first-name" />
          </div>
        )}
        {!state.lastName && (
          <div>
            <FormattedMessage id="form.last-name" />
          </div>
        )}
        {!state.email.match(emailRegex) && (
          <div>{formatMessage('error.invite-user')}</div>
        )}
      </div>,
    );

    return false;
  };

  const handleInvite = () => {
    if (!validateForm()) {
      return;
    }

    setFormError(null);
    setLoading(true);

    setPageModified(false);
    const inviteSuccess: any = inviteUser(
      state.firstName,
      state.lastName,
      state.email,
      role.value,
    );

    if (inviteSuccess) {
      toast.success(formatMessage('toast.users.invite', state.email));
      history.push(RoutePath.Users);
    }
  };

  useEffect(() => {
    if (error) {
      setLoading(false);
    }
  }, [error]);

  return (
    <section className="contentarea" id="user-invite-contentarea">
      <PageHeader
        title={<FormattedMessage id="users.invite" />}
        backAction={
          new PageHeaderAction(
            (<FormattedMessage id="users.back-to-list" />),
            RoutePath.Users,
          )
        }
      />

      {(error || formError) && (
        <div className="alert alert--warning s-bottom--med">
          {formError || error}
        </div>
      )}

      <Loading fullPage isLoading={loading}>
        <div className="field">
          <label htmlFor="firstName" className="field__lbl t-mandatory">
            <FormattedMessage id="form.first-name" />
          </label>
          <FormattedMessage id="form.first-name.placeholder">
            {placeholder => (
              <input
                className="input input--med"
                id="firstName"
                name="firstName"
                placeholder={placeholder as string}
                value={state.firstName}
                onChange={handleChange}
              />
            )}
          </FormattedMessage>
        </div>

        <div className="field">
          <label htmlFor="lastName" className="field__lbl t-mandatory">
            <FormattedMessage id="form.last-name" />
          </label>
          <FormattedMessage id="form.last-name.placeholder">
            {placeholder => (
              <input
                className="input input--med"
                id="lastName"
                name="lastName"
                placeholder={placeholder as string}
                value={state.lastName}
                onChange={handleChange}
              />
            )}
          </FormattedMessage>
        </div>

        <div className="field">
          <label htmlFor="E-mail" className="field__lbl t-mandatory">
            <FormattedMessage id="form.email" />
          </label>
          <FormattedMessage id="users.invite-email.placeholder">
            {placeholder => (
              <input
                className="input input--med"
                id="E-mail"
                name="email"
                placeholder={placeholder as string}
                value={state.email}
                onChange={handleChange}
              />
            )}
          </FormattedMessage>
        </div>

        <div className="field">
          <label htmlFor="Role" className="field__lbl">
            <FormattedMessage id="form.role" />
          </label>
          <Select
            id="Role"
            options={RoleOptions}
            value={role}
            onChange={handleRoleChange}
          />
        </div>

        <button
          className="btn btn--primary btn--med"
          onClick={handleInvite}
          disabled={!pageModified}
        >
          <FormattedMessage id="button.invite" />
        </button>
      </Loading>

      <PageGuard when={pageModified} />
    </section>
  );
};

export default connect<ReduxProps, DispatchProps, null, ApplicationState>(
  state => ({
    error: state.users.error,
  }),
  {
    inviteUser: UserThunks.inviteUser,
  },
)(UserInvite);
