import { ActionUnion } from 'modules/app-state';

import { SettingsActions } from './actions';
import { SettingsActionTypes } from './types';

import { MenuModule, Logo } from '../models';

export interface SettingsState {
  readonly loading: boolean;
  readonly error?: string;
  readonly modules: MenuModule[];
  readonly logo?: Logo;
}

const INITIAL_STATE: SettingsState = {
  loading: false,
  error: undefined,
  modules: [],
  logo: undefined,
};

export default (
  state: SettingsState = INITIAL_STATE,
  action: ActionUnion<typeof SettingsActions>,
): SettingsState => {
  switch (action.type) {
    case SettingsActionTypes.Change:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case SettingsActionTypes.Error:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case SettingsActionTypes.UpdateModules:
      return {
        ...state,
        loading: false,
        error: undefined,
        modules: action.payload.modules,
      };
    case SettingsActionTypes.UpdateLogo:
      return {
        ...state,
        loading: false,
        error: undefined,
        logo: action.payload.logo,
      };

    default:
      return state || INITIAL_STATE;
  }
};
