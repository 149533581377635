import { ForgotPassword, Login } from 'modules/authentication';

import { AppRoute, RoutePath } from '../models';

export const anonymousRoutes: AppRoute[] = [
  {
    key: 'login',
    title: 'Log in',
    accessbilityLevel: 'anonymous-only',
    component: Login,
    exact: true,
    path: RoutePath.Login,
  },
  {
    key: 'forgot-password',
    title: 'Forgot password',
    accessbilityLevel: 'anonymous-only',
    component: ForgotPassword,
    exact: true,
    path: RoutePath.ForgotPassword,
  },
];
