enum Actions {
  Change = 'localisation/CHANGE',
  UpdateAll = 'localisation/UPDATE_ALL',
  UpdateSingle = 'localisation/UPDATE_SINGLE',
  Error = 'localisation/ERROR',
}

export const LocalisationActionTypes = {
  ...Actions,
};
