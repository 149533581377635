import React from 'react';
import { MdDelete, MdFindInPage } from 'react-icons/md';
import Files from 'react-files';
import { FormattedMessage } from 'react-intl';

interface Props {
  imageUrls: string[];
  imageBlobs: string[];
  removeHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
  addHandler: (files: File[]) => void;
  mediaType: 'Image' | 'Gallery';
}

export const ImageGallery: React.FC<Props> = ({
  imageUrls,
  imageBlobs,
  removeHandler,
  addHandler,
  mediaType,
}) => (
  <div className="field">
    <label htmlFor="gallery" className="field__lbl">
      <FormattedMessage id="form.image-gallery" />
    </label>

    <div className="imageuploader-gallery">
      <div className="imageuploader-gallery__content">
        {!imageUrls.length && !imageBlobs.length && (
          <div className="imageuploader-gallery__image--empty">
            <div className="card t-center">
              <MdFindInPage className="o-20 s-bottom--med" size={64} />
              <p className="t-zeta o-60">Add images to gallery</p>
            </div>
          </div>
        )}

        {(imageUrls.length > 0 || imageBlobs.length > 0) && (
          <>
            {imageUrls.map((url, index) => (
              <div key={index} className="imageuploader-gallery__image">
                <img src={url} alt="gallery-url" />

                <div className="imageuploader__remove">
                  <button
                    className="btn btn--dark btn--close"
                    onClick={removeHandler}
                    data-index={`imageUrl_${index}`}
                  >
                    <MdDelete size={16} />
                  </button>
                </div>
              </div>
            ))}

            {imageBlobs.map((url, index) => (
              <div key={index} className="imageuploader-gallery__image">
                <img src={url} alt="gallery-url" />

                <div className="imageuploader__remove">
                  <button
                    className="btn btn--dark btn--close"
                    onClick={removeHandler}
                    data-index={`blobUrl_${index}`}
                  >
                    <MdDelete size={16} />
                  </button>
                </div>
              </div>
            ))}
          </>
        )}
      </div>

      <div className="imageloader__action">
        <Files
          onChange={addHandler}
          accepts={['image/*']}
          maxFiles={mediaType === 'Image' ? 1 : Infinity}
          maxFileSize={10000000}
          minFileSize={0}
          clickable
        >
          <button className="btn btn--primary btn--med s-top--med">
            Upload images
          </button>
        </Files>
      </div>
    </div>
  </div>
);
