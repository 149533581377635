import React from 'react';
import { isWebUri } from 'valid-url';
import { MdFindInPage } from 'react-icons/md';
import { FormattedMessage } from 'react-intl';

import { urlConverterService } from 'modules/editor';

interface Props {
  videoUrl: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Video: React.FC<Props> = ({ videoUrl, handleChange }) => (
  <div className="field">
    <label htmlFor="videoUrl" className="field__lbl">
      <FormattedMessage id="form.video-url" />
    </label>
    <div className="imageuploader">
      <div className="imageuploader__image">
        {!isWebUri(videoUrl) && (
          <div className="card t-center">
            <MdFindInPage className="o-20 s-bottom--med" size={64} />
            <p className="t-zeta o-60">
              <FormattedMessage id="form.video-url.placeholder" />
            </p>
          </div>
        )}

        {isWebUri(videoUrl) && (
          <iframe
            title="video"
            src={urlConverterService.convertYTUrl(videoUrl)}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        )}
      </div>
      <div className="imageuploader__action">
        <FormattedMessage id="form.video-url-input.placeholder">
          {placeholder => (
            <input
              type="text"
              className="input input--med"
              value={videoUrl}
              name="videoUrl"
              id="videoUrl"
              placeholder={placeholder as string}
              onChange={handleChange}
            />
          )}
        </FormattedMessage>
      </div>
    </div>
  </div>
);
