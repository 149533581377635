import React, { useState, useEffect } from 'react';
import { Switch, Redirect, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { remove } from 'lodash';

import {
  AppRoute,
  getRoute,
  RoutePath,
  applicationRoutes,
} from 'modules/navigation';
import { ApplicationState } from 'modules/app-state';
import { AuthenticationThunkActions } from 'modules/authentication';
import { SearchActions } from 'modules/search';
import { MenuModule } from 'modules/settings';
import {
  adminOnlyRoutes,
  authenticatedDetailsRoutes,
  authenticatedRoutes,
} from 'modules/navigation/const/authenticatedRoutes';
import { anonymousRoutes } from 'modules/navigation/const/anonymousRoutes';
import { errorRoutes } from 'modules/navigation/const/errorRoutes';
import { User } from 'modules/users';
import { Loading } from 'modules/app-ui';

import { ACLRoute } from './ACLRoute';

interface ReduxProps {
  authStateChanging: boolean;
  user?: User;
  isLoggedIn: boolean;
  enabledModules: MenuModule[];
}

interface DispatchProps {
  loginWithPersistedUser: VoidFunction;
  clearSearch: VoidFunction;
}

type Props = ReduxProps & DispatchProps;

const AccessControlList: React.FC<Props> = ({
  loginWithPersistedUser,
  authStateChanging,
  clearSearch,
  user,
  enabledModules,
  ...rest
}) => {
  const location = useLocation();
  const [enabledRoutes, setEnabledRoutes] = useState<AppRoute[]>([]);
  const allRoutes = applicationRoutes.map(route => route.path);

  useEffect(() => {
    loginWithPersistedUser();
  }, [loginWithPersistedUser]);

  useEffect(() => {
    const routing = [
      ...adminOnlyRoutes,
      ...anonymousRoutes,
      ...errorRoutes,
      ...authenticatedDetailsRoutes,
    ];

    if (user?.role !== 'administrator') {
      remove(routing, item => item.key === 'settings');
    }

    authenticatedRoutes.forEach(route => {
      routing.push(route);
    });

    setEnabledRoutes(routing);
  }, [enabledModules, user]);

  return (
    <Loading isLoading={authStateChanging || !enabledRoutes.length} fullPage>
      <Switch>
        <Redirect exact from="/" to={getRoute(RoutePath.Login).path} />

        {enabledRoutes.map((route: AppRoute) => (
          <ACLRoute
            location={location}
            key={route.key}
            {...route}
            {...rest}
            clearSearch={clearSearch}
          />
        ))}

        {!allRoutes.find(route => route === location.pathname) && (
          <Redirect to={getRoute(RoutePath.Error).path} />
        )}
      </Switch>
    </Loading>
  );
};

export default connect<
  ReduxProps,
  DispatchProps,
  React.FC<Props>,
  ApplicationState
>(
  state => ({
    isLoggedIn: state.auth.isLoggedIn,
    authStateChanging: state.auth.loading,
    user: state.auth.user,
    enabledModules: state.settings.modules,
  }),
  {
    loginWithPersistedUser: AuthenticationThunkActions.loginWithPersistedUser,
    clearSearch: SearchActions.clearSearch,
  },
)(AccessControlList);
