import { Localisation } from '../models';

export const en: Localisation = {
  articles: 'Articles',
  'articles.all': 'All Articles',
  'articles.back-to-list': 'Back to articles list',
  'articles.btn.add': 'Add new article',
  'articles.create.title': 'Create new article',
  'articles.details.title': 'Article details',
  'articles.draft': 'Draft Articles',
  'articles.published': 'Published Articles',
  'badge-level': 'Badge Level',
  'badge-levels': 'Badge Levels',
  'badge-levels.btn.add': 'Add level',
  badges: 'Badges',
  'badges.back-to-list': 'Back to badges list',
  'badges.create.new': 'Create new badge',
  'badges.details.title': 'Badge details',
  'badges.level': 'Level',
  'button.add': 'Add',
  'button.cancel': 'Cancel',
  'button.change-image': 'Change image',
  'button.confirm': 'Confirm',
  'button.delete-language': 'Delete language',
  'button.edit': 'Edit',
  'button.invite': 'Invite',
  'button.logout': 'Logout',
  'button.publish': 'Publish',
  'button.remove': 'Remove',
  'button.remove-image': 'Remove image',
  'button.save': 'Save',
  'button.save-draft': 'Save draft',
  'button.save-language': 'Save language',
  'button.send-pw-reset-mail': 'Send password reset email',
  'button.unpublish': 'Unpublish',
  'button.upload-image': 'Upload image',
  'dashboard.version': 'Version',
  'error.invite-user': 'Email is empty or badly formatted',
  'error.localisation': 'Please define strings for empty properties',
  'error.missing': 'Missing information',
  'error.page-404': 'The requested page does not exist.',
  'error.page-404.message':
    'Something went a bit wrong and you ended up in limbo. Click the link below to get back to safety.',
  'error.permissions': 'You do not have permission to access this page.',
  'error.permissions.login': 'Login to get access to the requested page.',
  faq: 'FAQ',
  'faq.back-to-list': 'Back to FAQ list',
  'faq.btn.add': 'Add new FAQ',
  'faq.create.title': 'Create new FAQ',
  'faq.details.title': 'FAQ details',
  products: 'Products',
  'products.btn.add': 'Add new product',
  'products.create.title': 'Create new product',
  'products.details.title': 'Product details',
  'products.back-to-list': 'Back to product list',
  feedback: 'Feedback',
  'feedback-cards': 'Feedback Cards',
  'feedback-cards.active': 'Active',
  'feedback-cards.btn.add': 'Add Feedback Card',
  'feedback-cards.direction': 'Direction',
  'feedback-cards.error': 'Max. number of active cards per direction type is 3',
  'feedback-cards.image-error':
    'Image aspect ratio must be 16:9 and max. supported width is 1280 px',
  'feedback-cards.image-error.size': 'Image size',
  'feedback-cards.image-error.aspect': 'Aspect ratio',
  'feedback-thanks-cards': 'Feedback Thanks Cards',
  'feedback-thanks-cards.btn.add': 'Add Feedback Thanks Card',
  'feedback.all': 'All Feedback',
  'feedback.giving': 'Giving feedback',
  'feedback.requesting': 'Requesting feedback',
  'feedbacks.back-to-list': 'Back to all feedback list',
  'feedbacks.status.read': 'Read',
  'feedbacks.status.unread': 'Unread',
  'form.name': 'Name',
  'form.name.placeholder': 'Enter a name',
  'form.action-name': 'Action name',
  'form.action-name.placeholder': 'Select action',
  'form.action-type': 'Action type',
  'form.action-points': 'Action points',
  'form.label': 'Label',
  'form.lead-text': 'Lead text',
  'form.url': 'URL',
  'form.barcode': 'Barcode',
  'form.address': 'Address',
  'form.all': 'All',
  'form.amount': 'Amount',
  'form.city': 'City',
  'form.company': 'Company',
  'form.company.placeholder': 'Enter company',
  'form.content': 'Content',
  'form.count': 'Count',
  'form.cover-photo': 'Cover photo',
  'form.cover-photo.placeholder': 'Add a cover photo',
  'form.date': 'Date',
  'form.date-order': 'Date order',
  'form.date-order.asc': 'Ascending',
  'form.date-order.desc': 'Descending',
  'form.department': 'Department',
  'form.department.placeholder': 'Enter department',
  'form.description': 'Description',
  'form.direction-order': 'Direction order',
  'form.display-name': 'Display name',
  'form.email': 'E-mail',
  'form.email.placeholder': 'Enter email',
  'form.featured-image': 'Featured Image',
  'form.first-name': 'First name',
  'form.first-name.placeholder': 'Enter first name',
  'form.from': 'From',
  'form.interval': 'Valid interval',
  'form.language': 'Language',
  'form.last-name': 'Last name',
  'form.last-name.placeholder': 'Enter last name',
  'form.level': 'Level',
  'form.not-shipped': 'Not shipped',
  'form.password': 'Password',
  'form.password.placeholder': 'Enter your password',
  'form.phone': 'Phone',
  'form.phone.placeholder': 'Enter phone number',
  'form.points': 'Points',
  'form.points-required': 'Points required',
  'form.position': 'Position',
  'form.position.placeholder': 'Enter position',
  'form.post-code': 'Post code',
  'form.publish-date': 'Publish date',
  'form.read-articles': 'Read articles',
  'form.remember-me': 'Remember me?',
  'form.role': 'Role',
  'form.shipped': 'Shipped',
  'form.slug': 'Slug',
  'form.slug.placeholder': 'Enter a slug',
  'form.spent-points': 'Spent points',
  'form.subtitle': 'Subtitle',
  'form.subtitle.placeholder': 'Enter a subtitle',
  'form.team': 'Team',
  'form.team.placeholder': 'Enter team',
  'form.title': 'Title',
  'form.title.placeholder': 'Enter a title',
  'form.to': 'To',
  'form.total-points': 'Total points',
  'form.type': 'Type',
  'form.user-actions': 'User actions',
  'form.user-badges': 'User badges',
  'form.user-filter': 'User',
  'form.user-filter.placeholder': 'Select user',
  'form.user-id': 'User id',
  'form.user-id.placeholder': 'Enter user ID',
  'form.video-url': 'Video',
  'form.video-url-input.placeholder': 'Enter the URL for your video',
  'form.video-url.placeholder': 'Add a video',
  'form.image-gallery': 'Image gallery',
  'form.rating': 'Average rating',
  'form.image.alt': 'There is a problem with image loading',
  gamification: 'Gamification',
  localisation: 'Localisation',
  'localisation.add-new-prop': 'Add new property',
  'localisation.back-to-list': 'Back to languages list',
  'localisation.btn.add': 'Add new language',
  'localisation.client': 'Application',
  'localisation.create.title': 'Create new language',
  'localisation.dashboard': 'Dashboard',
  'localisation.details.title': 'Language details',
  'localisation.export': 'Export language',
  'localisation.import': 'Import language',
  login: 'Login',
  'login.forgot-pw': 'Forgot password?',
  'login.message': 'Use your Styria account to log in to the admin console',
  'modal.are-you-sure': 'Are you sure?',
  'modal.image-select': "Drag 'n' drop image here, or click to select",
  'modal.image-url': 'Enter image URL',
  'modal.insert-image': 'Insert image',
  'modal.insert-video': 'Insert video',
  'modal.or': 'OR',
  'modal.unsaved-changes': 'You have unsaved changes, leave page anyway?',
  'modal.video-url': 'Enter video URL',
  'points-history': 'Points History',
  'points-history.back-to-list': 'Back to points history list',
  'points-history.btn.add': 'Add point',
  'points-history.create.title': 'Create new point in history',
  'points-history.details.title': 'Point details',
  'redeemed-rewards': 'Redeeemed Rewards',
  'redeemed-rewards.back-to-list': 'Back to redeeemed rewards',
  'redeemed-rewards.details.title': 'Redeeemed reward details',
  'redeemed-rewards.public-info': 'Public information',
  'redeemed-rewards.reward-info': 'Reward information',
  'redeemed-rewards.shipping-info': 'Shipping information',
  rewards: 'Rewards',
  'rewards.all': 'All Rewards',
  'rewards.virtual': 'Virtual Rewards',
  'rewards.back-to-list': 'Back to rewards list',
  'rewards.btn.add': 'Add new reward',
  'rewards.btn.add-virtual': 'Add new virtual reward',
  'rewards.missing-user': '- Missing user info -',
  'rewards.create.title': 'Create new reward',
  'rewards.details.title': 'Reward details',
  'rewards.draft': 'Draft Rewards',
  'rewards.not-shipped': 'Reward Not Shipped',
  'rewards.published': 'Published Rewards',
  'rewards.shipped': 'Reward Shipped',
  search: 'Search',
  settings: 'Settings',
  'settings.btn.save': 'Save',
  'settings.localisation.select': 'Select Language',
  'settings.localisation.title': 'Language',
  'settings.logo.title': 'Logo',
  'settings.modules.title': 'Enabled Modules',
  'static-content': 'Static Content',
  'static-content.back-to-list': 'Back to static content list',
  'static-content.btn.add': 'Add new static content',
  'static-content.create.title': 'Create new static content',
  'static-content.details.title': 'Static content details',
  summary: 'Summary',
  'summary.articles.title': 'Latest published articles',
  'summary.btn.seemore': 'More',
  'summary.feedback.title': 'Latest feedback',
  'summary.points.title': 'Latest point actions',
  'summary.redeemed.title': 'Latest redeemed rewards',
  'summary.stats.title': 'Stats title',
  'toast.article.publish': 'Successfully published {title} article',
  'toast.article.save': 'Successfully added new {title} article',
  'toast.article.unpublish': 'Successfully unpublished {title} article',
  'toast.article.update': 'Successfully updated {title} article',
  'toast.badge-levels.save': 'Successfully saved Badge Levels',
  'toast.badges.save': 'Added new {title} badge',
  'toast.badges.update': 'Successfully updated {title} badge',
  'toast.faq.save': 'Successfully added new {title} FAQ',
  'toast.faq.update': 'Successfully updated {title} FAQ',
  'toast.product.update': 'Successfully updated {title} product',
  'toast.product.save': 'Successfully added new {title} product',
  'toast.localisation.update': 'Updated localisation',
  'toast.modal.delete': 'Successfully removed {title}',
  'toast.point.add': 'Added new point in history',
  'toast.redeemed.update': 'Successfully edited redeemed reward',
  'toast.rewards.save': 'Added new {title} reward',
  'toast.rewards.update': 'Successfully updated {title} reward',
  'toast.settings-localisation.save':
    'Dashboard-Lokalisierung erfolgreich aktualisiert',
  'toast.settings-modules.save': 'Successfully updated dashboard modules',
  'toast.settings.logo.removed': 'Dashboard logo removed',
  'toast.settings.logo.update': 'Dashboard logo updated',
  'toast.static-content.save': 'Added new static content {title}',
  'toast.static-content.update': 'Successfully updated {title}',
  'toast.user-levels.save': 'Successfully saved User Levels',
  'toast.users.invite': 'Successfully invited user with email {title}',
  'toast.users.update': 'Successfully updated user {title}',
  'toast.media.update': 'Successfully updated {title} media',
  'toast.media.save': 'Successfully saved {title} media',
  'toast.thanks-cards.save': 'Successfully saved Feedback Thanks Cards',
  'toast.feedback-cards.save': 'Successfully saved Feedback Cards',
  'toast.language.disabled': 'Changing language is disabled in offline mode',
  'toast.logo.disabled': 'Changing logo is disabled in offline mode',
  'toast.images.disabled': 'Images can not be updated in offline mode',
  'toast.user.disabled': 'User editing is disabled in offline mode',
  'user-levels': 'User Levels',
  'user-levels.btn.add': 'Add new User level',
  'user-levels.title.placeholder': 'Add a level title',
  users: 'Users',
  'users.back-to-list': 'Back to users',
  'users.details.private': 'User private information',
  'users.details.public': 'User public information',
  'users.details.title': 'User Details',
  'users.invite': 'Invite user',
  'users.invite-email.placeholder': 'E-mail of the person you are inviting',
  media: 'Media',
  'modal.insert-gallery.title': 'Insert image gallery',
  'modal.insert-gallery.placeholder': 'Choose gallery...',
  'media.btn.add': 'Add new media',
  'media.details.title': 'Media Details',
  'media.create.title': 'Create new media',
  'media.back-to-list': 'Go back',
  gallery: 'Gallery',
  'alert.offline':
    'There is no internet connection. Your changes will be saved localy until connection is established.',
  'alert.offline.features': 'Some features are disabled in offline mode.',
  'form.main-image': 'Main image',
  'form.short-description': 'Short description',
  'form.short-description.placeholder': 'Short description',
  'rewards.learn-more.title': 'Learn More section',
  'form.learn-more-title': 'Learn More title',
  'form.learn-more-title.placeholder': 'Learn More title',
  'form.learn-more-image': 'Learn More image',
};
