import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { MdClose } from 'react-icons/md';

import { ImageUploader } from 'modules/media';

import { FeedbackThanks } from '../models';
import { greatestCommonDivisor } from '../services/mathService';

interface Props {
  id: number;
  thanksCard: FeedbackThanks;
  handleChange: (id: number, state: FeedbackThanks) => void;
  handleActiveChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  deleteThanksCard: (id: number) => void;
}

export const FeedbackThanksCardItem: React.FC<Props> = ({
  id,
  thanksCard,
  handleChange,
  handleActiveChange,
  deleteThanksCard,
}) => {
  const [error, setError] = useState<JSX.Element>();

  const handleChangeImage = (files: File[]) => {
    const img = new Image();
    const ImageFile = files[files.length - 1];
    img.src = URL.createObjectURL(ImageFile);

    img.onload = () => {
      const gcd = greatestCommonDivisor(img.naturalWidth, img.naturalHeight);
      const aspectRatio = `${img.naturalWidth / gcd}:${img.naturalHeight /
        gcd}`;

      if (img.naturalWidth <= 1280) {
        setError(undefined);
        const temp = { ...thanksCard };
        temp.imageFile = ImageFile;
        temp.imageUrl = URL.createObjectURL(ImageFile);
        handleChange(id, temp);

        return;
      }

      setError(
        <div>
          <FormattedMessage id="feedback-cards.image-error" />
          <p>
            - <FormattedMessage id="feedback-cards.image-error.size" />:{' '}
            {img.naturalWidth}x{img.naturalHeight}
          </p>
          <p>
            - <FormattedMessage id="feedback-cards.image-error.aspect" />:{' '}
            {aspectRatio}
          </p>
        </div>,
      );
    };
  };

  const handleDelete = () => deleteThanksCard(id);

  return (
    <div className="v-levels__list__item">
      <div className="v-levels__list__item__row">
        <div className="field">
          <label className="field__lbl">
            <FormattedMessage id="feedback-cards.active" />
          </label>
          <div className="onoffswitch">
            <input
              type="checkbox"
              name="onoffswitch"
              className="onoffswitch__checkbox"
              id={id.toString()}
              checked={thanksCard.active}
              data-id={id}
              onChange={handleActiveChange}
            />
            <label className="onoffswitch__label" htmlFor={id.toString()}>
              <span className="onoffswitch__inner" />
              <span className="onoffswitch__switch" />
            </label>
          </div>
        </div>
      </div>

      <div className="v-levels__list__item__remove">
        <button onClick={handleDelete} className="btn btn--dark btn--close">
          <MdClose size={16} />
        </button>
      </div>

      <div className="v-levels__list__item__row">
        <div className="field">
          <label className="field__lbl">
            <FormattedMessage id="form.featured-image" />
          </label>
          <ImageUploader
            imageUrl={thanksCard.imageUrl}
            changeHandler={handleChangeImage}
          />
        </div>

        {error && <div className="alert alert--warning">{error}</div>}
      </div>
    </div>
  );
};
