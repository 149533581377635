import { createAction } from 'modules/app-state';
import { User } from 'modules/users';

import { AuthenticationActionTypes } from './types';

export const AuthenticationActions = {
  change: () => createAction(AuthenticationActionTypes.Change),

  login: (user: User) =>
    createAction(AuthenticationActionTypes.Login, { user }),

  logout: () => createAction(AuthenticationActionTypes.Logout),

  error: (error?: string) =>
    createAction(AuthenticationActionTypes.Error, { error }),
};
