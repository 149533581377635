import React from 'react';
import { FormattedMessage } from 'react-intl';

export enum FeedbackDirection {
  Giving = 'GivingFeedback',
  Requesting = 'RequestFeedback',
}

export const DirectionOptions = [
  {
    value: FeedbackDirection.Giving,
    label: <FormattedMessage id="feedback.giving" />,
  },
  {
    value: FeedbackDirection.Requesting,
    label: <FormattedMessage id="feedback.requesting" />,
  },
];
