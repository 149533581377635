import 'firebase/storage';

import { FirebaseService } from './FirebaseService';

export class StorageService<T extends any> {
  private firebase = FirebaseService.Instance;
  private storage = this.firebase.storage();
  private storageBucket: firebase.storage.Reference;

  constructor(bucket: string) {
    this.storageBucket = this.storage.ref().child(bucket);
  }

  addItem(id?: string, files?: T | T[], name?: string) {
    if (!files) {
      return;
    }

    if (Array.isArray(files)) {
      return files.forEach(file =>
        this.storageBucket.child(name || `${id}_${file.name}`).put(file),
      );
    }

    return this.storageBucket.child(name || `${id}_${files.name}`).put(files);
  }

  addItemInFolder(id: string, files?: T | T[]) {
    if (!files) {
      return;
    }

    if (Array.isArray(files)) {
      return files.forEach(file =>
        this.storageBucket
          .child(`${id}`)
          .child(`${file.name}`)
          .put(file),
      );
    }

    return this.storageBucket
      .child(`${id}`)
      .child(`${files.name}`)
      .put(files);
  }

  removeItem(name: string | undefined) {
    if (!name || name === 'none') {
      return;
    }

    this.storageBucket
      .child(name)
      .delete()
      .then(() => {
        console.log('Image removed');
      })
      .catch(error => {
        console.log('TCL: StorageService -> staticremoveImage -> error', error);
      });
  }

  removeItemsInFolder(id: string, name: string) {
    this.storageBucket
      .child(id)
      .child(name)
      .delete()
      .then(() => console.log('Image removed'))
      .catch(error => console.log(error));
  }

  addLogo(logo: T) {
    return this.storageBucket.child(`logo.jpeg`).put(logo);
  }
}
