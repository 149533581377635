import React from 'react';
import { Link } from 'react-router-dom';
import { MdDelete } from 'react-icons/md';
import { FormattedMessage } from 'react-intl';

import { RoutePath } from 'modules/navigation';

import { Localisation } from '../models';

interface Props {
  language: Localisation;
  toggleModal: VoidFunction;
  localisationType: string;
}

export const LocalisationItem: React.FC<Props> = ({
  language,
  toggleModal,
  localisationType,
}) => (
  <div className="itemlist--simple__item">
    <div className="itemlist--simple__item__content">
      <Link to={`${RoutePath.Localisation}/${localisationType}/${language.id}`}>
        <h2 className="t-zeta">
          <strong>{language.id.replace(`${localisationType}-`, '')}</strong>
        </h2>
      </Link>
    </div>

    <div className="itemlist--simple__item__action">
      <div className="btn-group">
        <Link
          className="btn btn--sml btn--secondary"
          to={`${RoutePath.Localisation}/${localisationType}/${language.id}`}
        >
          <FormattedMessage id="button.edit" />
        </Link>
        <button
          className="btn btn--ghost btn--sml btn--hasIcon"
          onClick={toggleModal}
          data-id={language.id}
        >
          <MdDelete size={16} />
        </button>
      </div>
    </div>
  </div>
);
