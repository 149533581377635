import isHotkey from 'is-hotkey';
import React, { MouseEvent, useState, useRef } from 'react';
import { Editor, RenderBlockProps } from 'slate-react';
import { List } from 'immutable';
import { Value, Operation, Editor as CoreEditor } from 'slate';
import Icon from 'react-icons-kit';
import { image, video, folderPlus } from 'react-icons-kit/feather';
import { FormattedMessage } from 'react-intl';
import { Location } from 'history';
import { Link } from 'react-router-dom';

import { Media } from 'modules/media';
import { RoutePath } from 'modules/navigation';
import {
  SlateMarkType,
  SlateToolbarButtons,
  SlateMark,
  SlatePlugins,
  ModKeys,
} from 'modules/editor';
import { Loading } from 'modules/app-ui';

import { InsertGalleryModal } from './InsertGalleryModal';
import { InsertImageModal } from './InsertImageModal';
import { InsertVideoModal } from './InsertVideoModal';

import { SlateMarkEnum } from '../models';
import { schema } from '../const';

interface Props {
  content: Value;
  location: Location<any>;
  onContentChange: (change: {
    operations: List<Operation>;
    value: Value;
  }) => any;
  modifiedHandler: (state: boolean) => void;
  uploadMedia: (
    title: string,
    mediaType: 'Gallery' | 'Image' | 'Video',
    files: File[],
    videoUrl?: string,
  ) => Promise<void | firebase.firestore.DocumentReference>;
  galleryMedia: Media[];
  mediaIsChanging: boolean;
  setPageModified?: (state: boolean) => void;
}

export const SlateEditor: React.FC<Props> = ({
  content,
  onContentChange,
  modifiedHandler,
  uploadMedia,
  galleryMedia,
  location,
  mediaIsChanging,
  setPageModified,
}) => {
  const [imageModal, toggleImageModal] = useState(false);
  const [videoModal, toggleVideoModal] = useState(false);
  const [galleryModal, toggleGalleryModal] = useState(false);
  const editor = useRef<Editor | null>(null);

  const onKeyDown = (event: Event, coreEditor: CoreEditor, next: () => any) => {
    const e = event as KeyboardEvent;
    const mark =
      SlateMarkEnum[e.key.toLowerCase() as keyof typeof SlateMarkEnum];

    if (
      ModKeys.includes(e.key.toLowerCase()) &&
      isHotkey(`mod+${e.key.toLowerCase()}`, e)
    ) {
      event.preventDefault();
      coreEditor.toggleMark(mark);
    } else {
      next();
    }
  };

  const onButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    const type = event.currentTarget.dataset.type as SlateMarkType;
    if (type && editor.current) {
      editor.current.toggleMark(type);
    }

    if (modifiedHandler) {
      modifiedHandler(true);
    }
  };

  const showImageModal = async () => {
    await toggleImageModal(!imageModal);
  };

  const showVideoModal = async () => {
    await toggleVideoModal(!videoModal);
  };

  const showGalleryModal = async () => {
    await toggleGalleryModal(!galleryModal);
  };

  const renderBlock = (
    props: RenderBlockProps,
    coreEditor: CoreEditor,
    next: () => any,
  ) => {
    if (!coreEditor) {
      return next();
    }
    const { attributes, node } = props;
    const src = node.data.get('src');
    const dataId = node.data.get('dataId');
    const dataTitle = node.data.get('dataTitle');
    switch (props.node.type) {
      case 'gallery':
        const images = galleryMedia
          ? galleryMedia.find(media => media.id === dataId)
          : null;

        return (
          <Link
            to={{
              pathname: `${RoutePath.Media}/${dataId}`,
              state: { backRoute: location ? location.pathname : '' },
            }}
          >
            <div
              {...attributes}
              data-id={dataId}
              data-title={dataTitle}
              className="slate__gallery"
            >
              <p>
                <FormattedMessage id="gallery" />:{' '}
                {images ? images.title : dataTitle}
              </p>
              {images && (
                <div>
                  {images.imageUrls.map((url, index) => (
                    <img src={url} key={index} alt="" />
                  ))}
                </div>
              )}
            </div>
          </Link>
        );
      case 'image':
        return (
          <img
            className="slate__editor__image"
            {...attributes}
            src={src}
            alt=""
          />
        );
      case 'img':
        return (
          <img
            className="slate__editor__image"
            {...attributes}
            src={src}
            alt=""
          />
        );
      case 'video':
        return (
          <iframe
            src={src}
            title={src}
            frameBorder={0}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        );
      default:
        return next();
    }
  };

  return (
    <section className="slate">
      <Loading isLoading={mediaIsChanging ? mediaIsChanging : false}>
        <div className="slate__toolbar">
          <SlateToolbarButtons onButtonClick={onButtonClick} />
          <button
            key="image"
            type="button"
            onPointerDown={showImageModal}
            className="slate__toolbar__icon"
          >
            <Icon icon={image} />
          </button>

          <button
            key="gallery"
            type="button"
            onPointerDown={showGalleryModal}
            className="slate__toolbar__icon"
          >
            <Icon icon={folderPlus} />
          </button>

          <button
            key="video"
            type="button"
            onPointerDown={showVideoModal}
            className="slate__toolbar__icon"
          >
            <Icon icon={video} />
          </button>
        </div>

        <Editor
          ref={editor}
          schema={schema}
          className="slate__editor"
          value={content}
          onChange={onContentChange}
          onKeyDown={onKeyDown}
          renderMark={SlateMark}
          renderBlock={renderBlock}
          plugins={SlatePlugins}
          spellCheck={false}
        />

        <InsertImageModal
          editor={editor}
          showImageModal={showImageModal}
          imageModal={imageModal}
          uploadMedia={uploadMedia}
          setPageModified={setPageModified}
        />

        <InsertGalleryModal
          editor={editor}
          galleryModal={galleryModal}
          showGalleryModal={showGalleryModal}
          uploadMedia={uploadMedia}
          setPageModified={setPageModified}
        />

        <InsertVideoModal
          videoModal={videoModal}
          showVideoModal={showVideoModal}
          editor={editor}
          uploadMedia={uploadMedia}
          setPageModified={setPageModified}
        />
      </Loading>
    </section>
  );
};
