import { Dispatch } from 'redux';

import { ApplicationState } from 'modules/app-state';

import { MediaActions } from './actions';

import { Media } from '../models';
import { MediaService } from '../services';

const getAllAsync = () => async (
  dispatch: Dispatch,
  getState: () => ApplicationState,
) => {
  if (getState().media.items.length) {
    return;
  }

  dispatch(MediaActions.change());

  const successFunction = (data: Media[]) =>
    dispatch(MediaActions.update(data));

  const errorFunction = (error: string) => dispatch(MediaActions.error(error));

  const listenerProps = {
    successFunction,
    errorFunction,
  };

  MediaService.Database.filterAsync(undefined, listenerProps);

  return;
};

const saveMedia = (media: Media, oldMedia: Media) => async (
  dispatch: Dispatch,
) => {
  dispatch(MediaActions.change());

  let oldGalleryUrls = oldMedia.imageUrls || [];
  let oldGalleryRefs = oldMedia.imageReferences || [];

  const galleryHasChanged = () => {
    if (
      (media.imageFiles && media.imageFiles.length) ||
      (oldMedia.imageUrls &&
        media.imageUrls &&
        oldMedia.imageUrls.length !== media.imageUrls.length)
    ) {
      return true;
    }

    return false;
  };

  const handleGalleryImages = () => {
    const imageUrls: string[] = media.imageUrls ? [...media.imageUrls] : [];
    const imageRefs: string[] = media.imageReferences
      ? [...media.imageReferences]
      : [];

    if (!media.imageFiles) {
      return [imageUrls, imageRefs];
    }

    const promises = media.imageFiles.map(file => {
      return new Promise(
        (resolve: (url: string) => void, reject: () => void) => {
          const galleryUpload = MediaService.Storage.addItem(media.id, file);

          if (galleryUpload) {
            galleryUpload.on('state_changed', null, null, () => {
              galleryUpload.snapshot.ref
                .getDownloadURL()
                .then((url: string) => {
                  imageUrls.push(url);
                  imageRefs.push(`${media.id}_${file.name}`);

                  resolve(url);
                });
            });

            return;
          }

          reject();
        },
      );
    });

    return Promise.all(promises).then(() => {
      return [imageUrls, imageRefs];
    });
  };

  if (galleryHasChanged()) {
    const [galleryUrlsData, galleryRefsData] = await handleGalleryImages();
    oldGalleryUrls = galleryUrlsData;
    oldGalleryRefs = galleryRefsData;

    if (oldMedia.imageReferences) {
      oldMedia.imageReferences.forEach(oldRef => {
        if (!oldGalleryRefs.includes(oldRef)) {
          MediaService.Storage.removeItem(oldRef);
        }
      });
    }
  }

  const id = await MediaService.Database.addAsync({
    id: media.id,
    title: media.title,
    mediaType: media.mediaType,
    imageUrls: oldGalleryUrls,
    imageReferences: oldGalleryRefs,
    videoUrl: media.videoUrl || '',
  });

  return id;
};

const uploadMediaFromSlate = (
  title: string,
  mediaType: 'Image' | 'Video' | 'Gallery',
  files: File[],
  videoUrl?: string,
) => async (dispatch: Dispatch) => {
  dispatch(MediaActions.change());

  const imageUrls: string[] = [];
  const imageReferences: string[] = [];

  const promises = files.map(file => {
    return new Promise((resolve: (url: string) => void, reject: () => void) => {
      const galleryUpload = MediaService.Storage.addItem(
        undefined,
        file,
        `${title}_${file.name}`,
      );

      if (galleryUpload) {
        galleryUpload.on('state_changed', null, null, () => {
          galleryUpload.snapshot.ref.getDownloadURL().then((url: string) => {
            imageUrls.push(url);
            imageReferences.push(`${title}_${file.name}`);

            resolve(url);
          });
        });

        return;
      }

      reject();
    });
  });

  const reference = await Promise.all(promises).then(() => {
    const docReference = MediaService.Database.addAsync(
      new Media({
        title,
        mediaType,
        imageUrls,
        imageReferences,
        videoUrl,
      }),
    );

    return docReference;
  });

  return reference;
};

const removeMedia = (id: string) => async (
  _dispatch: Dispatch,
  getState: () => ApplicationState,
) => {
  const selectedMedia = getState().media.items.find(item => item.id === id);
  if (!selectedMedia) return;

  selectedMedia.imageReferences.forEach(oldRef =>
    MediaService.Storage.removeItem(oldRef),
  );

  await MediaService.Database.removeAsync(id);

  return;
};

export const MediaThunks = {
  getAllAsync,
  saveMedia,
  uploadMediaFromSlate,
  removeMedia,
};
