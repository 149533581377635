import React, { useState } from 'react';

import { PaginationProps, Pagination } from '../components';

export const usePagination = <T extends any>(
  unpagedItems: T[],
  itemsPerPage = 30,
): [T[], () => JSX.Element] => {
  const [currentPage, setCurrentPage] = useState(1);

  /**
   * Set total page count and ceil to higher integer number
   */
  const totalPages = Math.ceil(unpagedItems.length / itemsPerPage) || 1;

  /**
   * Logic for calculating the amount of returned items per page
   */
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = currentPage * itemsPerPage;
  const pagedItems = unpagedItems.slice(startIndex, endIndex);

  /**
   * Pagination handlers
   */
  const onGoToFirstPage = () => setCurrentPage(1);
  const onGoToLastPage = () => setCurrentPage(totalPages);
  const onGoToNextPage = () => setCurrentPage(currentPage + 1);
  const onGoToPreviousPage = () => setCurrentPage(currentPage - 1);
  const onGoToPage = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { pageNumber } = event.currentTarget.dataset;
    if (pageNumber) {
      setCurrentPage(parseInt(pageNumber));
    }
  };

  /**
   * Generate Props for pagination component
   */
  const paginationProps: PaginationProps = {
    totalPages,
    currentPage,
    onGoToFirstPage,
    onGoToLastPage,
    onGoToNextPage,
    onGoToPage,
    onGoToPreviousPage,
  };

  const paginationComponent = () => <Pagination {...paginationProps} />;

  return [pagedItems, paginationComponent];
};
