import 'firebase/auth';
import 'firebase/firestore';
import { Role } from 'modules/authorization';
import { User } from 'modules/users';

import { FirebaseService } from './FirebaseService';

import { errorMap } from '../const';

enum AuthPersistence {
  Local = 'local',
  Session = 'session',
  None = 'none',
}

export class FirebaseAuthService {
  private firebase = FirebaseService.Instance;
  private auth = this.firebase.auth();
  private firestore = this.firebase.firestore();

  initAuthStateListener(
    onSuccess: (user: User) => void,
    onError: (error?: string) => void,
  ) {
    this.auth.onAuthStateChanged(async user => {
      if (!user) {
        return onError();
      }

      const privateProfile = await this.firestore
        .collection('user-info')
        .doc(user.uid)
        .collection('private')
        .doc('profile')
        .get()
        .then(privateData => privateData.data() as { role: Role });

      const role = privateProfile?.role || 'user';

      const userData = { ...user.toJSON(), role };

      role === 'administrator' || role === 'moderator'
        ? onSuccess(userData as any)
        : onError('User is not authorized as administrator');
    });
  }

  async loginWithEmailAndPasswordAsync(
    email: string,
    password: string,
    rememberMe: boolean,
  ) {
    if (this.auth.currentUser) {
      await this.logoutAsync();
    }

    return this.auth
      .setPersistence(
        rememberMe ? AuthPersistence.Local : AuthPersistence.Session,
      )
      .then(() =>
        this.auth
          .signInWithEmailAndPassword(email, password)
          .then(() => undefined)
          .catch(
            async (error: firebase.FirebaseError) =>
              this.mapErrorMessages(error.code) || error.message,
          ),
      );
  }

  async getCurrentUserRole() {
    if (!this.auth.currentUser) {
      return;
    }

    return await this.auth.currentUser
      .getIdTokenResult()
      .then(idTokenResult => idTokenResult.claims.role || 'user');
  }

  async logoutAsync() {
    return this.auth
      .signOut()
      .then()
      .catch((error: firebase.FirebaseError) => error.message);
  }

  async sendPasswordResetEmail(email: string) {
    await this.auth.sendPasswordResetEmail(email);

    return;
  }

  mapErrorMessages(code: string) {
    if (errorMap[code]) {
      return errorMap[code];
    }

    return;
  }
}
