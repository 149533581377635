enum Actions {
  Change = 'feedback/CHANGE',
  UpdateFeedback = 'feedback/UPDATE',
  UpdateFeedbackCards = 'feedback/UPDATE_FEEDBACK_CARDS',
  UpdateThanksCards = 'feedback/UPDATE_THANKS_CARDS',
  Error = 'feedback/ERROR',
}

export const FeedbackActionTypes = {
  ...Actions,
};
