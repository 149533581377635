enum Actions {
  Change = 'media/CHANGE',
  Update = 'media/UPDATE',
  Error = 'media/ERROR',
  ChangeFilter = 'media/FILTER',
}

export const MediaActionTypes = {
  ...Actions,
};
