import {
  authenticatedRoutes,
  authenticatedDetailsRoutes,
  adminOnlyRoutes,
} from './authenticatedRoutes';
import { anonymousRoutes } from './anonymousRoutes';
import { errorRoutes } from './errorRoutes';

export const applicationRoutes = [
  ...authenticatedRoutes,
  ...authenticatedDetailsRoutes,
  ...adminOnlyRoutes,
  ...anonymousRoutes,
  ...errorRoutes,
];
