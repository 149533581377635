import React from 'react';

import { Navigation } from 'modules/navigation';

import { OfflineAlert } from './OfflineAlert';
import { ScrollToTop } from './ScrollToTop';

export const AppLayout: React.FC = ({ children }) => (
  <div className="container">
    <OfflineAlert />
    <div className="masterlayout">
      <Navigation />
      <div className="masterlayout__main">
        <main>{children}</main>
      </div>
    </div>
    <ScrollToTop />
  </div>
);
