// import React, { useEffect, useState, useCallback } from 'react';
// import { connect } from 'react-redux';
// import { toast } from 'react-toastify';
// import { FormattedMessage } from 'react-intl';

// import { ApplicationState } from 'modules/redux-store';
// import { Loading, PageHeader, EmptyState, PageGuard } from 'components';
// import { PageHeaderAction } from 'models';
// import { useFormatMessage } from 'hooks';

// import { BadgeLevelItem } from '../components';
// import { BadgeLevel } from '../models';

// interface DispatchProps {
//   // getAll: VoidFunction;
//   // updateLevel: (index: number, level: number) => void;
// }

// interface ReduxProps {
//   badgeLevels: BadgeLevel[];
//   error?: string;
//   badgeLevelsAreChanging: boolean;
// }

// type Props = DispatchProps & ReduxProps;

// const BadgeLevelsList: React.FC<Props> = ({
//   error,
//   badgeLevelsAreChanging,
//   getAll,
//   badgeLevels,
//   updateLevel,
// }) => {
//   const [nodes, setNodes] = useState<JSX.Element[]>();
//   const [badgeLevelsDirty, setBadgeLevelsDirty] = useState(false);
//   const [formatMessage] = useFormatMessage();

//   useEffect(() => {
//     getAll();
//   }, [getAll]);

//   const setBadgeLevels = () => {
//     if (!badgeLevels) {
//       return;
//     }

//     const levelsArray = badgeLevels.map((level, index) => (
//       <BadgeLevelItem
//         level={level}
//         index={index}
//         key={index}
//         updateLevel={updateLevel}
//         removeLevel={removeLevel}
//         setBadgeLevelsDirty={setBadgeLevelsDirty}
//       />
//     ));

//     setNodes(levelsArray);
//   };

//   const onSetBadgeLevels = useCallback(setBadgeLevels, [setBadgeLevels]);

//   useEffect(() => {
//     onSetBadgeLevels();
//   }, [onSetBadgeLevels]);

//   const addNewBadgeLevel = () => {
//     addLevel(0);

//     setBadgeLevelsDirty(true);
//   };

//   const save = () => {
//     setBadgeLevelsDirty(false);
//     saveAll(badgeLevels);
//     toast.success(formatMessage('toast.badge-levels.save'));
//   };

//   return (
//     <section className="contentarea v-levels" id="badge-levels-contentarea">
//       <PageHeader
//         title={<FormattedMessage id="badge-levels" />}
//         actions={[
//           new PageHeaderAction(
//             (<FormattedMessage id="badge-levels.btn.add" />),
//             addNewBadgeLevel,
//           ),
//         ]}
//       />

//       {error && <div className="alert alert--warning">{error}</div>}

//       <Loading fullPage isLoading={badgeLevelsAreChanging}>
//         {(!nodes || !nodes.length) && <EmptyState showImage />}

//         <div className="v-levels__list">{nodes}</div>

//         {nodes && (
//           <div className="contentarea__actionbar">
//             <button
//               className="btn btn--med btn--primary"
//               onClick={save}
//               disabled={!badgeLevelsDirty}
//             >
//               <FormattedMessage id="button.save" />
//             </button>
//           </div>
//         )}
//       </Loading>

//       <PageGuard when={badgeLevelsDirty} />
//     </section>
//   );
// };

// export default connect<ReduxProps, DispatchProps, null, ApplicationState>(
//   state => ({
//     badgeLevelsAreChanging: state.badges.loading,
//     error: state.badges.error,
//     badgeLevels: state.badges.items.reduceRight((prev, cur) => {
//       console.log(prev);
//       console.log(cur);
//       return [];
//     }, []),
//   }),
// )(BadgeLevelsList);

export default () => null;
