import React, { useEffect, useState } from 'react';
import { MdDelete } from 'react-icons/md';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { toast } from 'react-toastify';

import { PageHeaderAction } from 'modules/app-ui/models';
import { ApplicationState } from 'modules/app-state';
import { HTMLSerializer, SlateEditor, useSlateValue } from 'modules/editor';
import { useFormatMessage } from 'modules/localisation';
import { Media, MediaThunks, mediaOptions } from 'modules/media';
import { RoutePath } from 'modules/navigation';
import {
  useModal,
  usePageModified,
  EmptyState,
  Loading,
  PageHeader,
  PageGuard,
} from 'modules/app-ui';

import { FAQ } from '../models';
import { FAQThunks } from '../redux';

interface DispatchProps {
  getAll: VoidFunction;
  getAllMedia: VoidFunction;
  saveFaq: (faq: FAQ) => void;
  removeFAQ: (id: string) => void;
  uploadMedia: (
    title: string,
    mediaType: 'Gallery' | 'Image' | 'Video',
    files: File[],
  ) => Promise<void | firebase.firestore.DocumentReference>;
}

interface ReduxProps {
  faq: FAQ;
  faqsAreChanging: boolean;
  galleryMedia: Media[];
  mediaOptions: Array<{ value: string; label: string }>;
  mediaIsChanging: boolean;
}

type Props = DispatchProps & ReduxProps & RouteComponentProps<any>;

const FAQDetails: React.FC<Props> = ({
  faq,
  getAll,
  saveFaq,
  history,
  location,
  match,
  faqsAreChanging,
  removeFAQ,
  galleryMedia,
  mediaIsChanging,
  uploadMedia,
  getAllMedia,
}) => {
  const redirectToList = () => history.push(RoutePath.FAQ);

  const [toggleModal, Modal] = useModal(removeFAQ, redirectToList);
  const [state, setState] = useState(faq);
  const [contentValue, onContentChange, resetValue] = useSlateValue(
    faq && HTMLSerializer.init().deserialize(faq.content),
  );
  const [formatMessage] = useFormatMessage();
  const [error, setError] = useState<JSX.Element>();
  const [pageModified, setPageModified] = usePageModified('faq-contentarea');

  const isFormValid = () => {
    if (
      !state.title ||
      HTMLSerializer.init().serialize(contentValue) === '<p></p>'
    ) {
      setError(
        <>
          <strong>Missing information:</strong>
          {!state.title && <div>Title</div>}
          {HTMLSerializer.init().serialize(contentValue) === '<p></p>' && (
            <div>Content</div>
          )}
        </>,
      );
      return false;
    }

    return true;
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.currentTarget;

    setState({
      ...state,
      [name]: value,
    });
  };

  const saveFaqCard = async () => {
    if (!isFormValid()) {
      return;
    }

    await setPageModified(false);

    const data = {
      ...state,
      content: HTMLSerializer.init().serialize(contentValue),
    };

    saveFaq(data);

    toast.success(
      state.id
        ? formatMessage('toast.faq.update', state.title)
        : formatMessage('toast.faq.save', state.title),
    );

    redirectToList();
  };

  useEffect(() => {
    getAll();
    getAllMedia();
  }, [getAll, getAllMedia]);

  useEffect(() => {
    if (!faq) {
      return;
    }

    setState(faq);
    resetValue(HTMLSerializer.init().deserialize(faq.content));
  }, [faq, resetValue]);

  if (!state) {
    if (!faqsAreChanging) {
      return <EmptyState showImage />;
    }

    return <Loading isLoading fullPage />;
  }

  const { id, title } = state;

  return (
    <section className="contentarea" id="faq-contentarea">
      <PageHeader
        title={
          <FormattedMessage
            id={
              match.params.id === 'new'
                ? 'faq.create.title'
                : 'faq.details.title'
            }
          />
        }
        backAction={
          new PageHeaderAction(
            (<FormattedMessage id="faq.back-to-list" />),
            RoutePath.FAQ,
          )
        }
      />

      {error && (
        <div className="alert alert--warning s-bottom--med">{error}</div>
      )}

      <div className="card">
        <div className="field">
          <label htmlFor="title" className="field__lbl t-mandatory">
            <FormattedMessage id="form.title" />
          </label>
          <FormattedMessage id="form.title.placeholder">
            {placeholder => (
              <input
                type="text"
                className="input input--med"
                name="title"
                id="title"
                placeholder={placeholder as string}
                value={title}
                onChange={handleChange}
              />
            )}
          </FormattedMessage>
        </div>
        <div className="field">
          <label className="field__lbl t-mandatory">
            <FormattedMessage id="form.content" />
          </label>
          <SlateEditor
            content={contentValue}
            onContentChange={onContentChange}
            modifiedHandler={setPageModified}
            location={location}
            galleryMedia={galleryMedia}
            mediaIsChanging={mediaIsChanging}
            uploadMedia={uploadMedia}
          />
        </div>

        <footer className="card__footer">
          <div className="btn-group">
            <button
              className="btn btn--primary btn--med"
              onClick={saveFaqCard}
              disabled={!pageModified}
            >
              <FormattedMessage id="button.save" />
            </button>
            {id && (
              <button
                className="btn btn--ghost btn--med btn--hasIcon"
                onClick={toggleModal}
                data-id={id}
                data-title={title}
              >
                <MdDelete size={16} />
              </button>
            )}
          </div>
        </footer>
      </div>

      <Modal />

      <PageGuard when={pageModified} />
    </section>
  );
};

export default connect<ReduxProps, DispatchProps, Props, ApplicationState>(
  (state, componentProps) => {
    const id = componentProps.match.params.id;

    return {
      faq:
        id === 'new'
          ? new FAQ()
          : state.faq.items.filter(item => item.id === id)[0],
      faqsAreChanging: state.faq.loading,
      galleryMedia: state.media.items,
      mediaOptions: mediaOptions(state),
      mediaIsChanging: state.media.loading,
    };
  },
  {
    removeFAQ: FAQThunks.remove,
    getAll: FAQThunks.getAllAsync,
    saveFaq: FAQThunks.saveFaq,
    uploadMedia: MediaThunks.uploadMediaFromSlate as any,
    getAllMedia: MediaThunks.getAllAsync,
  },
)(FAQDetails);
