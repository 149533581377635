import { createAction } from 'modules/app-state';

import { UserActionTypes } from './types';

import { User, UserLevel } from '../models';

export const UserActions = {
  change: () => createAction(UserActionTypes.Change),

  update: (users: User[]) => createAction(UserActionTypes.Update, { users }),

  updateGamificationLevels: (userLevels: { id: string; levels: UserLevel[] }) =>
    createAction(UserActionTypes.UpdateGamificationLevels, { userLevels }),

  error: (error: string) => createAction(UserActionTypes.Error, { error }),
};
