import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Select, { ValueType } from 'react-select';

import {
  PageHeaderAction,
  useModal,
  usePagination,
  PageHeader,
  Loading,
  EmptyState,
} from 'modules/app-ui';
import { MediaItem } from 'modules/media/components';
import { Media } from 'modules/media/models';
import { MediaThunks, searchMedia } from 'modules/media';
import { RoutePath } from 'modules/navigation';
import { ApplicationState } from 'modules/app-state';
import { SearchActions, SearchQuery } from 'modules/search';

import { MediaActions } from '../redux';
import { MediaOptions } from '../const';

interface DispatchProps {
  getAll: VoidFunction;
  search: SearchQuery;
  removeMedia: (id: string) => void;
  changeFilter: (filter: 'Gallery' | 'Image' | 'Video' | null) => void;
}

interface ReduxProps {
  media: Media[];
  error?: string;
  mediaIsChanging: boolean;
  mediaFilter: 'Gallery' | 'Image' | 'Video' | null;
}

type Props = DispatchProps & ReduxProps;

const MediaList: React.FC<Props> = ({
  getAll,
  error,
  mediaIsChanging,
  removeMedia,
  media,
  search,
  mediaFilter,
  changeFilter,
}) => {
  useEffect(() => {
    getAll();
  }, [getAll]);

  const [toggleModal, Modal] = useModal(removeMedia);
  const [pagedItems, Pagination] = usePagination(media);

  const handleFilterChange = (
    value: ValueType<{
      value: string;
      label: string | React.ReactElement;
    }> | null,
  ) => changeFilter(value ? (value as any).value : null);

  return (
    <section className="contentarea">
      <PageHeader
        onSearch={search}
        title={<FormattedMessage id="media" />}
        actions={[
          new PageHeaderAction(
            (<FormattedMessage id="media.btn.add" />),
            `${RoutePath.Media}/new`,
          ),
        ]}
      />

      {error && <div className="alert alert--warning">{error}</div>}

      <Loading fullPage isLoading={mediaIsChanging}>
        <div className="field u-width--half">
          <label htmlFor="mediaTypeFilter" className="field__lbl">
            <FormattedMessage id="form.type" />
          </label>
          <Select
            value={
              mediaFilter
                ? {
                    value: mediaFilter,
                    label: mediaFilter,
                  }
                : null
            }
            onChange={handleFilterChange}
            options={MediaOptions}
            id="mediaTypeFilter"
            className="input--select s-bottom--lrg"
            isClearable
          />
        </div>

        {media && !media.length && <EmptyState showImage />}

        {pagedItems.map(mediaItem => (
          <MediaItem
            media={mediaItem}
            key={mediaItem.id}
            toggleModal={toggleModal}
          />
        ))}

        <Pagination />
        <Modal />
      </Loading>
    </section>
  );
};

export default connect<ReduxProps, DispatchProps, null, ApplicationState>(
  state => ({
    media: searchMedia(state),
    error: state.media.error,
    mediaIsChanging: state.media.loading,
    mediaFilter: state.media.filter,
  }),
  {
    removeMedia: MediaThunks.removeMedia,
    getAll: MediaThunks.getAllAsync,
    search: SearchActions.querySearch,
    changeFilter: MediaActions.changeFilter,
  },
)(MediaList);
