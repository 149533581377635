import { createAction } from 'modules/app-state';

import { ProductActionTypes } from './types';

import { Product } from '../models';

export const ProductsActions = {
  change: () => createAction(ProductActionTypes.Change),

  update: (products: Product[]) =>
    createAction(ProductActionTypes.Update, { products }),

  error: (error: string) => createAction(ProductActionTypes.Error, { error }),
};
