import { createAction } from 'modules/app-state';

import { LocalisationActionTypes } from './types';

import { Localisation } from '../models';

export const LocalisationActions = {
  change: () => createAction(LocalisationActionTypes.Change),

  updateAll: (localisations: Localisation[]) =>
    createAction(LocalisationActionTypes.UpdateAll, { localisations }),

  updateSingle: (localisation: Localisation) =>
    createAction(LocalisationActionTypes.UpdateSingle, { localisation }),

  error: (error: string) =>
    createAction(LocalisationActionTypes.Error, { error }),
};
