import { FirebaseDatabaseService, StorageService } from 'modules/firebase';

import { MenuModule, Logo } from '../models';

export const SettingsService = {
  Storage: new StorageService<File>('logo/'),
  Database: new FirebaseDatabaseService<
    { id: string; modules: MenuModule[] } | Logo
  >('settings'),
};
