import { useState, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';

import { ApplicationState } from 'modules/app-state';

import { Language } from '../models';

export const useFormatMessage = () => {
  const users = useSelector((state: ApplicationState) => state.users.items);
  const user = useSelector((state: ApplicationState) => state.auth.user);
  const languages = useSelector(
    (state: ApplicationState) => state.localisation.items,
  );

  const [locale, setLocale] = useState<Language>('en');
  const [messages, setMessages] = useState(
    languages.find(lang => lang.id === `dashboard-${locale}`),
  );

  useEffect(() => {
    if (!user) {
      return;
    }

    const currentUser = users.find(userData => userData.id === user.id);
    if (!currentUser || !currentUser.privateProfile) {
      setLocale('en');
      return;
    }

    setLocale(currentUser.privateProfile.dashboardLanguage || 'en');
  }, [user, users]);

  useEffect(() => {
    setMessages(languages.find(lang => lang.id === `dashboard-${locale}`));
  }, [languages, locale]);

  function formatMessage(id: string, value?: string) {
    const intlProvider = new IntlProvider({ locale, messages });
    return intlProvider
      .getChildContext()
      .intl.formatMessage({ id }, { title: value });
  }

  return [formatMessage];
};
