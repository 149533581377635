import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import { ApplicationState } from 'modules/app-state';
import { DashboardLocalisation } from 'modules/settings/components';
import { User, UserThunks } from 'modules/users';
import { useFormatMessage, Language } from 'modules/localisation';
import { PageHeader, Loading } from 'modules/app-ui';

interface DispatchProps {
  getAllUsers: VoidFunction;
  updateUser: (user: User, imageFile?: File) => void;
}

interface ReduxProps {
  users: User[];
  user?: User;
}

type Props = DispatchProps & ReduxProps;

const SettingsLocalisation: React.FC<Props> = ({
  updateUser,
  getAllUsers,
  users,
  user,
}) => {
  const [error] = useState();
  const [selectedLanguage, setSelectedLanguage] = useState<Language>('en');
  const [loading, setLoading] = useState(false);
  const [formatMessage] = useFormatMessage();

  const handleLanguageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSelectedLanguage(value as Language);
  };

  const handleSave = () => {
    if (!selectedLanguage || !user) {
      return;
    }

    const userObject = users.find(userData => userData.id === user.id);

    if (!userObject || !userObject.privateProfile) {
      return;
    }

    if (!navigator.onLine) {
      toast.warn(formatMessage('toast.language.disabled'));

      return;
    }

    setLoading(true);

    userObject.privateProfile.dashboardLanguage = selectedLanguage as Language;

    updateUser(userObject);
    getAllUsers();

    toast.success(formatMessage('toast.settings-localisation.save'));
  };

  useEffect(() => {
    if (!users.length) {
      getAllUsers();
    }

    if (!user) {
      return;
    }

    const userObject = users.find(userData => userData.id === user.id);

    if (!userObject || !userObject.privateProfile) {
      return;
    }

    setSelectedLanguage(userObject.privateProfile.dashboardLanguage || 'en');
    setLoading(false);
  }, [user, users, getAllUsers]);

  return (
    <section className="contentarea">
      <PageHeader
        title={<FormattedMessage id="settings.localisation.select" />}
      />

      {error && <div className="alert alert--warning">{error}</div>}

      <Loading fullPage isLoading={!user || loading}>
        <DashboardLocalisation
          localisation={selectedLanguage}
          handleLanguageChange={handleLanguageChange}
        />
      </Loading>

      <div className="contentarea__actionbar">
        <button
          className="btn btn--primary btn--med"
          onClick={handleSave}
          disabled={loading}
        >
          <FormattedMessage id="button.save" />
        </button>
      </div>
    </section>
  );
};

export default connect<ReduxProps, DispatchProps, null, ApplicationState>(
  state => ({
    users: state.users.items,
    user: state.auth.user,
  }),
  {
    updateUser: UserThunks.updateUser,
    getAllUsers: UserThunks.getAllUsers,
  },
)(SettingsLocalisation);
