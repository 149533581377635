enum Actions {
  Change = 'rewards/CHANGE',
  UpdateRewards = 'rewards/UPDATE_REWARDS',
  UpdateRedeemedRewards = 'rewards/UPDATE_REDEEMED_REWARDS',
  Error = 'rewards/ERROR',
}

export const RewardActionTypes = {
  ...Actions,
};
