import { createSelector } from 'reselect';

import { ApplicationState } from 'modules/app-state';
import { getSearchQuery, applySearchParams } from 'modules/search';

import { UsersObject, UserFilterOption } from '../models';

const getUsers = (state: ApplicationState) => state.users.items;

export const searchUsers = createSelector(
  [getUsers, getSearchQuery],
  (content, query) =>
    content.filter(({ id, firstName, lastName, email }) =>
      applySearchParams(
        [id, email, firstName, lastName, `${firstName} ${lastName}`],
        query,
      ),
    ),
);

export const getUsersObject = createSelector([getUsers], users => {
  const usersObject: UsersObject = {};
  users.forEach(user => (usersObject[user.id] = user));
  return usersObject;
});

export const usersFilterOptions = createSelector([getUsers], users => {
  let usersOptions: UserFilterOption[] = [];
  users.forEach(user => {
    if (user.firstName && user.lastName) {
      usersOptions = [
        ...usersOptions,
        { value: user.id, label: `${user.firstName} ${user.lastName}` },
      ];
    }
  });
  return usersOptions;
});
