import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link, NavLink, useHistory } from 'react-router-dom';

import { AuthenticationThunkActions } from 'modules/authentication';
import { ApplicationState } from 'modules/app-state';
import { Logo, MenuModule } from 'modules/settings/models';
import { User } from 'modules/users';

import { NavDropdown } from './NavDropdown';

import {
  adminOnlyRoutes,
  authenticatedRoutes,
} from '../const/authenticatedRoutes';
import { AppRoute, RoutePath } from '../models';

interface ReduxProps {
  isLoggedIn: boolean;
  logo?: Logo;
  user?: User;
  enabledModules: MenuModule[];
}

interface DispatchProps {
  logout: VoidFunction;
}

type Props = DispatchProps & ReduxProps;

const Navigation: React.FC<Props> = ({
  logout,
  isLoggedIn,
  logo,
  user,
  enabledModules,
}) => {
  const history = useHistory();
  if (!isLoggedIn) {
    return null;
  }

  const renderNavigationMenu = () => {
    if (!user) {
      return;
    }

    const navigationRoutes: AppRoute[] = [];
    authenticatedRoutes.forEach(route => {
      if (enabledModules) {
        enabledModules.forEach(mod => {
          if (route.title === mod.title && mod.showInMenu) {
            navigationRoutes.push(route);
          }
        });
      } else {
        navigationRoutes.push(route);
      }
    });

    if (user.role === 'administrator') {
      navigationRoutes.push(adminOnlyRoutes[0]);
    }

    return navigationRoutes.map(route => {
      if (!route.childLinks) {
        return (
          <li key={route.path} className="nav__list__item">
            <NavLink to={route.path} className="nav__link">
              <FormattedMessage id={route.key} />
            </NavLink>
          </li>
        );
      }

      return (
        <NavDropdown
          title={<FormattedMessage id={route.key} />}
          key={route.path}
          childLinks={route.childLinks}
        />
      );
    });
  };

  const handleLogout = () => {
    logout();
    history.push(RoutePath.Login);
  };

  return (
    <div className="masterlayout__aside">
      <aside className="sidebar">
        <div className="sidebar__top">
          <div className="sidebar__branding">
            {logo && logo.imageUrl && navigator.onLine && (
              <Link to={RoutePath.Summary}>
                <img src={logo.imageUrl} alt="Feedback" />
              </Link>
            )}
            {!navigator.onLine && (
              <Link to={RoutePath.Summary}>
                <img
                  src={require('assets/images/styria-white.jpeg')}
                  alt="Feedback"
                />
              </Link>
            )}
          </div>
          <div className="sidebar__nav">
            <nav className="nav">
              <ul className="nav__list">{renderNavigationMenu()}</ul>
            </nav>
          </div>
        </div>
        <div className="sidebar__bottom">
          <div className="sidebar__version">
            <FormattedMessage id="dashboard.version" />:{' '}
            {process.env.REACT_APP_VERSION}
          </div>
          <button className="btn sidebar__logout" onClick={handleLogout}>
            <FormattedMessage id="button.logout" />
          </button>
        </div>
      </aside>
    </div>
  );
};

export default connect<
  ReduxProps,
  DispatchProps,
  React.FC<Props>,
  ApplicationState
>(
  state => ({
    isLoggedIn: state.auth.isLoggedIn,
    logo: state.settings.logo,
    user: state.auth.user,
    enabledModules: state.settings.modules,
  }),
  {
    logout: AuthenticationThunkActions.logout,
  },
)(Navigation);
