enum Actions {
  Change = 'authentication/CHANGE',
  Login = 'authentication/LOGIN',
  Error = 'authentication/ERROR',
  Logout = 'authentication/LOGOUT',
}

export const AuthenticationActionTypes = {
  ...Actions,
};
