import React from 'react';
import Html, { Rule } from 'slate-html-serializer';

export const HTMLSerializer = {
  init() {
    function getSerializerRules(): Rule[] {
      return [
        {
          deserialize(el, next) {
            const type = el.tagName.toLowerCase();
            if (!type) {
              return;
            }

            if (type === 'img' || type === 'video') {
              return {
                object: 'block',
                type,
                data: {
                  src: el.getAttribute('src'),
                  alt: el.getAttribute('alt'),
                  className: el.getAttribute('class'),
                },
              };
            }

            if (type === 'gallery') {
              return {
                object: 'block',
                type,
                data: {
                  dataId: el.getAttribute('data-id'),
                  dataTitle: el.getAttribute('data-title'),
                  className: el.getAttribute('class'),
                },
              };
            }

            if (type === 'p') {
              return {
                object: 'block',
                type,
                data: {
                  className: el.getAttribute('class'),
                },
                nodes: next(el.childNodes),
              };
            }

            if (type !== 'p') {
              return {
                object: 'mark',
                type,
                nodes: next(el.childNodes),
              };
            }

            return;
          },
          serialize(obj, children) {
            switch (obj.object) {
              case 'block':
                if (obj.type === 'image' || obj.type === 'img') {
                  return React.createElement('img', {
                    className: obj.data.get('className'),
                    src: obj.data.get('src'),
                    alt: '',
                  });
                }
                if (obj.type === 'video') {
                  return React.createElement('video', {
                    className: obj.data.get('className'),
                    src: obj.data.get('src'),
                  });
                }
                if (obj.type === 'gallery') {
                  return React.createElement('gallery', {
                    className: obj.data.get('className'),
                    'data-id': obj.data.get('dataId'),
                    'data-title': obj.data.get('dataTitle'),
                  });
                }
                return React.createElement(
                  'p',
                  { className: obj.data.get('className') },
                  children,
                );

              case 'mark':
                return React.createElement(obj.type, {}, children);

              default:
                return;
            }
          },
        },
      ];
    }

    return new Html({ rules: getSerializerRules() });
  },
};
