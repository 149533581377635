import { ActionUnion } from 'modules/app-state';

import { FAQActions } from './actions';
import { FAQActionTypes } from './types';

import { FAQ } from '../models';

export interface FAQState {
  readonly loading: boolean;
  readonly error?: string;
  readonly items: FAQ[];
}

const INITIAL_STATE: FAQState = {
  loading: false,
  error: undefined,
  items: [],
};

export default (
  state: FAQState = INITIAL_STATE,
  action: ActionUnion<typeof FAQActions>,
): FAQState => {
  switch (action.type) {
    case FAQActionTypes.Change:
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case FAQActionTypes.Update:
      return {
        ...state,
        loading: false,
        error: undefined,
        items: action.payload.faqs,
      };

    case FAQActionTypes.Error:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state || INITIAL_STATE;
  }
};
