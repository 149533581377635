import { Dispatch } from 'redux';

import { ApplicationState } from 'modules/app-state';

import { SettingsActions } from './actions';

import { MenuModule, Logo } from '../models';
import { SettingsService } from '../services';

const getModulesAsync = () => async (
  dispatch: Dispatch,
  getState: () => ApplicationState,
) => {
  if (getState().settings.modules.length) {
    return;
  }

  dispatch(SettingsActions.change());

  const successFunction = (data: { modules: MenuModule[] }) => {
    dispatch(SettingsActions.updateModules(data.modules));
  };

  const errorFunction = (error: string) =>
    dispatch(SettingsActions.error(error));

  const listenerProps = {
    successFunction,
    errorFunction,
  };

  SettingsService.Database.getByIdAsync('displayed-modules', listenerProps);

  return;
};

const getLogoAsync = () => async (
  dispatch: Dispatch,
  getState: () => ApplicationState,
) => {
  if (getState().settings.logo) {
    return;
  }

  dispatch(SettingsActions.change());

  const successFunction = (data: Logo) => {
    dispatch(SettingsActions.updateLogo(data));
  };

  const errorFunction = (error: string) =>
    dispatch(SettingsActions.error(error));

  const listenerProps = {
    successFunction,
    errorFunction,
  };

  SettingsService.Database.getByIdAsync('logo', listenerProps);

  return;
};

const saveAllModules = (modules: MenuModule[]) => async (
  dispatch: Dispatch,
) => {
  dispatch(SettingsActions.change());

  SettingsService.Database.updateAsync({ id: 'displayed-modules', modules });
};

const saveLogo = (image: File) => async (
  dispatch: Dispatch,
  getState: () => ApplicationState,
) => {
  dispatch(SettingsActions.change());

  const res = await new Promise(
    (resolve: (url: string) => void, reject: VoidFunction) => {
      const uploadTask = SettingsService.Storage.addLogo(image);
      if (!uploadTask) {
        resolve('');
        return;
      }

      uploadTask.on(
        'state_changed',
        null,
        () => reject(),
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then(downloadUrl => {
            resolve(downloadUrl);
          });
        },
      );
    },
  );

  const logo = getState().settings.logo;
  if (!logo) {
    return;
  }

  logo.imageUrl = res;

  SettingsService.Database.addAsync(logo);
};

const removeLogo = () => (
  dispatch: Dispatch,
  getState: () => ApplicationState,
) => {
  dispatch(SettingsActions.change());

  const logo = getState().settings.logo;

  if (!logo) {
    return;
  }

  SettingsService.Storage.removeItem('logo.jpeg');

  const emptyLogo = {
    id: 'logo',
    imageUrl: '',
  };

  SettingsService.Database.addAsync(emptyLogo);
};

export const SettingsThunks = {
  getModulesAsync,
  saveAllModules,
  getLogoAsync,
  saveLogo,
  removeLogo,
};
