import React from 'react';
import { FormattedMessage } from 'react-intl';

import { MenuModule } from '../models';

interface Props {
  modules: MenuModule[];
  handleModulesChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const EnabledModules: React.FC<Props> = ({
  modules,
  handleModulesChange,
}) => (
  <ul className="l-boxed">
    {modules.map(item => (
      <li key={item.title} className="l-boxed__item g-spacebetween">
        <p className="t-zeta">
          <FormattedMessage id={item.key} />
        </p>
        <div className="onoffswitch">
          <input
            type="checkbox"
            name="onoffswitch"
            className="onoffswitch__checkbox"
            id={item.title}
            checked={item.showInMenu}
            data-title={item.title}
            onChange={handleModulesChange}
          />
          <label className="onoffswitch__label" htmlFor={item.title}>
            <span className="onoffswitch__inner" />
            <span className="onoffswitch__switch" />
          </label>
        </div>
      </li>
    ))}
  </ul>
);
