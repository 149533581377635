import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { RoutePath } from 'modules/navigation';

interface Props {
  title: string | React.ReactElement;
  content?: React.ReactElement;
  linkTo?: RoutePath;
}

export const SummaryCard: React.FC<Props> = ({ title, content, linkTo }) => (
  <div className="g-blocks__item">
    <div className="summarycard">
      <div className="summarycard__header">
        <h1 className="t-zeta">{title}</h1>
      </div>

      {content}

      <div className="summarycard__footer">
        <Link className="btn btn--primary btn--sml" to={linkTo || ''}>
          <FormattedMessage id="summary.btn.seemore" />
        </Link>
      </div>
    </div>
  </div>
);
