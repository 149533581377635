import React from 'react';
import { MdClose } from 'react-icons/md';
import { FormattedMessage } from 'react-intl';

import { useFormatMessage } from 'modules/localisation';

import { UserLevel } from '../models';

interface OwnProps {
  id: number;
  removeLevel?: (index: number) => void;
  updateLevel?: (index: number, level: UserLevel) => void;
  setUserLevelsDirty?: React.Dispatch<React.SetStateAction<boolean>>;
}

type Props = UserLevel & OwnProps;

export const UserLevelsItem: React.FC<Props> = ({ title, points, id }) => {
  const [formatMessage] = useFormatMessage();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, type } = event.currentTarget;
    if (type === 'number' && parseInt(value) > 1000000) {
      return;
    }

    // updateLevel(id, {
    //   title,
    //   points,
    //   level,
    //   [name]: type === 'number' ? parseInt(value, 10) || 0 : value,
    // });

    // setUserLevelsDirty(true);
  };

  const removeCard = () => {
    // removeLevel(id);
    // setUserLevelsDirty(true);
  };

  return (
    <div className="v-levels__list__item">
      <div className="field">
        <label className="field__lbl">
          <FormattedMessage id="form.title" />
        </label>
        <input
          className="input input--med"
          type="text"
          name="title"
          placeholder={formatMessage('user-levels.title.placeholder')}
          value={title}
          onChange={handleInputChange}
        />
      </div>

      <div className="field">
        <label className="field__lbl">
          <FormattedMessage id="form.points" />
        </label>
        <input
          className="input input--sml"
          type="number"
          min={0}
          max={1000000}
          name="points"
          value={points}
          onChange={handleInputChange}
        />
      </div>

      <div className="field">
        <label className="field__lbl">
          <FormattedMessage id="form.level" />
        </label>
        <input
          disabled
          className="input input--sml"
          type="number"
          value={id + 1}
          onChange={handleInputChange}
        />
      </div>

      <div className="v-levels__list__item__remove">
        <button onClick={removeCard} className="btn btn--dark btn--close">
          <MdClose size={16} />
        </button>
      </div>
    </div>
  );
};
