import 'firebase/firestore';
import 'firebase/functions';
import { User } from 'modules/users/models';

import { FirebaseService } from './FirebaseService';

import { QueryFilter, ListenerProps } from '../models';

export class FirebaseDatabaseService<T extends any> {
  private firebase = FirebaseService.Instance;
  private firestore = this.firebase.firestore();
  private functions = this.firebase.functions();
  private collection: firebase.firestore.CollectionReference;

  constructor(collection: string) {
    this.collection = this.firestore.collection(collection);
  }

  async filterAsync(
    queryParams?: QueryFilter[],
    listenerProps?: ListenerProps,
    forceId?: boolean,
  ): Promise<string | T[] | VoidFunction> {
    let queryRef: firebase.firestore.Query | undefined;

    if (queryParams) {
      for (const query of queryParams) {
        queryRef = queryRef
          ? queryRef.where(query.field, query.operator, query.value)
          : this.collection.where(query.field, query.operator, query.value);
      }
    }

    const data = queryRef || this.collection;

    /** If this should be a listener function */
    if (listenerProps) {
      return data.onSnapshot(
        { includeMetadataChanges: true },
        snapshot => {
          const items = snapshot.docs.map(document => {
            const convertedDocument = document.data() as T;
            if (!convertedDocument.id || forceId) {
              convertedDocument.id = document.id;
            }
            return convertedDocument;
          });

          listenerProps.successFunction(items);
        },
        (error: Error) => listenerProps.errorFunction(error.message),
      );
    }

    return data
      .get()
      .then(snapshot =>
        snapshot.docs.map(document => ({
          id: document.id,
          ...(document.data() as any),
        })),
      )
      .catch((error: firebase.FirebaseError) => error.message);
  }

  async getAllAsync(): Promise<string | T[]> {
    return this.collection
      .get()
      .then(snapshot =>
        snapshot.docs.map(document => ({
          id: document.id,
          ...(document.data() as T),
        })),
      )
      .catch((error: firebase.FirebaseError) => error.message);
  }

  /** Add new documents or collection. */
  async addAsync(entity: T | T[]) {
    if (Array.isArray(entity)) {
      return entity.forEach(doc => {
        if (doc.id) {
          this.collection.doc(doc.id).set(this.removeId(doc));
        } else {
          this.collection.add(doc);
        }
      });
    }

    if (entity.id) {
      return await this.collection.doc(entity.id).set(this.removeId(entity));
    }

    return await this.collection.add(this.removeId(entity));
  }

  removeAsync(entityId: string) {
    return this.collection.doc(entityId).delete();
  }

  removeSubcollectionAsync(
    entityId: string,
    subcollection: string,
    document: string,
  ) {
    return this.collection
      .doc(entityId)
      .collection(subcollection)
      .doc(document)
      .delete();
  }

  async getByIdAsync(entityId: string, listenerProps?: ListenerProps) {
    // If this should be listener.
    if (listenerProps) {
      return this.collection.doc(entityId).onSnapshot(
        { includeMetadataChanges: true },
        snapshot => {
          const result = { id: snapshot.id, ...(snapshot.data() as T) };
          listenerProps.successFunction(result);
        },
        (error: Error) => listenerProps.errorFunction(error.message),
      );
    }

    return this.collection
      .doc(entityId)
      .get()
      .then(snapshot => ({ id: snapshot.id, ...(snapshot.data() as T) }))
      .catch((error: firebase.FirebaseError) => error.message);
  }

  // Update fields in existing doc.
  updateAsync(entity: T) {
    return this.collection.doc(entity.id).update(this.removeId(entity));
  }

  // call cloud function for updating users data in firestore and auth
  async updateUserFirestoreAuth(user: User) {
    const updateUserInFirestoreAndAuth = this.functions.httpsCallable(
      'updateUserInFirestoreAndAuth',
    );
    return await updateUserInFirestoreAndAuth({ ...user }).then(
      result => result.data,
    );
  }

  async inviteUser(
    firstName: string,
    lastName: string,
    email: string,
    role: string,
  ) {
    const createUserByEmail = this.functions.httpsCallable('createUserByEmail');
    const id = await createUserByEmail({
      firstName,
      lastName,
      email,
      role,
    }).then(result => result.data);

    return id;
  }

  async getSubcollection(id: string, subcollection: string): Promise<any> {
    return this.collection
      .doc(id)
      .collection(subcollection)
      .get()
      .then(snapshot =>
        snapshot.docs.map(document => ({
          ...document.data(),
        })),
      );
  }

  private removeId(entity: T) {
    const tempEntity = { ...entity };
    delete tempEntity.id;

    return tempEntity;
  }
}
