import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Select, { ValueType } from 'react-select';
import { orderBy } from 'lodash';

import { ApplicationState } from 'modules/app-state';
import {
  RedeemReward,
  RedeemRewardItem,
  RewardThunks,
  ShippedOptions,
} from 'modules/rewards';
import { OrderOptions, SearchActions, SearchQuery } from 'modules/search';
import { User } from 'modules/users';
import {
  useModal,
  usePagination,
  Loading,
  EmptyState,
  PageHeader,
} from 'modules/app-ui';

import { sortRedeemedRewardsByShipped } from '../redux/selectors';

interface DispatchProps {
  getAll: VoidFunction;
  search: SearchQuery;
  removeRedeemedReward: (entityId: string) => void;
}

interface ReduxProps {
  redeemRewards: RedeemReward[];
  users: User[];
  redeemRewardsAreChanging: boolean;
  error?: string;
}

interface OwnProps {
  forSummaryModule?: boolean;
}

type Props = DispatchProps & ReduxProps & OwnProps;

const RedeemedRewardList: React.FC<Props> = ({
  getAll,
  redeemRewards,
  redeemRewardsAreChanging,
  users,
  error,
  search,
  removeRedeemedReward,
  forSummaryModule,
}) => {
  const [toggleModal, Modal] = useModal(removeRedeemedReward);
  const [filteredRewards, setFilteredRewards] = useState<RedeemReward[]>(
    redeemRewards,
  );
  const [chosenDateFilter, setChosenDateFilter] = useState(OrderOptions[1]);
  const [chosenShippedFilter, setChosenShippedFilter] = useState(
    ShippedOptions[0],
  );

  const handleFilterChange = (
    value: ValueType<{
      value: string;
      label: string | React.ReactElement;
    }>,
  ) => setChosenDateFilter(value as any);

  const handleShippedFilterChange = (
    value: ValueType<{
      value: boolean | null;
      label: string | React.ReactElement;
    }>,
  ) => setChosenShippedFilter(value as any);

  useEffect(() => {
    getAll();
  }, [getAll]);

  useEffect(() => {
    const filteredRewards = redeemRewards.filter(
      reward =>
        !chosenShippedFilter.value ||
        reward.shipped === chosenShippedFilter.value,
    );
    const sortedRewards = orderBy(
      filteredRewards,
      [reward => reward.date],
      chosenDateFilter.value,
    );

    setFilteredRewards(sortedRewards);
  }, [chosenDateFilter, chosenShippedFilter, redeemRewards]);

  const [pagedItems, Pagination] = usePagination(filteredRewards);

  if (forSummaryModule) {
    const size = 5;

    return (
      <section>
        <Loading isLoading={redeemRewardsAreChanging || !users.length}>
          {!pagedItems.length && <EmptyState showImage />}

          {pagedItems.slice(0, size).map((item, index) => (
            <RedeemRewardItem
              key={`${item.id}-${index}`}
              redeemReward={new RedeemReward(item)}
              user={users.find(user => user.id === item.userId)}
              toggleModal={toggleModal}
            />
          ))}
        </Loading>

        <Modal />
      </section>
    );
  }

  return (
    <section className="contentarea">
      <PageHeader
        onSearch={search}
        title={<FormattedMessage id="redeemed-rewards" />}
      />

      {error && <div className="alert alert--warning">{error}</div>}

      <div className="redeemed-rewards__filters">
        <div className="field">
          <label htmlFor="order" className="field__lbl">
            <FormattedMessage id="form.date-order" />
          </label>
          <Select
            value={chosenDateFilter}
            onChange={handleFilterChange}
            options={OrderOptions}
            id="order"
            className="input--select s-bottom--lrg"
          />
        </div>

        <div className="field">
          <label htmlFor="Shipped" className="field__lbl">
            <FormattedMessage id="form.shipped" />
          </label>
          <Select
            value={chosenShippedFilter}
            onChange={handleShippedFilterChange}
            options={ShippedOptions}
            className="input--select"
            placeholder="Select action"
            id="Shipped"
          />
        </div>
      </div>

      <Loading fullPage isLoading={redeemRewardsAreChanging || !users.length}>
        {!redeemRewards.length && <EmptyState showImage />}

        {pagedItems.map((item, index) => {
          return (
            <RedeemRewardItem
              key={`${item.id}-${index}`}
              redeemReward={item}
              user={users.find(user => user.id === item.userId)}
              toggleModal={toggleModal}
            />
          );
        })}

        <Pagination />
        <Modal />
      </Loading>
    </section>
  );
};

export default connect<ReduxProps, DispatchProps, OwnProps, ApplicationState>(
  state => ({
    users: state.users.items,
    redeemRewards: sortRedeemedRewardsByShipped(state),
    redeemRewardsAreChanging: state.rewards.loading,
    error: state.rewards.error,
  }),
  {
    getAll: RewardThunks.getAllRedeemedRewardsAsync,
    removeRedeemedReward: RewardThunks.removeRedeemed,
    search: SearchActions.querySearch,
  },
)(RedeemedRewardList);
