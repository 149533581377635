import React from 'react';
import { Link } from 'react-router-dom';
import { MdDelete } from 'react-icons/md';
import { FormattedMessage } from 'react-intl';

import { RoutePath } from 'modules/navigation';

import { Reward } from '../models';

interface Props {
  reward: Reward;
  virtual?: boolean;
  toggleModal: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const RewardItem: React.FC<Props> = ({
  reward,
  toggleModal,
  virtual,
}) => {
  const mainPath = virtual
    ? `${RoutePath.VirtualRewards}`
    : `${RoutePath.RewardsAll}`;

  const linkTo = `${mainPath}/${reward.slug}`;

  return (
    <div className="itemlist--simple__item">
      <div className="itemlist--simple__item__content">
        <Link to={linkTo}>
          <h2 className="t-zeta">
            <strong>{reward.title}</strong>
          </h2>
        </Link>
      </div>

      <div className="itemlist--simple__item__action">
        <div className="btn-group">
          <Link className="btn btn--sml btn--secondary" to={linkTo}>
            <FormattedMessage id="button.edit" />
          </Link>

          <button
            data-id={reward.id}
            data-title={reward.title}
            className="btn btn--ghost btn--sml btn--hasIcon"
            onClick={toggleModal}
          >
            <MdDelete size={16} />
          </button>
        </div>
      </div>
    </div>
  );
};
