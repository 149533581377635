import { ErrorPermissions, ErrorGeneral } from 'modules/error-handling';

import { AppRoute, RoutePath } from '../models';

export const errorRoutes: AppRoute[] = [
  {
    key: 'unauthorized',
    accessbilityLevel: 'public',
    component: ErrorPermissions,
    exact: true,
    path: RoutePath.Unauthorized,
    title: 'Not authorized',
  },
  {
    // Fallback route, always render last
    key: 'error',
    fallbackRoute: true,
    accessbilityLevel: 'public',
    component: ErrorGeneral,
    path: RoutePath.Error,
    title: 'Error!',
  },
];
