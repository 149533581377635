import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { ApplicationState } from 'modules/app-state';
import { FAQThunks, FAQ, FAQItem } from 'modules/faq';
import { RoutePath } from 'modules/navigation';
import { PageHeaderAction } from 'modules/app-ui/models';
import { SearchQuery, SearchActions } from 'modules/search';
import {
  PageHeader,
  useModal,
  usePagination,
  Loading,
  EmptyState,
} from 'modules/app-ui';

import { searchFAQ } from '../redux/selectors';

interface DispatchProps {
  getAll: VoidFunction;
  removeFAQ: (id: string) => void;
  search: SearchQuery;
}

interface ReduxProps {
  faqs: FAQ[];
  error?: string;
  faqsAreChanging: boolean;
}

type Props = DispatchProps & ReduxProps;

const FAQList: React.FC<Props> = ({
  getAll,
  error,
  faqsAreChanging,
  removeFAQ,
  faqs,
  search,
}) => {
  useEffect(() => {
    getAll();
  }, [getAll]);

  const [toggleModal, Modal] = useModal(removeFAQ);
  const [pagedItems, Pagination] = usePagination(faqs);

  return (
    <section className="contentarea">
      <PageHeader
        onSearch={search}
        title={<FormattedMessage id="faq" />}
        actions={[
          new PageHeaderAction(
            (<FormattedMessage id="faq.btn.add" />),
            `${RoutePath.FAQ}/new`,
          ),
        ]}
      />

      {error && <div className="alert alert--warning">{error}</div>}

      <Loading fullPage isLoading={faqsAreChanging}>
        {faqs && !faqs.length && <EmptyState showImage />}

        {pagedItems.map(faq => (
          <FAQItem faq={faq} key={faq.id} toggleModal={toggleModal} />
        ))}

        <Pagination />
        <Modal />
      </Loading>
    </section>
  );
};

export default connect<ReduxProps, DispatchProps, null, ApplicationState>(
  state => ({
    faqs: searchFAQ(state),
    error: state.faq.error,
    faqsAreChanging: state.faq.loading,
  }),
  {
    removeFAQ: FAQThunks.remove,
    getAll: FAQThunks.getAllAsync,
    search: SearchActions.querySearch,
  },
)(FAQList);
