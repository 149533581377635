import { ActionUnion } from 'modules/app-state';

import { UserActionTypes } from './types';
import { UserActions } from './actions';

import { User, UserLevel } from '../models';

export interface UsersState {
  readonly items: User[];
  readonly loading: boolean;
  readonly error?: string;
  readonly userLevels?: { id: string; levels: UserLevel[] };
}

const INITIAL_STATE: UsersState = {
  items: [],
  loading: false,
  error: undefined,
  userLevels: undefined,
};

export default (
  state: UsersState = INITIAL_STATE,
  action: ActionUnion<typeof UserActions>,
): UsersState => {
  switch (action.type) {
    case UserActionTypes.Change:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case UserActionTypes.Update:
      return {
        ...state,
        items: action.payload.users,
        loading: false,
        error: undefined,
      };
    case UserActionTypes.Error:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state || INITIAL_STATE;
  }
};
