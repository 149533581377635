import { FirebaseDatabaseService, StorageService } from 'modules/firebase';

import { Article } from '../models';

const moduleName = 'articles';

export const ArticleService = {
  Database: new FirebaseDatabaseService<Article>(moduleName),
  Storage: new StorageService<File>(moduleName + '/'),
};
