import { createAction } from 'modules/app-state';

import { GamificationActionTypes } from './types';

import { Point } from '../models';

export const GamificationActions = {
  change: () => createAction(GamificationActionTypes.Change),

  updatePoints: (points: Point[]) =>
    createAction(GamificationActionTypes.UpdatePoints, { points }),

  error: (error: string) =>
    createAction(GamificationActionTypes.Error, { error }),
};
