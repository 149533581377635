import { ActionUnion } from 'modules/app-state';

import { ProductsActions } from './actions';
import { ProductActionTypes } from './types';

import { Product } from '../models';

export interface ProductsState {
  readonly loading: boolean;
  readonly error?: string;
  readonly items: Product[];
}

const INITIAL_STATE: ProductsState = {
  loading: false,
  error: undefined,
  items: [],
};

export default (
  state: ProductsState = INITIAL_STATE,
  action: ActionUnion<typeof ProductsActions>,
): ProductsState => {
  switch (action.type) {
    case ProductActionTypes.Change:
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case ProductActionTypes.Update:
      return {
        ...state,
        loading: false,
        error: undefined,
        items: action.payload.products,
      };

    case ProductActionTypes.Error:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    default:
      return state || INITIAL_STATE;
  }
};
