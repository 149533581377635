import React, { useState, useEffect } from 'react';
import { SingleDatePicker } from 'react-dates';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { MdDelete, MdFindInPage } from 'react-icons/md';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import 'react-dates/lib/css/_datepicker.css';
import Barcode from 'react-barcode';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import {
  PageHeaderAction,
  useModal,
  usePageModified,
  EmptyState,
  Loading,
  PageHeader,
  PageGuard,
} from 'modules/app-ui';
import { ApplicationState } from 'modules/app-state';
import { RoutePath } from 'modules/navigation';
import { Product, ProductThunks } from 'modules/products';
import { useFormatMessage } from 'modules/localisation';
import { InsertImageModal } from 'modules/editor';
import { MediaThunks } from 'modules/media';

interface DispatchProps {
  getAll: VoidFunction;
  saveProduct: (product: Product, oldProduct: Product) => void;
  removeProduct: (id: string) => void;
  uploadMedia: (
    title: string,
    mediaType: 'Gallery' | 'Image' | 'Video',
    files: File[],
  ) => Promise<void | firebase.firestore.DocumentReference>;
  getAllMedia: VoidFunction;
}

interface ReduxProps {
  product: Product;
  productsAreChanging: boolean;
  mediaIsChanging: boolean;
}

type Props = DispatchProps & ReduxProps & RouteComponentProps<any>;

const ProductDetails: React.FC<Props> = ({
  product,
  getAll,
  saveProduct,
  productsAreChanging,
  removeProduct,
  mediaIsChanging,
  uploadMedia,
  getAllMedia,
}) => {
  const history = useHistory();
  const redirectToList = () => history.push(RoutePath.Products);

  const [toggleModal, Modal] = useModal(removeProduct, redirectToList);
  const [state, setState] = useState(product);
  const [formatMessage] = useFormatMessage();
  const [error, setError] = useState<any>();
  const [focusedDate, setFocusedDate] = useState(false);
  const [coverPhotoModal, toggleCoverPhotoModal] = useState(false);
  const [coverPhotoExists, setCoverPhotoExists] = useState(true);
  const [pageModified, setPageModified] = usePageModified(
    'product-contentarea',
  );

  const checkImage = (imageSrc: string) => {
    const img = new Image();
    img.src = imageSrc;
    img.onload = () => setCoverPhotoExists(true);
    img.onerror = () => setCoverPhotoExists(false);
  };

  useEffect(() => {
    getAll();
    getAllMedia();
  }, [getAll, getAllMedia]);

  useEffect(() => {
    setState(product);
  }, [product]);

  useEffect(() => {
    if (state?.imageUrl) {
      checkImage(state.imageUrl);
    }
  }, [state]);

  if (!state || !product) {
    if (!productsAreChanging) {
      return <EmptyState showImage />;
    }

    return <Loading isLoading fullPage />;
  }

  const {
    id,
    name,
    actionPoints,
    barcode,
    description,
    imageUrl,
    leadText,
    activeDate,
    url,
  } = state;

  const showCoverPhotoModal = async () => {
    await toggleCoverPhotoModal(!coverPhotoModal);
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.currentTarget;

    setState({
      ...state,
      [name]: value,
    });
  };

  const isFormValid = () => {
    if (!state.name) {
      setError(
        <>
          <strong>Missing information:</strong>
          {!state.name && <div>Name</div>}
        </>,
      );
      return false;
    }

    return true;
  };

  const saveProductCard = async () => {
    if (!isFormValid()) {
      return;
    }

    await setPageModified(false);
    const data = {
      ...state,
    };

    const error = saveProduct(data, product) as string | undefined;
    if (error) {
      setError(<strong>{error}</strong>);

      return;
    }

    toast.success(
      state.id
        ? formatMessage('toast.product.update', state.name)
        : formatMessage('toast.product.save', state.name),
    );

    redirectToList();
  };

  const handleImageChange = (imageUrl: string) => {
    setState({
      ...state,
      imageUrl,
    });

    setPageModified(true);
  };

  const handleDateChange = (date: moment.Moment | null) => {
    if (!date) {
      return;
    }

    setState({
      ...state,
      activeDate: +moment(date).toDate(),
    });

    setPageModified(true);
  };

  const removeImage = () => {
    setState({
      ...state,
      imageUrl: '',
    });

    setPageModified(true);
  };

  const onFocusChange = (arg: { focused: boolean | null }) => {
    setFocusedDate(arg.focused || false);
  };

  return (
    <section className="contentarea" id="product-contentarea">
      <PageHeader
        title={
          <FormattedMessage
            id={
              id === 'new' ? 'products.create.title' : 'products.details.title'
            }
          />
        }
        backAction={
          new PageHeaderAction(
            (<FormattedMessage id="products.back-to-list" />),
            RoutePath.Products,
          )
        }
      />

      {error && (
        <div className="alert alert--warning s-bottom--med">{error}</div>
      )}

      <div className="card">
        <div className="g-split">
          <div className="g-split__item">
            <div className="field">
              <label htmlFor="name" className="field__lbl t-mandatory">
                <FormattedMessage id="form.name" />
              </label>
              <FormattedMessage id="form.name.placeholder">
                {placeholder => (
                  <input
                    type="text"
                    className="input input--med"
                    name="name"
                    id="name"
                    placeholder={placeholder as string}
                    value={name}
                    onChange={handleChange}
                  />
                )}
              </FormattedMessage>
            </div>
          </div>
          <div className="g-split__item">
            <div className="field">
              <label htmlFor="actionPoints" className="field__lbl">
                <FormattedMessage id="form.action-points" />
              </label>
              <input
                type="number"
                min="0"
                className="input input--med"
                data-type="actionPoints"
                id="actionPoints"
                name="actionPoints"
                value={
                  typeof actionPoints === 'number'
                    ? actionPoints
                    : parseInt(actionPoints)
                }
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="g-split__item">
            <div className="field">
              <label htmlFor="leadText" className="field__lbl">
                <FormattedMessage id="form.lead-text" />
              </label>
              <input
                type="text"
                min="0"
                className="input input--med"
                data-type="leadText"
                id="leadText"
                name="leadText"
                value={leadText}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="g-split__item">
            <div className="field">
              <label htmlFor="url" className="field__lbl">
                <FormattedMessage id="form.url" />
              </label>
              <input
                type="text"
                min="0"
                className="input input--med"
                data-type="url"
                id="url"
                name="url"
                value={url}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        <div className="g-split">
          <div className="g-split__item">
            <div className="field">
              <label className="field__lbl">
                <FormattedMessage id="form.featured-image" />
              </label>

              <div className="imageuploader">
                <Loading isLoading={mediaIsChanging}>
                  {!imageUrl && (
                    <div className="imageuploader__image">
                      <div className="card t-center s-top--lrg">
                        <MdFindInPage
                          className="o-20 s-bottom--med"
                          size={64}
                        />
                        <p className="t-zeta o-60">
                          <FormattedMessage id="form.cover-photo.placeholder" />
                        </p>
                      </div>
                    </div>
                  )}

                  {imageUrl && coverPhotoExists && (
                    <>
                      <img src={imageUrl} alt="Not found" />

                      <div className="imageuploader__remove">
                        <button
                          className="btn btn--dark btn--close"
                          onClick={removeImage}
                        >
                          <MdDelete size={16} />
                        </button>
                      </div>
                    </>
                  )}
                  {imageUrl && !coverPhotoExists && (
                    <div className="s-top--lrg">
                      <EmptyState text="Image no loger exists" showImage />
                    </div>
                  )}
                  <div className="imageloader__action">
                    <button
                      onClick={showCoverPhotoModal}
                      className="btn btn--primary btn--med s-top--med"
                    >
                      <FormattedMessage
                        id={
                          !imageUrl
                            ? 'button.upload-image'
                            : 'button.change-image'
                        }
                      />
                    </button>
                  </div>
                </Loading>
              </div>
            </div>
          </div>

          <div className="g-split__item">
            <div className="field">
              <label htmlFor="barcode" className="field__lbl">
                <FormattedMessage id="form.barcode" />
              </label>
              <input
                type="text"
                min="0"
                className="input input--med"
                data-type="barcode"
                id="barcode"
                name="barcode"
                value={barcode}
                onChange={handleChange}
              />
            </div>

            {barcode && (
              <div className="field t-center">
                <Barcode value={barcode} />
              </div>
            )}

            <div className="field">
              <label htmlFor="active-date" className="field__lbl">
                <FormattedMessage id="form.publish-date" />
              </label>
              <SingleDatePicker
                date={activeDate ? moment(activeDate) : null}
                onDateChange={handleDateChange}
                focused={focusedDate}
                onFocusChange={onFocusChange}
                id="active-date"
                displayFormat="DD/MM/YYYY"
                numberOfMonths={1}
                openDirection="up"
                isOutsideRange={() => false}
                showDefaultInputIcon={true}
              />
            </div>
            {/* 
            <div className="field">
              <p className="t-zeta">Scanned:</p>
              <div className="onoffswitch">
                <input
                  type="checkbox"
                  name="onoffswitch"
                  className="onoffswitch__checkbox"
                  id="scanned"
                  checked={scanned}
                  data-title="scanned"
                  onChange={handleChange}
                  disabled
                />
                <label className="onoffswitch__label" htmlFor="scanned">
                  <span className="onoffswitch__inner" />
                  <span className="onoffswitch__switch" />
                </label>
              </div>
            </div> */}
          </div>
        </div>

        <div className="field">
          <label htmlFor="description" className="field__lbl t-mandatory">
            <FormattedMessage id="form.description" />
          </label>
          <textarea
            rows={4}
            name="description"
            id="description"
            onChange={handleChange}
            className="input input--textarea"
            value={description}
          />
        </div>

        <footer className="card__footer">
          <div className="btn-group">
            <button
              className="btn btn--primary btn--med"
              onClick={saveProductCard}
              disabled={!pageModified}
            >
              <FormattedMessage id="button.save" />
            </button>
            {id && (
              <button
                className="btn btn--ghost btn--med btn--hasIcon"
                onClick={toggleModal}
                data-id={id}
                data-title={name}
              >
                <MdDelete size={16} />
              </button>
            )}
          </div>
        </footer>
      </div>

      <InsertImageModal
        showImageModal={showCoverPhotoModal}
        imageModal={coverPhotoModal}
        uploadMedia={uploadMedia}
        externalSubmitHandler={handleImageChange}
      />
      <Modal />

      <PageGuard when={pageModified} />
    </section>
  );
};

export default connect<ReduxProps, DispatchProps, Props, ApplicationState>(
  (state, componentProps) => {
    const id = componentProps.match.params.id;

    return {
      product:
        id === 'new'
          ? new Product()
          : state.products.items.filter(item => item.id === id)[0],
      productsAreChanging: state.products.loading,
      mediaIsChanging: state.media.loading,
    };
  },
  {
    removeProduct: ProductThunks.removeProduct,
    getAll: ProductThunks.getAllAsync,
    saveProduct: ProductThunks.saveProduct,
    uploadMedia: MediaThunks.uploadMediaFromSlate as any,
    getAllMedia: MediaThunks.getAllAsync,
  },
)(ProductDetails);
