import { Role } from 'modules/authorization';

import { UserPrivate } from './UserPrivate';

export interface User {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  photoUrl?: string;
  phone?: string;
  position?: string;
  role?: Role;
  department?: string;
  team?: string;
  company?: string;
  active?: boolean;
  rating: number[];
  privateProfile: UserPrivate;
}

export class User {
  constructor(
    email: string,
    firstName: string,
    lastName: string,
    role?: Role,
    photoUrl?: string,
    phone?: string,
    id?: string,
    department?: string,
    team?: string,
    company?: string,
    active?: boolean,
    rating?: number[],
    privateProfile?: UserPrivate,
  ) {
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.active = active || true;

    if (photoUrl) {
      this.photoUrl = photoUrl;
    }
    if (department) {
      this.department = department;
    }
    if (team) {
      this.team = team;
    }
    if (company) {
      this.company = company;
    }

    if (phone) {
      this.phone = phone;
    }

    if (id) {
      this.id = id;
    }

    if (role) {
      this.role = role;
    }

    if (rating) {
      this.rating = rating;
    }

    if (privateProfile) {
      this.privateProfile = privateProfile;
    }
  }
}
