import { createAction } from 'modules/app-state';

import { BadgeActionTypes } from './types';

import { Badge } from '../models';

export const BadgeActions = {
  change: () => createAction(BadgeActionTypes.Change),

  update: (badges: Badge[]) =>
    createAction(BadgeActionTypes.Update, { badges }),

  error: (error: string) => createAction(BadgeActionTypes.Error, { error }),
};
