import { ActionUnion } from 'modules/app-state';

import { MediaActions } from './actions';
import { MediaActionTypes } from './types';

import { Media } from '../models';

export interface MediaState {
  readonly loading: boolean;
  readonly error?: string;
  readonly items: Media[];
  readonly filter: 'Gallery' | 'Image' | 'Video' | null;
}

const INITIAL_STATE: MediaState = {
  loading: true,
  error: undefined,
  items: [],
  filter: null,
};

export default (
  state: MediaState = INITIAL_STATE,
  action: ActionUnion<typeof MediaActions>,
): MediaState => {
  switch (action.type) {
    case MediaActionTypes.Change:
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case MediaActionTypes.Update:
      return {
        ...state,
        loading: false,
        error: undefined,
        items: action.payload.media,
      };

    case MediaActionTypes.Error:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case MediaActionTypes.ChangeFilter:
      return {
        ...state,
        filter: action.payload.filter,
      };

    default:
      return state || INITIAL_STATE;
  }
};
