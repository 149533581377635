import { createSelector } from 'reselect';
import { orderBy } from 'lodash';

import { ApplicationState } from 'modules/app-state';
import { applySearchParams, getSearchQuery } from 'modules/search';

const getProducts = (state: ApplicationState) => state.products.items;

const sortProductsByValidDate = createSelector([getProducts], product =>
  orderBy(product, [product => product.activeDate || true], 'desc'),
);

export const searchProducts = createSelector(
  [sortProductsByValidDate, getSearchQuery],
  (content, query) =>
    content.filter(({ name, description, id }) =>
      applySearchParams([name, description, id], query),
    ),
);
