import { createSelector } from 'reselect';
import { orderBy } from 'lodash';

import {
  applySearchParams,
  getSearchQuery,
  getOrderFilter,
} from 'modules/search';
import { ApplicationState } from 'modules/app-state';
import { getUsersObject } from 'modules/users/redux/selectors';

const getFeedback = (state: ApplicationState) => state.feedback.items;

export const searchFeedback = createSelector(
  [getFeedback, getSearchQuery, getUsersObject],
  (content, query, usersObject) =>
    content.filter(feedback => {
      const userFrom = usersObject[feedback.feedbackFrom];
      const userTo = usersObject[feedback.feedbackTo];
      if (!userFrom || !userTo) {
        return false;
      }

      return applySearchParams(
        [
          feedback.id,
          feedback.title,
          new Date(feedback.date).toDateString(),
          `${userFrom.firstName} ${userFrom.lastName}`,
          `${userTo.firstName} ${userTo.lastName}`,
        ],
        query,
      );
    }),
);

export const sortFeedbackByDate = createSelector(
  [searchFeedback, getOrderFilter],
  (feedback, orderFilter) => orderBy(feedback, ['date'], [orderFilter]),
);
