export interface PageHeaderAction {
  name: string | React.ReactElement;
  actionHandler?: string | VoidFunction | ((...arg: []) => void);
}

export class PageHeaderAction {
  constructor(
    name: string | React.ReactElement,
    actionHandler?: string | VoidFunction | ((...arg: []) => void),
  ) {
    this.name = name;
    this.actionHandler = actionHandler;
  }
}
