import React, { useEffect, useState } from 'react';
import { MdArrowForward, MdClose, MdDelete } from 'react-icons/md';
import { connect } from 'react-redux';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import { useHistory } from 'react-router-dom';

import {
  PageHeaderAction,
  useModal,
  EmptyState,
  Loading,
  PageHeader,
} from 'modules/app-ui';
import { RoutePath } from 'modules/navigation';
import { ApplicationState } from 'modules/app-state';
import { User, UsersService } from 'modules/users';

import 'react-dates/lib/css/_datepicker.css';
import { Feedback } from '../models';
import { FeedbackThunks } from '../redux';

interface DispatchProps {
  getAll: VoidFunction;
  removeFeedback: (id: string) => void;
}

interface ReduxProps {
  feedback?: Feedback;
  feedbackAreChanging: boolean;
}

type Props = DispatchProps & ReduxProps;

const FeedbackDetails: React.FC<Props> = ({
  getAll,
  feedback,
  removeFeedback,
  feedbackAreChanging,
}) => {
  const history = useHistory();

  const redirectToList = () => history.push(RoutePath.AllFeedbacks);

  const [toggleModal, Modal] = useModal(removeFeedback, redirectToList);
  const [users, setUsers] = useState({
    userFrom: new User('', '', ''),
    userTo: new User('', '', ''),
  });

  useEffect(() => {
    if (!feedback) {
      getAll();
    }
  }, [feedback, getAll]);

  useEffect(() => {
    if (!feedback || !feedback.feedbackFrom || !feedback.feedbackTo) {
      return;
    }

    const usersPromises: [Partial<User>?, Partial<User>?] = [];

    const promise1 = UsersService.Database.getByIdAsync(
      feedback.feedbackFrom,
    ).then(user => {
      usersPromises.push({ userFrom: user } as Partial<User>);
    });

    const promise2 = UsersService.Database.getByIdAsync(
      feedback.feedbackTo,
    ).then(user => {
      usersPromises.push({ userTo: user } as Partial<User>);
    });

    Promise.all([promise1, promise2]).then(() => {
      setUsers({ ...usersPromises[0], ...usersPromises[1] } as {
        userFrom: User;
        userTo: User;
      });
    });
  }, [feedback]);

  if (!feedback) {
    if (!feedbackAreChanging) {
      return <EmptyState showImage />;
    }

    return <Loading isLoading fullPage />;
  }

  const { date, feedbackDirection, read } = feedback;

  return (
    <section className="contentarea">
      <PageHeader
        title="Feedback details"
        backAction={
          new PageHeaderAction(
            (<FormattedMessage id="feedbacks.back-to-list" />),
            RoutePath.AllFeedbacks,
          )
        }
      />

      <div className="card">
        <div className="card__header">
          <FormattedMessage id="form.date" />
          {': '}
          <FormattedDate
            value={new Date(date)}
            year="numeric"
            month="long"
            day="2-digit"
          />
        </div>

        <section className="v-feedback__fromto card__content s-bottom--lrg">
          <div className="v-feedback__fromto__left">
            <Loading isLoading={!users.userFrom.id}>
              {users.userFrom && (
                <div className="v-feedback__item">
                  <p className="t-zeta s-bottom--sml">
                    {users.userFrom.firstName} {users.userFrom.lastName}
                  </p>
                  <p className="t-eta o-60">{users.userFrom.email}</p>
                </div>
              )}
            </Loading>
          </div>

          <div className="v-feedback__fromto__dir">
            <div className="v-feedback__actiontitle">
              <div className="v-feedback__actiontitle__arrow">
                <MdArrowForward size={32} />
              </div>
              <div className="v-feedback__actiontitle__title label label--primary s-top--med">
                {feedbackDirection}
              </div>
            </div>
          </div>

          <Loading isLoading={!users.userTo.id}>
            {users.userTo && (
              <div className="v-feedback__fromto__right">
                <div className="v-feedback__item">
                  <p className="t-zeta s-bottom--sml">
                    {users.userTo.firstName} {users.userTo.lastName}
                  </p>
                  <p className="t-eta o-60">{users.userTo.email}</p>
                  <div className="label label--primary label--hasIcon s-top--med">
                    <MdClose size={16} />
                    <span>
                      <FormattedMessage
                        id={
                          read
                            ? 'feedbacks.status.read'
                            : 'feedbacks.status.unread'
                        }
                      />
                    </span>
                  </div>
                </div>
              </div>
            )}
          </Loading>
        </section>

        <div className="card__footer">
          <div className="btn-group">
            <button
              className="btn btn--ghost btn--med btn--hasIcon"
              onClick={toggleModal}
              data-id={feedback.id}
            >
              <MdDelete size={16} />
            </button>
          </div>
        </div>

        <Modal />
      </div>
    </section>
  );
};

export default connect<
  ReduxProps,
  DispatchProps,
  RouteComponentProps<any>,
  ApplicationState
>(
  (state, ownProps) => ({
    feedback: state.feedback.items.find(
      item => item.id === ownProps.match.params.id,
    ),
    feedbackAreChanging: state.feedback.loading,
  }),
  {
    getAll: FeedbackThunks.getAllAsync,
    removeFeedback: FeedbackThunks.removeFeedback,
  },
)(FeedbackDetails);
