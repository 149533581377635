import React, { Fragment, MouseEvent } from 'react';
import Icon from 'react-icons-kit';
import {
  bold,
  italic,
  code,
  list,
  underline,
  minus,
} from 'react-icons-kit/feather';

import { SlateMarkType } from '../models';

interface Props {
  onButtonClick: (event: MouseEvent<HTMLButtonElement>) => void;
}

export const SlateToolbarButtons: React.FC<Props> = ({ onButtonClick }) => {
  const toolbarButtonTypes: SlateMarkType[] = [
    'strong',
    'em',
    'code',
    'li',
    'u',
    's',
  ];

  const getButtonIcon = (type: SlateMarkType) => {
    switch (type) {
      case 'strong':
        return bold;
      case 'em':
        return italic;
      case 'code':
        return code;
      case 'li':
        return list;
      case 'u':
        return underline;
      case 's':
        return minus;
      default:
        return null;
    }
  };

  return (
    <Fragment>
      {toolbarButtonTypes.map(buttonType => (
        <button
          key={buttonType}
          type="button"
          onPointerDown={onButtonClick}
          data-type={buttonType}
          className="slate__toolbar__icon"
        >
          <Icon icon={getButtonIcon(buttonType)} />
        </button>
      ))}
    </Fragment>
  );
};
