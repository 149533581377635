import { Dispatch } from 'redux';

import { ApplicationState } from 'modules/app-state';

import { LocalisationActions } from './actions';

import { Localisation } from '../models';
import { LocalisationService } from '../services';

const getAllAsync = () => async (
  dispatch: Dispatch,
  getState: () => ApplicationState,
) => {
  if (getState().localisation.items.length) {
    return;
  }

  dispatch(LocalisationActions.change());

  const successFunction = (data: Localisation[]) =>
    dispatch(LocalisationActions.updateAll(data));

  const errorFunction = (error: string) =>
    dispatch(LocalisationActions.error(error));

  const listenerProps = {
    successFunction,
    errorFunction,
  };

  LocalisationService.filterAsync(undefined, listenerProps);

  return;
};

const saveLocalisation = (localisations: Localisation[]) => (
  dispatch: Dispatch,
) => {
  dispatch(LocalisationActions.change());

  LocalisationService.addAsync(localisations);
};

export const LocalisationThunks = {
  getAllAsync,
  saveLocalisation,
};
