import React, { useState } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { ApplicationState } from 'modules/app-state';
import { AuthenticationThunkActions } from 'modules/authentication';
import { RoutePath } from 'modules/navigation';

interface DispatchProps {
  sendPasswordResetEmail: (email: string) => void;
}

type Props = DispatchProps;

const ForgotPassword: React.FC<Props> = ({ sendPasswordResetEmail }) => {
  const history = useHistory();
  const [email, setEmail] = useState('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;

    setEmail(value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    sendPasswordResetEmail(email);
    history.push(RoutePath.Login);
  };

  return (
    <div className="wrapper wrapper--narrow">
      <section className="auth">
        <header className="auth__header">
          <h1 className="t-delta s-bottom--lrg">
            <FormattedMessage id="login" />
          </h1>
          <p className="t-eta o-60">
            <FormattedMessage id="login.message" />
          </p>
        </header>
        <form onSubmit={handleSubmit}>
          <div className="auth__content">
            <div className="field s-bottom--lrg">
              <label htmlFor="forgot-password" className="field__lbl">
                <FormattedMessage id="form.email" />
              </label>
              <FormattedMessage id="form.email.placeholder">
                {placeholder => (
                  <input
                    id="forgot-password"
                    name="email"
                    value={email}
                    placeholder={placeholder as string}
                    className="input input--med input--text"
                    type="email"
                    onChange={handleInputChange}
                  />
                )}
              </FormattedMessage>
            </div>
          </div>

          <div className="auth__action">
            <button
              type="submit"
              value="Submit"
              className="btn btn--med btn--primary"
            >
              <FormattedMessage id="button.send-pw-reset-mail" />
            </button>
          </div>
        </form>
      </section>
    </div>
  );
};

export default connect<{}, DispatchProps, null, ApplicationState>(null, {
  sendPasswordResetEmail: AuthenticationThunkActions.forgotPassword,
})(ForgotPassword);
