import { createAction } from 'modules/app-state';

import { FAQActionTypes } from './types';

import { FAQ } from '../models';

export const FAQActions = {
  change: () => createAction(FAQActionTypes.Change),

  update: (faqs: FAQ[]) => createAction(FAQActionTypes.Update, { faqs }),

  error: (error: string) => createAction(FAQActionTypes.Error, { error }),
};
