import { useEffect } from 'react';
import { toast } from 'react-toastify';

export const useToaster = () => {
  useEffect(() => {
    toast.configure({
      closeButton: false,
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  }, []);

  return null;
};
