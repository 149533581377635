import { FirebaseDatabaseService, StorageService } from 'modules/firebase';

import { Badge } from '../models';

const moduleName = 'badges';

export const BadgeService = {
  Database: new FirebaseDatabaseService<Badge>(moduleName),
  Storage: new StorageService<File>(moduleName + '/'),
};
