import * as React from 'react';

interface Props {
  fullPage?: boolean;
  isLoading: boolean;
}

export const Loading: React.FC<Props> = ({ fullPage, isLoading, children }) =>
  isLoading ? (
    <div className={`loading ${fullPage ? 'loading--page' : ''}`} />
  ) : (
    <>{children}</>
  );
