import orderBy from 'lodash/orderBy';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { PageHeaderAction } from 'modules/app-ui/models';
import {
  Article,
  ArticleItem,
  ArticlesThunks,
  searchArticles,
} from 'modules/articles';
import { RoutePath } from 'modules/navigation';
import { ApplicationState } from 'modules/app-state';
import { SearchActions, SearchQuery } from 'modules/search';
import {
  Loading,
  usePagination,
  useModal,
  EmptyState,
  PageHeader,
} from 'modules/app-ui';

interface DispatchProps {
  getAll: VoidFunction;
  removeArticle: (articleId: string) => void;
  search: SearchQuery;
}

interface ReduxProps {
  articles: Article[];
  error?: string;
  articlesAreChanging: boolean;
}

interface OwnProps {
  forSummaryModule?: boolean;
}

type Props = OwnProps & DispatchProps & ReduxProps;

const ArticleList: React.FC<Props> = ({
  getAll,
  error,
  articlesAreChanging,
  removeArticle,
  articles,
  search,
  forSummaryModule,
}) => {
  const { pathname } = useLocation();
  let articlesToDisplay: Article[] = [];

  if (!forSummaryModule) {
    const articleType = pathname.split('/')[2];
    switch (articleType) {
      case RoutePath.DraftArticles.split('/')[2]:
        articlesToDisplay = articles.filter(article => !article.published);
        break;
      case RoutePath.PublishedArticles.split('/')[2]:
        articlesToDisplay = articles.filter(article => article.published);
        break;
      default:
        articlesToDisplay = articles;
        break;
    }
  }

  useEffect(() => {
    getAll();
  }, [getAll]);

  const [pagedItems, Pagination] = usePagination(articlesToDisplay);
  const [toggleModal, Modal] = useModal(removeArticle);

  if (forSummaryModule) {
    const size = 5;
    const latestPublishedArticles = articles.filter(
      article => article.published,
    );

    return (
      <section>
        <Loading isLoading={articlesAreChanging}>
          {!latestPublishedArticles.length && <EmptyState showImage />}

          {latestPublishedArticles.slice(0, size).map(article => (
            <ArticleItem
              article={article}
              key={article.id}
              toggleModal={toggleModal}
            />
          ))}
        </Loading>

        <Modal />
      </section>
    );
  }

  return (
    <section className="contentarea">
      <PageHeader
        onSearch={search}
        title={<FormattedMessage id="articles" />}
        actions={[
          new PageHeaderAction(
            (<FormattedMessage id="articles.btn.add" />),
            `${RoutePath.ArticlesAll}/new`,
          ),
        ]}
      />

      {error && <div className="alert alert--warning">{error}</div>}

      <Loading fullPage isLoading={articlesAreChanging}>
        {pagedItems && !pagedItems.length && <EmptyState showImage />}

        {pagedItems.map(article => (
          <ArticleItem
            article={article}
            key={article.id}
            toggleModal={toggleModal}
          />
        ))}

        <Pagination />
        <Modal />
      </Loading>
    </section>
  );
};

export default connect<ReduxProps, DispatchProps, OwnProps, ApplicationState>(
  state => ({
    articles: orderBy(searchArticles(state), ['publishDate'], 'desc'),
    error: state.articles.error,
    articlesAreChanging: state.articles.loading,
  }),
  {
    getAll: ArticlesThunks.getAllAsync,
    removeArticle: ArticlesThunks.removeArticle,
    search: SearchActions.querySearch,
  },
)(ArticleList);
