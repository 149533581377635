import React from 'react';
import { MdEmail, MdMarkunreadMailbox } from 'react-icons/md';
import { FormattedMessage } from 'react-intl';

import { UserBadge } from '../models';

interface Props {
  badge: UserBadge;
}

export const Badge: React.FC<Props> = ({ badge }) => (
  <div className="l-bordered__item">
    <div className="g-inline">
      <div className="g-inline__item">
        <p className="t-eta o-60 s-bottom--sml">
          <FormattedMessage id="badge-level" />:
        </p>
        <p className="t-zeta">{badge.level}</p>
      </div>
      <div className="g-inline__item">
        <p className="t-eta o-60 s-bottom--sml">Status: </p>
        {badge.read ? (
          <div className="label label--primary label--hasIcon">
            <MdMarkunreadMailbox size={16} />
            <span>
              <FormattedMessage id="feedbacks.status.unread" />
            </span>
          </div>
        ) : (
          <div className="label label--success label--hasIcon">
            <MdEmail size={16} />
            <span>
              <FormattedMessage id="feedbacks.status.read" />
            </span>
          </div>
        )}
      </div>
      <div className="g-inline__item">
        <p className="t-eta o-60 s-bottom--sml">
          <FormattedMessage id="form.title" />:
        </p>
        <p className="t-zeta">{badge.title}</p>
      </div>
    </div>
  </div>
);
