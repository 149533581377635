import React from 'react';

interface Props {
  text?: string;
}
export const EmptyField: React.FC<Props> = ({ text }) => (
  <p className="label label--note">
    {text || 'No data is found for your search query, try a different query.'}
  </p>
);
