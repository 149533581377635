import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { ApplicationState } from 'modules/app-state';
import { AuthenticationThunkActions } from 'modules/authentication';
import { RoutePath } from 'modules/navigation';

interface DispatchProps {
  loginUser: (username: string, password: string, rememberMe: boolean) => void;
}

interface ReduxProps {
  authStateChanging: boolean;
  isLoggedIn?: boolean;
  error?: string;
}

type Props = DispatchProps & ReduxProps & RouteComponentProps<any>;

interface State {
  email: string;
  password: string;
  rememberMe: boolean;
}

const Login: React.FC<Props> = ({ loginUser, authStateChanging, error }) => {
  const [{ email, password, rememberMe }, setValues] = useState<State>({
    email: '',
    password: '',
    rememberMe: false,
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    loginUser(email, password, rememberMe);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, type, checked, value } = event.currentTarget;
    const inputValue = type === 'checkbox' ? checked : value;

    setValues(s => ({
      ...s,
      [name]: inputValue,
    }));
  };

  return (
    <div className="wrapper wrapper--narrow">
      <section className="auth">
        <header className="auth__header">
          <h1 className="t-delta s-bottom--lrg">
            <FormattedMessage id="login" />
          </h1>
          <p className="t-eta o-60">
            <FormattedMessage id="login.message" />
          </p>
        </header>
        <form onSubmit={handleSubmit}>
          <div className="auth__content">
            <div className="field">
              <p className="alert alert--warning">{error}</p>
            </div>
            <div className="field s-bottom--lrg">
              <label htmlFor="username" className="field__lbl">
                <FormattedMessage id="form.email" />
              </label>
              <FormattedMessage id="form.email.placeholder">
                {placeholder => (
                  <input
                    id="username"
                    name="email"
                    value={email}
                    placeholder={placeholder as string}
                    className="input input--med input--text"
                    type="email"
                    onChange={handleInputChange}
                  />
                )}
              </FormattedMessage>
            </div>
            <div className="field s-bottom--lrg">
              <label htmlFor="password" className="field__lbl">
                <FormattedMessage id="form.password" />
              </label>
              <FormattedMessage id="form.password.placeholder">
                {placeholder => (
                  <input
                    id="password"
                    name="password"
                    value={password}
                    placeholder={placeholder as string}
                    className="input input--med input--text"
                    type="password"
                    onChange={handleInputChange}
                  />
                )}
              </FormattedMessage>
            </div>
            <div className="field">
              <input
                id="rememberMe"
                name="rememberMe"
                checked={rememberMe}
                className="input--check"
                type="checkbox"
                onChange={handleInputChange}
              />
              <label htmlFor="rememberMe">
                <FormattedMessage id="form.remember-me" />
              </label>
            </div>
          </div>
          <div className="auth__action btn-group">
            <button
              type="submit"
              value="Submit"
              className="btn btn--med btn--primary"
              disabled={authStateChanging}
            >
              <FormattedMessage id="login" />
            </button>

            <Link
              className="btn btn--ghost btn--med"
              to={RoutePath.ForgotPassword}
            >
              <FormattedMessage id="login.forgot-pw" />
            </Link>
          </div>
        </form>
        <div className="auth__action t-zeta">
          <FormattedMessage id="dashboard.version" />:{' '}
          {process.env.REACT_APP_VERSION}
        </div>
      </section>
    </div>
  );
};

export default connect<ReduxProps, DispatchProps, null, ApplicationState>(
  state => ({
    authStateChanging: state.auth.loading,
    isLoggedIn: state.auth.isLoggedIn,
    error: state.auth.error,
  }),
  {
    loginUser: AuthenticationThunkActions.loginAsync,
  },
)(Login);
