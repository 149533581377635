import React, { useEffect, useState } from 'react';
import { MdDelete } from 'react-icons/md';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Select, { ValueType } from 'react-select';

import { PageHeaderAction } from 'modules/app-ui/models';
import { ApplicationState } from 'modules/app-state';
import { useFormatMessage } from 'modules/localisation';
import { Media, MediaThunks, ImageGallery } from 'modules/media';
import { RoutePath } from 'modules/navigation';
import {
  useModal,
  usePageModified,
  EmptyState,
  Loading,
  PageHeader,
  PageGuard,
  Video,
} from 'modules/app-ui';

import { MediaOptions } from '../const';

interface DispatchProps {
  getAll: VoidFunction;
  saveMedia: (media: Media, oldMedia: Media) => void;
  removeMedia: (id: string) => void;
}

interface ReduxProps {
  media: Media;
  mediaIsChanging: boolean;
}

type Props = DispatchProps & ReduxProps & RouteComponentProps<any>;

const MediaDetails: React.FC<Props> = ({
  media,
  getAll,
  saveMedia,
  mediaIsChanging,
  removeMedia,
}) => {
  const location = useLocation();
  const history = useHistory();

  const backRoute = location.state ? location.state.backRoute : null;
  const redirectToList = () => history.push(backRoute || RoutePath.Media);

  const [toggleModal, Modal] = useModal(removeMedia, redirectToList);
  const [state, setState] = useState(media);
  const [mediaTypeValue, setMediaTypeValue] = useState({
    value: media?.mediaType || 'Gallery',
    label: media?.mediaType || 'Gallery',
  });
  const [formatMessage] = useFormatMessage();
  const [pageModified, setPageModified] = usePageModified('media-contentarea');

  useEffect(() => {
    getAll();
    if (media) setState(media);
  }, [getAll, media]);

  useEffect(() => {
    setMediaTypeValue({
      value: media?.mediaType || 'Gallery',
      label: media?.mediaType || 'Gallery',
    });
  }, [media]);

  if (!state) {
    if (!mediaIsChanging) {
      return <EmptyState showImage />;
    }

    return <Loading isLoading fullPage />;
  }

  const {
    id,
    title,
    imageUrls = [],
    imageReferences = [],
    imageFiles = [],
    imageBlobs = [],
    videoUrl = '',
  } = state;

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.currentTarget;

    setState({
      ...state,
      [name]: value,
    });
  };

  const handleSelectChange = (
    value: ValueType<{
      value: string;
      label: string;
    }>,
  ) => {
    setMediaTypeValue(value as any);
    setPageModified(true);

    setState({
      ...state,
      imageFiles: [],
      imageUrls: [],
      imageBlobs: [],
      imageReferences: [],
      videoUrl: '',
    });
  };

  const removeImageFromGallery = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    const { index } = event.currentTarget.dataset;
    if (!index) {
      return;
    }

    const newUrls = [...imageUrls];
    const newFiles = [...imageFiles];
    const newBlobs = [...imageBlobs];
    const newRefs = [...imageReferences];

    if (index.includes('imageUrl_')) {
      newUrls.splice(parseInt(index.replace('imageUrl_', '')), 1);
      newRefs.splice(parseInt(index.replace('imageUrl_', '')), 1);
    }

    if (index.includes('blobUrl_')) {
      newBlobs.splice(parseInt(index.replace('blobUrl_', '')), 1);
      newFiles.splice(parseInt(index.replace('blobUrl_', '')), 1);
    }

    setState({
      ...state,
      imageFiles: newFiles,
      imageUrls: newUrls,
      imageBlobs: newBlobs,
      imageReferences: newRefs,
    });

    setPageModified(true);
  };

  const addImagesToGallery = (files: File[]) => {
    const isGallery = mediaTypeValue.value === 'Gallery';
    const newGalleryBlobs: string[] = isGallery
      ? [...(state.imageBlobs || [])]
      : [];
    const newGalleryFiles: File[] = isGallery
      ? [...(state.imageFiles || [])]
      : [];

    files.forEach(file => {
      newGalleryBlobs.push(URL.createObjectURL(file));
      newGalleryFiles.push(file);
    });

    setState({
      ...state,
      imageFiles: newGalleryFiles,
      imageBlobs: newGalleryBlobs,
      imageUrls: [],
    });

    setPageModified(true);
    files.splice(0, files.length);
  };

  const saveMediaCard = async () => {
    await setPageModified(false);

    const data = {
      ...state,
      mediaType: mediaTypeValue.value,
    };

    if (!navigator.onLine) {
      toast.warn(formatMessage('toast.images.disabled'));
      redirectToList();

      return;
    }

    saveMedia(data, media);

    toast.success(
      state.id
        ? formatMessage('toast.media.update', state.title)
        : formatMessage('toast.media.save', state.title),
    );

    redirectToList();
  };

  return (
    <section className="contentarea" id="media-contentarea">
      <PageHeader
        title={
          <FormattedMessage
            id={id === 'new' ? 'media.create.title' : 'media.details.title'}
          />
        }
        backAction={
          new PageHeaderAction(
            (<FormattedMessage id="media.back-to-list" />),
            backRoute || RoutePath.Media,
          )
        }
      />

      <div className="card">
        <div className="field">
          <label htmlFor="title" className="field__lbl t-mandatory">
            <FormattedMessage id="form.title" />
          </label>
          <FormattedMessage id="form.title.placeholder">
            {placeholder => (
              <input
                type="text"
                className="input input--med"
                name="title"
                id="title"
                placeholder={placeholder as string}
                value={title}
                onChange={handleChange}
              />
            )}
          </FormattedMessage>
        </div>

        <div className="field">
          <label htmlFor="mediaType" className="field__lbl t-mandatory">
            <FormattedMessage id="form.type" />
          </label>
          <Select
            id="mediaType"
            value={mediaTypeValue}
            onChange={handleSelectChange}
            options={MediaOptions}
            className="input--select"
          />
        </div>

        {mediaTypeValue.value !== 'Video' && (
          <ImageGallery
            imageBlobs={imageBlobs || []}
            imageUrls={imageUrls || []}
            removeHandler={removeImageFromGallery}
            addHandler={addImagesToGallery}
            mediaType={mediaTypeValue.value}
          />
        )}

        {mediaTypeValue.value === 'Video' && (
          <Video videoUrl={videoUrl} handleChange={handleChange} />
        )}

        <footer className="card__footer">
          <div className="btn-group">
            <button
              className="btn btn--primary btn--med"
              onClick={saveMediaCard}
              disabled={!pageModified}
            >
              <FormattedMessage id="button.save" />
            </button>
            {id && (
              <button
                className="btn btn--ghost btn--med btn--hasIcon"
                onClick={toggleModal}
                data-id={id}
                data-title={title}
              >
                <MdDelete size={16} />
              </button>
            )}
          </div>
        </footer>
      </div>

      <Modal />
      <PageGuard when={pageModified} />
    </section>
  );
};

export default connect<ReduxProps, DispatchProps, Props, ApplicationState>(
  (state, componentProps) => {
    const id = componentProps.match.params.id;

    return {
      media:
        id === 'new'
          ? new Media()
          : state.media.items.filter(item => item.id === id)[0],
      mediaIsChanging: state.media.loading,
    };
  },
  {
    removeMedia: MediaThunks.removeMedia,
    getAll: MediaThunks.getAllAsync,
    saveMedia: MediaThunks.saveMedia,
  },
)(MediaDetails);
