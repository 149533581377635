import { Editor } from 'slate-react';

export const SlatePlugins = [];

export const insertBlock = (editor: Editor, url: string, type: string) => {
  editor
    .focus()
    .moveToEndOfBlock()
    .insertBlock({
      type,
      data: {
        src: url,
        alt: '',
        className: '',
      },
    })
    .insertBlock('p');
};

export const insertGallery = (editor: Editor, url: string, title: string) => {
  editor
    .focus()
    .moveToEndOfBlock()
    .insertBlock({
      type: 'gallery',
      data: {
        dataId: url,
        dataTitle: title,
      },
    })
    .insertBlock('p');
};
