export interface Article {
  id: string;
  title: string;
  subtitle: string;
  slug: string;
  content: string;
  featuredImageUrl: string;
  videoUrl: string;
  publishDate?: number | Date | null;
  published: boolean;
}

export class Article {
  constructor() {
    this.title = '';
    this.subtitle = '';
    this.slug = '';
    this.content = '';
    this.featuredImageUrl = '';
    this.videoUrl = '';
    this.published = false;
  }
}
