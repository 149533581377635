import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import Files from 'react-files';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import { MdSave } from 'react-icons/md';
import { useLocation, useHistory, useParams } from 'react-router-dom';

import {
  PageHeaderAction,
  useModal,
  usePageModified,
  EmptyState,
  Loading,
  PageHeader,
  PageGuard,
} from 'modules/app-ui';
import {
  LocalisationThunks,
  LocalisationDetailsRow,
  useFormatMessage,
} from 'modules/localisation';
import { ApplicationState } from 'modules/app-state';
import { RoutePath } from 'modules/navigation';

import { Localisation } from '../models';

interface DispatchProps {
  getAll: VoidFunction;
  saveLocalisations: (languages: Localisation[]) => void;
}

interface ReduxProps {
  languages: Localisation[];
  languagesAreChanging: boolean;
}

type Props = DispatchProps & ReduxProps & RouteComponentProps<any>;

const LocalisationDetails: React.FC<Props> = ({
  languages,
  getAll,
  saveLocalisations,
  languagesAreChanging,
}) => {
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const languageType = location.pathname.split('/')[2];

  const redirectToList = () =>
    history.push(`${RoutePath.Localisation}/${languageType}`);

  const newLang = id === 'new';

  const [state, setState] = useState<Localisation>({
    ...languages.filter(item => item.id === id)[0],
  });

  const removeLanguage = (entityId: string) =>
    saveLocalisations(languages.filter(item => item.id !== entityId));

  const [toggleModal, Modal] = useModal(removeLanguage, redirectToList);

  const [error, setError] = useState<JSX.Element[]>();
  const [langTitle, setLangTitle] = useState(newLang ? 'language' : '');
  const [formatMessage] = useFormatMessage();
  const [pageModified, setPageModified] = usePageModified(
    'localisation-contentarea',
  );

  const handleKeysChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;

    let newWordsObject = { id: langTitle };

    Object.keys(state).forEach(key => {
      if (key === name) {
        const newPair = { [value]: state[name] };
        newWordsObject = { ...newWordsObject, ...newPair };
      } else {
        newWordsObject = { ...newWordsObject, [key]: state[key] };
      }
    });

    setState(newWordsObject);

    // const type = state.id.split('-')[0];
  };

  const handleNewProp = () => {
    const newProp = { '': '' };
    const newStateWithProp = { ...newProp, ...state };

    setState(newStateWithProp);
    setPageModified(true);
  };

  const handleDeleteProp = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { key } = event.currentTarget.dataset;
    if (!key) {
      return;
    }

    const tempState = { ...state };
    delete tempState[key];
    setState(tempState);

    toast.success(formatMessage('toast.localisation.update'));
  };

  const handleValuesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;

    setState({
      ...state,
      [name]: value,
    });
  };

  const saveLanguage = () => {
    const emptyProps: { [prop: string]: string } = {};

    Object.keys(state).forEach(prop => {
      if (state[prop] === '') {
        emptyProps[prop] = state[prop];
      }
    });

    if (Object.keys(emptyProps).length) {
      const errorMessage = [
        <strong key={0}>{formatMessage('error.localisation')}:</strong>,
      ];

      Object.keys(emptyProps).forEach(key => {
        errorMessage.push(
          <strong key={errorMessage.length}>{`${key} `}</strong>,
        );
      });

      setError(errorMessage);
      return;
    }

    setPageModified(false);

    const newLocalisation = [
      ...languages.filter(item => item.id !== id),
      {
        ...state,
        id: langTitle ? `${languageType}-${langTitle}` : state.id,
      },
    ];

    saveLocalisations(newLocalisation);

    toast.success(formatMessage('toast.localisation.update'));
  };

  const renderProperties = () => {
    const words = [];
    for (const key in state) {
      if (key === 'id') {
        continue;
      }

      words.push(
        <LocalisationDetailsRow
          index={words.length}
          key={words.length}
          objectKey={key}
          locState={state}
          handleKeysChange={handleKeysChange}
          handleValuesChange={handleValuesChange}
          deleteHandler={handleDeleteProp}
          saveHandler={saveLanguage}
        />,
      );
    }

    return words;
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLangTitle(event.currentTarget.value);
  };

  const importLanguage = (files: File[]) => {
    handleNewProp();

    const reader = new FileReader();
    reader.onload = () => {
      const text = reader.result;
      const jsObject = JSON.parse(String(text));

      setState({ ...jsObject, id: state.id });
    };

    reader.readAsText(files[files.length - 1]);
  };

  const exportLanguage = () => {
    const language = { ...state };
    delete language.id;

    const element = document.createElement('a');
    element.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' +
        encodeURIComponent(JSON.stringify(language)),
    );
    element.setAttribute('download', `${state.id}_language.json`);

    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  useEffect(() => {
    if (!languages.length) {
      getAll();
    }
  }, [languages, getAll]);

  useEffect(() => {
    if (!newLang && Object.keys(state).length <= 1) {
      setState({
        ...languages.filter(item => item.id === id)[0],
      });
    }

    if (newLang && Object.keys(state).length < 1) {
      setState({ id: 'language' });
    }
  }, [languages, id, state, newLang]);

  if (!state) {
    if (!languagesAreChanging) {
      return <EmptyState showImage />;
    }

    return <Loading isLoading fullPage />;
  }

  return (
    <section className="contentarea" id="localisation-contentarea">
      <PageHeader
        title={
          <FormattedMessage
            id={
              newLang
                ? 'localisation.create.title'
                : 'localisation.details.title'
            }
          />
        }
        backAction={
          new PageHeaderAction(
            (<FormattedMessage id="localisation.back-to-list" />),
            `${RoutePath.Localisation}/${languageType}`,
          )
        }
        actions={[
          new PageHeaderAction(
            (<FormattedMessage id="localisation.export" />),
            exportLanguage,
          ),
          new PageHeaderAction(
            (
              <Files
                onChange={importLanguage}
                accepts={['.json']}
                maxFiles={Infinity}
                maxFileSize={10000000}
                minFileSize={0}
                clickable
              >
                <button className="btn btn--primary btn--med">
                  <FormattedMessage id="localisation.import" />
                </button>
              </Files>
            ),
          ),
          new PageHeaderAction(
            (<FormattedMessage id="localisation.add-new-prop" />),
            handleNewProp,
          ),
        ]}
      />

      <div>
        <div className="v-localisation__heading__content">
          <div className="field">
            <label htmlFor="language" className="field__lbl t-mandatory">
              <FormattedMessage id="form.language" />
            </label>

            <div className="t-delta s-bottom--lrg">
              {!newLang && (
                <>
                  <FormattedMessage id="form.language" />: {state.id}
                </>
              )}
              {newLang && (
                <>
                  {languageType}
                  {' - '}
                  <input
                    id="language"
                    type="text"
                    className="input--language"
                    value={langTitle}
                    onChange={handleTitleChange}
                  />
                </>
              )}
            </div>
          </div>

          {pageModified && (
            <button
              className="btn btn--ghost btn--sml btn--hasIcon u-color--success s-top--med"
              onClick={saveLanguage}
            >
              <MdSave size={16} type="sharp" />
            </button>
          )}
        </div>

        {error && (
          <div className="alert alert--warning s-bottom--lrg">{error}</div>
        )}

        <div className="l-boxed">{renderProperties()}</div>
      </div>

      {!newLang && (
        <div className="contentarea__actionbar">
          <button
            className="btn btn--med btn--secondary"
            data-id={state.id}
            onClick={toggleModal}
          >
            <FormattedMessage id="button.delete-language" />
          </button>
        </div>
      )}

      <Modal />

      <PageGuard when={pageModified} />
    </section>
  );
};

export default connect<ReduxProps, DispatchProps, null, ApplicationState>(
  state => ({
    languages: state.localisation.items,
    languagesAreChanging: state.localisation.loading,
  }),
  {
    getAll: LocalisationThunks.getAllAsync,
    saveLocalisations: LocalisationThunks.saveLocalisation,
  },
)(LocalisationDetails);
