import { createAction } from 'modules/app-state';

import { MediaActionTypes } from './types';

import { Media } from '../models';

const AddActions = {
  change: () => createAction(MediaActionTypes.Change),

  update: (media: Media[]) => createAction(MediaActionTypes.Update, { media }),

  error: (error: string) => createAction(MediaActionTypes.Error, { error }),

  changeFilter: (filter: 'Gallery' | 'Image' | 'Video' | null) =>
    createAction(MediaActionTypes.ChangeFilter, { filter }),
};

export const MediaActions = {
  ...AddActions,
};
