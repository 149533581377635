import { ActionUnion } from 'modules/app-state';

import { ArticleActions } from './actions';
import { ArticleActionTypes } from './types';

import { Article } from '../models';

export interface ArticleState {
  readonly loading: boolean;
  readonly error?: string;
  readonly items: Article[];
}

const INITIAL_STATE: ArticleState = {
  loading: true,
  error: undefined,
  items: [],
};

export default (
  state: ArticleState = INITIAL_STATE,
  action: ActionUnion<typeof ArticleActions>,
): ArticleState => {
  switch (action.type) {
    case ArticleActionTypes.Change:
      return {
        ...state,
        loading: INITIAL_STATE.loading,
        error: INITIAL_STATE.error,
      };

    case ArticleActionTypes.Update:
      return {
        ...state,
        loading: !INITIAL_STATE.loading,
        error: INITIAL_STATE.error,
        items: action.payload.articles,
      };

    case ArticleActionTypes.Error:
      return {
        ...state,
        loading: !INITIAL_STATE.loading,
        error: action.payload.error,
      };

    default:
      return state || INITIAL_STATE;
  }
};
