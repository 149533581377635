import React from 'react';

interface Props {
  localisation?: string;
  handleLanguageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const DashboardLocalisation: React.FC<Props> = ({
  localisation,
  handleLanguageChange,
}) => (
  <div>
    {localisation && (
      <ul className="l-boxed">
        <li className="l-boxed__item">
          <input
            type="radio"
            name="localisation"
            id="localisationEn"
            className="input--radio"
            value={'en'}
            checked={localisation === 'en'}
            onChange={handleLanguageChange}
          />
          <label htmlFor="localisationEn">{'en'}</label>
        </li>

        <li className="l-boxed__item">
          <input
            type="radio"
            name="localisation"
            id="localisationDe"
            className="input--radio"
            value={'de'}
            checked={localisation === 'de'}
            onChange={handleLanguageChange}
          />
          <label htmlFor="localisationDe">{'de'}</label>
        </li>
      </ul>
    )}
  </div>
);
