import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import {
  PageHeaderAction,
  useModal,
  usePagination,
  PageHeader,
  Loading,
  EmptyState,
} from 'modules/app-ui';
import { ApplicationState } from 'modules/app-state';
import { RoutePath } from 'modules/navigation';
import { VirtualReward } from 'modules/rewards/models/VirtualReward';
import { Reward, RewardItem, RewardThunks } from 'modules/rewards';
import { SearchQuery, SearchActions } from 'modules/search';

import { searchRewards } from '../redux/selectors';

interface DispatchProps {
  removeReward: (entityId: string) => void;
  getRewards: VoidFunction;
  search: SearchQuery;
}

interface ReduxProps {
  rewards: Reward[];
  isChanging: boolean;
  error?: string;
}

type Props = DispatchProps & ReduxProps & RouteComponentProps<any>;

const RewardList: React.FC<Props> = ({
  getRewards,
  rewards,
  isChanging,
  error,
  search,
  removeReward,
  location,
}) => {
  const rewardsType = location.pathname.split('/')[2];
  const isVirtualRewards =
    rewardsType === RoutePath.VirtualRewards.split('/')[2];

  let rewardsToDisplay = [];
  switch (rewardsType) {
    case RoutePath.DraftRewards.split('/')[2]:
      rewardsToDisplay = rewards.filter(reward => !reward.published);
      break;
    case RoutePath.PublishedRewards.split('/')[2]:
      rewardsToDisplay = rewards.filter(reward => reward.published);
      break;
    case RoutePath.VirtualRewards.split('/')[2]:
      rewardsToDisplay = rewards
        .map(reward => reward as VirtualReward)
        .filter(reward => reward.virtual);
      break;
    default:
      rewardsToDisplay = rewards;
      break;
  }

  useEffect(() => {
    getRewards();
  }, [getRewards]);

  const [toggleModal, Modal] = useModal(removeReward, undefined);
  const [pagedItems, Pagination] = usePagination(rewardsToDisplay);

  return (
    <section className="contentarea">
      <PageHeader
        onSearch={search}
        title={<FormattedMessage id="rewards" />}
        actions={[
          new PageHeaderAction(
            (
              <FormattedMessage
                id={
                  isVirtualRewards
                    ? 'rewards.btn.add-virtual'
                    : 'rewards.btn.add'
                }
              />
            ),
            isVirtualRewards
              ? `${RoutePath.VirtualRewards}/new`
              : `${RoutePath.RewardsAll}/new`,
          ),
        ]}
      />

      {error && <div className="alert alert--warning">{error}</div>}

      <Loading fullPage isLoading={isChanging}>
        {!pagedItems.length && <EmptyState showImage />}

        {pagedItems.map(reward => (
          <RewardItem
            key={reward.id}
            toggleModal={toggleModal}
            reward={reward}
            virtual={(reward as VirtualReward).virtual}
          />
        ))}

        <Pagination />
        <Modal />
      </Loading>
    </section>
  );
};

export default connect<ReduxProps, DispatchProps, Props, ApplicationState>(
  state => ({
    rewards: searchRewards(state),
    isChanging: state.rewards.loading,
    error: state.rewards.error,
  }),
  {
    removeReward: RewardThunks.removeReward,
    getRewards: RewardThunks.getAllRewardsAsync,
    search: SearchActions.querySearch,
  },
)(RewardList);
