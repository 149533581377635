import React from 'react';
import { Link } from 'react-router-dom';
import { MdDelete } from 'react-icons/md';
import { FormattedMessage, FormattedDate } from 'react-intl';

import { RoutePath } from 'modules/navigation';
import { User } from 'modules/users';

import { RedeemReward } from '../models';

interface Props {
  redeemReward: RedeemReward;
  user?: User;
  toggleModal: VoidFunction;
}

export const RedeemRewardItem: React.FC<Props> = ({
  redeemReward,
  user,
  toggleModal,
}) => {
  return (
    <div className="itemlist--simple__item">
      <div className="itemlist--simple__item__content">
        <div>
          <h2 className="t-zeta">
            <Link to={`${RoutePath.RedeemedRewards}/${redeemReward.id}`}>
              <strong>
                {!user ? (
                  <FormattedMessage id="rewards.missing-user" />
                ) : user.firstName && user.lastName ? (
                  `${user.firstName} ${user.lastName}`
                ) : (
                  `${user.email}`
                )}
              </strong>
            </Link>
          </h2>
          <div>
            <p className="t-eta o-60 s-bottom--sml">
              <FormattedDate
                value={new Date(redeemReward.date)}
                year="numeric"
                month="long"
                day="2-digit"
              />
            </p>
            {redeemReward.shipped ? (
              <div className="label label--success">
                <FormattedMessage id="rewards.shipped" />
              </div>
            ) : (
              <div className="label label--note">
                <FormattedMessage id="rewards.not-shipped" />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="itemlist--simple__item__action">
        <div className="btn-group">
          <Link
            className="btn btn--sml btn--secondary"
            to={`${RoutePath.RedeemedRewards}/${redeemReward.id}`}
          >
            <FormattedMessage id="button.edit" />
          </Link>
          <button
            className="btn btn--ghost btn--sml btn--hasIcon"
            onClick={toggleModal}
            data-id={redeemReward.id}
          >
            <MdDelete size={16} />
          </button>
        </div>
      </div>
    </div>
  );
};
