import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { FormattedMessage } from 'react-intl';

import { ApplicationState } from 'modules/app-state';
import {
  Localisation,
  LocalisationThunks,
  LocalisationItem,
} from 'modules/localisation';
import { RoutePath } from 'modules/navigation';
import { PageHeaderAction } from 'modules/app-ui/models';
import {
  usePagination,
  useModal,
  PageHeader,
  Loading,
  EmptyState,
} from 'modules/app-ui';

import { LocalisationService } from '../services';

interface DispatchProps {
  getAll: VoidFunction;
  removeLanguage: (entityId: string) => void;
}

interface ReduxProps {
  languages: Localisation[];
  error?: string;
  localisationsAreChanging: boolean;
}

type Props = DispatchProps & ReduxProps & RouteComponentProps<any>;

const LocalisationList: React.FC<Props> = ({
  getAll,
  error,
  localisationsAreChanging,
  removeLanguage,
  languages,
  match,
}) => {
  const localisationType = match.path.split('/')[2];

  useEffect(() => {
    getAll();
  }, [getAll]);

  const [pagedItems, Pagination] = usePagination(
    languages.filter(lang => lang.id.startsWith(localisationType)),
  );
  const [toggleModal, Modal] = useModal(removeLanguage);

  return (
    <section className="contentarea v-localisation">
      <PageHeader
        title={
          <>
            <FormattedMessage id={`localisation.${localisationType}`} />{' '}
            <FormattedMessage id={'localisation'} />
          </>
        }
        actions={[
          new PageHeaderAction(
            (<FormattedMessage id="localisation.btn.add" />),
            `${RoutePath.Localisation}/${localisationType}/new`,
          ),
        ]}
      />

      {error && <div className="alert alert--warning">{error}</div>}

      <Loading fullPage isLoading={localisationsAreChanging}>
        {!languages.length && <EmptyState showImage />}

        {pagedItems.map(lang => (
          <LocalisationItem
            key={lang.id}
            language={lang}
            toggleModal={toggleModal}
            localisationType={localisationType}
          />
        ))}

        <Pagination />
        <Modal />
      </Loading>
    </section>
  );
};

export default connect<ReduxProps, DispatchProps, null, ApplicationState>(
  state => ({
    languages: state.localisation.items,
    error: state.localisation.error,
    localisationsAreChanging: state.localisation.loading,
  }),
  {
    getAll: LocalisationThunks.getAllAsync,
    removeLanguage: LocalisationService.removeAsync,
  },
)(LocalisationList);
