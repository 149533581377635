import React from 'react';
import NavigationPrompt from 'react-router-navigation-prompt';
import { FormattedMessage } from 'react-intl';

import { Modal } from './Modal';

interface Props {
  when: boolean;
}

export const PageGuard: React.FC<Props> = ({ when }) => {
  return (
    <NavigationPrompt when={when}>
      {({ onConfirm, onCancel }) => (
        <Modal
          isOpen
          onClose={onCancel}
          onSuccess={onConfirm}
          title={<FormattedMessage id="modal.unsaved-changes" />}
        />
      )}
    </NavigationPrompt>
  );
};
