import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDropzone } from 'react-dropzone';
import { Editor } from 'slate-react';
import { MdCloudUpload } from 'react-icons/md';
import { useSelector } from 'react-redux';

import { Modal, EmptyState } from 'modules/app-ui';
import { ApplicationState } from 'modules/app-state';

import { insertGallery } from '../const';

interface Props {
  galleryModal: boolean;
  showGalleryModal: () => Promise<void>;
  editor: React.MutableRefObject<Editor | null>;
  uploadMedia: (
    title: string,
    mediaType: 'Gallery' | 'Image' | 'Video',
    files: File[],
  ) => Promise<void | firebase.firestore.DocumentReference>;
  setPageModified?: (state: boolean) => void;
}

export const InsertGalleryModal: React.FC<Props> = ({
  editor,
  galleryModal,
  showGalleryModal,
  uploadMedia,
  setPageModified,
}) => {
  const [mediaTitle, setMediaTitle] = useState('');
  const [chosenMediaOption, setChosenMediaOption] = useState<{
    mediaId: string;
    mediaTitle: string;
  }>();
  const [radioOption, setRadioOption] = useState<'choose' | 'upload'>('choose');
  const { items } = useSelector((state: ApplicationState) => state.media);
  const galleries = items.filter(({ mediaType }) => mediaType === 'Gallery');

  const {
    acceptedFiles: galleryFiles,
    getRootProps: galleryRootProps,
    getInputProps: galleryInputProps,
  } = useDropzone({
    multiple: true,
  });

  const handleMediaTitleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setMediaTitle(event.currentTarget.value);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setRadioOption(value as 'choose' | 'upload');
  };

  const handleGalleryClick = (event: React.MouseEvent<HTMLLIElement>) => {
    const { title, id } = event.currentTarget.dataset;
    if (!title || !id) return;

    setChosenMediaOption({
      mediaId: id,
      mediaTitle: title,
    });
  };

  const uploadGalleryImages = async () => {
    await showGalleryModal();
    if (setPageModified) setPageModified(true);

    if (radioOption === 'choose') {
      if (!chosenMediaOption || !editor.current) return;

      setChosenMediaOption(undefined);
      insertGallery(
        editor.current,
        chosenMediaOption.mediaId,
        chosenMediaOption.mediaTitle,
      );

      return;
    }

    if (uploadMedia && galleryFiles.length) {
      const docRef = await uploadMedia(mediaTitle, 'Gallery', galleryFiles);
      if (docRef && editor.current) {
        insertGallery(editor.current, docRef.id, mediaTitle);
      }
    }
  };

  return (
    <Modal
      isOpen={galleryModal}
      onClose={showGalleryModal}
      onSuccess={uploadGalleryImages}
      title={<FormattedMessage id="modal.insert-gallery.title" />}
      submitDisabled={
        (radioOption === 'choose' && !chosenMediaOption) ||
        (radioOption === 'upload' && (!mediaTitle || !galleryFiles))
      }
    >
      <div className="slate__modal">
        <div className="slate__modal-radio">
          <input
            type="radio"
            name="galleryOption"
            id="chooseOption"
            className="input--radio"
            value="choose"
            checked={radioOption === 'choose'}
            onChange={handleRadioChange}
          />
          <label htmlFor="chooseOption">Choose gallery from media</label>
        </div>

        {!galleries.length && (
          <EmptyState
            showImage
            text="Currently there are no galleries in media"
          />
        )}
        {galleries.length > 0 && (
          <ul
            className={`slate__modal-section slate__modal__gallery ${
              radioOption === 'choose' ? 'visible' : ''
            }`}
          >
            {galleries.map(gallery => (
              <li
                key={gallery.id}
                onClick={handleGalleryClick}
                data-title={gallery.title}
                data-id={gallery.id}
                className={`slate__modal__gallery-item${
                  chosenMediaOption?.mediaId === gallery.id ? ' selected' : ''
                }`}
              >
                {gallery.imageUrls.slice(0, 4).map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`${gallery.title}_${index}`}
                    className="thumbnail-img"
                  />
                ))}
              </li>
            ))}
          </ul>
        )}

        <div className="slate__modal-radio">
          <input
            type="radio"
            name="galleryOption"
            id="uploadOption"
            className="input--radio"
            value="upload"
            checked={radioOption === 'upload'}
            onChange={handleRadioChange}
          />
          <label htmlFor="uploadOption">Upload new gallery</label>
        </div>

        <div
          className={`slate__modal-section ${
            radioOption === 'upload' ? 'visible' : ''
          }`}
        >
          <div className="field">
            <label htmlFor="mediaTitle" className="field__lbl">
              <FormattedMessage id="form.title" />
            </label>
            <FormattedMessage id="form.title.placeholder">
              {placeholder => (
                <input
                  type="text"
                  className="input input--med"
                  name="mediaTitle"
                  placeholder={placeholder as string}
                  id="mediaTitle"
                  value={mediaTitle}
                  onChange={handleMediaTitleChange}
                />
              )}
            </FormattedMessage>
          </div>

          <div {...galleryRootProps()}>
            <input {...galleryInputProps()} />
            <div className="u-well u-well--dropzone t-center">
              {!galleryFiles.length && (
                <>
                  <MdCloudUpload className="o-20 s-bottom--med" size={64} />
                  <p>
                    <FormattedMessage id="modal.image-select" />
                  </p>
                </>
              )}

              {galleryFiles.length > 0 && (
                <ul className="slate__modal__uploaded-images">
                  {galleryFiles.map((file, index) => (
                    <li key={index}>
                      <img src={URL.createObjectURL(file)} alt={file.name} />
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
