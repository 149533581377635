export const urlConverterService = (() => {
  const convertYTUrl = (url: string) => {
    const [
      ,
      ,
      youtubeId,
    ] = /(youtu\.be\/|youtube.com\/watch\?v=|youtube.com\/embed\/)(.+)/gm.exec(
      url,
    ) || [undefined, undefined, undefined];

    return `https://www.youtube.com/embed/${youtubeId}`;
  };

  const getVideoThumbnail = (url: string) => {
    const [
      ,
      ,
      youtubeId,
    ] = /(youtu\.be\/|youtube.com\/watch\?v=|youtube.com\/embed\/)(.+)/gm.exec(
      url,
    ) || [undefined, undefined, undefined];

    return `https://img.youtube.com/vi/${youtubeId}/sddefault.jpg`;
  };

  return { convertYTUrl, getVideoThumbnail };
})();
