import { Dispatch } from 'redux';

import { ApplicationState } from 'modules/app-state';
import { PointAction } from 'modules/gamification';

import { BadgeActions } from './actions';

import { Badge, BadgeLevel } from '../models';
import { BadgeService } from '../services';

export const getAllAsync = () => async (
  dispatch: Dispatch,
  getState: () => ApplicationState,
) => {
  if (getState().badges.items.length) {
    return;
  }

  dispatch(BadgeActions.change());

  const successFunction = (data: Badge[]) =>
    dispatch(BadgeActions.update(data));

  const errorFunction = (error: string) => dispatch(BadgeActions.error(error));

  const listenerProps = {
    successFunction,
    errorFunction,
  };

  BadgeService.Database.filterAsync(undefined, listenerProps);
  return;
};

export const saveBadge = (
  levelValues: BadgeLevel[],
  oldlevelValues: BadgeLevel[],
  id: string,
  title: string,
  actionName: PointAction,
) => async (dispatch: Dispatch) => {
  dispatch(BadgeActions.change());

  if (!navigator.onLine) {
    BadgeService.Database.addAsync({
      id,
      title,
      levels: oldlevelValues,
      actionName,
    });

    return;
  }

  const newImageReferences: string[] = [];

  const results = levelValues.map(
    badgeLevel =>
      new Promise(
        (resolve: (badgeLevel: BadgeLevel) => void, reject: VoidFunction) => {
          const uploadTask = BadgeService.Storage.addItem(
            id,
            badgeLevel.imageFile,
          );
          if (!uploadTask || !badgeLevel.imageFile) {
            if (badgeLevel.imageReference) {
              newImageReferences.push(badgeLevel.imageReference);
            }
            resolve(badgeLevel);
            return;
          }

          uploadTask.on(
            'state_changed',
            null,
            () => reject(),
            () => {
              uploadTask.snapshot.ref.getDownloadURL().then(url => {
                badgeLevel.imageUrl = url;
                badgeLevel.imageReference = `${id}_${
                  badgeLevel.imageFile!.name
                }`;
                newImageReferences.push(`${id}_${badgeLevel.imageFile!.name}`);
                delete badgeLevel.imageFile;

                resolve(badgeLevel);
              });
            },
          );
        },
      ),
  );

  const res = await Promise.all(results);
  let BadgeObject: any = {
    title,
    levels: res,
    actionName,
  };

  if (id) {
    BadgeObject = { id, ...BadgeObject };
  }

  await BadgeService.Database.addAsync(BadgeObject);

  // delete images from storage with unused references
  oldlevelValues.forEach(level => {
    if (!level.imageReference) {
      return;
    }

    if (!newImageReferences.includes(level.imageReference)) {
      BadgeService.Storage.removeItem(level.imageReference);
    }
  });

  return;
};

const removeBadge = (id: string) => (
  dispatch: Dispatch,
  getState: () => ApplicationState,
) => {
  const badge = getState().badges.items.find(item => item.id === id);
  if (!badge) {
    return;
  }

  dispatch(BadgeActions.change());

  BadgeService.Database.removeAsync(id);

  // delete all images from storage
  return badge.levels.forEach(level => {
    if (level.imageReference) {
      BadgeService.Storage.removeItem(level.imageReference);
    }
  });
};

export const BadgeThunks = {
  getAllAsync,
  saveBadge,
  removeBadge,
};
