import * as React from 'react';

export interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onGoToNextPage: VoidFunction;
  onGoToPreviousPage: VoidFunction;
  onGoToPage: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onGoToFirstPage: VoidFunction;
  onGoToLastPage: VoidFunction;
}

export const Pagination: React.SFC<PaginationProps> = ({
  currentPage,
  totalPages,
  onGoToNextPage,
  onGoToPreviousPage,
  onGoToPage,
  onGoToFirstPage,
  onGoToLastPage,
}) => (
  <section className="pagination">
    <div className="pagination__wrapper">
      <button
        onClick={onGoToPreviousPage}
        disabled={currentPage === 1}
        className="btn pagination__arrow pagination__arrow--left"
      >
        &#9664;
      </button>

      <div className="pagination__numbers">
        {currentPage > 2 && (
          <button
            onClick={onGoToFirstPage}
            className="btn pagination__number pagination__dots"
          >
            &hellip;
          </button>
        )}

        {currentPage > 1 && (
          <button
            onClick={onGoToPreviousPage}
            className="btn pagination__number"
          >
            {currentPage - 1}
          </button>
        )}

        <button
          disabled
          data-page-number={currentPage}
          onClick={onGoToPage}
          className="btn pagination__number is-active"
        >
          {currentPage}
        </button>

        {currentPage < totalPages && (
          <button onClick={onGoToNextPage} className="btn pagination__number">
            {currentPage + 1}
          </button>
        )}

        {totalPages - 1 > currentPage && (
          <button
            onClick={onGoToLastPage}
            className="btn pagination__number pagination__dots"
          >
            ...
          </button>
        )}
      </div>

      <button
        onClick={onGoToNextPage}
        disabled={currentPage === totalPages}
        className="btn pagination__arrow pagination__arrow--right"
      >
        &#9654;
      </button>
    </div>
  </section>
);
