import firebase from 'firebase/app';

import * as env from '../env';

export class FirebaseService {
  private static instance: firebase.app.App;

  public static get Instance() {
    const firebaseEnv = process.env.REACT_APP_FIREBASE_ENV || 'test';

    if (!this.instance) {
      this.instance = firebase.initializeApp(env[firebaseEnv]);

      if (firebase.firestore()) {
        firebase.firestore().enablePersistence();
      }
    }

    return this.instance;
  }
}
