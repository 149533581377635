import * as React from 'react';
import { MdClose } from 'react-icons/md';
import { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';

export interface Props {
  isOpen: boolean;
  children?: React.ReactNode;
  type?: 'success' | 'danger';
  additionalClasses?: string;
  title?: string | React.ReactElement;
  onSuccess?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  onClose: VoidFunction;
  submitDisabled?: boolean;
}

export const Modal: React.FC<Props> = ({
  isOpen,
  children,
  title,
  additionalClasses,
  onClose,
  onSuccess,
  type,
  submitDisabled,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);

  const onBlur = (event: MouseEvent) => {
    if (event.target === modalRef.current) {
      onClose();
    }
  };

  const onEscOrEnter = (event: KeyboardEvent) => {
    if (isOpen) {
      switch (event.keyCode) {
        case 27:
          if (isOpen) {
            onClose();
          }
          break;
        case 13:
          if (isOpen && onSuccess) {
            onSuccess();
          }
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', onEscOrEnter, false);
    window.addEventListener('mousedown', onBlur, false);

    return () => {
      window.removeEventListener('keydown', onEscOrEnter, false);
      window.removeEventListener('mousedown', onBlur, false);
    };
  });

  if (!isOpen) {
    return null;
  }

  let modalClassNames = 'modal';
  if (additionalClasses) {
    modalClassNames += ` ${additionalClasses}`;
  }

  let primaryButtonClasses = 'btn btn--med';
  switch (type) {
    case 'danger':
      primaryButtonClasses += ' btn--danger';
      break;
    default:
      primaryButtonClasses += ' btn--primary';
      break;
  }

  return (
    <div ref={modalRef} className={`modal ${modalClassNames}`}>
      <div className="modal__wrapper">
        <div className="modal__header">
          <h1 className="t-delta">
            {title || <FormattedMessage id="modal.are-you-sure" />}
          </h1>
        </div>

        {children && (
          <div className="modal__content">
            <div className="t-zeta">{children}</div>
          </div>
        )}

        <div className="modal__footer">
          <div className="btn-group">
            <button
              onClick={onSuccess}
              disabled={submitDisabled}
              className={primaryButtonClasses}
            >
              <FormattedMessage
                id={type === 'danger' ? 'button.remove' : 'button.confirm'}
              />
            </button>
            <button
              type="button"
              onClick={onClose}
              className="btn btn--ghost btn--med btn--hasIcon"
            >
              <MdClose size={16} />{' '}
              <span>
                <FormattedMessage id="button.cancel" />
              </span>
            </button>
          </div>
        </div>
        <div className="modal__close">
          <button
            type="button"
            onClick={onClose}
            className="btn btn--dark btn--close"
          >
            <MdClose size={16} />
          </button>
        </div>
      </div>
    </div>
  );
};
