import { FeedbackDirection } from '../const';

export interface FeedbackCard {
  id: string;
  active: boolean;
  direction: FeedbackDirection;
  imageUrl: string;
  imageReference?: string;
  imageFile?: File;
}

export class FeedbackCard {
  constructor(direction?: FeedbackDirection, id?: string, imageUrl?: string) {
    this.imageUrl = imageUrl || '';
    this.active = false;
    this.direction = direction || FeedbackDirection.Giving;

    if (id) {
      this.id = id;
    }
  }
}
