enum Actions {
  Change = 'users/CHANGE',
  Update = 'users/UPDATE',
  UpdateRole = 'users/UPDATE_ROLE',
  UpdateGamificationLevels = 'users/UPDATE_GAMIFICATION_LEVELS',
  Error = 'users/ERROR',
}

export const UserActionTypes = {
  ...Actions,
};
