export interface StaticContent {
  id: string;
  content: string;
}

export class StaticContent {
  constructor(staticContent?: StaticContent) {
    this.content = staticContent ? staticContent.content : '';
  }
}

export function isStaticContent(item: any): item is StaticContent {
  return typeof item === 'object' && item.content;
}
