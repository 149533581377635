import { createSelector } from 'reselect';

import { ApplicationState } from 'modules/app-state';
import { applySearchParams, getSearchQuery } from 'modules/search';

const getMedia = (state: ApplicationState) => state.media.items;
const getMediaFilter = (state: ApplicationState) => state.media.filter;

export const mediaOptions = createSelector([getMedia], media => {
  let mediaArray: Array<{ value: string; label: string }> = [];
  media.forEach(item => {
    if (item.id && item.title) {
      mediaArray = [...mediaArray, { value: item.id, label: item.title }];
    }
  });
  return mediaArray;
});

export const searchMedia = createSelector(
  [getMedia, getSearchQuery, getMediaFilter],
  (content, query, filter) =>
    content
      .filter(media => (filter ? media.mediaType === filter : media))
      .filter(({ title, id }) => applySearchParams([id, title], query)),
);
