export type SlateMarkType = 'strong' | 'em' | 'code' | 'li' | 'u' | 's';

export enum SlateMarkEnum {
  l = 'li',
  b = 'strong',
  i = 'em',
  d = 'code',
  u = 'u',
  s = 's',
}

export const ModKeys = ['b', 'i', 'd', 'l', 'u', 's'];

export const BLOCK_TAGS: { [key: string]: string } = {
  p: 'paragraph',
};
