import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Editor } from 'slate-react';
import { useSelector } from 'react-redux';

import { Modal, Video, EmptyState } from 'modules/app-ui';
import { ApplicationState } from 'modules/app-state';
import { Media } from 'modules/media';

import { urlConverterService } from '../services';
import { insertBlock } from '../const';

interface Props {
  videoModal: boolean;
  showVideoModal: () => Promise<void>;
  editor?: React.MutableRefObject<Editor | null>;
  uploadMedia: (
    title: string,
    mediaType: 'Gallery' | 'Image' | 'Video',
    files: File[],
    videoUrl?: string,
  ) => Promise<void | firebase.firestore.DocumentReference>;
  setPageModified?: (state: boolean) => void;
  externalSubmitHandler?: (imageUrl: string) => void;
}

export const InsertVideoModal: React.FC<Props> = ({
  videoModal,
  showVideoModal,
  editor,
  uploadMedia,
  setPageModified,
  externalSubmitHandler,
}) => {
  const [mediaTitle, setMediaTitle] = useState('');
  const [chosenMediaOption, setChosenMediaOption] = useState<{
    mediaId: string;
    mediaTitle: string;
    mediaUrl: string;
  }>();
  const [videoUrl, setVideoUrl] = useState('');
  const [radioOption, setRadioOption] = useState<'choose' | 'upload'>('choose');
  const { items } = useSelector((state: ApplicationState) => state.media);
  const videos = items.filter(({ mediaType }) => mediaType === 'Video');

  const handleMediaTitleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setMediaTitle(event.currentTarget.value);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setRadioOption(value as 'choose' | 'upload');
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setVideoUrl(value);
  };

  const handleVideoClick = (event: React.MouseEvent<HTMLLIElement>) => {
    const { title, id, url } = event.currentTarget.dataset;
    if (!title || !id || !url) return;

    setChosenMediaOption({
      mediaId: id,
      mediaTitle: title,
      mediaUrl: url,
    });
  };

  const uploadVideo = async () => {
    await showVideoModal();
    if (setPageModified) setPageModified(true);

    if (radioOption === 'choose') {
      if (!chosenMediaOption) return;
      const validUrl = urlConverterService.convertYTUrl(
        chosenMediaOption.mediaUrl,
      );
      setChosenMediaOption(undefined);
      if (editor && editor.current) {
        insertBlock(editor.current, validUrl, 'video');
        return;
      }

      if (externalSubmitHandler) {
        externalSubmitHandler(validUrl);
      }

      return;
    }

    if (videoUrl && mediaTitle) {
      const docRef = await uploadMedia(mediaTitle, 'Video', [], videoUrl);
      if (!docRef) return;

      const { videoUrl: url } = (await docRef.get()).data() as Media;
      if (url) {
        const validUrl = urlConverterService.convertYTUrl(url);
        if (editor && editor.current) {
          insertBlock(editor.current, validUrl, 'video');
          return;
        }
        if (externalSubmitHandler) {
          externalSubmitHandler(validUrl);
        }
      }
    }
  };

  return (
    <Modal
      isOpen={videoModal}
      onClose={showVideoModal}
      onSuccess={uploadVideo}
      title={<FormattedMessage id="modal.insert-image" />}
      submitDisabled={
        (radioOption === 'choose' && !chosenMediaOption) ||
        (radioOption === 'upload' && !videoUrl)
      }
    >
      <div className="slate__modal">
        <div className="slate__modal-radio">
          <input
            type="radio"
            name="galleryOption"
            id="chooseOption"
            className="input--radio"
            value="choose"
            checked={radioOption === 'choose'}
            onChange={handleRadioChange}
          />
          <label htmlFor="chooseOption">Choose video from media</label>
        </div>

        {!videos.length && (
          <EmptyState showImage text="Currently there are no videos in media" />
        )}

        {videos.length > 0 && (
          <ul
            className={`slate__modal-section slate__modal__gallery ${
              radioOption === 'choose' ? 'visible' : ''
            }`}
          >
            {videos.map(video => {
              return (
                <li
                  key={video.id}
                  onClick={handleVideoClick}
                  data-title={video.title}
                  data-id={video.id}
                  data-url={video.videoUrl}
                  className={`slate__modal__video-item${
                    chosenMediaOption?.mediaId === video.id ? ' selected' : ''
                  }`}
                >
                  <img
                    src={urlConverterService.getVideoThumbnail(
                      video.videoUrl || '',
                    )}
                    alt={video.title}
                  />
                  <p>{video.title}</p>
                </li>
              );
            })}
          </ul>
        )}

        <div className="slate__modal-radio">
          <input
            type="radio"
            name="galleryOption"
            id="uploadOption"
            className="input--radio"
            value="upload"
            checked={radioOption === 'upload'}
            onChange={handleRadioChange}
          />
          <label htmlFor="uploadOption">Upload new video</label>
        </div>

        <div
          className={`slate__modal-section ${
            radioOption === 'upload' ? 'visible' : ''
          }`}
        >
          <div className="field">
            <label htmlFor="mediaTitle" className="field__lbl">
              <FormattedMessage id="form.title" />
            </label>
            <FormattedMessage id="form.title.placeholder">
              {placeholder => (
                <input
                  type="text"
                  className="input input--med"
                  name="mediaTitle"
                  placeholder={placeholder as string}
                  id="mediaTitle"
                  value={mediaTitle}
                  onChange={handleMediaTitleChange}
                />
              )}
            </FormattedMessage>
          </div>

          <Video videoUrl={videoUrl} handleChange={handleChange} />
        </div>
      </div>
    </Modal>
  );
};
