import { BadgeLevel } from '.';

import { PointAction } from 'modules/gamification';

export interface Badge {
  levels: BadgeLevel[];
  title: string;
  id: string;
  actionName?: PointAction;
}

export class Badge {
  constructor() {
    this.levels = [
      { imageUrl: '', level: 0 },
      { imageUrl: '', level: 1 },
      { imageUrl: '', level: 2 },
      { imageUrl: '', level: 3 },
    ];

    this.title = '';
  }
}
