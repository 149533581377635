import { Dispatch } from 'redux';

import { ApplicationState } from 'modules/app-state';

import { ArticleActions } from './actions';

import { Article } from '../models';
import { ArticleService } from '../services';

const getAllAsync = () => async (
  dispatch: Dispatch,
  getState: () => ApplicationState,
) => {
  if (getState().articles.items.length) {
    return;
  }

  dispatch(ArticleActions.change());

  const successFunction = (data: Article[]) =>
    dispatch(ArticleActions.update(data));

  const errorFunction = (error: string) =>
    dispatch(ArticleActions.error(error));

  const listenerProps = {
    successFunction,
    errorFunction,
  };

  ArticleService.Database.filterAsync(undefined, listenerProps);
  return;
};

const saveArticle = (article: Article, content: string) => (
  dispatch: Dispatch,
) => {
  dispatch(ArticleActions.change());

  const sendArticleData = (url?: string) => {
    ArticleService.Database.addAsync({
      id: article.id,
      title: article.title,
      subtitle: article.subtitle,
      slug: article.slug,
      content,
      featuredImageUrl: url || article.featuredImageUrl,
      videoUrl: article.videoUrl,
      published: article.published,
      publishDate: article.published ? article.publishDate : null,
    });
  };

  sendArticleData();
};

const removeArticle = (articleId: string) => () => {
  ArticleService.Database.removeAsync(articleId);
};

export const ArticlesThunks = {
  getAllAsync,
  saveArticle,
  removeArticle,
};
