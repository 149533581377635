import { FirebaseDatabaseService, StorageService } from 'modules/firebase';

import { Media } from '../models';

const moduleName = 'media';

export const MediaService = {
  Storage: new StorageService<File>(moduleName + '/'),
  Database: new FirebaseDatabaseService<Media>(moduleName),
};
