import { useState, useCallback } from 'react';
import { Value, Operation } from 'slate';
import { List } from 'immutable';

export const useSlateValue = (
  item?: Value,
): [
  Value,
  (change: { operations: List<Operation>; value: Value }) => void,
  (value: Value) => void,
] => {
  const getInitialValue = () => {
    return Value.fromJS({
      document: {
        nodes: [
          {
            object: 'block',
            type: 'paragraph',
          },
        ],
      },
    });
  };

  const [currentValue, setValue] = useState(item || getInitialValue());

  const onReset = useCallback((newValue?: Value) => {
    setValue(newValue || getInitialValue());
  }, []);

  const updateValue = (change: {
    operations: List<Operation>;
    value: Value;
  }) => {
    setValue(change.value);
  };

  return [currentValue, updateValue, onReset];
};
