import { Dispatch, AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { FirebaseAuthService } from 'modules/firebase';
import { UserThunks, User } from 'modules/users';
import { SettingsThunks } from 'modules/settings';

import { AuthenticationActions } from './actions';

const firebaseAuth = new FirebaseAuthService();

const loginAsync = (
  email: string,
  password: string,
  rememberMe: boolean,
) => async (dispatch: Dispatch) => {
  dispatch(AuthenticationActions.change());

  const error = await firebaseAuth.loginWithEmailAndPasswordAsync(
    email,
    password,
    rememberMe,
  );

  if (error) {
    dispatch(AuthenticationActions.error(error));
  }
};

function loginSuccess(
  user: User,
  dispatch: ThunkDispatch<any, any, AnyAction>,
) {
  dispatch(SettingsThunks.getModulesAsync());
  dispatch(SettingsThunks.getLogoAsync());
  dispatch(UserThunks.getAllUsers());
  dispatch(AuthenticationActions.login(user));
}

const loginWithPersistedUser = () => (dispatch: Dispatch) => {
  dispatch(AuthenticationActions.change());

  firebaseAuth.initAuthStateListener(
    (user: User) => loginSuccess(user, dispatch),
    (error?: string) => dispatch(AuthenticationActions.error(error)),
  );
};

const logout = () => async (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  await firebaseAuth.logoutAsync();
  dispatch(AuthenticationActions.logout());

  return;
};

const forgotPassword = (email: string) => async () => {
  await firebaseAuth.sendPasswordResetEmail(email);

  return;
};

export const AuthenticationThunkActions = {
  loginAsync,
  loginWithPersistedUser,
  logout,
  forgotPassword,
};
