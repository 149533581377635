import React from 'react';
import { Link } from 'react-router-dom';
import { MdDelete } from 'react-icons/md';
import { FormattedMessage, FormattedDate } from 'react-intl';

import { RoutePath } from 'modules/navigation';
import { User } from 'modules/users';

import { Feedback } from '../models';

interface Props {
  id: string;
  feedback: Feedback;
  toggleModal: VoidFunction;
  userFrom?: User;
  userTo?: User;
}

export const FeedbackItem: React.FC<Props> = ({
  id,
  feedback,
  toggleModal,
  userFrom,
  userTo,
}) => (
  <div className="itemlist--simple__item">
    <div className="itemlist--simple__item__content">
      <Link to={`${RoutePath.AllFeedbacks}/${id}`}>
        <h2 className="t-zeta s-bottom--sml">
          <FormattedMessage id="form.from" />:{' '}
          <strong>
            {userFrom && `${userFrom.firstName} ${userFrom.lastName}`}
          </strong>
          <br /> <FormattedMessage id="form.to" />:{' '}
          <strong>{userTo && `${userTo.firstName} ${userTo.lastName}`}</strong>
        </h2>
      </Link>
      <p className="t-eta o-60">
        <FormattedDate
          value={new Date(feedback.date)}
          year="numeric"
          month="long"
          day="2-digit"
        />
      </p>
    </div>

    <div className="itemlist--simple__item__action">
      <div className="btn-group">
        <Link
          className="btn btn--secondary btn--sml"
          to={`${RoutePath.AllFeedbacks}/${id}`}
        >
          <FormattedMessage id="button.edit" />
        </Link>

        <button
          className="btn btn--ghost btn--sml btn--hasIcon"
          onClick={toggleModal}
          data-id={id}
        >
          <MdDelete size={16} />
        </button>
      </div>
    </div>
  </div>
);
