import React from 'react';
import { FormattedMessage } from 'react-intl';
import { OptionsType } from 'react-select';

import { OrderFilter } from '../models';

export const OrderOptions: OptionsType<{
  value: OrderFilter;
  label: JSX.Element;
}> = [
  {
    value: 'asc',
    label: <FormattedMessage id="form.date-order.asc" />,
  },
  {
    value: 'desc',
    label: <FormattedMessage id="form.date-order.desc" />,
  },
];
