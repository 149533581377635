export interface Media {
  id: string;
  title: string;
  mediaType: 'Image' | 'Video' | 'Gallery';
  imageReferences: string[];
  imageUrls: string[];
  imageBlobs?: string[];
  imageFiles?: File[];
  videoUrl?: string;
}

export class Media {
  constructor(object?: Partial<Media>) {
    if (object?.id) {
      this.id = object.id;
    }

    this.title = object?.title || '';
    this.mediaType = object?.mediaType || 'Gallery';
    this.imageReferences = object?.imageReferences || [];
    this.imageUrls = object?.imageUrls || [];
    this.videoUrl = object?.videoUrl || '';
  }
}
