import { Reward } from 'modules/rewards';

export interface RedeemReward {
  id: string;
  date: number;
  reward: Reward;
  shipped: boolean;
  shippingInfo: {
    city: string;
    zipCode: string;
    address: string;
  };
  userId: string;
}

export class RedeemReward {
  constructor(redeemReward: RedeemReward) {
    this.date = redeemReward.date || Date.now();
    this.reward = redeemReward.reward || new Reward();
    this.shipped = redeemReward.shipped || false;
    this.shippingInfo = redeemReward.shippingInfo || {
      city: '',
      zipCode: '',
      address: '',
    };
    this.userId = redeemReward.userId || '';

    if (redeemReward.id) {
      this.id = redeemReward.id;
    }
  }
}
