import { createAction } from 'modules/app-state';

import { SearchActionTypes } from './types';

import { OrderFilter } from '../models';

export const SearchActions = {
  querySearch: (query: string) =>
    createAction(SearchActionTypes.QuerySearch, {
      query,
    }),

  clearSearch: () => createAction(SearchActionTypes.ClearSearch),

  updateOrderFilter: (orderFilter: OrderFilter) =>
    createAction(SearchActionTypes.UpdateOrderFilter, { orderFilter }),
};
