import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { PageHeaderAction, useModal, usePagination, PageHeader, Loading, EmptyState } from 'modules/app-ui';
import { ApplicationState } from 'modules/app-state';
import { RoutePath } from 'modules/navigation';
import { SearchActions, SearchQuery } from 'modules/search';
import {
  searchUsers,
  UserActions,
  UserItem,
  UserThunks,
  User,
} from 'modules/users';

interface DispatchProps {
  removeUser: (userId: string) => void;
  search: SearchQuery;
  setError: (error: string) => void;
}

interface ReduxProps {
  users: User[];
  usersAreChanging: boolean;
  error?: string;
}

type Props = DispatchProps & ReduxProps;

export const UserList: React.FC<Props> = ({
  users,
  usersAreChanging,
  error,
  search,
  removeUser,
  setError,
}) => {
  const [toggleModal, Modal] = useModal(removeUser);
  const [pagedItems, Pagination] = usePagination(users);

  useEffect(() => {
    setError('');
  }, [setError]);

  return (
    <section className="contentarea">
      <PageHeader
        onSearch={search}
        title={<FormattedMessage id="users" />}
        actions={[
          new PageHeaderAction(
            (<FormattedMessage id="users.invite" />),
            `${RoutePath.UserInvite}`,
          ),
        ]}
      />

      {error && <div className="alert alert--warning">{error}</div>}

      <Loading fullPage isLoading={usersAreChanging}>
        {!users.length && <EmptyState showImage />}

        {pagedItems.map(user => (
          <UserItem user={user} key={user.id} toggleModal={toggleModal} />
        ))}

        <Pagination />
        <Modal />
      </Loading>
    </section>
  );
};

export default connect<ReduxProps, DispatchProps, null, ApplicationState>(
  state => ({
    users: searchUsers(state),
    usersAreChanging: state.users.loading,
    error: state.users.error,
  }),
  {
    removeUser: UserThunks.removeUser,
    search: SearchActions.querySearch,
    setError: UserActions.error,
  },
)(UserList);
