import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';

import { useFormatMessage } from 'modules/localisation';

import { Modal } from '../components';

export const useModal = (
  onSuccess: (entityId: string, type?: string) => void,
  onRedirect?: VoidFunction,
): [
  (event?: React.MouseEvent<HTMLButtonElement>) => void,
  () => JSX.Element,
] => {
  const [isOpen, setIsOpen] = useState(false);
  const [entityId, setEntityId] = useState('');
  const [entityTitle, setEntityTitle] = useState('');
  const [formatMessage] = useFormatMessage();

  const removeItem = () => {
    onSuccess(entityId);
    setIsOpen(false);

    toast.success(formatMessage('toast.modal.delete', entityTitle || entityId));

    if (onRedirect) {
      onRedirect();
    }
  };

  const toggleModal = (event?: React.MouseEvent<HTMLButtonElement>) => {
    /**
     * Toggle current modal open state
     */
    setIsOpen(!isOpen);

    /**
     * Set entity id if event exists
     */
    if (event) {
      const { id, title } = event.currentTarget.dataset;

      if (id) {
        setEntityId(id);
      }

      if (title) {
        setEntityTitle(title);
      }
    }
  };

  const onToggle = useCallback(toggleModal, [isOpen]);

  const modalComponent = () => (
    <Modal
      isOpen={isOpen}
      onClose={onToggle}
      onSuccess={removeItem}
      type="danger"
    />
  );

  return [toggleModal, modalComponent];
};
