import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Select, { ValueType } from 'react-select';

import {
  Feedback,
  FeedbackItem,
  FeedbackThunks,
  sortFeedbackByDate,
} from 'modules/feedback';
import { ApplicationState } from 'modules/app-state';
import {
  OrderFilter,
  SearchActions,
  SearchQuery,
  OrderOptions,
} from 'modules/search';
import { User } from 'modules/users';
import {
  usePagination,
  useModal,
  Loading,
  EmptyState,
  PageHeader,
} from 'modules/app-ui';

interface DispatchProps {
  getAll: VoidFunction;
  removeFeedback: (id: string) => void;
  search: SearchQuery;
  updateOrderFilter: (filter: OrderFilter) => void;
}

interface ReduxProps {
  feedback: Feedback[];
  users: User[];
  error?: string;
  feedbackAreChanging: boolean;
}

interface OwnProps {
  forSummaryModule?: boolean;
}

type Props = DispatchProps & ReduxProps & OwnProps;

const FeedbackList: React.FC<Props> = ({
  getAll,
  feedback,
  feedbackAreChanging,
  error,
  search,
  updateOrderFilter,
  removeFeedback,
  users,
  forSummaryModule,
}) => {
  const [toggleModal, Modal] = useModal(removeFeedback);
  const [dateFilter, setDateFilter] = useState<
    ValueType<{
      value: OrderFilter;
      label: JSX.Element;
    }>
  >(OrderOptions[1]);

  const handleFilterChange = (
    value: ValueType<{
      value: OrderFilter;
      label: JSX.Element;
    }>,
  ) => setDateFilter(value);

  useEffect(() => {
    getAll();
  }, [getAll]);

  useEffect(() => {
    if (dateFilter) {
      updateOrderFilter((dateFilter as any).value);
    }
  }, [updateOrderFilter, dateFilter]);

  const [pagedItems, Pagination] = usePagination(feedback);

  if (forSummaryModule) {
    const size = 5;

    return (
      <section>
        <Loading isLoading={feedbackAreChanging || !users.length}>
          {!pagedItems.length && <EmptyState showImage />}

          {pagedItems.slice(0, size).map(feedback => (
            <FeedbackItem
              key={feedback.id}
              id={feedback.id}
              feedback={feedback}
              toggleModal={toggleModal}
              userFrom={users.find(user => user.id === feedback.feedbackFrom)}
              userTo={users.find(user => user.id === feedback.feedbackTo)}
            />
          ))}
        </Loading>

        <Modal />
      </section>
    );
  }

  return (
    <section className="contentarea v-feedback">
      <PageHeader
        onSearch={search}
        title={<FormattedMessage id="feedback" />}
      />

      {error && <div className="alert alert--warning">{error}</div>}

      <div className="g-three">
        <div className="g-three__item">
          <div className="field">
            <label htmlFor="order" className="field__lbl">
              <FormattedMessage id="form.date-order" />
            </label>
            <Select
              value={dateFilter}
              onChange={handleFilterChange}
              options={OrderOptions}
              id="order"
              className="input--select s-bottom--lrg"
            />
          </div>
        </div>
      </div>

      <Loading fullPage isLoading={feedbackAreChanging}>
        {!pagedItems.length && <EmptyState showImage />}

        {pagedItems.map(feedback => (
          <FeedbackItem
            key={feedback.id}
            id={feedback.id}
            feedback={feedback}
            toggleModal={toggleModal}
            userFrom={users.find(user => user.id === feedback.feedbackFrom)}
            userTo={users.find(user => user.id === feedback.feedbackTo)}
          />
        ))}

        <Pagination />
        <Modal />
      </Loading>
    </section>
  );
};

export default connect<ReduxProps, DispatchProps, OwnProps, ApplicationState>(
  state => ({
    users: state.users.items,
    feedback: sortFeedbackByDate(state),
    error: state.feedback.error,
    feedbackAreChanging: state.feedback.loading,
  }),
  {
    getAll: FeedbackThunks.getAllAsync,
    search: SearchActions.querySearch,
    removeFeedback: FeedbackThunks.removeFeedback,
    updateOrderFilter: SearchActions.updateOrderFilter,
  },
)(FeedbackList);
