import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import { MdDelete, MdSave, MdFindInPage } from 'react-icons/md';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { toast } from 'react-toastify';
import { isWebUri } from 'valid-url';

import { PageHeaderAction } from 'modules/app-ui/models';
import { ApplicationState } from 'modules/app-state';
import {
  HTMLSerializer,
  SlateEditor,
  useSlateValue,
  InsertImageModal,
  InsertVideoModal,
  urlConverterService,
} from 'modules/editor';
import { useFormatMessage } from 'modules/localisation';
import { Media, MediaThunks } from 'modules/media';
import 'react-dates/lib/css/_datepicker.css';
import { RoutePath } from 'modules/navigation';
import {
  PageGuard,
  EmptyState,
  useModal,
  usePageModified,
  Loading,
  PageHeader,
} from 'modules/app-ui';

import { Article, ArticlesThunks } from '..';

interface DispatchProps {
  getAll: VoidFunction;
  getAllMedia: VoidFunction;
  saveArticle: (article: Article, content: string) => void;
  removeArticle: (articleId: string) => void;
  uploadMedia: (
    title: string,
    mediaType: 'Gallery' | 'Image' | 'Video',
    files: File[],
    videoUrl?: string,
  ) => Promise<void | firebase.firestore.DocumentReference>;
}

interface ReduxProps {
  article: Article;
  articlesAreChanging: boolean;
  galleryMedia: Media[];
  mediaIsChanging: boolean;
}

type Props = DispatchProps & ReduxProps & RouteComponentProps<any>;

const ArticleDetails: React.FC<Props> = ({
  article,
  saveArticle,
  removeArticle,
  getAll,
  articlesAreChanging,
  history,
  match,
  location,
  uploadMedia,
  galleryMedia,
  getAllMedia,
  mediaIsChanging,
}) => {
  const redirectToList = () => {
    history.push(RoutePath.ArticlesAll);
  };

  const [toggleModal, Modal] = useModal(removeArticle, redirectToList);
  const [focusedDate, setFocusedDate] = useState(false);
  const [articleState, setArticleState] = useState(article);
  const [error, setError] = useState<JSX.Element>();
  const [formatMessage] = useFormatMessage();
  const [pageModified, setPageModified] = usePageModified(
    'articles-contentarea',
  );
  const [coverPhotoModal, toggleCoverPhotoModal] = useState(false);
  const [videoModal, toggleVideoModal] = useState(false);
  const [coverPhotoExists, setCoverPhotoExists] = useState(true);
  const [contentValue, onContentChange, resetValue] = useSlateValue(
    article && HTMLSerializer.init().deserialize(article.content),
  );

  const checkImage = (imageSrc: string) => {
    const img = new Image();
    img.src = imageSrc;
    img.onload = () => setCoverPhotoExists(true);
    img.onerror = () => setCoverPhotoExists(false);
  };

  useEffect(() => {
    getAll();
    getAllMedia();
  }, [getAll, getAllMedia]);

  useEffect(() => {
    if (!article || articleState) {
      return;
    }

    const tempArticle = { ...article };
    if (!tempArticle.publishDate) {
      tempArticle.publishDate = Date.now();
    }

    setArticleState(tempArticle);
    resetValue(HTMLSerializer.init().deserialize(article.content));
  }, [article, resetValue, articleState]);

  useEffect(() => {
    if (articleState?.featuredImageUrl) {
      checkImage(articleState.featuredImageUrl);
    }
  }, [articleState]);

  if (!articleState) {
    if (!articlesAreChanging) {
      return <EmptyState showImage />;
    }

    return <Loading isLoading fullPage />;
  }

  const {
    id,
    title,
    subtitle,
    slug,
    featuredImageUrl,
    videoUrl,
    publishDate = Date.now(),
    published,
  } = articleState;

  const isFormValid = () => {
    if (
      !title ||
      !slug ||
      HTMLSerializer.init().serialize(contentValue) === '<p></p>'
    ) {
      setError(
        <>
          <strong>{formatMessage('error.missing')}:</strong>
          {!title && <div>{formatMessage('form.title')}</div>}
          {!slug && <div>{formatMessage('form.slug')}</div>}
          {HTMLSerializer.init().serialize(contentValue) === '<p></p>' && (
            <div>{formatMessage('form.content')}</div>
          )}
        </>,
      );
      return false;
    }

    return true;
  };

  const showCoverPhotoModal = async () => {
    await toggleCoverPhotoModal(!coverPhotoModal);
  };

  const showVideoModal = async () => {
    await toggleVideoModal(!videoModal);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;

    setArticleState({
      ...articleState,
      [name]: value,
    });

    setPageModified(true);
  };

  const handlePublish = () => {
    if (!isFormValid()) {
      return;
    }

    const tempArticle = { ...articleState };
    tempArticle.published = !tempArticle.published;

    setPageModified(false);

    saveArticle(tempArticle, HTMLSerializer.init().serialize(contentValue));

    toast.success(
      formatMessage(
        tempArticle.published
          ? 'toast.article.publish'
          : 'toast.article.unpublish',
        title,
      ),
    );

    redirectToList();
  };

  const handleDateChange = (date: moment.Moment | null) => {
    if (!date) {
      return;
    }

    setArticleState({
      ...articleState,
      publishDate: +moment(date).toDate(),
    });

    setPageModified(true);
  };

  const onFocusChange = (arg: { focused: boolean | null }) => {
    setFocusedDate(arg.focused || false);
  };

  const generateSlug = () => {
    if (!title) {
      return;
    }

    const newSlug = title
      .trim()
      .toLowerCase()
      .split(' ')
      .join('-')
      .replace('---', '-');

    setArticleState({
      ...articleState,
      slug: newSlug,
    });
  };

  const handleImageChange = (imageUrl: string) => {
    setArticleState({
      ...articleState,
      featuredImageUrl: imageUrl,
    });

    setPageModified(true);
  };

  const handleVideoChange = (videoUrl: string) => {
    setArticleState({
      ...articleState,
      videoUrl,
    });

    setPageModified(true);
  };

  const removeFeaturedImage = () => {
    setArticleState({
      ...articleState,
      featuredImageUrl: '',
    });

    setPageModified(true);
  };

  const removeVideo = () => {
    setArticleState({
      ...articleState,
      videoUrl: '',
    });

    setPageModified(true);
  };

  const saveArticleCard = async () => {
    if (!isFormValid()) {
      return;
    }

    await setPageModified(false);

    saveArticle(articleState, HTMLSerializer.init().serialize(contentValue));

    toast.success(
      formatMessage(id ? 'toast.article.update' : 'toast.article.save', title),
    );

    redirectToList();
  };

  return (
    <section className="contentarea v-articles" id="articles-contentarea">
      <PageHeader
        title={
          <FormattedMessage
            id={
              match.params.id === 'new'
                ? 'articles.create.title'
                : 'articles.details.title'
            }
          />
        }
        backAction={
          new PageHeaderAction(
            (<FormattedMessage id="articles.back-to-list" />),
            RoutePath.ArticlesAll,
          )
        }
      />

      {error && (
        <div className="alert alert--warning s-bottom--med">{error}</div>
      )}

      <article className="article card">
        <div className="field">
          <label htmlFor="title" className="field__lbl t-mandatory">
            <FormattedMessage id="form.title" />
          </label>
          <FormattedMessage id="form.title.placeholder">
            {placeholder => (
              <input
                type="text"
                className="input input--med"
                name="title"
                id="title"
                placeholder={placeholder as string}
                value={title}
                onChange={handleChange}
                onBlur={generateSlug}
              />
            )}
          </FormattedMessage>
        </div>

        <div className="field">
          <label htmlFor="subtitle" className="field__lbl">
            <FormattedMessage id="form.subtitle" />
          </label>
          <FormattedMessage id="form.subtitle.placeholder">
            {placeholder => (
              <input
                type="text"
                className="input input--med"
                name="subtitle"
                placeholder={placeholder as string}
                id="subtitle"
                value={subtitle}
                onChange={handleChange}
              />
            )}
          </FormattedMessage>
        </div>

        <div className="field">
          <label htmlFor="slug" className="field__lbl t-mandatory">
            <FormattedMessage id="form.slug" />
          </label>
          <FormattedMessage id="form.subtitle.placeholder">
            {placeholder => (
              <input
                type="text"
                className="input input--med"
                name="slug"
                id="slug"
                placeholder={placeholder as string}
                value={slug || ''}
                onChange={handleChange}
              />
            )}
          </FormattedMessage>
        </div>

        <div className="g-three">
          <div className="g-three__item">
            <div className="field">
              <label className="field__lbl">
                <FormattedMessage id="form.cover-photo" />
              </label>

              <div className="imageuploader">
                <Loading isLoading={mediaIsChanging}>
                  {!featuredImageUrl && (
                    <div className="imageuploader__image">
                      <div className="card t-center s-top--lrg">
                        <MdFindInPage
                          className="o-20 s-bottom--med"
                          size={64}
                        />
                        <p className="t-zeta o-60">
                          <FormattedMessage id="form.cover-photo.placeholder" />
                        </p>
                      </div>
                    </div>
                  )}

                  {featuredImageUrl && coverPhotoExists && (
                    <>
                      <img src={featuredImageUrl} alt="Not found" />

                      <div className="imageuploader__remove">
                        <button
                          className="btn btn--dark btn--close"
                          onClick={removeFeaturedImage}
                        >
                          <MdDelete size={16} />
                        </button>
                      </div>
                    </>
                  )}
                  {featuredImageUrl && !coverPhotoExists && (
                    <div className="s-top--lrg">
                      <EmptyState text="Image no loger exists" showImage />
                    </div>
                  )}
                  <div className="imageloader__action">
                    <button
                      onClick={showCoverPhotoModal}
                      className="btn btn--primary btn--med s-top--med"
                    >
                      <FormattedMessage
                        id={
                          !featuredImageUrl
                            ? 'button.upload-image'
                            : 'button.change-image'
                        }
                      />
                    </button>
                  </div>
                </Loading>
              </div>
            </div>
          </div>
          <div className="g-three__item">
            <div className="field">
              <label className="field__lbl">
                <FormattedMessage id="form.video-url" />
              </label>
              <div className="imageuploader">
                <Loading isLoading={mediaIsChanging}>
                  <div className="imageuploader__image">
                    {!isWebUri(videoUrl) && (
                      <div className="card t-center s-top--lrg">
                        <MdFindInPage
                          className="o-20 s-bottom--med"
                          size={64}
                        />
                        <p className="t-zeta o-60">
                          <FormattedMessage id="form.video-url.placeholder" />
                        </p>
                      </div>
                    )}

                    {isWebUri(videoUrl) && (
                      <>
                        <iframe
                          title="video"
                          src={urlConverterService.convertYTUrl(videoUrl)}
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        />

                        <div className="imageuploader__remove">
                          <button
                            className="btn btn--dark btn--close"
                            onClick={removeVideo}
                          >
                            <MdDelete size={16} />
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="imageloader__action">
                    <button
                      onClick={showVideoModal}
                      className="btn btn--primary btn--med s-top--med"
                    >
                      <FormattedMessage id="form.video-url.placeholder" />
                    </button>
                  </div>
                </Loading>
              </div>
            </div>
          </div>
        </div>

        <div className="field">
          <label htmlFor="content" className="field__lbl t-mandatory">
            <FormattedMessage id="form.content" />
          </label>
          <SlateEditor
            content={contentValue}
            onContentChange={onContentChange}
            modifiedHandler={setPageModified}
            uploadMedia={uploadMedia}
            location={location}
            galleryMedia={galleryMedia}
            mediaIsChanging={mediaIsChanging}
            setPageModified={setPageModified}
          />
        </div>

        <div className="field">
          <label htmlFor="publish-date" className="field__lbl">
            <FormattedMessage id="form.publish-date" />
          </label>
          <SingleDatePicker
            date={moment(publishDate ? publishDate : Date.now())}
            onDateChange={handleDateChange}
            focused={focusedDate}
            onFocusChange={onFocusChange}
            id="publish-date"
            displayFormat="DD/MM/YYYY"
            numberOfMonths={1}
            openDirection="up"
            isOutsideRange={() => false}
            showDefaultInputIcon={true}
          />
        </div>

        <footer className="card__footer">
          <div className="btn-group">
            <button
              className="btn btn--primary btn--med btn--hasIcon"
              onClick={saveArticleCard}
              disabled={!pageModified}
            >
              <MdSave size={16} />{' '}
              <span>
                <FormattedMessage
                  id={published ? 'button.save' : 'button.save-draft'}
                />
              </span>
            </button>

            <button
              className={`btn btn--med ${
                published ? 'btn--danger' : 'btn--primary'
              }`}
              onClick={handlePublish}
            >
              <FormattedMessage
                id={published ? 'button.unpublish' : 'button.publish'}
              />
            </button>

            {id && (
              <button
                className="btn btn--ghost btn--med btn--hasIcon"
                onClick={toggleModal}
                data-id={id}
                data-title={title}
              >
                <MdDelete size={16} />
              </button>
            )}
          </div>
        </footer>

        <Modal />

        <InsertImageModal
          showImageModal={showCoverPhotoModal}
          imageModal={coverPhotoModal}
          uploadMedia={uploadMedia}
          externalSubmitHandler={handleImageChange}
        />

        <InsertVideoModal
          videoModal={videoModal}
          showVideoModal={showVideoModal}
          uploadMedia={uploadMedia}
          externalSubmitHandler={handleVideoChange}
        />

        <PageGuard when={pageModified} />
      </article>
    </section>
  );
};

export default connect<
  ReduxProps,
  DispatchProps,
  RouteComponentProps<any>,
  ApplicationState
>(
  (state, ownProps) => {
    const id = ownProps.match.params.id;

    return {
      article:
        id === 'new'
          ? new Article()
          : state.articles.items.filter(item => item.slug === id)[0],
      articlesAreChanging: state.articles.loading,
      galleryMedia: state.media.items,
      mediaIsChanging: state.media.loading,
    };
  },
  {
    getAll: ArticlesThunks.getAllAsync,
    saveArticle: ArticlesThunks.saveArticle,
    removeArticle: ArticlesThunks.removeArticle,
    uploadMedia: MediaThunks.uploadMediaFromSlate as any,
    getAllMedia: MediaThunks.getAllAsync,
  },
)(ArticleDetails);
