import React, { useState, HTMLAttributes } from 'react';
import { Omit } from 'react-redux';

type Props = Omit<HTMLAttributes<HTMLInputElement>, 'onChange'> & {
  onChange: (query: string) => void;
};

export const Search: React.FC<Props> = ({ onChange, ...rest }) => {
  const [query, setQuery] = useState('');

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.currentTarget.value);

    return query.length ? onChange(e.currentTarget.value) : onChange('');
  };

  return (
    <input
      {...rest}
      id="search"
      className="input input--med"
      onChange={handleSearch}
      value={query}
    />
  );
};
