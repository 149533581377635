import { connect } from 'react-redux';
import { IntlProvider, addLocaleData } from 'react-intl';
import de from 'react-intl/locale-data/de';
import en from 'react-intl/locale-data/en';
import React, { useEffect, useState } from 'react';

import { ApplicationState } from 'modules/app-state';
import { User } from 'modules/users';

import { LocalisationThunks } from '../redux/thunks';
import { Localisation, Language } from '../models';
import { applicationStrings } from '../intl';

addLocaleData([...de, ...en]);

interface DispatchProps {
  syncStrings: () => void;
}

interface OwnProps {
  languages: Localisation[];
  user?: User;
  users: User[];
}

type Props = DispatchProps & OwnProps;

const Intl: React.FC<Props> = ({
  syncStrings,
  languages,
  user,
  users,
  ...rest
}) => {
  const [locale, setLocale] = useState<Language>('en');
  const [strings, setStrings] = useState(
    languages.filter(lang => lang.id === `dashboard-${locale}`)[0],
  );

  useEffect(() => {
    if (!user) {
      return;
    }

    const currentUser = users.find(userData => userData.id === user.id);

    if (!currentUser || !currentUser.privateProfile) {
      return;
    }

    setLocale(currentUser.privateProfile.dashboardLanguage || 'en');
  }, [user, users]);

  useEffect(() => {
    setStrings(languages.filter(lang => lang.id === `dashboard-${locale}`)[0]);
  }, [languages, locale]);

  useEffect(() => {
    syncStrings();
  }, [syncStrings]);

  const fallbackStrings =
    locale === 'en' ? applicationStrings.en : applicationStrings.de;

  return (
    <IntlProvider
      messages={{ ...fallbackStrings, ...strings }}
      locale={locale}
      {...rest}
    />
  );
};

// This function will map the current redux state to the props for the component that it is "connected" to.
// When the state of the redux store changes, this function will be called, if the props that come out of
// this function are different, then the component that is wrapped is re-rendered.
export default connect<
  IntlProvider.Props & OwnProps,
  DispatchProps,
  React.FC<Props>,
  ApplicationState
>(
  state => ({
    languages: state.localisation.items,
    user: state.auth.user,
    users: state.users.items,
  }),
  {
    syncStrings: LocalisationThunks.getAllAsync,
  },
)(Intl);
