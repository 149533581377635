import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDropzone } from 'react-dropzone';
import { Editor } from 'slate-react';
import { MdCloudUpload } from 'react-icons/md';
import { useSelector } from 'react-redux';

import { Modal, EmptyState } from 'modules/app-ui';
import { ApplicationState } from 'modules/app-state';
import { Media } from 'modules/media';

import { insertBlock } from '../const';

interface Props {
  imageModal: boolean;
  showImageModal: () => Promise<void>;
  editor?: React.MutableRefObject<Editor | null>;
  uploadMedia: (
    title: string,
    mediaType: 'Gallery' | 'Image' | 'Video',
    files: File[],
  ) => Promise<void | firebase.firestore.DocumentReference>;
  setPageModified?: (state: boolean) => void;
  externalSubmitHandler?: (imageUrl: string) => void;
}

export const InsertImageModal: React.FC<Props> = ({
  imageModal,
  showImageModal,
  editor,
  uploadMedia,
  setPageModified,
  externalSubmitHandler,
}) => {
  const [mediaTitle, setMediaTitle] = useState('');
  const [chosenMediaOption, setChosenMediaOption] = useState<{
    mediaId: string;
    mediaTitle: string;
    mediaUrl: string;
  }>();
  const [radioOption, setRadioOption] = useState<'choose' | 'upload'>('choose');
  const { items } = useSelector((state: ApplicationState) => state.media);
  const images = items.filter(({ mediaType }) => mediaType === 'Image');

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    multiple: false,
  });

  const handleMediaTitleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setMediaTitle(event.currentTarget.value);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setRadioOption(value as 'choose' | 'upload');
  };

  const handleImageClick = (event: React.MouseEvent<HTMLLIElement>) => {
    const { title, id, url } = event.currentTarget.dataset;
    if (!title || !id || !url) return;

    setChosenMediaOption({
      mediaId: id,
      mediaTitle: title,
      mediaUrl: url,
    });
  };

  const uploadImages = async () => {
    await showImageModal();
    if (setPageModified) setPageModified(true);

    if (radioOption === 'choose') {
      if (!chosenMediaOption) return;
      setChosenMediaOption(undefined);

      if (editor && editor.current) {
        insertBlock(editor.current, chosenMediaOption.mediaUrl, 'image');
        return;
      }

      if (externalSubmitHandler) {
        externalSubmitHandler(chosenMediaOption.mediaUrl);
      }
      return;
    }

    if (acceptedFiles.length) {
      const docRef = await uploadMedia(mediaTitle, 'Image', acceptedFiles);
      if (!docRef) return;

      const { imageUrls } = (await docRef.get()).data() as Media;

      if (editor && editor.current) {
        insertBlock(editor.current, imageUrls[0], 'image');
        return;
      }

      if (externalSubmitHandler) {
        externalSubmitHandler(imageUrls[0]);
      }
    }
  };

  return (
    <Modal
      isOpen={imageModal}
      onClose={showImageModal}
      onSuccess={uploadImages}
      title={<FormattedMessage id="modal.insert-image" />}
      submitDisabled={
        (radioOption === 'choose' && !chosenMediaOption) ||
        (radioOption === 'upload' && (!acceptedFiles.length || !mediaTitle))
      }
    >
      <div className="slate__modal">
        <div className="slate__modal-radio">
          <input
            type="radio"
            name="galleryOption"
            id="chooseOption"
            className="input--radio"
            value="choose"
            checked={radioOption === 'choose'}
            onChange={handleRadioChange}
          />
          <label htmlFor="chooseOption">Choose image from media</label>
        </div>

        {!images.length && (
          <EmptyState showImage text="Currently there are no images in media" />
        )}
        {images.length > 0 && (
          <ul
            className={`slate__modal-section slate__modal__gallery ${
              radioOption === 'choose' ? 'visible' : ''
            }`}
          >
            {images.map(image => (
              <li
                key={image.id}
                onClick={handleImageClick}
                data-title={image.title}
                data-id={image.id}
                data-url={image.imageUrls[0]}
                className={`slate__modal__gallery-item${
                  chosenMediaOption?.mediaId === image.id ? ' selected' : ''
                }`}
              >
                {image.imageUrls.map(image => (
                  <img
                    className="slate__modal__image"
                    key={image}
                    src={image}
                    alt={image}
                  />
                ))}
              </li>
            ))}
          </ul>
        )}

        <div className="slate__modal-radio">
          <input
            type="radio"
            name="galleryOption"
            id="uploadOption"
            className="input--radio"
            value="upload"
            checked={radioOption === 'upload'}
            onChange={handleRadioChange}
          />
          <label htmlFor="uploadOption">Upload new image</label>
        </div>

        <div
          className={`slate__modal-section ${
            radioOption === 'upload' ? 'visible' : ''
          }`}
        >
          <div className="field">
            <label htmlFor="mediaTitle" className="field__lbl">
              <FormattedMessage id="form.title" />
            </label>
            <FormattedMessage id="form.title.placeholder">
              {placeholder => (
                <input
                  type="text"
                  className="input input--med"
                  name="mediaTitle"
                  placeholder={placeholder as string}
                  id="mediaTitle"
                  value={mediaTitle}
                  onChange={handleMediaTitleChange}
                />
              )}
            </FormattedMessage>
          </div>

          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <div className="u-well u-well--dropzone t-center">
              {!acceptedFiles.length && (
                <>
                  <MdCloudUpload className="o-20 s-bottom--med" size={64} />
                  <p>
                    <FormattedMessage id="modal.image-select" />
                  </p>
                </>
              )}

              {acceptedFiles.length > 0 && (
                <ul className="slate__modal__uploaded-images">
                  {acceptedFiles.map((file, index) => (
                    <li key={index}>
                      <img src={URL.createObjectURL(file)} alt={file.name} />
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
