import React from 'react';
import { toast } from 'react-toastify';

import { useFormatMessage } from 'modules/localisation';
import { ImageUploader } from 'modules/media';

import { Logo } from '../models';

interface Props {
  logo?: Logo;
  saveImage: (image: File) => void;
  removeImage: VoidFunction;
}

export const AdministrationLogo: React.FC<Props> = ({
  logo,
  saveImage,
  removeImage,
}) => {
  const [formatMessage] = useFormatMessage();
  const onImageChange = (files: File[]) => {
    if (!navigator.onLine) {
      toast.warn(formatMessage('toast.logo.disabled'));
      return;
    }

    saveImage(files[files.length - 1]);
    toast.success(formatMessage('toast.settings.logo.update'));
  };

  const handleRemove = () => {
    if (!navigator.onLine) {
      toast.warn(formatMessage('toast.logo.disabled'));
      return;
    }

    removeImage();
    toast.success(formatMessage('toast.settings.logo.removed'));
  };

  return (
    <div className="imageuploader__wrapper">
      <ImageUploader
        imageUrl={logo && logo.imageUrl}
        removeHandler={handleRemove}
        changeHandler={onImageChange}
        imgClassName="logo__background-gradient"
      />
    </div>
  );
};
