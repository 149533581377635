import React, { useState } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { AdministrationLogo, SettingsThunks, Logo } from 'modules/settings';
import { ApplicationState } from 'modules/app-state';
import { PageHeader, Loading } from 'modules/app-ui';

interface DispatchProps {
  saveLogo: (image: File) => void;
  removeLogo: VoidFunction;
}

interface ReduxProps {
  logo?: Logo;
  error?: string;
  isChanging: boolean;
}

type Props = DispatchProps & ReduxProps;

const SettingsLogo: React.FC<Props> = ({
  logo,
  saveLogo,
  removeLogo,
  isChanging,
}) => {
  const [error] = useState();

  return (
    <section className="contentarea">
      <PageHeader title={<FormattedMessage id="settings.logo.title" />} />

      {error && <div className="alert alert--warning">{error}</div>}

      <Loading fullPage isLoading={isChanging}>
        <AdministrationLogo
          logo={logo}
          saveImage={saveLogo}
          removeImage={removeLogo}
        />
      </Loading>
    </section>
  );
};

export default connect<ReduxProps, DispatchProps, null, ApplicationState>(
  state => ({
    logo: state.settings.logo,
    error: state.settings.error,
    isChanging: state.settings.loading,
  }),
  {
    saveLogo: SettingsThunks.saveLogo,
    removeLogo: SettingsThunks.removeLogo,
  },
)(SettingsLogo);
