import { ActionUnion } from 'modules/app-state';

import { RewardActionTypes } from './types';
import { RewardActions } from './actions';

import { RedeemReward, Reward } from '../models';

export interface RewardsState {
  readonly loading: boolean;
  readonly items: Reward[];
  readonly redeemedRewards: RedeemReward[];
  readonly error?: string;
}

const INITIAL_STATE: RewardsState = {
  items: [],
  redeemedRewards: [],
  loading: false,
  error: undefined,
};

export default (
  state: RewardsState = INITIAL_STATE,
  action: ActionUnion<typeof RewardActions>,
): RewardsState => {
  switch (action.type) {
    case RewardActionTypes.Change:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case RewardActionTypes.UpdateRewards:
      return {
        ...state,
        items: action.payload.rewards,
        loading: false,
        error: undefined,
      };
    case RewardActionTypes.UpdateRedeemedRewards:
      return {
        ...state,
        redeemedRewards: action.payload.redeemedRewards,
        loading: false,
        error: undefined,
      };
    case RewardActionTypes.Error:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    default:
      return state || INITIAL_STATE;
  }
};
