/**
 * Route paths should follow one of possible paradigms:
 *  - /module
 *  - /module/:<dynamic-id>
 *  - /module/submodule
 *  - /module/submodule/:<dynamic-id>
 */
export enum RoutePath {
  Login = '/login',
  ForgotPassword = '/forgot-password',
  Summary = '/summary',
  Settings = '/settings',
  SettingsLogo = '/settings/logo',
  SettingsModules = '/settings/modules',
  SettingsLocalisation = '/settings/language',
  StaticContent = '/static-content',
  Locations = '/locations',
  LocationDetails = '/locations/:id',
  Products = '/products',
  ProductDetails = '/products/:id',
  StaticContentDetails = '/static-content/:id',
  Rewards = '/rewards',
  RewardsAll = '/rewards/all',
  RewardDetails = '/rewards/all/:id',
  DraftRewards = '/rewards/draft',
  PublishedRewards = '/rewards/published',
  RedeemedRewards = '/rewards/redeemed-rewards',
  RedeemedRewardDetails = '/rewards/redeemed-rewards/:id',
  VirtualRewards = '/rewards/virtual-rewards',
  VirtualRewardDetails = '/rewards/virtual-rewards/:id',
  Articles = '/articles',
  ArticlesAll = '/articles/all',
  ArticleDetails = '/articles/all/:id',
  DraftArticles = '/articles/draft',
  PublishedArticles = '/articles/published',
  Localisation = '/localisation',
  LocalisationClient = '/localisation/client',
  LocalisationClientDetails = '/localisation/client/:id',
  LocalisationDashboard = '/localisation/dashboard',
  LocalisationDashboardDetails = '/localisation/dashboard/:id',
  FAQ = '/faq',
  FAQDetails = '/faq/:id',
  Users = '/users',
  UserDetails = '/users/:id',
  UserInvite = '/users/invite',
  Gamification = '/gamification',
  Badges = '/gamification/badges',
  BadgeDetails = '/gamification/badges/:id',
  PointHistory = '/gamification/point-history',
  PointHistoryDetails = '/gamification/point-history/:id',
  UserLevels = '/gamification/user-levels',
  BadgeLevels = '/gamification/badge-levels',
  Feedback = '/feedback',
  AllFeedbacks = '/feedback/all',
  FeedbackCards = '/feedback/cards',
  FeedbackThanksCards = '/feedback/thanks',
  FeedbackDetails = '/feedback/all/:id',
  Media = '/media',
  MediaDetails = '/media/:id',
  Unauthorized = '/unauthorized',
  Error = '/error',
}
