import { Dispatch } from 'redux';

import { ApplicationState } from 'modules/app-state';

import { StaticContentActions } from './actions';

import { StaticContentService } from '../services';
import { StaticContent } from '../models';

export const getAllAsync = () => async (
  dispatch: Dispatch,
  getState: () => ApplicationState,
) => {
  if (getState().staticContent.items.length) {
    return;
  }

  dispatch(StaticContentActions.change());

  const successFunction = (data: StaticContent[]) =>
    dispatch(StaticContentActions.update(data));

  const errorFunction = (error: string) =>
    dispatch(StaticContentActions.error(error));

  const listenerProps = {
    successFunction,
    errorFunction,
  };

  StaticContentService.filterAsync(undefined, listenerProps);

  return;
};

const saveStaticContent = (data: StaticContent) => (dispatch: Dispatch) => {
  dispatch(StaticContentActions.change());

  StaticContentService.addAsync(data);
};

const removeStaticContent = (entityId: string) => () => {
  StaticContentService.removeAsync(entityId);
};

export const StaticContentThunks = {
  getAllAsync,
  saveStaticContent,
  removeStaticContent,
};
