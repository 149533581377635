import { FirebaseDatabaseService, StorageService } from 'modules/firebase';

import { Product } from '../models';

const moduleName = 'products';

export const ProductsService = {
  Database: new FirebaseDatabaseService<Product>(moduleName),
  Storage: new StorageService<File>(moduleName + '/'),
};
