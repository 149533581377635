import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { PageHeaderAction } from 'modules/app-ui/models';
import { ApplicationState } from 'modules/app-state';
import {
  FirebaseLocation,
  LocationItem,
  LocationThunks,
  searchLocations,
} from 'modules/locations';
import { RoutePath } from 'modules/navigation';
import { SearchActions, SearchQuery } from 'modules/search';
import {
  useModal,
  usePagination,
  PageHeader,
  Loading,
  EmptyState,
} from 'modules/app-ui';

interface DispatchProps {
  getAll: VoidFunction;
  removeLocation: (id: string) => void;
  search: SearchQuery;
}

interface ReduxProps {
  locations: FirebaseLocation[];
  error?: string;
  locationsAreChanging: boolean;
}

type Props = DispatchProps & ReduxProps;

const LocationList: React.FC<Props> = ({
  getAll,
  error,
  locationsAreChanging,
  removeLocation,
  locations,
  search,
}) => {
  useEffect(() => {
    getAll();
  }, [getAll]);

  const [toggleModal, Modal] = useModal(removeLocation);
  const [pagedItems, Pagination] = usePagination(locations);

  return (
    <section className="contentarea">
      <PageHeader
        onSearch={search}
        title={<FormattedMessage id="locations" />}
        actions={[
          new PageHeaderAction(
            (<FormattedMessage id="locations.btn.add" />),
            `${RoutePath.Locations}/new`,
          ),
        ]}
      />

      {error && <div className="alert alert--warning">{error}</div>}

      <Loading fullPage isLoading={locationsAreChanging}>
        {locations && !locations.length && <EmptyState showImage />}

        {pagedItems.map(location => (
          <LocationItem
            location={location}
            key={location.id}
            toggleModal={toggleModal}
          />
        ))}

        <Pagination />
        <Modal />
      </Loading>
    </section>
  );
};

export default connect<ReduxProps, DispatchProps, Props, ApplicationState>(
  state => ({
    locations: searchLocations(state),
    error: state.locations.error,
    locationsAreChanging: state.locations.loading,
  }),
  {
    removeLocation: LocationThunks.removeLocation,
    getAll: LocationThunks.getAllAsync,
    search: SearchActions.querySearch,
  },
)(LocationList);
