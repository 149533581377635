import { StaticContentActionTypes, StaticContentActions } from './index';

import { ActionUnion } from 'modules/app-state';

import { StaticContent } from '../models';

export interface StaticContentState {
  readonly items: StaticContent[];
  readonly loading: boolean;
  readonly error?: string;
}

const INITIAL_STATE: StaticContentState = {
  items: [],
  loading: false,
  error: undefined,
};

export default (
  state: StaticContentState = INITIAL_STATE,
  action: ActionUnion<typeof StaticContentActions>,
) => {
  switch (action.type) {
    case StaticContentActionTypes.Change:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case StaticContentActionTypes.Update:
      return {
        ...state,
        loading: false,
        error: undefined,
        items: action.payload.staticContent,
      };
    case StaticContentActionTypes.Error:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    default:
      return state || INITIAL_STATE;
  }
};
