export interface FAQ {
  id: string;
  title: string;
  content: string;
}

export class FAQ {
  constructor() {
    this.title = '';
    this.content = '';
  }
}
