import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import {
  PageHeaderAction,
  useModal,
  usePagination,
  Loading,
  EmptyState,
  PageHeader,
} from 'modules/app-ui';
import { ApplicationState } from 'modules/app-state';
import {
  GamificationThunks,
  Point,
  PointHistoryItem,
} from 'modules/gamification';
import { RoutePath } from 'modules/navigation';
import { SearchActions, SearchQuery } from 'modules/search';
import { User, UserFilterOption, usersFilterOptions } from 'modules/users';

import { sortPointsHistoryByUser } from '../redux/selectors';

interface DispatchProps {
  getAll: VoidFunction;
  removePointHistory: (id: string) => void;
  search: SearchQuery;
}

interface ReduxProps {
  points: Point[];
  users: User[];
  error?: string;
  isChanging: boolean;
  usersFilterOptions: UserFilterOption[];
}

interface OwnProps {
  forSummaryModule?: boolean;
}

type Props = DispatchProps & ReduxProps & OwnProps;

const PointHistoryList: React.FC<Props> = ({
  getAll,
  error,
  isChanging,
  points,
  removePointHistory,
  search,
  users,
  forSummaryModule,
}) => {
  const [toggleModal, Modal] = useModal(removePointHistory);

  useEffect(() => {
    getAll();
  }, [getAll]);

  // const handleFilterChange = (
  //   value: ValueType<{
  //     value: string;
  //     label: string | React.ReactElement;
  //   }>,
  // ) => changeDateFilter((value as any).value);

  // const handleActionFilterChange = (
  //   value: ValueType<{
  //     value: string;
  //     label: string | React.ReactElement;
  //   }>,
  // ) => {
  //   if (value) {
  //     changeActionNameFilter((value as any).value);
  //     return;
  //   }

  //   changeActionNameFilter(null);
  // };

  // const handleUserFilterChange = (
  //   value: ValueType<{
  //     value: string;
  //     label: string;
  //   }>,
  // ) => {
  //   if (value) {
  //     changeUserFilter((value as any).value);
  //     return;
  //   }

  //   changeUserFilter(null);
  // };

  const [pagedItems, Pagination] = usePagination(points);

  if (forSummaryModule) {
    const size = 5;

    return (
      <section>
        <Loading isLoading={isChanging || !users.length}>
          {!points.length && <EmptyState showImage />}

          {pagedItems.slice(0, size).map(point => (
            <PointHistoryItem
              key={point.id}
              user={users.find(user => user.id === point.userId)}
              point={point}
              toggleModal={toggleModal}
            />
          ))}
        </Loading>

        <Modal />
      </section>
    );
  }

  return (
    <section className="contentarea">
      <PageHeader
        onSearch={search}
        title={<FormattedMessage id="points-history" />}
        actions={[
          new PageHeaderAction(
            (<FormattedMessage id="points-history.btn.add" />),
            `${RoutePath.PointHistory}/new`,
          ),
        ]}
      />

      {error && <div className="alert alert--warning">{error}</div>}

      <div className="point__filters">
        <div className="field">
          {/* <label htmlFor="order" className="field__lbl">
            <FormattedMessage id="form.date-order" />
          </label>
          <Select
            value={
              dateFilter
                ? OrderOptions.find(option => option.value === dateFilter)
                : OrderOptions[1]
            }
            onChange={handleFilterChange}
            options={OrderOptions}
            id="order"
            className="input--select s-bottom--lrg"
          /> */}
        </div>

        <div className="field">
          {/* <label htmlFor="actionName" className="field__lbl">
            <FormattedMessage id="form.action-name" />
          </label>
          <FormattedMessage id="form.action-name.placeholder">
            {placeholder => (
              <Select
                value={
                  actionNameFilter
                    ? PointOptions.find(
                        option => option.value === actionNameFilter,
                      )
                    : null
                }
                onChange={handleActionFilterChange}
                options={PointOptions}
                className="input--select"
                placeholder={placeholder as string}
                id="actionName"
                isClearable
              />
            )}
          </FormattedMessage> */}
        </div>

        <div className="field">
          {/* <label htmlFor="user-filter" className="field__lbl">
            <FormattedMessage id="form.user-filter" />
          </label>
          <FormattedMessage id="form.action-name.placeholder">
            {placeholder => (
              <Select
                value={
                  userFilter
                    ? usersFilterOptions.find(
                        (user: UserFilterOption) => user.value === userFilter,
                      )
                    : null
                }
                onChange={handleUserFilterChange}
                options={usersFilterOptions}
                id="user-filter"
                placeholder={placeholder as string}
                isClearable
                className="input--select s-bottom--lrg"
              />
            )}
          </FormattedMessage> */}
        </div>
      </div>

      <Loading fullPage isLoading={isChanging || !users.length}>
        {!points.length && <EmptyState showImage />}

        <div>
          {pagedItems.map(point => (
            <PointHistoryItem
              key={point.id}
              user={users.find(user => user.id === point.userId)}
              point={point}
              toggleModal={toggleModal}
            />
          ))}

          <Pagination />
          <Modal />
        </div>
      </Loading>
    </section>
  );
};

export default connect<ReduxProps, DispatchProps, OwnProps, ApplicationState>(
  state => ({
    users: state.users.items,
    points: sortPointsHistoryByUser(state),
    isChanging: state.gamification.loading,
    error: state.gamification.error,
    usersFilterOptions: usersFilterOptions(state),
  }),
  {
    getAll: GamificationThunks.getAllAsync,
    removePointHistory: GamificationThunks.removePointHistory,
    search: SearchActions.querySearch,
  },
)(PointHistoryList);
