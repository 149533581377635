import { Dispatch } from 'redux';
import { sortBy } from 'lodash';

import { ApplicationState } from 'modules/app-state';

import { UserActions } from './actions';

import { UsersService, UserLevelsService } from '../services';
import { User, UserLevel } from '../models';

const getAllUsers = () => (dispatch: Dispatch) => {
  dispatch(UserActions.change());

  const successFunction = async (data: User[]) => {
    const newUserData: User[] = [];
    const promise = data.map(async user => {
      return UsersService.Database.getSubcollection(user.id, 'private').then(
        value => {
          user = { ...user, privateProfile: value[0] };
          return newUserData.push(user);
        },
      );
    });

    Promise.all(promise).then(() => {
      dispatch(UserActions.update(newUserData));
    });
  };

  const errorFunction = (error: string) => dispatch(UserActions.error(error));

  const listenerProps = {
    successFunction,
    errorFunction,
  };

  UsersService.Database.filterAsync(undefined, listenerProps);

  return;
};

const updateUser = (user: User, imageFile?: File) => async () => {
  if (!user.id) {
    return;
  }

  delete user.role;

  if (!imageFile) {
    await UsersService.Database.updateUserFirestoreAuth(user);
    return;
  }

  const uploadTask = UsersService.Storage.addItemInFolder(user.id, imageFile);

  if (uploadTask) {
    uploadTask.on('state_changed', null, null, async () => {
      await uploadTask!.snapshot.ref.getDownloadURL().then(async url => {
        const newUserState = { ...user };
        newUserState.photoUrl = url;
        await UsersService.Database.updateUserFirestoreAuth(newUserState);
      });
    });
  }

  return;
};

const removeUser = (userId: string) => async (dispatch: Dispatch) => {
  dispatch(UserActions.change());

  if (!userId) {
    return;
  }

  UsersService.Database.removeAsync(userId);
  UsersService.Database.removeSubcollectionAsync(userId, 'private', 'profile');
};

const inviteUser = (
  firstName: string,
  lastName: string,
  email: string,
  role: string,
) => async (dispatch: Dispatch, getState: () => ApplicationState) => {
  dispatch(UserActions.change());

  const allUsers = getState().users.items;
  const userExistsPromise = await new Promise(
    (resolve: (data: boolean) => void) => {
      allUsers.forEach((user, index, array) => {
        if (user.email === email) {
          dispatch(
            UserActions.error('User with corresponding email alredy exists'),
          );
          resolve(true);
        }

        if (index === array.length - 1) {
          resolve(false);
        }
      });
    },
  );

  if (!userExistsPromise) {
    await UsersService.Database.inviteUser(firstName, lastName, email, role);
    await UsersService.Auth.sendPasswordResetEmail(email);

    return true;
  }

  return false;
};

const getAllGamificationLevels = () => (
  dispatch: Dispatch,
  getState: () => ApplicationState,
) => {
  if (getState().users.userLevels?.levels) {
    return;
  }

  dispatch(UserActions.change());

  const successFunction = (data: { id: string; levels: UserLevel[] }) => {
    dispatch(UserActions.updateGamificationLevels(data));
  };

  const errorFunction = (error: string) => dispatch(UserActions.error(error));

  const listenerProps = {
    successFunction,
    errorFunction,
  };

  UserLevelsService.getByIdAsync('user-levels', listenerProps);

  return;
};

const saveAllGamificationLevels = (entity: {
  id: string;
  levels: UserLevel[];
}) => async (dispatch: Dispatch) => {
  dispatch(UserActions.change());

  const sortedLevels = sortBy(entity.levels, ['points']);
  const result = sortedLevels.map((level, index) => {
    level.level = index + 1;
    return level;
  });

  UserLevelsService.addAsync({ id: entity.id, levels: result });
};

export const UserThunks = {
  getAllGamificationLevels,
  saveAllGamificationLevels,
  getAllUsers,
  updateUser,
  removeUser,
  inviteUser,
};
