import { PointType, PointAction } from '../const/PointAction';

export interface Point {
  actionName?: PointAction;
  actionType: PointType;
  date: Date | number;
  points: number;
  userId: string;
  id: string;
}

export class Point {
  constructor(
    actionType?: PointType,
    id?: string,
    actionName?: PointAction,
    points?: number,
    userId?: string,
  ) {
    this.actionName = actionName;
    this.actionType = actionType || PointType.Add;
    this.date = Date.now();
    this.points = points || 0;
    this.userId = userId || '';

    if (id) {
      this.id = id;
    }
  }
}
