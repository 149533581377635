import { Dispatch } from 'redux';

import { ApplicationState } from 'modules/app-state';

import { FeedbackActions } from './actions';

import { Feedback, FeedbackCard, FeedbackThanks } from '../models';
import {
  FeedbackService,
  FeedbackCardsService,
  FeedbackThanksService,
} from '../services';

export const getAllAsync = () => async (
  dispatch: Dispatch,
  getState: () => ApplicationState,
) => {
  if (getState().feedback.items.length) {
    return;
  }

  dispatch(FeedbackActions.change());

  const successFunction = (data: Feedback[]) =>
    dispatch(FeedbackActions.updateFeedback(data));

  const errorFunction = (error: string) =>
    dispatch(FeedbackActions.error(error));

  const listenerProps = {
    successFunction,
    errorFunction,
  };

  FeedbackService.filterAsync(undefined, listenerProps);

  return;
};

const saveFeedback = (feedback: Feedback) => (dispatch: Dispatch) => {
  dispatch(FeedbackActions.change());

  FeedbackService.addAsync(feedback);
};

const removeFeedback = (id: string) => () => {
  FeedbackService.removeAsync(id);
};

const getAllFeedbackCards = () => async (
  dispatch: Dispatch,
  getState: () => ApplicationState,
) => {
  if (getState().feedback.cards.length) {
    return;
  }

  dispatch(FeedbackActions.change());

  const successFunction = (data: FeedbackCard[]) =>
    dispatch(FeedbackActions.updateFeedbackCards(data));

  const errorFunction = (error: string) =>
    dispatch(FeedbackActions.error(error));

  const listenerProps = {
    successFunction,
    errorFunction,
  };

  FeedbackCardsService.Database.filterAsync(undefined, listenerProps);

  return;
};

const saveFeedbackCards = (
  feedbackCards: FeedbackCard[],
  oldFeedbackCards: FeedbackCard[],
) => async (dispatch: Dispatch) => {
  if (!navigator.onLine) {
    const tempCards = [...feedbackCards].map(card => {
      const oldTemp = oldFeedbackCards.find(oldCard => oldCard.id === card.id);
      if (oldTemp) {
        card.imageUrl = oldTemp.imageUrl;
        card.imageReference = oldTemp.imageReference;
      }
      delete card.imageFile;

      return card;
    });

    await FeedbackCardsService.Database.addAsync(tempCards);

    return;
  }

  dispatch(FeedbackActions.change());

  const newIds = feedbackCards.map(card => card.id);
  const newImageReferences: string[] = [];

  const results = feedbackCards.map(
    card =>
      new Promise(
        (resolve: (card: FeedbackCard) => void, reject: VoidFunction) => {
          const uploadTask = FeedbackCardsService.Storage.addItem(
            card.id,
            card.imageFile,
          );
          if (!uploadTask || !card.imageFile) {
            if (card.imageReference) {
              newImageReferences.push(card.imageReference);
            }
            resolve({ ...card });
            return;
          }

          uploadTask.on(
            'state_changed',
            null,
            () => reject(),
            () => {
              uploadTask.snapshot.ref.getDownloadURL().then(url => {
                card.imageUrl = url;
                card.imageReference = `${card.id}_${card.imageFile!.name}`;
                newImageReferences.push(`${card.id}_${card.imageFile!.name}`);
                delete card.imageFile;

                resolve(card);
              });
            },
          );
        },
      ),
  );

  const res = await Promise.all(results);

  await FeedbackCardsService.Database.addAsync(res);

  // delete old documents
  oldFeedbackCards.forEach(oldCard => {
    if (!newIds.includes(oldCard.id)) {
      FeedbackCardsService.Database.removeAsync(oldCard.id);
    }
  });

  // delete images from storage with unused references
  oldFeedbackCards.forEach(type => {
    if (!type.imageReference) {
      return;
    }

    if (!newImageReferences.includes(type.imageReference)) {
      FeedbackCardsService.Storage.removeItem(type.imageReference);
    }
  });

  return;
};

const getAllThanksCards = () => async (
  dispatch: Dispatch,
  getState: () => ApplicationState,
) => {
  if (getState().feedback.thanksCards.length) {
    return;
  }

  dispatch(FeedbackActions.change());

  const successFunction = (data: FeedbackThanks[]) =>
    dispatch(FeedbackActions.updateThanksCards(data));

  const errorFunction = (error: string) =>
    dispatch(FeedbackActions.error(error));

  const listenerProps = {
    successFunction,
    errorFunction,
  };

  FeedbackThanksService.Database.filterAsync(undefined, listenerProps);

  return;
};

const saveFeedbackThanksCards = (
  feedbackCards: FeedbackThanks[],
  oldFeedbackCards: FeedbackThanks[],
) => async (dispatch: Dispatch) => {
  if (!navigator.onLine) {
    const tempCards = [...feedbackCards].map(card => {
      const oldTemp = oldFeedbackCards.find(oldCard => oldCard.id === card.id);
      if (oldTemp) {
        card.imageUrl = oldTemp.imageUrl;
        dispatch(FeedbackActions.change());
        card.imageReference = oldTemp.imageReference;
      }
      delete card.imageFile;

      return card;
    });

    await FeedbackThanksService.Database.addAsync(tempCards);

    return;
  }

  dispatch(FeedbackActions.change());

  const newIds = feedbackCards.map(card => card.id);
  const newImageReferences: string[] = [];

  const results = feedbackCards.map(
    card =>
      new Promise(
        (resolve: (card: FeedbackThanks) => void, reject: VoidFunction) => {
          const uploadTask = FeedbackThanksService.Storage.addItem(
            card.id,
            card.imageFile,
          );
          if (!uploadTask || !card.imageFile) {
            if (card.imageReference) {
              newImageReferences.push(card.imageReference);
            }
            resolve({ ...card });
            return;
          }

          uploadTask.on(
            'state_changed',
            null,
            () => reject(),
            () => {
              uploadTask.snapshot.ref.getDownloadURL().then(url => {
                card.imageUrl = url;
                card.imageReference = `${card.id}_${card.imageFile!.name}`;
                newImageReferences.push(`${card.id}_${card.imageFile!.name}`);
                delete card.imageFile;

                resolve(card);
              });
            },
          );
        },
      ),
  );

  const res = await Promise.all(results);

  await FeedbackThanksService.Database.addAsync(res);

  // delete old documents
  oldFeedbackCards.forEach(oldCard => {
    if (!newIds.includes(oldCard.id)) {
      FeedbackThanksService.Database.removeAsync(oldCard.id);
    }
  });

  // delete images from storage with unused references
  oldFeedbackCards.forEach(type => {
    if (!type.imageReference) {
      return;
    }

    if (!newImageReferences.includes(type.imageReference)) {
      FeedbackThanksService.Storage.removeItem(type.imageReference);
    }
  });

  return;
};

export const FeedbackThunks = {
  saveFeedback,
  saveFeedbackCards,
  saveFeedbackThanksCards,
  removeFeedback,
  getAllAsync,
  getAllFeedbackCards,
  getAllThanksCards,
};
