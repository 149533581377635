import { Dispatch } from 'redux';

import { ApplicationState } from 'modules/app-state';

import { GamificationActions } from './actions';

import { Point } from '../models';
import { PointsHistoryService } from '../services';

const getAllAsync = () => async (
  dispatch: Dispatch,
  getState: () => ApplicationState,
) => {
  if (getState().gamification.points.length) {
    return;
  }

  dispatch(GamificationActions.change());

  const successFunction = (data: Point[]) =>
    dispatch(GamificationActions.updatePoints(data));

  const errorFunction = (error: string) =>
    dispatch(GamificationActions.error(error));

  const listenerProps = {
    successFunction,
    errorFunction,
  };

  PointsHistoryService.filterAsync(undefined, listenerProps);

  return;
};

const savePointHistory = (point: Point) => (dispatch: Dispatch) => {
  dispatch(GamificationActions.change());

  if (point.date) {
    const newPoint = { ...point, date: +point.date };
    PointsHistoryService.addAsync(newPoint);
    return;
  }

  PointsHistoryService.addAsync(point);
};

const removePointHistory = (id: string) => () => {
  if (!id) {
    return;
  }

  PointsHistoryService.removeAsync(id);
};

export const GamificationThunks = {
  getAllAsync,
  savePointHistory,
  removePointHistory,
};
