import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import orderBy from 'lodash/orderBy';
import { toast } from 'react-toastify';

import { PageHeaderAction } from 'modules/app-ui/models';
import { ApplicationState } from 'modules/app-state';
import { useFormatMessage } from 'modules/localisation';
import {
  usePageModified,
  PageHeader,
  Loading,
  EmptyState,
  PageGuard,
} from 'modules/app-ui';

import { FeedbackThanksCardItem } from '../components';
import { FeedbackThanks } from '../models';
import { FeedbackThunks } from '../redux';

interface DispatchProps {
  getAll: VoidFunction;
  saveAll: (
    feedbackCards: FeedbackThanks[],
    oldFeedbackCards: FeedbackThanks[],
  ) => void;
}

interface ReduxProps {
  feedbackThanksCards: FeedbackThanks[];
  error?: string;
  feedbackCardsAreChanging: boolean;
}

type Props = DispatchProps & ReduxProps;

const FeedbackThanksCardsList: React.FC<Props> = ({
  getAll,
  feedbackThanksCards,
  error,
  feedbackCardsAreChanging,
  saveAll,
}) => {
  const [feedbackThanksState, setFeedbackThanksState] = useState(
    feedbackThanksCards,
  );
  const [pageModified, setPageModified] = usePageModified(
    'feedback-thanks-contentarea',
  );
  const [formatMessage] = useFormatMessage();

  const handleChange = (id: number, feedbackCard: FeedbackThanks) => {
    const tempState = [...feedbackThanksState];
    tempState[id] = feedbackCard;

    setFeedbackThanksState(tempState);
    setPageModified(true);
  };

  const saveFeedbackThanksCards = () => {
    setPageModified(false);

    if (
      !navigator.onLine &&
      feedbackThanksState.some(card => Boolean(card.imageFile))
    ) {
      toast.warn(formatMessage('toast.images.disabled'));
    }

    saveAll(feedbackThanksState, feedbackThanksCards);

    toast.success(formatMessage('toast.thanks-cards.save'));
  };

  const handleActiveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { dataset, checked } = event.currentTarget;
    const id = parseInt(dataset.id || '');
    const tempState = [...feedbackThanksState];
    tempState[id].active = checked;

    setFeedbackThanksState(tempState);
    setPageModified(true);
  };

  const pushNewThanksCard = () => {
    const tempState = [...feedbackThanksState];

    tempState.push({ ...new FeedbackThanks() });

    setFeedbackThanksState(tempState);
  };

  const deleteThanksCard = (id: number) => {
    const tempState = [...feedbackThanksState];
    tempState.splice(id, 1);

    setFeedbackThanksState(tempState);
    setPageModified(true);
  };

  useEffect(() => {
    setFeedbackThanksState(feedbackThanksCards);
  }, [feedbackThanksCards]);

  useEffect(() => {
    getAll();
  }, [getAll]);

  return (
    <section className="contentarea v-levels" id="feedback-thanks-contentarea">
      <PageHeader
        title={<FormattedMessage id="feedback-thanks-cards" />}
        actions={[
          new PageHeaderAction(
            (<FormattedMessage id="feedback-thanks-cards.btn.add" />),
            pushNewThanksCard,
          ),
        ]}
      />

      {error && <p className="alert alert--warning">{error}</p>}

      <Loading fullPage isLoading={feedbackCardsAreChanging}>
        {!feedbackThanksState.length && <EmptyState showImage />}

        <div className="v-levels__list">
          {feedbackThanksState.length > 0 &&
            feedbackThanksState.map((feedbackThank, index) => (
              <FeedbackThanksCardItem
                key={index}
                id={index}
                thanksCard={feedbackThank}
                handleChange={handleChange}
                handleActiveChange={handleActiveChange}
                deleteThanksCard={deleteThanksCard}
              />
            ))}
        </div>

        {feedbackThanksState.length > 0 && (
          <div className="contentarea__actionbar">
            <button
              className="btn btn--primary btn--med"
              onClick={saveFeedbackThanksCards}
            >
              <FormattedMessage id="button.save" />
            </button>
          </div>
        )}
      </Loading>

      <PageGuard when={pageModified} />
    </section>
  );
};

export default connect<ReduxProps, DispatchProps, null, ApplicationState>(
  state => ({
    feedbackThanksCards: orderBy(
      state.feedback.thanksCards,
      ['active'],
      'desc',
    ),
    error: state.feedback.error,
    feedbackCardsAreChanging: state.feedback.loading,
  }),
  {
    getAll: FeedbackThunks.getAllThanksCards,
    saveAll: FeedbackThunks.saveFeedbackThanksCards,
  },
)(FeedbackThanksCardsList);
