import { orderBy } from 'lodash';
import { createSelector } from 'reselect';

import { ApplicationState } from 'modules/app-state';
import { getSearchQuery, applySearchParams } from 'modules/search';
import { getUsersObject } from 'modules/users/redux/selectors';

const getPointsHistory = (state: ApplicationState) => state.gamification.points;

export const searchPointHistory = createSelector(
  [getPointsHistory, getSearchQuery, getUsersObject],
  (content, query, usersObject) =>
    content.filter(point => {
      const user = usersObject[point.userId];
      if (!user) {
        return false;
      }

      return applySearchParams(
        [
          new Date(point.date).toDateString(),
          `${user.firstName} ${user.lastName}`,
        ],
        query,
      );
    }),
);

export const sortPointsHistoryByUser = createSelector(
  [searchPointHistory],
  points => orderBy(points, ['userId'], 'desc'),
);
