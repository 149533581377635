import { createAction } from 'modules/app-state';

import { LocationActionTypes } from './types';

import { FirebaseLocation } from '../models';

const AddActions = {
  change: () => createAction(LocationActionTypes.Change),

  update: (locations: FirebaseLocation[]) =>
    createAction(LocationActionTypes.Update, { locations }),

  error: (error: string) => createAction(LocationActionTypes.Error, { error }),
};

export const LocationActions = {
  ...AddActions,
};
