import { createSelector } from 'reselect';

import { ApplicationState } from 'modules/app-state';
import { applySearchParams, getSearchQuery } from 'modules/search';

const getStaticContent = (state: ApplicationState) => state.staticContent.items;

export const searchStaticContent = createSelector(
  [getStaticContent, getSearchQuery],
  (content, query) =>
    content.filter(({ id }) => applySearchParams([id], query)),
);
