import { ActionUnion } from 'modules/app-state';

import { FeedbackActions } from './actions';
import { FeedbackActionTypes } from './types';

import { Feedback, FeedbackCard, FeedbackThanks } from '../models';

export interface FeedbackState {
  readonly loading: boolean;
  readonly error?: string;
  readonly items: Feedback[];
  readonly cards: FeedbackCard[];
  readonly thanksCards: FeedbackThanks[];
}

const INITIAL_STATE: FeedbackState = {
  loading: false,
  error: undefined,
  items: [],
  cards: [],
  thanksCards: [],
};

export default (
  state: FeedbackState = INITIAL_STATE,
  action: ActionUnion<typeof FeedbackActions>,
): FeedbackState => {
  switch (action.type) {
    case FeedbackActionTypes.Change:
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case FeedbackActionTypes.UpdateFeedback:
      return {
        ...state,
        loading: false,
        error: undefined,
        items: action.payload.feedback,
      };
    case FeedbackActionTypes.UpdateFeedbackCards:
      return {
        ...state,
        loading: false,
        error: undefined,
        cards: action.payload.feedbackCards,
      };
    case FeedbackActionTypes.UpdateThanksCards:
      return {
        ...state,
        loading: false,
        error: undefined,
        thanksCards: action.payload.thanksCards,
      };
    case FeedbackActionTypes.Error:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state || INITIAL_STATE;
  }
};
