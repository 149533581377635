import { createAction } from 'modules/app-state';

import { RewardActionTypes } from './types';

import { RedeemReward, Reward } from '../models';

export const RewardActions = {
  change: () => createAction(RewardActionTypes.Change),

  updateRewards: (rewards: Reward[]) =>
    createAction(RewardActionTypes.UpdateRewards, { rewards }),

  updateRedeemedRewards: (redeemedRewards: RedeemReward[]) =>
    createAction(RewardActionTypes.UpdateRedeemedRewards, { redeemedRewards }),

  error: (error?: string) => createAction(RewardActionTypes.Error, { error }),
};
