import { Value } from 'slate';
import { Dispatch } from 'redux';

import { ApplicationState } from 'modules/app-state';

import { RewardActions } from './actions';

import { Reward, RedeemReward, VirtualReward } from '../models';
import { RewardsService, RedeemRewardService } from '../services';

export const getAllRewardsAsync = () => async (
  dispatch: Dispatch,
  getState: () => ApplicationState,
) => {
  if (getState().rewards.items.length) {
    return;
  }

  dispatch(RewardActions.change());

  const successFunction = (data: Reward[]) =>
    dispatch(RewardActions.updateRewards(data));

  const errorFunction = (error: string) => dispatch(RewardActions.error(error));

  const listenerProps = {
    successFunction,
    errorFunction,
  };

  RewardsService.Database.filterAsync(undefined, listenerProps);

  return;
};

const getAllRedeemedRewardsAsync = () => async (
  dispatch: Dispatch,
  getState: () => ApplicationState,
) => {
  if (getState().rewards.redeemedRewards.length) {
    return;
  }

  dispatch(RewardActions.change());

  const successFunction = (data: RedeemReward[]) =>
    dispatch(RewardActions.updateRedeemedRewards(data));

  const errorFunction = (error: string) => dispatch(RewardActions.error(error));

  const listenerProps = {
    successFunction,
    errorFunction,
  };

  RedeemRewardService.filterAsync(undefined, listenerProps, true);

  return;
};

const saveReward = (rewardValues: Reward, editorValue: Value) => async (
  dispatch: Dispatch,
) => {
  dispatch(RewardActions.change());

  const {
    id,
    amount,
    slug,
    title,
    points,
    featuredImageUrl,
    validFrom,
    validTo,
    published,
    dateCreated,
  } = rewardValues;

  const sendRewardData = (url?: string) => {
    const reward = new Reward(
      id,
      amount,
      slug,
      title,
      '',
      points,
      featuredImageUrl,
      validFrom,
      validTo,
      published,
      dateCreated,
    );

    RewardsService.Database.addAsync(reward.exportData(editorValue, url));
  };

  sendRewardData();
};

const saveVirtualReward = (
  rewardValues: VirtualReward,
  editorValue: Value,
  oldRewardValues?: VirtualReward,
) => async (dispatch: Dispatch) => {
  dispatch(RewardActions.change());

  const sendVirtualRewardData = (mainImageUrl?: {
    url: string;
    reference: string;
  }) => {
    const reward = new VirtualReward(
      rewardValues.id,
      rewardValues.amount,
      rewardValues.slug,
      rewardValues.title,
      rewardValues.description,
      rewardValues.points,

      rewardValues.featuredImageUrl,

      rewardValues.validFrom,
      rewardValues.validTo,

      rewardValues.published,
      rewardValues.dateCreated,

      rewardValues.shortDescription,

      rewardValues.mainImageFile,
      rewardValues.mainBlobImageUrl,
      rewardValues.mainImageUrl,
      mainImageUrl
        ? mainImageUrl.reference
        : rewardValues.mainImageStorageReference,

      rewardValues.learnMoreTitle,
      rewardValues.learnMoreImageUrl,
    );

    RewardsService.Database.addAsync(
      reward.exportDataVirtual(
        editorValue,
        mainImageUrl ? mainImageUrl.url : '',
      ),
    );
  };

  const uploadImage = async (prefix: string, id: string, imageFile?: File) => {
    return new Promise<{ url: string; reference: string } | undefined>(
      resolve => {
        if (!imageFile) {
          resolve(undefined);
        }

        const idE = id || Date.now();
        const prefixReference = `${prefix}_${idE}`;
        const reference = `${prefixReference}_${imageFile?.name}`;

        const uploadTask = RewardsService.Storage.addItem(
          prefixReference,
          imageFile,
        );

        if (uploadTask) {
          uploadTask.on('state_changed', null, null, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((url: string) => {
              resolve({ url, reference });
            });
          });
        } else {
          resolve(undefined);
        }
      },
    );
  };

  if (!navigator.onLine) {
    sendVirtualRewardData();

    return;
  }

  const uploadTaskMain = await uploadImage(
    'main',
    rewardValues.id,
    rewardValues.mainImageFile,
  );

  if (
    oldRewardValues &&
    !rewardValues.mainImageUrl &&
    !rewardValues.mainBlobImageUrl
  ) {
    RewardsService.Storage.removeItem(
      oldRewardValues.mainImageStorageReference,
    );
  }

  sendVirtualRewardData(uploadTaskMain);
};

const saveRedeemReward = (item: RedeemReward) => (dispatch: Dispatch) => {
  dispatch(RewardActions.change());

  RedeemRewardService.addAsync(item);
};

const removeReward = (entityId: string) => () => {
  RewardsService.Database.removeAsync(entityId);
};

const removeRedeemed = (entityId: string) => () => {
  RedeemRewardService.removeAsync(entityId);
};

export const RewardThunks = {
  getAllRewardsAsync,
  getAllRedeemedRewardsAsync,
  saveReward,
  saveVirtualReward,
  saveRedeemReward,
  removeReward,
  removeRedeemed,
};
