enum Actions {
  Change = 'settings/CHANGE',
  UpdateLogo = 'settings/UPDATE_LOGO',
  UpdateModules = 'settings/UPDATE_MODULES',
  Error = 'settings/ERROR',
}

export const SettingsActionTypes = {
  ...Actions,
};
