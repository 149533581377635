import React, { ReactFragment, useEffect, useState } from 'react';
import { MdDelete } from 'react-icons/md';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import { PageHeaderAction } from 'modules/app-ui/models';
import { FirebaseLocation, LocationThunks } from 'modules/locations';
import { RoutePath } from 'modules/navigation';
import { ApplicationState } from 'modules/app-state';
import { useFormatMessage } from 'modules/localisation';
import {
  useModal,
  usePageModified,
  EmptyState,
  Loading,
  PageHeader,
  PageGuard,
} from 'modules/app-ui';

interface DispatchProps {
  getAll: VoidFunction;
  saveLocation: (item: FirebaseLocation) => void;
  removeLocation: (id: string) => void;
}

interface ReduxProps {
  firebaseLocation: FirebaseLocation;
  locationsAreChanging: boolean;
}

type Props = DispatchProps & ReduxProps & RouteComponentProps<any>;

const LocationDetails: React.FC<Props> = ({
  firebaseLocation,
  getAll,
  saveLocation,
  match,
  locationsAreChanging,
  removeLocation,
}) => {
  const history = useHistory();
  const redirectToList = () => history.push(RoutePath.Locations);

  const [toggleModal, Modal] = useModal(removeLocation, redirectToList);
  const [state, setState] = useState<FirebaseLocation>(firebaseLocation);
  const [formatMessage] = useFormatMessage();
  const [error, setError] = useState<ReactFragment | null>(null);
  const [pageModified, setPageModified] = usePageModified(
    'product-contentarea',
  );

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.currentTarget;

    setState({
      ...state,
      [name]: value,
    });
  };

  const handleLngLatChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.currentTarget;

    setState({
      ...state,
      location: {
        longitude:
          name === 'longitude' ? +value || 0 : state.location.longitude,
        latitude: name === 'latitude' ? +value || 0 : state.location.latitude,
      },
    });
  };

  const isFormValid = () => {
    if (!state.name) {
      setError(
        <>
          <strong>Missing information:</strong>
          {!state.name && <div>Name</div>}
        </>,
      );
      return false;
    }

    return true;
  };

  const saveProductCard = async () => {
    if (!isFormValid()) {
      return;
    }

    await setPageModified(false);
    saveLocation(state);

    toast.success(
      state.id
        ? formatMessage('toast.location.update', state.name)
        : formatMessage('toast.location.save', state.name),
    );

    redirectToList();
  };

  useEffect(() => {
    getAll();
  }, [getAll]);

  useEffect(() => {
    setState(firebaseLocation);
  }, [firebaseLocation]);

  if (!state || !firebaseLocation) {
    if (!locationsAreChanging) {
      return <EmptyState showImage />;
    }

    return <Loading isLoading fullPage />;
  }

  const { id, name, location, distance } = state;

  return (
    <section className="contentarea" id="product-contentarea">
      <PageHeader
        title={
          <FormattedMessage
            id={
              match.params.id === 'new'
                ? 'locations.create.title'
                : 'locations.details.title'
            }
          />
        }
        backAction={
          new PageHeaderAction(
            (<FormattedMessage id="locations.back-to-list" />),
            RoutePath.Locations,
          )
        }
      />

      {error && (
        <div className="alert alert--warning s-bottom--med">{error}</div>
      )}

      <div className="card">
        <div className="field">
          <label htmlFor="name" className="field__lbl t-mandatory">
            <FormattedMessage id="form.name" />
          </label>
          <FormattedMessage id="form.name.placeholder">
            {placeholder => (
              <input
                type="text"
                className="input input--med"
                name="name"
                id="name"
                placeholder={placeholder as string}
                value={name}
                onChange={handleChange}
              />
            )}
          </FormattedMessage>
        </div>
        <div className="g-split">
          <div className="g-three__item">
            <div className="field">
              <label htmlFor="lng" className="field__lbl t-mandatory">
                <FormattedMessage id="form.latitude" />
              </label>
              <FormattedMessage id="form.latitude.placeholder">
                {placeholder => (
                  <input
                    type="text"
                    className="input input--med"
                    name="latitude"
                    id="latitude"
                    placeholder={placeholder as string}
                    value={location.latitude}
                    onChange={handleLngLatChange}
                  />
                )}
              </FormattedMessage>
            </div>
          </div>
          <div className="g-three__item">
            <div className="field">
              <label htmlFor="longitude" className="field__lbl t-mandatory">
                <FormattedMessage id="form.longitude" />
              </label>
              <FormattedMessage id="form.longitude.placeholder">
                {placeholder => (
                  <input
                    type="text"
                    className="input input--med"
                    name="longitude"
                    id="longitude"
                    placeholder={placeholder as string}
                    value={location.longitude}
                    onChange={handleLngLatChange}
                  />
                )}
              </FormattedMessage>
            </div>
          </div>
          <div className="g-three__item">
            <div className="field">
              <label htmlFor="distance" className="field__lbl t-mandatory">
                <FormattedMessage id="form.distance" />
              </label>
              <FormattedMessage id="form.distance.placeholder">
                {placeholder => (
                  <input
                    type="number"
                    className="input input--med"
                    name="distance"
                    id="distance"
                    placeholder={placeholder as string}
                    value={distance}
                    onChange={handleChange}
                  />
                )}
              </FormattedMessage>
            </div>
          </div>
        </div>

        <footer className="card__footer">
          <div className="btn-group">
            <button
              className="btn btn--primary btn--med"
              onClick={saveProductCard}
              disabled={!pageModified}
            >
              <FormattedMessage id="button.save" />
            </button>
            {id && (
              <button
                className="btn btn--ghost btn--med btn--hasIcon"
                onClick={toggleModal}
                data-id={id}
                data-title={name}
              >
                <MdDelete size={16} />
              </button>
            )}
          </div>
        </footer>
      </div>

      <Modal />

      <PageGuard when={pageModified} />
    </section>
  );
};

export default connect<ReduxProps, DispatchProps, Props, ApplicationState>(
  (state, componentProps) => {
    const id = componentProps.match.params.id;

    return {
      firebaseLocation:
        id === 'new'
          ? new FirebaseLocation()
          : state.locations.items.filter(item => item.id === id)[0],
      locationsAreChanging: state.locations.loading,
    };
  },
  {
    removeLocation: LocationThunks.removeLocation,
    getAll: LocationThunks.getAllAsync,
    saveLocation: LocationThunks.saveLocation,
  },
)(LocationDetails);
