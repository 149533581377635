import 'react-dates/lib/css/_datepicker.css';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { DateRangePicker, SingleDatePicker } from 'react-dates';
import Switch from 'react-switch';
import moment from 'moment';
import { RouteComponentProps } from 'react-router';
import { MdDelete } from 'react-icons/md';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { UsersService, User } from 'modules/users';
import { ApplicationState } from 'modules/app-state';
import { RoutePath } from 'modules/navigation';
import { PageHeaderAction } from 'modules/app-ui/models';
import { RedeemReward, RewardThunks } from 'modules/rewards';
import { useFormatMessage } from 'modules/localisation';
import {
  useModal,
  usePageModified,
  EmptyState,
  Loading,
  PageHeader,
  PageGuard,
} from 'modules/app-ui';

interface DispatchProps {
  getAll: VoidFunction;
  saveRedeemReward: (item: RedeemReward) => void;
  removeRedeemedReward: (id: string) => void;
}

interface ReduxProps {
  redeemReward: RedeemReward;
  rewardsAreChanging: boolean;
}

type Props = DispatchProps & ReduxProps & RouteComponentProps<any>;

const RedeemRewardDetails: React.FC<Props> = ({
  getAll,
  redeemReward,
  saveRedeemReward,
  removeRedeemedReward,
  rewardsAreChanging,
}) => {
  const voidHandler = () => null;
  const history = useHistory();
  const redirectToList = () => history.push(RoutePath.RedeemedRewards);

  const [toggleModal, Modal] = useModal(removeRedeemedReward, redirectToList);
  const [state, setState] = useState<RedeemReward>();
  const [user, setUser] = useState<User>();
  const [formatMessage] = useFormatMessage();
  const [pageModified, setPageModified] = usePageModified(
    'redeemed-rewards-contentarea',
  );

  function handleChange(checked: boolean) {
    setState({
      ...state,
      shipped: checked,
    } as RedeemReward);

    setPageModified(true);
  }

  const handleShippingInfo = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;

    if (!state) {
      return;
    }

    setState({
      ...state,
      shippingInfo: {
        ...state.shippingInfo,
        [name]: value,
      },
    });

    setPageModified(true);
  };

  const saveCard = async () => {
    if (!state) {
      return;
    }
    await setPageModified(false);

    saveRedeemReward(state);
    toast.success(formatMessage('toast.redeemed.update'));
    redirectToList();
  };

  useEffect(() => {
    if (!redeemReward) {
      getAll();
    }
  }, [redeemReward, getAll]);

  useEffect(() => {
    if (!redeemReward) {
      return;
    }

    setState(redeemReward);

    UsersService.Database.getByIdAsync(redeemReward.userId).then(userData => {
      setUser(userData as User);
    });
  }, [redeemReward]);

  if (!state) {
    if (!rewardsAreChanging) {
      return <EmptyState showImage />;
    }

    return <Loading isLoading fullPage />;
  }

  const { date, reward, shipped, shippingInfo } = state;

  return (
    <section className="contentarea" id="redeemed-rewards-contentarea">
      <PageHeader
        title={<FormattedMessage id="redeemed-rewards.details.title" />}
        backAction={
          new PageHeaderAction(
            (<FormattedMessage id="redeemed-rewards.back-to-list" />),
            RoutePath.RedeemedRewards,
          )
        }
      />

      <div className="card">
        <Loading isLoading={!user}>
          {user && (
            <>
              <div className="s-bottom--lrg">
                <h2 className="t-delta s-bottom--lrg">
                  <FormattedMessage id="redeemed-rewards.public-info" />
                </h2>
                <dl className="l-delimited t-zeta">
                  <dt>
                    <FormattedMessage id="form.display-name" />:
                  </dt>
                  <dd>
                    {user.firstName} {user.lastName}
                  </dd>
                  <dt>
                    <FormattedMessage id="form.email" />:
                  </dt>
                  <dd>{user.email}</dd>
                  <dt>
                    <FormattedMessage id="form.phone" />:
                  </dt>
                  <dd>{user.phone}</dd>
                  <dt>
                    <FormattedMessage id="form.position" />:
                  </dt>
                  <dd>{user.position}</dd>
                </dl>
              </div>
            </>
          )}
        </Loading>

        <div className="s-bottom--lrg">
          <h2 className="t-delta s-bottom--lrg">
            <FormattedMessage id="redeemed-rewards.reward-info" />
          </h2>
          <dl className="l-delimited t-zeta">
            <dt>
              <FormattedMessage id="form.title" />:
            </dt>
            <dd>{reward.title}</dd>
            <dt>
              <FormattedMessage id="form.points" />:
            </dt>
            <dd>{reward.points}</dd>
          </dl>

          <dl className="l-delimited t-zeta">
            <dt>
              <FormattedMessage id="form.description" />:
            </dt>
            <dd dangerouslySetInnerHTML={{ __html: reward.description }} />
            <dt>
              <FormattedMessage id="form.amount" />:
            </dt>
            <dd>{reward.amount}</dd>
            <dt>
              <FormattedMessage id="form.featured-image" />:
            </dt>
            <dd>
              <div className="u-well u-well--primary s-bottom--lrg">
                <img
                  src={reward.featuredImageUrl}
                  alt={reward.featuredImageUrl}
                />
              </div>
            </dd>
          </dl>

          <div className="g-inline">
            <div className="g-inline__item">
              {reward.validFrom && (
                <div className="field">
                  <label className="field__lbl">
                    <FormattedMessage id="form.interval" />
                  </label>
                  <DateRangePicker
                    startDate={moment(reward.validFrom)}
                    startDateId="react-date-redeem-start"
                    endDate={moment(reward.validTo)}
                    endDateId="react-date-redeem-end"
                    onDatesChange={voidHandler}
                    displayFormat="DD/MM/YYYY"
                    focusedInput={null}
                    onFocusChange={voidHandler}
                    disabled={true}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="">
          <h2 className="t-delta s-bottom--lrg">
            <FormattedMessage id="redeemed-rewards.shipping-info" />
          </h2>
          <div className="field">
            <label htmlFor="address" className="field__lbl">
              <FormattedMessage id="form.address" />
            </label>
            <input
              type="text"
              className="input input--med"
              placeholder="Enter address"
              name="address"
              id="address"
              value={shippingInfo.address || ''}
              onChange={handleShippingInfo}
            />
          </div>
          <div className="field">
            <label htmlFor="zipCode" className="field__lbl">
              <FormattedMessage id="form.post-code" />
            </label>
            <input
              type="text"
              className="input input--med"
              placeholder="Enter zip code"
              name="zipCode"
              id="zipCode"
              value={shippingInfo.zipCode || ''}
              onChange={handleShippingInfo}
            />
          </div>
          <div className="field">
            <label htmlFor="city" className="field__lbl">
              <FormattedMessage id="form.city" />
            </label>
            <input
              type="text"
              className="input input--med"
              placeholder="Enter city name"
              name="city"
              id="city"
              value={shippingInfo.city || ''}
              onChange={handleShippingInfo}
            />
          </div>
        </div>

        <div className="field">
          <label htmlFor="react-date-redeeem-reward" className="field__lbl">
            <FormattedMessage id="form.date" />
          </label>
          <SingleDatePicker
            date={moment(date)}
            onDateChange={voidHandler}
            focused={false}
            onFocusChange={voidHandler}
            id="react-date-redeeem-reward"
            displayFormat="DD/MM/YYYY"
            disabled={true}
            showDefaultInputIcon={true}
          />
        </div>

        <div className="field">
          <label htmlFor="shipped" className="field__lbl">
            <FormattedMessage id="form.shipped" />
          </label>
          <Switch onChange={handleChange} checked={shipped} id="shipped" />
        </div>

        <footer className="card__footer">
          <div className="btn-group">
            <button
              className="btn btn--primary btn--med"
              onClick={saveCard}
              disabled={!pageModified}
            >
              <FormattedMessage id="button.save" />
            </button>
            <button
              className="btn btn--ghost btn--med btn--hasIcon"
              onClick={toggleModal}
              data-id={redeemReward.id}
            >
              <MdDelete size={16} />
            </button>
          </div>
        </footer>
      </div>

      <Modal />

      <PageGuard when={pageModified} />
    </section>
  );
};

export default connect<ReduxProps, DispatchProps, Props, ApplicationState>(
  (state, ownProps) => ({
    redeemReward: state.rewards.redeemedRewards.filter(
      item => item.id === ownProps.match.params.id,
    )[0],
    rewardsAreChanging: state.rewards.loading,
  }),
  {
    getAll: RewardThunks.getAllRedeemedRewardsAsync,
    saveRedeemReward: RewardThunks.saveRedeemReward,
    removeRedeemedReward: RewardThunks.removeRedeemed,
  },
)(RedeemRewardDetails);
