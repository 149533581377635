import { createSelector } from 'reselect';

import { ApplicationState } from 'modules/app-state';
import { getSearchQuery, applySearchParams } from 'modules/search';

const getFAQ = (state: ApplicationState) => state.faq.items;

export const searchFAQ = createSelector(
  [getFAQ, getSearchQuery],
  (content, query) =>
    content.filter(({ title, id }) => applySearchParams([title, id], query)),
);
