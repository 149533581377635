import { ActionUnion } from 'modules/app-state';

import { LocalisationActions } from './actions';
import { LocalisationActionTypes } from './types';

import { Localisation } from '../models';

export interface LocalisationState {
  readonly loading: boolean;
  readonly error?: string;
  readonly items: Localisation[];
}

const INITIAL_STATE: LocalisationState = {
  loading: false,
  error: undefined,
  items: [],
};

export default (
  state: LocalisationState = INITIAL_STATE,
  action: ActionUnion<typeof LocalisationActions>,
): LocalisationState => {
  switch (action.type) {
    case LocalisationActionTypes.Change:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case LocalisationActionTypes.UpdateAll:
      return {
        ...state,
        loading: false,
        error: undefined,
        items: action.payload.localisations,
      };
    case LocalisationActionTypes.UpdateSingle:
      return {
        ...state,
        loading: false,
        error: undefined,
        items: [
          ...state.items.filter(i => i !== action.payload.localisation),
          action.payload.localisation,
        ],
      };
    case LocalisationActionTypes.Error:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state || INITIAL_STATE;
  }
};
