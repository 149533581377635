import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Select, { ValueType } from 'react-select';
import orderBy from 'lodash/orderBy';
import { toast } from 'react-toastify';

import { ApplicationState } from 'modules/app-state';
import {
  FeedbackDirection,
  DirectionOptions,
  FeedbackCard,
  FeedbackCardItem,
} from 'modules/feedback';
import { PageHeaderAction } from 'modules/app-ui/models';
import { useFormatMessage } from 'modules/localisation';
import {
  usePageModified,
  PageHeader,
  Loading,
  EmptyState,
  PageGuard,
} from 'modules/app-ui';

import { FeedbackThunks } from '../redux';

interface DispatchProps {
  getAll: VoidFunction;
  saveAll: (
    feedbackCards: FeedbackCard[],
    oldFeedbackCards: FeedbackCard[],
  ) => void;
}

interface ReduxProps {
  feedbackCards: FeedbackCard[];
  error?: string;
  feedbackCardsAreChanging: boolean;
}

type Props = DispatchProps & ReduxProps;

const FeedbackCardsList: React.FC<Props> = ({
  getAll,
  feedbackCards,
  error,
  feedbackCardsAreChanging,
  saveAll,
}) => {
  const [feedbackCardsState, setFeedbackCardsState] = useState(feedbackCards);
  const [pageModified, setPageModified] = usePageModified(
    'feedback-cards-contentarea',
  );
  const [cardsError, setCardsError] = useState('');
  const [directionFilter, setDirectionFilter] = useState(DirectionOptions[0]);
  const [formatMessage] = useFormatMessage();

  const handleChange = (id: string, feedbackCard: FeedbackCard) => {
    const tempState = feedbackCardsState.map(card => {
      if (card.id === id) {
        return feedbackCard;
      }
      return card;
    });

    setFeedbackCardsState(tempState);
    setPageModified(true);
  };

  const handleActiveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { dataset, checked } = event.currentTarget;

    const tempState = feedbackCardsState.map(item => {
      if (item.id === dataset.id) {
        return {
          ...item,
          active: checked,
        };
      }
      return item;
    });

    setFeedbackCardsState(tempState);
    setPageModified(true);
  };

  const handleDirectionChange = (
    value: ValueType<{
      value: FeedbackDirection;
      label: string | JSX.Element;
    }>,
  ) => {
    setDirectionFilter(value as any);
  };

  const setDirection = (id: string, direction: FeedbackDirection) => {
    const tempState = feedbackCardsState.map(item => {
      if (item.id === id) {
        return {
          ...item,
          direction,
        };
      }
      return item;
    });

    setFeedbackCardsState(tempState);
    setPageModified(true);
  };

  const pushNewFeedbackCard = () => {
    const tempState = [...feedbackCardsState];
    const empty = tempState.find(card => !card.id);
    if (empty) {
      return;
    }

    tempState.push({ ...new FeedbackCard(directionFilter.value) });

    setFeedbackCardsState(tempState);
  };

  const deleteFeedbackCard = (id: string) => {
    const tempState = feedbackCardsState.map(card => {
      if (card.id !== id) {
        return card;
      }
      return null;
    });

    const newState = tempState.filter(card => card !== null) as FeedbackCard[];

    setFeedbackCardsState(newState);
    setPageModified(true);
  };

  const saveFeedbackCards = () => {
    setCardsError('');
    setPageModified(false);

    if (
      !navigator.onLine &&
      feedbackCardsState.some(card => Boolean(card.imageFile))
    ) {
      toast.warn(formatMessage('toast.images.disabled'));
    }

    saveAll(feedbackCardsState, feedbackCards);

    toast.success(formatMessage('toast.feedback-cards.save'));
  };

  useEffect(() => {
    setFeedbackCardsState(feedbackCards);
  }, [feedbackCards]);

  useEffect(() => {
    getAll();
  }, [getAll]);

  return (
    <section className="contentarea v-levels" id="feedback-cards-contentarea">
      <PageHeader
        title={<FormattedMessage id="feedback-cards" />}
        actions={[
          new PageHeaderAction(
            (<FormattedMessage id="feedback-cards.btn.add" />),
            pushNewFeedbackCard,
          ),
        ]}
      />

      <div className="g-three s-bottom--lrg">
        <div className="g-three__item">
          <div className="field">
            <label htmlFor="order" className="field__lbl">
              <FormattedMessage id="form.direction-order" />
            </label>
            <Select
              value={directionFilter}
              onChange={handleDirectionChange}
              options={DirectionOptions}
              id="order"
              className="input--select s-bottom--lrg"
            />
          </div>
        </div>
      </div>

      {error && (
        <div className="alert alert--warning s-bottom--lrg">{error}</div>
      )}

      <Loading fullPage isLoading={feedbackCardsAreChanging}>
        {!feedbackCardsState.length && <EmptyState showImage />}

        <div className="v-levels__list">
          {feedbackCardsState.length > 0 &&
            feedbackCardsState
              .filter(card => card.direction === directionFilter.value)
              .map((feedbackCard, index) => (
                <FeedbackCardItem
                  key={`giving_${index}`}
                  feedbackCard={feedbackCard}
                  handleChange={handleChange}
                  handleActiveChange={handleActiveChange}
                  setDirection={setDirection}
                  deleteFeedbackCard={deleteFeedbackCard}
                />
              ))}
        </div>

        {cardsError && (
          <div className="alert alert--warning s-bottom--lrg">{cardsError}</div>
        )}

        {feedbackCardsState.length > 0 && (
          <div className="contentarea__actionbar">
            <button
              className="btn btn--primary btn--med"
              onClick={saveFeedbackCards}
            >
              <FormattedMessage id="button.save" />
            </button>
          </div>
        )}
      </Loading>

      <PageGuard when={pageModified} />
    </section>
  );
};

export default connect<ReduxProps, DispatchProps, null, ApplicationState>(
  state => ({
    feedbackCards: orderBy(state.feedback.cards, ['active'], 'desc'),
    error: state.feedback.error,
    feedbackCardsAreChanging: state.feedback.loading,
  }),
  {
    getAll: FeedbackThunks.getAllFeedbackCards,
    saveAll: FeedbackThunks.saveFeedbackCards,
  },
)(FeedbackCardsList);
