import { Dispatch } from 'redux';
import moment from 'moment';

import { ApplicationState } from 'modules/app-state';

import { ProductsActions } from './actions';

import { Product } from '../models';
import { ProductsService } from '../services';

const getAllAsync = () => async (
  dispatch: Dispatch,
  getState: () => ApplicationState,
) => {
  if (getState().products.items.length) {
    return;
  }

  dispatch(ProductsActions.change());

  const successFunction = (data: Product[]) =>
    dispatch(ProductsActions.update(data));

  const errorFunction = (error: string) =>
    dispatch(ProductsActions.error(error));

  const listenerProps = {
    successFunction,
    errorFunction,
  };

  ProductsService.Database.filterAsync(undefined, listenerProps);

  return;
};

const saveProduct = (product: Product) => (
  dispatch: Dispatch,
  getState: () => ApplicationState,
): string | undefined => {
  const sendProductData = (url?: string) => {
    ProductsService.Database.addAsync({
      id: product.id,
      name: product.name,
      actionPoints: parseInt(product.actionPoints as string),
      imageUrl: url || product.imageUrl,
      barcode: product.barcode,
      description: product.description,
      leadText: product.leadText,
      scanned: product.scanned,
      url: product.url,
      activeDate: product.activeDate,
    });
  };

  const checkNumberOfDates = () => {
    const productActiveDay = moment(product.activeDate).format('YYYY-MM-DD');
    console.log('checkNumberOfDates -> productActiveDay', productActiveDay);

    return getState().products.items.filter(
      item =>
        item.activeDate &&
        moment(item.activeDate).format('YYYY-MM-DD') === productActiveDay &&
        item.id !== product.id,
    );
  };

  if (checkNumberOfDates().length >= 3) {
    return 'There are alredy 3 products belonging to this date';
  }

  dispatch(ProductsActions.change());

  if (!navigator.onLine) {
    sendProductData();

    return;
  }

  sendProductData();
  return;
};

const removeProduct = (id: string) => () => {
  ProductsService.Database.removeAsync(id);
};

export const ProductThunks = {
  getAllAsync,
  saveProduct,
  removeProduct,
};
