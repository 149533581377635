import { ActionUnion } from 'modules/app-state';
import { User } from 'modules/users';

import { AuthenticationActions } from './actions';
import { AuthenticationActionTypes } from './types';

export interface AuthenticationState {
  readonly loading: boolean;
  readonly isLoggedIn: boolean;
  readonly user?: User;
  readonly error?: string;
}

const INITIAL_STATE: AuthenticationState = {
  loading: false,
  isLoggedIn: false,
  user: undefined,
  error: undefined,
};

export default (
  state: AuthenticationState = INITIAL_STATE,
  action: ActionUnion<typeof AuthenticationActions>,
): AuthenticationState => {
  switch (action.type) {
    case AuthenticationActionTypes.Change:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case AuthenticationActionTypes.Login:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        user: action.payload.user,
      };
    case AuthenticationActionTypes.Error:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        error: action.payload.error,
      };
    case AuthenticationActionTypes.Logout:
      return INITIAL_STATE;
    default:
      return state || INITIAL_STATE;
  }
};
