export interface FirebaseLocation {
  id: string;
  distance: number;
  name: string;
  location: {
    latitude: number;
    longitude: number;
  };
}

export class FirebaseLocation {
  constructor(data?: Partial<FirebaseLocation>) {
    if (data && data.id) {
      this.id = data.id;
    }

    this.name = '';
    this.distance = 0;
    this.location = {
      latitude: 0,
      longitude: 0,
    };
  }
}
