import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';

import { MenuModule } from 'modules/settings/models';
import { ApplicationState } from 'modules/app-state';
import { SettingsThunks, EnabledModules } from 'modules/settings';
import { useFormatMessage } from 'modules/localisation';
import { PageHeader, Loading } from 'modules/app-ui';

interface DispatchProps {
  saveModules: (modules: MenuModule[]) => void;
}

interface ReduxProps {
  displayModules: MenuModule[];
  error?: string;
  isChanging: boolean;
}

type Props = DispatchProps & ReduxProps;

const SettingsModules: React.FC<Props> = ({
  displayModules,
  saveModules,
  isChanging,
}) => {
  const [error] = useState();
  const [activeModules, setActiveModules] = useState(displayModules);
  const [formatMessage] = useFormatMessage();

  useEffect(() => {
    setActiveModules(displayModules);
  }, [displayModules]);

  const handleModulesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { dataset, checked } = event.currentTarget;

    const tempModules = activeModules.map(item => {
      if (item.title === dataset.title) {
        return {
          key: item.key,
          title: item.title,
          showInMenu: checked,
        };
      }
      return item;
    });

    setActiveModules(tempModules);
  };

  const handleSave = () => {
    saveModules(activeModules);
    toast.success(formatMessage('toast.settings-modules.save'));
  };

  return (
    <section className="contentarea">
      <PageHeader title={<FormattedMessage id="settings.modules.title" />} />

      {error && <div className="alert alert--warning">{error}</div>}

      <Loading fullPage isLoading={isChanging}>
        <EnabledModules
          modules={activeModules}
          handleModulesChange={handleModulesChange}
        />
      </Loading>

      <div className="contentarea__actionbar">
        <button className="btn btn--primary btn--med" onClick={handleSave}>
          <FormattedMessage id="button.save" />
        </button>
      </div>
    </section>
  );
};

export default connect<ReduxProps, DispatchProps, null, ApplicationState>(
  state => ({
    displayModules: state.settings.modules,
    error: state.settings.error,
    isChanging: state.settings.loading,
  }),
  {
    saveModules: SettingsThunks.saveAllModules,
  },
)(SettingsModules);
