import React from 'react';
import { MdDelete } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { FormattedMessage, FormattedDate } from 'react-intl';

import { RoutePath } from 'modules/navigation';
import { User } from 'modules/users';

import { Point } from '../models';

interface Props {
  user?: User;
  point: Point;
  toggleModal: VoidFunction;
}

export const PointHistoryItem: React.FC<Props> = ({
  user,
  point,
  toggleModal,
}) => (
  <div className="itemlist--simple__item">
    <div className="itemlist--simple__item__content">
      <Link to={`${RoutePath.PointHistory}/${point.id}`}>
        <h2 className="t-zeta">
          <strong>
            {user
              ? user.firstName && user.lastName
                ? `${user.firstName} ${user.lastName}`
                : `${user.email}`
              : 'User not found'}{' '}
          </strong>
        </h2>
      </Link>
      <p className="t-eta">
        <span className="o-60">{point.actionName}</span>{' '}
        <span className="o-40">
          <FormattedDate
            value={new Date(point.date)}
            year="numeric"
            month="long"
            day="2-digit"
          />
        </span>
      </p>
    </div>

    <div className="itemlist--simple__item__action">
      <div className="btn-group">
        <Link
          className="btn btn--sml btn--secondary"
          to={`${RoutePath.PointHistory}/${point.id}`}
        >
          <FormattedMessage id="button.edit" />
        </Link>
        <button
          data-id={point.id}
          className="btn btn--ghost btn--sml btn--hasIcon"
          onClick={toggleModal}
        >
          <MdDelete size={16} />
        </button>
      </div>
    </div>
  </div>
);
