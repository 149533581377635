import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { uniq } from 'lodash';

import { ApplicationState } from 'modules/app-state';
import { RoutePath } from 'modules/navigation';
import { PageHeaderAction } from 'modules/app-ui/models';
import { SearchQuery, SearchActions } from 'modules/search';
import {
  ProductThunks,
  Product,
  ProductItem,
  searchProducts,
} from 'modules/products';
import {
  useModal,
  usePagination,
  PageHeader,
  Loading,
  EmptyState,
} from 'modules/app-ui';

interface DispatchProps {
  getAll: VoidFunction;
  removeProduct: (id: string) => void;
  search: SearchQuery;
}

interface ReduxProps {
  products: Product[];
  error?: string;
  productsAreChanging: boolean;
}

type Props = DispatchProps & ReduxProps;

const ProductList: React.FC<Props> = ({
  getAll,
  error,
  productsAreChanging,
  removeProduct,
  products,
  search,
}) => {
  useEffect(() => {
    getAll();
  }, [getAll]);

  const [toggleModal, Modal] = useModal(removeProduct);

  const uniqSortedDates = uniq(
    products.map(item =>
      item.activeDate ? moment(item.activeDate).format('DD-MM-YYYY') : 'Draft',
    ),
  );

  const [pagedItems, Pagination] = usePagination(uniqSortedDates);

  return (
    <section className="contentarea">
      <PageHeader
        onSearch={search}
        title={<FormattedMessage id="products" />}
        actions={[
          new PageHeaderAction(
            (<FormattedMessage id="products.btn.add" />),
            `${RoutePath.Products}/new`,
          ),
        ]}
      />

      {error && <div className="alert alert--warning">{error}</div>}

      <Loading fullPage isLoading={productsAreChanging}>
        {products && !products.length && <EmptyState showImage />}

        {pagedItems.map(date => (
          <div key={date} className="s-bottom--lrg">
            <h3 className="t-delta">{date}</h3>
            {products
              .filter(item =>
                item.activeDate
                  ? moment(item.activeDate).format('DD-MM-YYYY') === date
                  : !item.activeDate && date === 'Draft',
              )
              .map(product => (
                <ProductItem
                  product={product}
                  key={product.id}
                  toggleModal={toggleModal}
                />
              ))}
          </div>
        ))}
        <Modal />

        <Pagination />
      </Loading>
    </section>
  );
};

export default connect<ReduxProps, DispatchProps, Props, ApplicationState>(
  state => ({
    products: searchProducts(state),
    error: state.products.error,
    productsAreChanging: state.products.loading,
  }),
  {
    removeProduct: ProductThunks.removeProduct,
    getAll: ProductThunks.getAllAsync,
    search: SearchActions.querySearch,
  },
)(ProductList);
