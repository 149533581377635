import {
  FirebaseDatabaseService,
  StorageService,
  FirebaseAuthService,
} from 'modules/firebase';

import { User, UserLevel } from '../models';

const collectionName = 'user-info';

export const UsersService = {
  Auth: new FirebaseAuthService(),
  Database: new FirebaseDatabaseService<User>(collectionName),
  Storage: new StorageService<File>(collectionName + '/'),
};

export const UserLevelsService = new FirebaseDatabaseService<{
  id: string;
  levels: UserLevel[];
}>('settings');
