export const MediaOptions: Array<{ value: string; label: string }> = [
  {
    value: 'Gallery',
    label: 'Gallery',
  },
  {
    value: 'Image',
    label: 'Image',
  },
  {
    value: 'Video',
    label: 'Video',
  },
];
