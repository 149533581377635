import { createSelector } from 'reselect';

import { ApplicationState } from 'modules/app-state';
import { applySearchParams, getSearchQuery } from 'modules/search';

const getArticles = (state: ApplicationState) => state.articles.items;

export const searchArticles = createSelector(
  [getArticles, getSearchQuery],
  (content, query) =>
    content.filter(({ title, id }) => applySearchParams([id, title], query)),
);
