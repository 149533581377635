import React from 'react';
import { MdDelete, MdSave } from 'react-icons/md';

import { usePageModified } from 'modules/app-ui';

import { Localisation } from '../models';

interface Props {
  index: number;
  objectKey: string;
  locState: Localisation;
  saveHandler: () => void;
  deleteHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleKeysChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleValuesChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const LocalisationDetailsRow: React.FC<Props> = ({
  index,
  objectKey,
  locState,
  deleteHandler,
  saveHandler,
  handleKeysChange,
  handleValuesChange,
}) => {
  const [pageModified, setPageModified] = usePageModified(
    `localisation_row_${index}`,
  );

  const save = () => {
    setPageModified(false);
    saveHandler();
  };

  return (
    <div className="l-boxed__item" id={`localisation_row_${index}`}>
      <div className="v-localisation__grid">
        <div className="v-localisation__grid__item">
          <input
            className="input input--sml"
            type="text"
            placeholder="Enter a key name"
            value={objectKey}
            name={objectKey}
            onChange={handleKeysChange}
          />
        </div>
        <div className="v-localisation__grid__item">
          <input
            className="input input--sml"
            type="text"
            placeholder="Enter a value"
            value={locState[objectKey]}
            onChange={handleValuesChange}
            name={objectKey}
          />
        </div>
        <div className="v-localisation__grid__item">
          {!pageModified && (
            <button
              className="btn btn--ghost btn--sml btn--hasIcon"
              data-key={objectKey}
              onClick={deleteHandler}
            >
              <MdDelete size={16} />
            </button>
          )}

          {pageModified && (
            <button
              className="btn btn--ghost btn--sml btn--hasIcon u-color--success"
              onClick={save}
            >
              <MdSave size={16} type="sharp" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
