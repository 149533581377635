import * as React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { AppRoute, getRoute, RoutePath } from 'modules/navigation';

interface Props {
  loginRoute: AppRoute;
}

export const ErrorPermissions: React.FC<Props> = ({
  loginRoute = getRoute(RoutePath.Login),
}) => (
  <section className="v-errorpage">
    <div className="v-errorpage__content">
      <div className="card">
        <h1 className="t-gamma s-bottom--lrg">
          <FormattedMessage id="error.permissions" />
        </h1>
        <p className="t-zeta s-bottom--lrg">
          <FormattedMessage id="error.permissions.login" />
        </p>
        <Link to={loginRoute.path} className="btn btn--primary btn--med">
          {loginRoute.title}
        </Link>
      </div>
    </div>
  </section>
);
