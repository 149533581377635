import React, { useState, ChangeEvent, useEffect } from 'react';
import Files from 'react-files';
import { MdClose } from 'react-icons/md';
import { FormattedMessage } from 'react-intl';

import { BadgeLevel } from '../models';

interface Props {
  badgeLevel: BadgeLevel;
  id: number;
  onUpdate: (currentLevel: number, badgeLevel: BadgeLevel) => void;
  onRemove: (badgeLevel: BadgeLevel) => void;
}

export const BadgeDetailsCardItem: React.FC<Props> = ({
  badgeLevel,
  id,
  onUpdate,
  onRemove,
}) => {
  const [imageUrl, setImageUrl] = useState(badgeLevel.imageUrl);
  const [show, toggleShow] = useState(false);

  useEffect(() => {
    if (!badgeLevel.imageFile) {
      setImageUrl(badgeLevel.imageUrl);
    }
  }, [badgeLevel]);

  const handleRemove = () => onRemove(badgeLevel);
  const toggleVisibility = () => toggleShow(!show);

  const handleUpdate = (event: ChangeEvent<HTMLInputElement>) => {
    const templevels = { ...badgeLevel };
    templevels.level = parseInt(event.currentTarget.value) || 0;
    onUpdate(id, templevels);
  };

  const handleImageChange = (files: File[]) => {
    setImageUrl(URL.createObjectURL(files[files.length - 1]));

    // update image reference in the parent
    badgeLevel.imageUrl = `badges/${files[files.length - 1].name}`;
    badgeLevel.imageFile = files[files.length - 1];

    onUpdate(badgeLevel.level, badgeLevel);
  };

  return (
    <div className="v-badges__list__item">
      <div className="v-badges__list__item__remove">
        <button onClick={handleRemove} className="btn btn--dark btn--close">
          <MdClose size={16} />
        </button>
      </div>

      <div
        className="v-badges__list__item__image"
        onMouseEnter={toggleVisibility}
        onMouseLeave={toggleVisibility}
      >
        {!imageUrl && (
          <img
            className={show ? 'o-60 u-z-0' : ''}
            src={require('assets/images/badge-blank.svg')}
            alt={'badge-blank'}
          />
        )}
        {imageUrl && (
          <img
            className={show ? 'o-60 u-z-0' : ''}
            src={imageUrl}
            alt="badge-icon"
          />
        )}

        <div
          className={
            show
              ? 'v-badges__list__item__image__change'
              : 'v-badges__list__item__image__change o-zero u-z-0'
          }
        >
          <Files
            onChange={handleImageChange}
            maxFiles={Infinity}
            maxFileSize={10000000}
            minFileSize={0}
            clickable
          >
            <button className="btn btn--primary btn--sml">
              <FormattedMessage id="button.change-image" />
            </button>
          </Files>
        </div>
      </div>

      {badgeLevel && (
        <div className="badge__level">
          <label className="field__lbl">
            <FormattedMessage id="badges.level" />
          </label>
          <input
            className="input input--sml"
            type="number"
            min={0}
            value={badgeLevel.level}
            onChange={handleUpdate}
          />
        </div>
      )}
    </div>
  );
};
