import includes from 'lodash/includes';
import isEqual from 'lodash/isEqual';
import meanBy from 'lodash/meanBy';
import React, { useEffect, useState } from 'react';
import { MdDelete } from 'react-icons/md';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import Select, { ValueType } from 'react-select';
import { toast } from 'react-toastify';

import {
  RoleOptions,
  User,
  UserPrivateProfile,
  UserThunks,
} from 'modules/users';
import { RoutePath } from 'modules/navigation';
import { useFormatMessage } from 'modules/localisation';
import { Article, ArticlesThunks } from 'modules/articles';
import { ApplicationState } from 'modules/app-state';
import {
  PageHeaderAction,
  usePageModified,
  useModal,
  EmptyState,
  Loading,
  PageHeader,
  PageGuard,
} from 'modules/app-ui';
import { ImageUploader } from 'modules/media';

interface DispatchProps {
  getAll: VoidFunction;
  updateUser: (user: User, imageFile?: File) => void;
  removeUser: (userId: string) => void;
  getArticles: VoidFunction;
}

interface ReduxProps {
  currentUser?: User;
  user: User;
  usersAreChanging: boolean;
  articles: Article[];
}

type Props = DispatchProps & ReduxProps & RouteComponentProps<any>;

const UserDetails: React.FC<Props> = ({
  getAll,
  currentUser,
  user,
  articles,
  getArticles,
  updateUser,
  usersAreChanging,
  removeUser,
  history,
}) => {
  const redirectToList = () => {
    history.push(RoutePath.Users);
  };

  const [state, setState] = useState(user);
  const [imageFile, setImageFile] = useState<File>();
  const [validationError, setValidationError] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [formatMessage] = useFormatMessage();
  const [toggleModal, Modal] = useModal(removeUser, redirectToList);
  const [pageModified, setPageModified] = usePageModified('users-contentarea');

  useEffect(() => {
    if (!articles.length) {
      getArticles();
    }
  }, [articles, getArticles]);

  useEffect(() => {
    if (!user) {
      return;
    }

    setState(user);
  }, [user]);

  if (!state) {
    if (!usersAreChanging) {
      return <EmptyState showImage />;
    }

    return <Loading isLoading fullPage />;
  }

  const {
    firstName,
    lastName,
    email,
    phone,
    photoUrl,
    position,
    department,
    team,
    company,
    active,
    rating,
    privateProfile,
  } = state;

  const onImageChange = (files: File[]) => {
    setImageFile(files[files.length - 1]);

    setState({
      ...state,
      photoUrl: URL.createObjectURL(files[files.length - 1]),
    });

    setPageModified(true);
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setState({
      ...state,
      [name]: value,
    });
    setPageModified(true);
  };

  const handleRoleChange = (
    value: ValueType<{
      value: string;
      label: string;
    }>,
  ) => {
    setState({
      ...state,
      privateProfile: {
        ...state.privateProfile,
        role: (value as any).value,
      },
    });

    setPageModified(true);
  };

  const isFormValid = () => {
    if (!email || !firstName || !lastName) {
      setValidationError(
        <>
          <strong>{formatMessage('error.missing')}:</strong>
          {!firstName && <div>{formatMessage('form.first-name')}</div>}
          {!lastName && <div>{formatMessage('form.last-name')}</div>}
          {!email && <div>{formatMessage('form.email')}</div>}
        </>,
      );

      window.scrollTo(0, 0);
      return false;
    }

    return true;
  };

  const saveUser = async () => {
    if (!isFormValid()) {
      return;
    }

    await setPageModified(false);

    setLoading(true);

    updateUser({ ...state }, imageFile);
    if (!isEqual(user.privateProfile, privateProfile)) {
      getAll();
    }

    if (!navigator.onLine) {
      toast.warn('toast.user.disabled');
      redirectToList();

      return;
    }

    toast.success(
      formatMessage(
        'toast.users.update',
        `${state.firstName} ${state.lastName}`,
      ),
    );
    redirectToList();
  };

  const removeProfileImage = () => {
    setState({
      ...state,
      photoUrl: '',
    });

    setImageFile(undefined);
    setPageModified(true);
  };

  return (
    <section className="contentarea" id="users-contentarea">
      <PageHeader
        title={<FormattedMessage id="users.details.title" />}
        backAction={
          new PageHeaderAction(
            (<FormattedMessage id="users.back-to-list" />),
            RoutePath.Users,
          )
        }
      />

      <div className="card">
        <Loading isLoading={loading} fullPage>
          {validationError && (
            <div className="alert alert--warning s-bottom--med">
              {validationError}
            </div>
          )}

          <h2 className="t-delta s-bottom--lrg">
            <FormattedMessage id="users.details.public" />
          </h2>

          <div className="field">
            <label htmlFor="firstName" className="field__lbl t-mandatory">
              <FormattedMessage id="form.first-name" />
            </label>
            <input
              type="text"
              className="input input--med"
              name="firstName"
              id="firstName"
              placeholder={formatMessage('form.first-name.placeholder')}
              value={firstName || ''}
              onChange={onChange}
            />
          </div>

          <div className="field">
            <label htmlFor="lastName" className="field__lbl t-mandatory">
              <FormattedMessage id="form.last-name" />
            </label>
            <input
              type="text"
              className="input input--med"
              name="lastName"
              id="lastName"
              placeholder={formatMessage('form.last-name.placeholder')}
              value={lastName || ''}
              onChange={onChange}
            />
          </div>

          <div className="field">
            <label htmlFor="email" className="field__lbl t-mandatory">
              <FormattedMessage id="form.email" />
            </label>
            <input
              type="text"
              className="input input--med"
              name="email"
              id="email"
              placeholder={formatMessage('form.email.placeholder')}
              value={email || ''}
              onChange={onChange}
            />
          </div>

          <div className="field">
            <label htmlFor="phone" className="field__lbl">
              <FormattedMessage id="form.phone" />
            </label>
            <input
              type="text"
              className="input input--med"
              name="phone"
              id="phone"
              placeholder={formatMessage('form.phone.placeholder')}
              value={phone || ''}
              onChange={onChange}
            />
          </div>

          <div className="s-bottom--xlrg">
            <ImageUploader
              imageUrl={photoUrl}
              removeHandler={removeProfileImage}
              changeHandler={onImageChange}
            />
          </div>

          <div className="field">
            <label htmlFor="rating" className="field__lbl">
              <FormattedMessage id="form.rating" />
            </label>
            <div className="t-gamma">
              {rating ? meanBy(rating).toPrecision(2) : '0.0'}
            </div>
          </div>

          {privateProfile && (
            <>
              <h2 className="t-delta s-bottom--lrg">
                <FormattedMessage id="users.details.private" />
              </h2>

              <div className="field">
                <label htmlFor="position" className="field__lbl">
                  <FormattedMessage id="form.position" />
                </label>
                <input
                  type="text"
                  className="input input--med"
                  name="position"
                  id="position"
                  placeholder={formatMessage('form.position.placeholder')}
                  value={position || ''}
                  onChange={onChange}
                />
              </div>

              <div className="field">
                <label htmlFor="department" className="field__lbl">
                  <FormattedMessage id="form.department" />
                </label>
                <input
                  type="text"
                  className="input input--med"
                  name="department"
                  id="department"
                  placeholder={formatMessage('form.department.placeholder')}
                  value={department || ''}
                  onChange={onChange}
                />
              </div>

              <div className="field">
                <label htmlFor="team" className="field__lbl">
                  <FormattedMessage id="form.team" />
                </label>
                <input
                  type="text"
                  className="input input--med"
                  name="team"
                  id="team"
                  placeholder={formatMessage('form.team.placeholder')}
                  value={team || ''}
                  onChange={onChange}
                />
              </div>

              <div className="field">
                <label htmlFor="company" className="field__lbl">
                  <FormattedMessage id="form.company" />
                </label>
                <input
                  type="text"
                  className="input input--med"
                  name="company"
                  id="company"
                  placeholder={formatMessage('form.company.placeholder')}
                  value={company || ''}
                  onChange={onChange}
                />
              </div>

              <div className="field">
                <label htmlFor="UserRole" className="field__lbl">
                  <FormattedMessage id="form.role" />
                </label>
                <Loading isLoading={!privateProfile.role || !currentUser}>
                  <Select
                    id="UserRole"
                    options={RoleOptions}
                    value={
                      privateProfile.role
                        ? RoleOptions.find(
                            roleData =>
                              roleData.value ===
                              privateProfile.role.toLowerCase(),
                          )
                        : RoleOptions[0]
                    }
                    onChange={handleRoleChange}
                    isDisabled={currentUser?.role !== 'administrator'}
                  />
                </Loading>
              </div>
            </>
          )}

          {privateProfile && Object.keys(privateProfile).length > 0 && (
            <UserPrivateProfile
              privateProfile={privateProfile}
              handlePrivateChange={setState}
              articles={articles.filter(article =>
                includes(privateProfile.readArticles, article.id)
                  ? article
                  : null,
              )}
            />
          )}

          <div className="field">
            <input
              type="checkbox"
              className="input--check input--check--disabled"
              id="active"
              checked={active || false}
              disabled
            />
            <label htmlFor="active">Active user</label>
          </div>

          <div className="card__footer">
            <div className="btn-group">
              <button
                className="btn btn--med btn--primary"
                onClick={saveUser}
                disabled={!pageModified}
              >
                <FormattedMessage id="button.save" />
              </button>
              <button
                className="btn btn--ghost btn--med btn--hasIcon"
                onClick={toggleModal}
                data-id={user.id}
                data-title={`${user.firstName} ${user.lastName}`}
              >
                <MdDelete size={16} />
              </button>
            </div>
          </div>
        </Loading>
      </div>

      <Modal />

      <PageGuard when={pageModified} />
    </section>
  );
};

export default connect<ReduxProps, DispatchProps, Props, ApplicationState>(
  (state, ownProps) => ({
    currentUser: state.auth.user,
    user: state.users.items.filter(
      user => user.id === ownProps.match.params.id,
    )[0],
    articles: state.articles.items,
    usersAreChanging: state.users.loading,
  }),
  {
    getAll: UserThunks.getAllUsers,
    getArticles: ArticlesThunks.getAllAsync,
    updateUser: UserThunks.updateUser,
    removeUser: UserThunks.removeUser,
  },
)(UserDetails);
