import { HTMLSerializer } from 'modules/editor';

export interface Reward {
  id: string;
  published: boolean;
  amount: number;
  slug: string;
  title: string;
  description: string;
  points: number;
  validFrom: number | Date;
  validTo: number | Date;
  dateCreated?: number;
  featuredImageUrl: string;
}

export class Reward {
  constructor(
    id?: string,
    amount?: number,
    slug?: string,
    title?: string,
    description?: string,
    points?: number,
    featuredImageUrl?: string,
    validFrom?: number | Date,
    validTo?: number | Date,
    published?: boolean,
    dateCreated?: number,
  ) {
    this.amount = amount || 0;
    this.slug = slug || '';
    this.title = title || '';
    this.description = description || '';
    this.points = points || 0;
    this.featuredImageUrl = featuredImageUrl || '';
    this.validFrom = validFrom || Date.now();
    this.validTo = validTo || Date.now();
    this.dateCreated = dateCreated || Date.now();
    this.published = published || false;

    if (id) {
      this.id = id;
    }
  }

  serializeDates() {
    this.validFrom = +this.validFrom;
    this.validTo = +this.validTo;

    return this;
  }

  deserializeDates() {
    this.validFrom = new Date(this.validFrom);
    this.validTo = new Date(this.validTo);

    return this;
  }

  exportData(editorValue: any, url?: string): Reward {
    this.description = HTMLSerializer.init().serialize(editorValue);

    this.featuredImageUrl = url || this.featuredImageUrl;

    return { ...this };
  }
}
