import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { RoutePath } from 'modules/navigation';
import { ApplicationState } from 'modules/app-state';
import { ArticleList } from 'modules/articles';
import { FeedbackList } from 'modules/feedback';
import { PointHistoryList } from 'modules/gamification';
import { RedeemedRewardList } from 'modules/rewards';
import { PageHeader } from 'modules/app-ui';

import { SummaryCard } from '../components';

const Summary: React.FC = () => {
  const enabledModules = useSelector(
    (state: ApplicationState) => state.settings.modules,
  );

  return (
    <section className="contentarea">
      <PageHeader title={<FormattedMessage id="summary" />} />

      <div className="g-blocks">
        {enabledModules?.find(m => m.key === 'feedback' && m.showInMenu) && (
          <SummaryCard
            title={<FormattedMessage id="summary.feedback.title" />}
            content={<FeedbackList forSummaryModule={true} />}
            linkTo={RoutePath.AllFeedbacks}
          />
        )}

        <SummaryCard
          title={<FormattedMessage id="summary.redeemed.title" />}
          content={<RedeemedRewardList forSummaryModule={true} />}
          linkTo={RoutePath.RedeemedRewards}
        />

        <SummaryCard
          title={<FormattedMessage id="summary.articles.title" />}
          content={<ArticleList forSummaryModule={true} />}
          linkTo={RoutePath.PublishedArticles}
        />

        <SummaryCard
          title={<FormattedMessage id="summary.points.title" />}
          content={<PointHistoryList forSummaryModule={true} />}
          linkTo={RoutePath.PointHistory}
        />
      </div>
    </section>
  );
};

export default Summary;
