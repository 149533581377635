import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import { FAQReducer } from 'modules/faq';
import { authenticationReducer } from 'modules/authentication';
import { gamificationReducer } from 'modules/gamification';
import { articlesReducer } from 'modules/articles';
import { rewardsReducer } from 'modules/rewards';
import { localisationReducer } from 'modules/localisation';
import { staticContentReducer } from 'modules/static-content';
import { usersReducer } from 'modules/users';
import { badgeReducer } from 'modules/badges';
import { feedbackReducer } from 'modules/feedback';
import { searchReducer } from 'modules/search';
import { settingsReducer } from 'modules/settings';
import { mediaReducer } from 'modules/media';
import { productsReducer } from 'modules/products';
import { locationsReducer } from 'modules/locations';

export const configureStore = () => {
  /**
   * Create the composing function for our middlewares
   * Include dev tools support
   */
  const composeEnhancers = composeWithDevTools({});

  /**
   * Merge all reducers into a single object
   */
  const rootReducer = {
    auth: authenticationReducer,
    gamification: gamificationReducer,
    articles: articlesReducer,
    rewards: rewardsReducer,
    products: productsReducer,
    localisation: localisationReducer,
    faq: FAQReducer,
    staticContent: staticContentReducer,
    users: usersReducer,
    badges: badgeReducer,
    feedback: feedbackReducer,
    settings: settingsReducer,
    media: mediaReducer,
    search: searchReducer,
    locations: locationsReducer,
  };

  /**
   * We'll create our store with the combined reducers and all enchancers
   */
  return createStore(
    combineReducers(rootReducer),
    {},
    composeEnhancers(applyMiddleware(thunk)),
  );
};
