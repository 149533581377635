import { createAction } from 'modules/app-state';

import { StaticContentActionTypes } from './types';

import { StaticContent } from '../models';

export const StaticContentActions = {
  change: () => createAction(StaticContentActionTypes.Change),

  update: (staticContent: StaticContent[]) =>
    createAction(StaticContentActionTypes.Update, { staticContent }),

  error: (error?: string) =>
    createAction(StaticContentActionTypes.Error, { error }),
};
