import React from 'react';
import 'normalize.css';
import 'css/app.css';
import 'react-dates/initialize';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import { AccessControlList } from 'modules/authorization';
import { configureStore } from 'modules/app-state';
import { ConnectedIntl } from 'modules/localisation';
import { AppLayout, useToaster } from 'modules/app-ui';

export const App: React.FC = () => {
  /**
   * Initialise toaster component
   */
  useToaster();

  const store = configureStore();

  /**
   * Return App components
   */
  return (
    <Provider store={store}>
      <ConnectedIntl>
        <BrowserRouter>
          <AppLayout>
            <AccessControlList />
          </AppLayout>
        </BrowserRouter>
      </ConnectedIntl>
    </Provider>
  );
};
