export enum PointAction {
  AppOpened = 'App Opened',
  giveFeedback = 'Give Feedback',
  askFeedback = 'Ask for Feedback',
  receiveFeedback = 'Receive Feedback',
  receivedThanks = 'Received Thanks',
  giveThanks = 'Give Thanks',
  readArticle = 'Read Article',
  doneSurvey = 'Done Survey',
  redeemedReward = 'Redeemed reward',
  wroteEmail = 'Wrote E-Mail',
  callUser = 'Called user',
  onboardingComplete = 'Onboarding completed',
  feedbackLoopComlete = 'Combination of Give and Receive Feedback and Give Thanks and Receive Thanks',
  contacted = 'Asked something or shared some ideas with the (HR) contact',
}

export enum PointType {
  Add = 'Add',
  Subtract = 'Subtract',
}

export const PointOptions = [
  { value: PointAction.AppOpened, label: PointAction.AppOpened },
  { value: PointAction.giveFeedback, label: PointAction.giveFeedback },
  { value: PointAction.askFeedback, label: PointAction.askFeedback },
  { value: PointAction.receiveFeedback, label: PointAction.receiveFeedback },
  { value: PointAction.receivedThanks, label: PointAction.receivedThanks },
  { value: PointAction.giveThanks, label: PointAction.giveThanks },
  { value: PointAction.readArticle, label: PointAction.readArticle },
  { value: PointAction.doneSurvey, label: PointAction.doneSurvey },
  { value: PointAction.redeemedReward, label: PointAction.redeemedReward },
  { value: PointAction.wroteEmail, label: PointAction.wroteEmail },
  { value: PointAction.callUser, label: PointAction.callUser },
  {
    value: PointAction.onboardingComplete,
    label: PointAction.onboardingComplete,
  },
  {
    value: PointAction.feedbackLoopComlete,
    label: PointAction.feedbackLoopComlete,
  },
  {
    value: PointAction.contacted,
    label: PointAction.contacted,
  },
];
