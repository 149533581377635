import { HTMLSerializer } from 'modules/editor';

import { Reward } from './Reward';

export interface VirtualReward extends Reward {
  virtual: boolean;
  shortDescription?: string;

  mainImageFile?: File;
  mainBlobImageUrl?: string;
  mainImageUrl: string;
  mainImageStorageReference: string;

  learnMoreTitle: string;
  learnMoreImageUrl: string;

  currentPoints: number;
  numberOfParts: number;

  imageGroupPrefix: string;
  imageGroupSeparator: string;
  imageGroupOptionalSuffix: string;
}

export class VirtualReward extends Reward {
  constructor(
    id?: string,
    amount?: number,
    slug?: string,
    title?: string,
    description?: string,
    points?: number,

    featuredImageUrl?: string,

    validFrom?: number | Date,
    validTo?: number | Date,

    published?: boolean,
    dateCreated?: number,

    shortDescription?: string,

    mainImageFile?: File,
    mainBlobImageUrl?: string,
    mainImageUrl?: string,
    mainImageStorageReference?: string,

    learnMoreTitle?: string,

    learnMoreImageUrl?: string,
  ) {
    super(
      id,
      amount || 1,
      slug,
      title,
      description,
      points,
      featuredImageUrl,
      validFrom,
      validTo,
      published,
      dateCreated,
    );

    this.virtual = true;

    this.shortDescription = shortDescription || '';

    this.mainImageFile = mainImageFile;
    this.mainBlobImageUrl = mainBlobImageUrl;
    this.mainImageUrl = mainImageUrl || '';
    this.mainImageStorageReference = mainImageStorageReference || '';

    this.learnMoreTitle = learnMoreTitle || '';
    this.learnMoreImageUrl = learnMoreImageUrl || '';

    this.currentPoints = 0;
    this.numberOfParts = 40;

    this.imageGroupPrefix = '_x3';
    this.imageGroupSeparator = '_';
    this.imageGroupOptionalSuffix = '_1_';
  }

  exportDataVirtual(editorValue: any, mainImageUrl?: string): VirtualReward {
    this.description = HTMLSerializer.init().serialize(editorValue);
    this.mainImageUrl = mainImageUrl || this.mainImageUrl;

    delete this.mainImageFile;
    delete this.mainBlobImageUrl;

    return { ...this };
  }
}
