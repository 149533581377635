import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-dates';
import { MdDelete, MdSave, MdFindInPage } from 'react-icons/md';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  PageHeaderAction,
  useModal,
  usePageModified,
  EmptyState,
  Loading,
  PageHeader,
  PageGuard,
} from 'modules/app-ui';
import { ApplicationState } from 'modules/app-state';
import {
  HTMLSerializer,
  SlateEditor,
  useSlateValue,
  InsertImageModal,
} from 'modules/editor';
import { useFormatMessage } from 'modules/localisation';
import { Media, MediaThunks } from 'modules/media';
import { RoutePath } from 'modules/navigation';
import { Reward, RewardThunks, useRewardValues } from 'modules/rewards';
import 'react-dates/lib/css/_datepicker.css';

interface DispatchProps {
  getRewards: VoidFunction;
  getAllMedia: VoidFunction;
  removeReward: (id: string) => void;
  saveReward: (
    rewardValues: Reward,
    editorValue: any,
    oldRewardValues?: Reward,
  ) => void;
  uploadMedia: (
    title: string,
    mediaType: 'Gallery' | 'Image' | 'Video',
    files: File[],
  ) => Promise<void | firebase.firestore.DocumentReference>;
}

interface ReduxProps {
  reward: Reward;
  existingSlugs: string[];
  rewardsAreChanging: boolean;
  galleryMedia: Media[];
  mediaIsChanging: boolean;
}

type Props = DispatchProps & ReduxProps & RouteComponentProps<any>;

const RewardDetails: React.FC<Props> = ({
  reward,
  saveReward,
  removeReward,
  getRewards,
  rewardsAreChanging,
  getAllMedia,
  galleryMedia,
  mediaIsChanging,
  uploadMedia,
  existingSlugs,
}) => {
  const location = useLocation();
  const history = useHistory();
  const redirectToList = () => history.push(RoutePath.RewardsAll);

  const [toggleModal, Modal] = useModal(removeReward, redirectToList);
  const [editorValue, onChange, resetValue] = useSlateValue();
  const [error, setError] = useState<JSX.Element>();
  const [formatMessage] = useFormatMessage();
  const [coverPhotoModal, toggleCoverPhotoModal] = useState(false);
  const [coverPhotoExists, setCoverPhotoExists] = useState(true);
  const [pageModified, setPageModified] = usePageModified(
    'rewards-contentarea',
  );

  const {
    state,
    onInputChange,
    onImageChange,
    onDatesChange,
    focusesInput,
    onFocusChange,
    resetRewardValues,
    removeFeaturedImage,
    updateSlug,
  } = useRewardValues(reward, existingSlugs, setPageModified);

  const checkImage = (imageSrc: string) => {
    const img = new Image();
    img.src = imageSrc;
    img.onload = () => setCoverPhotoExists(true);
    img.onerror = () => setCoverPhotoExists(false);
  };

  useEffect(() => {
    if (state?.featuredImageUrl) {
      checkImage(state.featuredImageUrl);
    }
  }, [state]);

  useEffect(() => {
    getRewards();
    getAllMedia();
  }, [getRewards, getAllMedia]);

  useEffect(() => {
    if (!reward || state) {
      return;
    }

    const rewardObj = new Reward(
      reward.id,
      reward.amount,
      reward.slug,
      reward.title,
      '',
      reward.points,
      reward.featuredImageUrl,
      reward.validFrom,
      reward.validTo,
      reward.published,
      reward.dateCreated,
    );
    resetRewardValues(rewardObj.deserializeDates());
  }, [reward, resetRewardValues, state]);

  useEffect(() => {
    if (reward?.description) {
      resetValue(HTMLSerializer.init().deserialize(reward.description));
    }
  }, [resetValue, reward]);

  if (!state) {
    if (!rewardsAreChanging) {
      return <EmptyState showImage />;
    }

    return <Loading isLoading fullPage />;
  }

  const {
    id,
    title,
    slug,
    amount,
    points,
    validFrom,
    validTo,
    featuredImageUrl,
    published,
    dateCreated,
  } = state;

  const showCoverPhotoModal = async () => {
    await toggleCoverPhotoModal(!coverPhotoModal);
  };

  const isFormValid = () => {
    if (
      !state.title ||
      !state.slug ||
      !state.amount ||
      HTMLSerializer.init().serialize(editorValue) === '<p></p>'
    ) {
      setError(
        <>
          <div>{formatMessage('error.missing')}:</div>
          {!state.title && (
            <div>
              <strong>{formatMessage('form.title')}</strong>
            </div>
          )}
          {!state.slug && (
            <div>
              <strong>{formatMessage('form.slug')}</strong>
            </div>
          )}
          {!state.amount && (
            <div>
              <strong>{formatMessage('form.amount')}</strong>
            </div>
          )}
          {HTMLSerializer.init().serialize(editorValue) === '<p></p>' && (
            <div>
              <strong>{formatMessage('form.description')}</strong>
            </div>
          )}
        </>,
      );
      return false;
    }

    return true;
  };

  const checkAndSaveReward = async (changePublished?: boolean) => {
    if (!isFormValid()) {
      return;
    }
    await setPageModified(false);

    const rewardState = new Reward(
      id,
      amount,
      slug,
      title,
      '',
      points,
      featuredImageUrl,
      validFrom,
      validTo,
      changePublished ? !published : published,
      dateCreated,
    );

    saveReward(rewardState.serializeDates(), editorValue, reward);

    if (!navigator.onLine) {
      toast.warn(formatMessage('toast.images.disabled'));
    }

    toast.success(
      formatMessage(
        changePublished
          ? rewardState.published
            ? 'toast.article.publish'
            : 'toast.article.unpublish'
          : state.id
          ? 'toast.rewards.update'
          : 'toast.rewards.save',
        state.title,
      ),
    );
    redirectToList();
  };

  const handlePublish = () => {
    checkAndSaveReward(true);
  };

  const handleRewardSave = () => {
    checkAndSaveReward();
  };

  return (
    <section className="contentarea" id="rewards-contentarea">
      <PageHeader
        title={
          <FormattedMessage
            id={id === 'new' ? 'rewards.create.title' : 'rewards.details.title'}
          />
        }
        backAction={
          new PageHeaderAction(
            (<FormattedMessage id="rewards.back-to-list" />),
            RoutePath.RewardsAll,
          )
        }
      />

      {error && (
        <div className="alert alert--warning s-bottom--med">{error}</div>
      )}

      <div className="card">
        <div className="field">
          <label htmlFor="title" className="field__lbl t-mandatory">
            <FormattedMessage id="form.title" />
          </label>
          <FormattedMessage id="form.title.placeholder">
            {placeholder => (
              <input
                className="input input--med"
                data-type="title"
                id="title"
                placeholder={placeholder as string}
                value={title}
                onChange={onInputChange}
                onBlur={updateSlug}
              />
            )}
          </FormattedMessage>
        </div>

        <div className="field">
          <label htmlFor="slug" className="field__lbl t-mandatory">
            <FormattedMessage id="form.slug" />
          </label>
          <FormattedMessage id="form.slug.placeholder">
            {placeholder => (
              <input
                className="input input--med"
                data-type="slug"
                id="slug"
                placeholder={placeholder as string}
                value={slug}
                onChange={onInputChange}
              />
            )}
          </FormattedMessage>
        </div>

        <div className="g-inline">
          <div className="g-inline__item">
            <div className="field">
              <label htmlFor="amount" className="field__lbl t-mandatory">
                <FormattedMessage id="form.amount" />
              </label>
              <input
                type="number"
                min="0"
                className="input input--med"
                id="amount"
                data-type="amount"
                value={amount}
                onChange={onInputChange}
              />
            </div>
          </div>
          <div className="g-inline__item">
            <div className="field">
              <label htmlFor="points" className="field__lbl">
                <FormattedMessage id="form.points" />
              </label>
              <input
                type="number"
                min="0"
                className="input input--med"
                data-type="points"
                id="points"
                value={points}
                onChange={onInputChange}
              />
            </div>
          </div>
        </div>

        <div className="g-three">
          <div className="g-three__item">
            <div className="field">
              <label className="field__lbl">
                <FormattedMessage id="form.cover-photo" />
              </label>

              <div className="imageuploader">
                <Loading isLoading={mediaIsChanging}>
                  {!featuredImageUrl && (
                    <div className="imageuploader__image">
                      <div className="card t-center s-top--lrg">
                        <MdFindInPage
                          className="o-20 s-bottom--med"
                          size={64}
                        />
                        <p className="t-zeta o-60">
                          <FormattedMessage id="form.cover-photo.placeholder" />
                        </p>
                      </div>
                    </div>
                  )}

                  {featuredImageUrl && coverPhotoExists && (
                    <>
                      <img src={featuredImageUrl} alt="Not found" />

                      <div className="imageuploader__remove">
                        <button
                          className="btn btn--dark btn--close"
                          onClick={removeFeaturedImage}
                        >
                          <MdDelete size={16} />
                        </button>
                      </div>
                    </>
                  )}
                  {featuredImageUrl && !coverPhotoExists && (
                    <div className="s-top--lrg">
                      <EmptyState text="Image no loger exists" showImage />
                    </div>
                  )}
                  <div className="imageloader__action">
                    <button
                      onClick={showCoverPhotoModal}
                      className="btn btn--primary btn--med s-top--med"
                    >
                      <FormattedMessage
                        id={
                          !featuredImageUrl
                            ? 'button.upload-image'
                            : 'button.change-image'
                        }
                      />
                    </button>
                  </div>
                </Loading>
              </div>
            </div>
          </div>
        </div>

        <div className="field">
          <label className="field__lbl t-mandatory">
            <FormattedMessage id="form.description" />
          </label>
          <SlateEditor
            content={editorValue}
            onContentChange={onChange}
            modifiedHandler={setPageModified}
            location={location}
            galleryMedia={galleryMedia}
            mediaIsChanging={mediaIsChanging}
            uploadMedia={uploadMedia}
          />
        </div>

        <div className="g-inline">
          <div className="g-inline__item">
            <div className="field">
              <label className="field__lbl t-mandatory">
                <FormattedMessage id="form.interval" />
              </label>

              <DateRangePicker
                startDate={moment(validFrom)}
                startDateId="react-date-start"
                endDate={moment(validTo)}
                endDateId="react-date-end"
                onDatesChange={onDatesChange}
                displayFormat="DD/MM/YYYY"
                focusedInput={focusesInput}
                onFocusChange={onFocusChange}
                openDirection="up"
                isOutsideRange={() => false}
                showDefaultInputIcon={true}
              />
            </div>
          </div>
        </div>

        <footer className="card__footer">
          <div className="btn-group">
            <button
              className="btn btn--primary btn--med"
              onClick={handleRewardSave}
              disabled={!pageModified}
            >
              <MdSave size={16} />{' '}
              <FormattedMessage
                id={published ? 'button.save' : 'button.save-draft'}
              />
            </button>

            <button
              className={`btn btn--med ${
                published ? 'btn--danger' : 'btn--primary'
              }`}
              onClick={handlePublish}
              disabled={!id && !pageModified}
            >
              <FormattedMessage
                id={published ? 'button.unpublish' : 'button.publish'}
              />
            </button>

            {reward.id && (
              <button
                data-id={reward.id}
                data-title={reward.title}
                className="btn btn--ghost btn--med btn--hasIcon"
                onClick={toggleModal}
              >
                <MdDelete size={16} />
              </button>
            )}
          </div>
        </footer>

        <InsertImageModal
          showImageModal={showCoverPhotoModal}
          imageModal={coverPhotoModal}
          uploadMedia={uploadMedia}
          externalSubmitHandler={onImageChange}
        />

        <Modal />

        <PageGuard when={pageModified} />
      </div>
    </section>
  );
};

export default connect<ReduxProps, DispatchProps, Props, ApplicationState>(
  (state, ownProps) => {
    const id = ownProps.match.params.id;
    const reward =
      id === 'new'
        ? new Reward()
        : state.rewards.items.filter(item => item.slug === id)[0];

    return {
      reward,
      existingSlugs: state.rewards.items
        .filter(item => item.id !== reward?.id)
        .map(item => item.slug),
      rewardsAreChanging: state.rewards.loading,
      galleryMedia: state.media.items,
      mediaIsChanging: state.media.loading,
    };
  },
  {
    getRewards: RewardThunks.getAllRewardsAsync,
    saveReward: RewardThunks.saveReward,
    removeReward: RewardThunks.removeReward,
    uploadMedia: MediaThunks.uploadMediaFromSlate as any,
    getAllMedia: MediaThunks.getAllAsync,
  },
)(RewardDetails);
