export interface FeedbackThanks {
  id: string;
  active: boolean;
  imageUrl: string;
  imageReference?: string;
  imageFile?: File;
}

export class FeedbackThanks {
  constructor(id?: string, active?: boolean, imageUrl?: string) {
    this.imageUrl = imageUrl || '';
    this.active = active || false;

    if (id) {
      this.id = id;
    }
  }
}
