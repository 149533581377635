import * as React from 'react';
import { MdFindInPage } from 'react-icons/md';

interface Props {
  text?: string;
  showImage?: boolean;
}

export const EmptyState: React.FC<Props> = ({ text, showImage = false }) => (
  <React.Fragment>
    <div className="card t-center">
      {showImage && <MdFindInPage className="o-20 s-bottom--med" size={64} />}
      <p className="t-zeta o-60">
        {text ||
          'No data is found for your search query, try a different query.'}
      </p>
    </div>
  </React.Fragment>
);
