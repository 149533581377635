import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { PageHeaderAction } from 'modules/app-ui/models';
import { ApplicationState } from 'modules/app-state';
import { RoutePath } from 'modules/navigation';
import { SearchActions, SearchQuery } from 'modules/search';
import {
  searchStaticContent,
  StaticContent,
  StaticContentItem,
  StaticContentThunks,
} from 'modules/static-content';
import {
  usePagination,
  useModal,
  PageHeader,
  Loading,
  EmptyState,
} from 'modules/app-ui';

interface DispatchProps {
  removeStaticContent: (entityId: string) => void;
  getStaticContent: VoidFunction;
  searchStaticContent: SearchQuery;
}

interface ReduxProps {
  staticContent: StaticContent[];
  isChanging: boolean;
  error?: string;
}

type Props = DispatchProps & ReduxProps;

const StaticContentList: React.FC<Props> = ({
  getStaticContent,
  removeStaticContent,
  staticContent,
  isChanging,
  error,
  searchStaticContent,
}) => {
  useEffect(() => {
    getStaticContent();
  }, [getStaticContent]);

  const [pagedItems, Pagination] = usePagination(staticContent);
  const [toggleModal, Modal] = useModal(removeStaticContent, undefined);

  return (
    <section className="contentarea">
      <PageHeader
        onSearch={searchStaticContent}
        title={<FormattedMessage id="static-content" />}
        actions={[
          new PageHeaderAction(
            (<FormattedMessage id="static-content.btn.add" />),
            `${RoutePath.StaticContent}/new`,
          ),
        ]}
      />

      {error && <div className="alert alert--warning">{error}</div>}

      <Loading fullPage isLoading={isChanging}>
        {!staticContent.length && <EmptyState showImage />}
        {pagedItems.map(content => (
          <StaticContentItem
            key={content.id}
            staticContent={content}
            toggleModal={toggleModal}
          />
        ))}
        <Pagination />
        <Modal />
      </Loading>
    </section>
  );
};

export default connect<ReduxProps, DispatchProps, null, ApplicationState>(
  state => ({
    staticContent: searchStaticContent(state),
    isChanging: state.staticContent.loading,
    error: state.staticContent.error,
  }),
  {
    removeStaticContent: StaticContentThunks.removeStaticContent,
    getStaticContent: StaticContentThunks.getAllAsync,
    searchStaticContent: SearchActions.querySearch,
  },
)(StaticContentList);
