import React from 'react';
import { FormattedMessage } from 'react-intl';

import { UserAction } from '../models';

interface Props {
  action: UserAction;
}

export const Action: React.FC<Props> = ({ action }) => (
  <div className="l-bordered__item">
    <div className="g-inline">
      <div className="g-inline__item">
        <p className="t-eta o-60 s-bottom--sml">
          <FormattedMessage id="form.count" />:
        </p>
        <p className="t-zeta">{action.count}</p>
      </div>
      <div className="g-inline__item">
        <p className="t-eta o-60 s-bottom--sml">
          <FormattedMessage id="form.action-name" />:
        </p>
        <p className="t-zeta">{action.actionName}</p>
      </div>
    </div>
  </div>
);
