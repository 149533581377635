import React from 'react';
import { Link } from 'react-router-dom';
import { MdDelete } from 'react-icons/md';
import { FormattedMessage } from 'react-intl';

import { RoutePath } from 'modules/navigation';

import { FAQ } from '../models';

interface Props {
  faq: FAQ;
  toggleModal: VoidFunction;
}

export const FAQItem: React.FC<Props> = ({ faq, toggleModal }) => (
  <div className="itemlist--simple__item">
    <div className="itemlist--simple__item__content">
      <Link to={`${RoutePath.FAQ}/${faq.id}`}>
        <h2 className="t-zeta">
          <strong>{faq.title}</strong>
        </h2>
      </Link>
    </div>

    <div className="itemlist--simple__item__action">
      <div className="btn-group">
        <Link
          className="btn btn--sml btn--secondary"
          to={`${RoutePath.FAQ}/${faq.id}`}
        >
          <FormattedMessage id="button.edit" />
        </Link>
        <button
          className="btn btn--ghost btn--sml btn--hasIcon"
          data-id={faq.id}
          data-title={faq.title}
          onClick={toggleModal}
        >
          <MdDelete size={16} />
        </button>
      </div>
    </div>
  </div>
);
