import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Article } from 'modules/articles';
import { EmptyField } from 'modules/app-ui';

import { Badge } from './Badge';
import { Action } from './Action';

import { UserPrivate, User } from '../models';

interface Props {
  privateProfile: UserPrivate;
  handlePrivateChange: React.Dispatch<React.SetStateAction<User>>;
  articles: Article[];
}

export const UserPrivateProfile: React.FC<Props> = ({
  privateProfile,
  handlePrivateChange,
  articles,
}) => {
  const {
    totalPoints,
    spentPoints,
    newBadge,
    actions,
    badges,
    finishedOnboarding,
  } = privateProfile;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;

    handlePrivateChange(prevState => {
      return {
        ...prevState,
        privateProfile: {
          ...prevState.privateProfile,
          [name]: parseInt(value) || 0,
        },
      } as User;
    });
  };

  return (
    <div className="">
      <div className="field">
        <label className="field__lbl" htmlFor="totalPoints">
          <FormattedMessage id="form.total-points" />
        </label>
        <input
          name="totalPoints"
          id="totalPoints"
          type="number"
          className="input input--med"
          value={totalPoints}
          onChange={handleChange}
        />
      </div>

      <div className="field">
        <label className="field__lbl" htmlFor="spentPoints">
          <FormattedMessage id="form.spent-points" />
        </label>
        <input
          name="spentPoints"
          id="spentPoints"
          type="number"
          className="input input--med"
          value={spentPoints}
          onChange={handleChange}
        />
      </div>

      <div className="field">
        <input
          type="checkbox"
          className="input--check input--check--disabled"
          id="newBadge"
          checked={newBadge}
          disabled
        />
        <label htmlFor="newBadge">New badge</label>
      </div>

      <div className="field">
        <input
          type="checkbox"
          className="input--check input--check--disabled"
          id="finishedOnboarding"
          checked={finishedOnboarding}
          disabled
        />
        <label htmlFor="finishedOnboarding">Finished onboarding</label>
      </div>

      <div className="s-bottom--xlrg">
        <h1 className="t-delta s-bottom--lrg">
          <FormattedMessage id="form.user-actions" />:
        </h1>
        <div className="l-bordered">
          {(!actions || !actions.length) && (
            <EmptyField text="User has no actions" />
          )}
          {actions &&
            actions.length > 0 &&
            actions.map((action, index) => (
              <Action action={action} key={index} />
            ))}
        </div>
      </div>

      <div className="s-bottom--xlrg">
        <h1 className="t-delta s-bottom--lrg">
          <FormattedMessage id="form.user-badges" />:
        </h1>
        <div className="l-bordered">
          {(!badges || !badges.length) && (
            <EmptyField text="User has no badges" />
          )}
          {badges &&
            badges.length > 0 &&
            badges.map((badge, index) => <Badge badge={badge} key={index} />)}
        </div>
      </div>

      <div className="s-bottom--xlrg">
        <h1 className="t-delta s-bottom--lrg">
          <FormattedMessage id="form.read-articles" />:
        </h1>
        <ul className="l-bordered">
          {(!articles || !articles.length) && (
            <EmptyField text="User did not read any articles" />
          )}
          {articles.map(readArticle => (
            <li key={readArticle.id} className="l-bordered__item">
              <h2 className="t-zeta s-bottom--sml">{readArticle.title}</h2>
              <p className="t-eta o-60">{readArticle.subtitle}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
