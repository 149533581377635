import { useState, useCallback } from 'react';
import moment from 'moment';

import { Reward } from 'modules/rewards/models';

const initialState: Reward = new Reward();

export const useRewardValues = (
  rewardValues: Reward,
  existingSlugs: string[],
  setPageModified: (state: boolean) => void,
) => {
  const [state, setValues] = useState<Partial<Reward>>(rewardValues);
  const [focusesInput, setFocusedInput] = useState<
    'startDate' | 'endDate' | null
  >(null);

  function onInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value, dataset } = event.currentTarget;
    switch (dataset.type) {
      case 'title':
      case 'slug':
        setValues({
          ...state,
          [dataset.type]: value,
        });
        break;
      case 'amount':
      case 'points':
        setValues({
          ...state,
          [dataset.type]: parseInt(value),
        });
        break;

      default:
        break;
    }
    setPageModified(true);
  }

  function onImageChange(imageUrl: string) {
    setValues({
      ...state,
      featuredImageUrl: imageUrl,
    });
    setPageModified(true);
  }

  const resetRewardValues = useCallback((reward?: Reward) => {
    setValues(reward || initialState);
  }, []);

  function removeFeaturedImage() {
    setValues({
      ...state,
      featuredImageUrl: '',
    });
    setPageModified(true);
  }

  function onDatesChange(arg: {
    startDate: moment.Moment | null;
    endDate: moment.Moment | null;
  }) {
    setValues({
      ...state,
      validFrom: moment(arg.startDate || Date.now()).toDate(),
      validTo: moment(arg.endDate || Date.now()).toDate(),
    });
    setPageModified(true);
  }

  function updateSlug() {
    if (!state.title) {
      return;
    }

    const initialSlug = state.title
      .trim()
      .toLowerCase()
      .split(' ')
      .join('-');

    let slug = initialSlug;
    let appendix = 0;
    while (existingSlugs.includes(slug)) {
      slug = `${initialSlug}-${++appendix}`;
    }

    setValues({
      ...state,
      slug,
    });

    setPageModified(true);
  }

  function onFocusChange(arg: 'startDate' | 'endDate' | null) {
    setFocusedInput(arg);
  }

  return {
    state,
    setValues,
    onInputChange,
    onImageChange,
    resetRewardValues,
    removeFeaturedImage,
    onDatesChange,
    focusesInput,
    onFocusChange,
    updateSlug,
  };
};
