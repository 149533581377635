import { Dispatch } from 'redux';

import { ApplicationState } from 'modules/app-state';

import { LocationActions } from './actions';

import { FirebaseLocation } from '../models';
import { LocationService } from '../services';

const getAllAsync = () => async (
  dispatch: Dispatch,
  getState: () => ApplicationState,
) => {
  if (getState().locations.items.length) {
    return;
  }

  dispatch(LocationActions.change());

  const successFunction = (data: FirebaseLocation[]) =>
    dispatch(LocationActions.update(data));

  const errorFunction = (error: string) =>
    dispatch(LocationActions.error(error));

  const listenerProps = {
    successFunction,
    errorFunction,
  };

  LocationService.filterAsync(undefined, listenerProps);

  return;
};

const saveLocation = (item: FirebaseLocation) => (dispatch: Dispatch) => {
  dispatch(LocationActions.change());

  LocationService.addAsync({
    id: item.id,
    location: item.location,
    name: item.name,
    distance: item.distance,
  });
};

const removeLocation = (id: string) => () => {
  LocationService.removeAsync(id);
};

export const LocationThunks = {
  getAllAsync,
  saveLocation,
  removeLocation,
};
