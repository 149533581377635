import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import { connect } from 'react-redux';
import Select from 'react-select';
import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { MdDelete } from 'react-icons/md';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { ApplicationState } from 'modules/app-state';
import {
  PointType,
  PointOptions,
} from 'modules/gamification/const/PointAction';
import { PageHeaderAction } from 'modules/app-ui/models';
import { RoutePath } from 'modules/navigation';
import { useFormatMessage } from 'modules/localisation';
import {
  usePageModified,
  useModal,
  EmptyState,
  Loading,
  PageHeader,
  PageGuard,
} from 'modules/app-ui';

import { Point } from '../models';
import { GamificationThunks } from '../redux';

interface DispatchProps {
  getAll: VoidFunction;
  savePointHistory: (point: Point) => void;
  removePointHistory: (id: string) => void;
}

interface ReduxProps {
  point: Point;
  disabled: boolean;
  pointIsChanging: boolean;
}

type Props = DispatchProps & ReduxProps & RouteComponentProps<any>;

const PointHistoryDetails: React.FC<Props> = ({
  point,
  disabled,
  savePointHistory,
  getAll,
  removePointHistory,
  pointIsChanging,
}) => {
  const history = useHistory();
  const onRedirect = () => history.push(RoutePath.PointHistory);

  const [toggleModal, Modal] = useModal(removePointHistory, onRedirect);
  const [pointState, setPointState] = useState(point);
  const [focusedDate, setFocusedDate] = useState(false);
  const [error, setError] = useState<JSX.Element>();
  const [formatMessage] = useFormatMessage();
  const [pageModified, setPageModified] = usePageModified(
    'point-history-contentarea',
  );

  const { actionName, actionType, points, userId, date, id } = pointState;

  const isFormValid = () => {
    if (!actionName || !userId) {
      setError(
        <>
          <strong>{formatMessage('error.missing')}:</strong>
          {!actionName && <div>{formatMessage('form.action-name')}</div>}
          {!userId && <div>{formatMessage('form.user-id')}</div>}
        </>,
      );
      return false;
    }

    return true;
  };

  const handleSave = () => {
    if (!isFormValid()) {
      return;
    }

    setPageModified(false);
    savePointHistory(pointState);
    toast.success(formatMessage('toast.point.add'));
    onRedirect();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type } = e.currentTarget;

    setPointState({
      ...pointState,
      [name]: type === 'number' ? parseInt(value) || 0 : value,
    });

    setPageModified(true);
  };

  const handleSelectChange = (value: any) => {
    setPointState({
      ...pointState,
      actionName: value.value,
    });

    setPageModified(true);
  };

  const handleDateChange = (dateValue: moment.Moment | null) => {
    if (!dateValue) {
      return;
    }

    setPointState({
      ...pointState,
      date: moment(dateValue).toDate(),
    });

    setPageModified(true);
  };

  const onFocusChange = (arg: { focused: boolean | null }) => {
    setFocusedDate(arg.focused || false);
  };

  useEffect(() => {
    if (!point) {
      getAll();
    }
  }, [getAll, point]);

  useEffect(() => {
    setPointState(point);
  }, [point, setPointState]);

  useEffect(() => {
    if (!pointState.actionName || disabled) {
      return;
    }

    setPointState(state => ({
      ...state,
      actionType:
        pointState.actionName === 'Redeemed reward'
          ? PointType.Subtract
          : PointType.Add,
    }));
  }, [pointState.actionName, disabled]);

  if (!pointState) {
    if (!pointIsChanging) {
      return <EmptyState showImage />;
    }

    return <Loading isLoading fullPage />;
  }

  return (
    <section className="contentarea" id="point-history-contentarea">
      <PageHeader
        title={
          <FormattedMessage
            id={
              id === 'new'
                ? 'points-history.create.title'
                : 'points-history.details.title'
            }
          />
        }
        backAction={
          new PageHeaderAction(
            (<FormattedMessage id="points-history.back-to-list" />),
            RoutePath.PointHistory,
          )
        }
      />

      {error && (
        <div className="alert alert--warning s-bottom--med">{error}</div>
      )}

      <div className="card">
        <div className="field">
          <label htmlFor="actionName" className="field__lbl t-mandatory">
            <FormattedMessage id="form.action-name" />
          </label>
          <FormattedMessage id="form.action-name.placeholder">
            {placeholder => (
              <Select
                id="actionName"
                value={
                  actionName
                    ? { value: actionName || '', label: actionName || '' }
                    : undefined
                }
                onChange={handleSelectChange}
                options={PointOptions}
                className="input--select"
                placeholder={placeholder as string}
                isDisabled={disabled}
              />
            )}
          </FormattedMessage>
        </div>

        <div className="field">
          <label htmlFor="actionType" className="field__lbl">
            <FormattedMessage id="form.action-type" />
          </label>
          <input
            className="input input--med"
            disabled={true}
            type="text"
            name="actionType"
            id="actionType"
            value={actionType}
            onChange={handleChange}
          />
        </div>

        <div className="field">
          <label htmlFor="points" className="field__lbl">
            <FormattedMessage id="form.points" />
          </label>
          <input
            className="input input--med"
            disabled={disabled}
            type="number"
            name="points"
            id="points"
            value={points}
            onChange={handleChange}
          />
        </div>

        <div className="field">
          <label htmlFor="userId" className="field__lbl t-mandatory">
            <FormattedMessage id="form.user-id" />
          </label>
          <FormattedMessage id="form.user-id.placeholder">
            {placeholder => (
              <input
                className="input input--med"
                disabled={disabled}
                type="text"
                name="userId"
                id="userId"
                placeholder={placeholder as string}
                value={userId}
                onChange={handleChange}
              />
            )}
          </FormattedMessage>
        </div>

        <div className="field">
          <label htmlFor="react-date" className="field__lbl t-mandatory">
            <FormattedMessage id="form.date" />
          </label>
          <SingleDatePicker
            date={moment(date)}
            onDateChange={handleDateChange}
            focused={focusedDate}
            onFocusChange={onFocusChange}
            id="react-date"
            displayFormat="DD/MM/YYYY"
            disabled={disabled}
            isOutsideRange={() => false}
            numberOfMonths={1}
            showDefaultInputIcon={true}
          />
        </div>

        <footer className="card__footer">
          <div className="btn-group">
            {!disabled && (
              <button
                className="btn btn--med btn--primary"
                onClick={handleSave}
              >
                <FormattedMessage id="button.add" />
              </button>
            )}
            {disabled && (
              <button
                data-id={id}
                className="btn btn--ghost btn--med btn--hasIcon"
                onClick={toggleModal}
              >
                <MdDelete size={16} />
              </button>
            )}
          </div>
        </footer>
      </div>

      <Modal />

      <PageGuard when={pageModified} />
    </section>
  );
};

export default connect<ReduxProps, DispatchProps, Props, ApplicationState>(
  (state, ownProps) => {
    const id = ownProps.match.params.id;

    return {
      point:
        id === 'new'
          ? new Point()
          : state.gamification.points.filter(item => item.id === id)[0],
      disabled: id === 'new' ? false : true,
      pointIsChanging: state.gamification.loading,
    };
  },
  {
    getAll: GamificationThunks.getAllAsync,
    savePointHistory: GamificationThunks.savePointHistory,
    removePointHistory: GamificationThunks.removePointHistory,
  },
)(PointHistoryDetails);
