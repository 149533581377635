import { ActionUnion } from 'modules/app-state';

import { LocationActionTypes } from './types';
import { LocationActions } from './actions';

import { FirebaseLocation } from '../models';

export interface LocationState {
  loading: boolean;
  error?: string;
  items: FirebaseLocation[];
}

const INITIAL_STATE: LocationState = {
  loading: false,
  error: undefined,
  items: [],
};

export default (
  state: LocationState = INITIAL_STATE,
  action: ActionUnion<typeof LocationActions>,
): LocationState => {
  switch (action.type) {
    case LocationActionTypes.Change:
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case LocationActionTypes.Update:
      return {
        ...state,
        loading: false,
        error: undefined,
        items: action.payload.locations,
      };

    case LocationActionTypes.Error:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    default:
      return state || INITIAL_STATE;
  }
};
