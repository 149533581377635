import React from 'react';
import { MdDelete, MdFindInPage } from 'react-icons/md';
import Files from 'react-files';
import { FormattedMessage } from 'react-intl';

export interface FilesError {
  code: number;
  message: string;
}
interface Props {
  blobUrl?: string;
  imageUrl?: string;
  removeHandler?: VoidFunction;
  imgClassName?: string;
  accepts?: string[];
  changeHandler: (files: File[]) => void;
  errorHandler?: (error: FilesError, file: File) => void;
}

export const ImageUploader: React.FC<Props> = ({
  blobUrl,
  imageUrl,
  removeHandler,
  changeHandler,
  errorHandler,
  imgClassName,
  accepts,
}) => {
  return (
    <div className="imageuploader">
      <div className="imageuploader__image">
        {!blobUrl && !imageUrl && (
          <div className="card t-center s-top--lrg">
            <MdFindInPage className="o-20 s-bottom--med" size={64} />
            <p className="t-zeta o-60">
              <FormattedMessage id="form.cover-photo.placeholder" />
            </p>
            <p className="t-zeta o-60">(.svg format)</p>
          </div>
        )}
      </div>
      {(blobUrl || imageUrl) && (
        <>
          <img
            src={blobUrl || imageUrl}
            alt="Not found"
            className={imgClassName}
          />

          {removeHandler && (
            <div className="imageuploader__remove">
              <button
                className="btn btn--dark btn--close"
                onClick={removeHandler}
              >
                <MdDelete size={16} />
              </button>
            </div>
          )}
        </>
      )}
      <div className="imageloader__action">
        <Files
          onChange={changeHandler}
          accepts={accepts || ['image/*']}
          onError={errorHandler}
          maxFiles={Infinity}
          maxFileSize={10000000}
          minFileSize={0}
          clickable
        >
          <button className="btn btn--primary btn--med s-top--med">
            <FormattedMessage
              id={
                !blobUrl && !imageUrl
                  ? 'button.upload-image'
                  : 'button.change-image'
              }
            />
          </button>
        </Files>
      </div>
    </div>
  );
};
