import { Dispatch } from 'redux';

import { ApplicationState } from 'modules/app-state';

import { FAQActions } from './actions';

import { FAQ } from '../models';
import { FAQService } from '../services';

const getAllAsync = () => async (
  dispatch: Dispatch,
  getState: () => ApplicationState,
) => {
  if (getState().faq.items.length) {
    return;
  }

  dispatch(FAQActions.change());

  const successFunction = (data: FAQ[]) => dispatch(FAQActions.update(data));

  const errorFunction = (error: string) => dispatch(FAQActions.error(error));

  const listenerProps = {
    successFunction,
    errorFunction,
  };

  FAQService.filterAsync(undefined, listenerProps);

  return;
};

const saveFaq = (faq: FAQ) => (dispatch: Dispatch) => {
  dispatch(FAQActions.change());

  FAQService.addAsync(faq);
};

const remove = (id: string) => () => {
  FAQService.removeAsync(id);
};

export const FAQThunks = {
  getAllAsync,
  saveFaq,
  remove,
};
