import { ActionUnion } from 'modules/app-state';

import { GamificationActions } from './actions';
import { GamificationActionTypes } from './types';

import { Point } from '../models';

export interface GamificationState {
  readonly loading: boolean;
  readonly error?: string;
  readonly points: Point[];
}

const INITIAL_STATE: GamificationState = {
  loading: false,
  error: undefined,
  points: [],
};

export default (
  state: GamificationState = INITIAL_STATE,
  action: ActionUnion<typeof GamificationActions>,
): GamificationState => {
  switch (action.type) {
    case GamificationActionTypes.Change:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case GamificationActionTypes.UpdatePoints:
      return {
        ...state,
        error: undefined,
        loading: false,
        points: action.payload.points,
      };
    case GamificationActionTypes.Error:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state || INITIAL_STATE;
  }
};
