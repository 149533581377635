import { createSelector } from 'reselect';

import { getSearchQuery, applySearchParams } from 'modules/search';
import { ApplicationState } from 'modules/app-state';

const getLocations = (state: ApplicationState) => state.locations.items;

export const searchLocations = createSelector(
  [getLocations, getSearchQuery],
  (content, query) =>
    content.filter(({ name, id }) => applySearchParams([name, id], query)),
);
