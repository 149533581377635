import { createAction } from 'modules/app-state';

import { ArticleActionTypes } from './types';

import { Article } from '../models';

export const ArticleActions = {
  change: () => createAction(ArticleActionTypes.Change),

  update: (articles: Article[]) =>
    createAction(ArticleActionTypes.Update, { articles }),

  error: (error: string) => createAction(ArticleActionTypes.Error, { error }),
};
