import { createAction } from 'modules/app-state';

import { FeedbackActionTypes } from './types';

import { Feedback, FeedbackCard, FeedbackThanks } from '../models';

export const FeedbackActions = {
  change: () => createAction(FeedbackActionTypes.Change),

  updateFeedback: (feedback: Feedback[]) =>
    createAction(FeedbackActionTypes.UpdateFeedback, { feedback }),

  updateFeedbackCards: (feedbackCards: FeedbackCard[]) =>
    createAction(FeedbackActionTypes.UpdateFeedbackCards, { feedbackCards }),

  updateThanksCards: (thanksCards: FeedbackThanks[]) =>
    createAction(FeedbackActionTypes.UpdateThanksCards, { thanksCards }),

  error: (error: string) => createAction(FeedbackActionTypes.Error, { error }),
};
