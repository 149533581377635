import { createSelector } from 'reselect';
import orderBy from 'lodash/orderBy';

import { ApplicationState } from 'modules/app-state';
import { getSearchQuery, applySearchParams } from 'modules/search';
import { getUsersObject } from 'modules/users';

const getRewards = (state: ApplicationState) => state.rewards.items;
const getRedeemedRewards = (state: ApplicationState) =>
  state.rewards.redeemedRewards;

const sortRewardsByValidDate = createSelector([getRewards], reward =>
  orderBy(reward, [reward => reward.dateCreated || reward.validFrom], 'desc'),
);

export const searchRewards = createSelector(
  [sortRewardsByValidDate, getSearchQuery],
  (content, query) =>
    content.filter(({ id, title }) => applySearchParams([id, title], query)),
);

export const searchRedeemedRewards = createSelector(
  [getRedeemedRewards, getSearchQuery, getUsersObject],
  (content, query, usersObject) =>
    content.filter(reward => {
      const user = usersObject[reward.userId];
      if (!user) {
        return false;
      }

      return applySearchParams(
        [
          new Date(reward.date).toDateString(),
          `${user.firstName} ${user.lastName}`,
        ],
        query,
      );
    }),
);

export const sortRedeemedRewardsByShipped = createSelector(
  [getRedeemedRewards],
  reward => orderBy(reward, ['date'], 'asc'),
);
