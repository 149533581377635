import React from 'react';
import { Link } from 'react-router-dom';
import { MdDelete } from 'react-icons/md';
import { FormattedMessage } from 'react-intl';

import { RoutePath } from 'modules/navigation';

import { User } from '../models';

interface Props {
  user: User;
  toggleModal: VoidFunction;
}

export const UserItem: React.FC<Props> = ({ user, toggleModal }) => (
  <div className="itemlist--simple__item">
    <div className="itemlist--simple__item__content">
      <Link to={`${RoutePath.Users}/${user.id}`}>
        <h2 className="t-zeta">
          <strong>
            {user.firstName && user.lastName
              ? `${user.firstName} ${user.lastName}`
              : `${user.email}`}
          </strong>
        </h2>
        {user.firstName && user.lastName && (
          <p className="t-eta o-60 u-color--text">{user.email}</p>
        )}
      </Link>
    </div>

    <div className="itemlist--simple__item__action">
      <div className="btn-group">
        <Link
          className="btn btn--secondary btn--sml"
          to={`${RoutePath.Users}/${user.id}`}
        >
          <FormattedMessage id="button.edit" />
        </Link>

        <button
          className="btn btn--ghost btn--sml btn--hasIcon"
          onClick={toggleModal}
          data-id={user.id}
          data-title={`${user.firstName} ${user.lastName}`}
        >
          <MdDelete size={16} />
        </button>
      </div>
    </div>
  </div>
);
