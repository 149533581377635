import { ActionUnion } from 'modules/app-state';

import { BadgeActions } from './actions';
import { BadgeActionTypes } from './types';

import { Badge } from '../models/Badge';

export interface BadgeState {
  readonly loading: boolean;
  readonly items: Badge[];
  readonly error?: string;
}

const INITIAL_STATE: BadgeState = {
  loading: false,
  error: undefined,
  items: [],
};

export default (
  state: BadgeState = INITIAL_STATE,
  action: ActionUnion<typeof BadgeActions>,
): BadgeState => {
  switch (action.type) {
    case BadgeActionTypes.Change:
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case BadgeActionTypes.Update:
      return {
        ...state,
        loading: false,
        error: undefined,
        items: action.payload.badges,
      };

    case BadgeActionTypes.Error:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    default:
      return state || INITIAL_STATE;
  }
};
