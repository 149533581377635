import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom';
import { MdDelete } from 'react-icons/md';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';

import { ApplicationState } from 'modules/app-state';
import { RoutePath } from 'modules/navigation';
import { SlateEditor, useSlateValue, HTMLSerializer } from 'modules/editor';
import { PageHeaderAction } from 'modules/app-ui/models';
import { Media, MediaThunks } from 'modules/media';
import { StaticContent, StaticContentThunks } from 'modules/static-content';
import { useFormatMessage } from 'modules/localisation';
import {
  useModal,
  usePageModified,
  EmptyState,
  Loading,
  PageHeader,
  PageGuard,
} from 'modules/app-ui';

interface DispatchProps {
  getStaticContent: VoidFunction;
  getAllMedia: VoidFunction;
  saveStaticContent: (data: StaticContent) => void;
  removeStaticContent: (entityId: string) => void;
  uploadMedia: (
    title: string,
    mediaType: 'Gallery' | 'Image' | 'Video',
    files: File[],
  ) => Promise<void | firebase.firestore.DocumentReference>;
}

interface ReduxProps {
  staticContent: StaticContent;
  contentIsChanging: boolean;
  error?: string;
  galleryMedia: Media[];
  mediaIsChanging: boolean;
}

type Props = DispatchProps & ReduxProps & RouteComponentProps<any>;

const StaticContentDetails: React.FC<Props> = ({
  getStaticContent,
  saveStaticContent,
  removeStaticContent,
  staticContent,
  contentIsChanging,
  match,
  getAllMedia,
  uploadMedia,
  galleryMedia,
  mediaIsChanging,
}) => {
  const history = useHistory();
  const location = useLocation();

  const onRedirect = () => history.push(RoutePath.StaticContent);

  const [toggleDeleteModal, DeleteModal] = useModal(
    removeStaticContent,
    onRedirect,
  );
  const [title, setTitle] = useState('');
  const [content, onChange, resetValue] = useSlateValue(
    HTMLSerializer.init().deserialize(
      staticContent ? staticContent.content : '',
    ),
  );
  const [error, setError] = useState<JSX.Element>();
  const [formatMessage] = useFormatMessage();
  const [pageModified, setPageModified] = usePageModified(
    'static-content-contentarea',
  );

  const onTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.currentTarget.value);
  };

  const isFormValid = () => {
    if (
      (!staticContent.id && !title) ||
      HTMLSerializer.init().serialize(content) === '<p></p>'
    ) {
      setError(
        <>
          <div>{formatMessage('error.missing')}:</div>
          {!staticContent.id && !title && (
            <div>
              <strong>{formatMessage('form.title')}</strong>
            </div>
          )}
          {HTMLSerializer.init().serialize(content) === '<p></p>' && (
            <div>
              <strong>{formatMessage('form.content')}</strong>
            </div>
          )}
        </>,
      );
      return false;
    }

    return true;
  };

  const saveContent = async () => {
    if (!isFormValid()) {
      return;
    }

    await setPageModified(false);

    saveStaticContent({
      id: title ? title : staticContent.id,
      content: HTMLSerializer.init().serialize(content),
    });

    toast.success(
      staticContent.id
        ? formatMessage('toast.static-content.update', staticContent.id)
        : formatMessage('toast.static-content.save', title),
    );

    onRedirect();
  };

  useEffect(() => {
    getStaticContent();
    getAllMedia();
  }, [getStaticContent, getAllMedia]);

  useEffect(() => {
    resetValue(
      HTMLSerializer.init().deserialize(
        staticContent ? staticContent.content : '',
      ),
    );
  }, [staticContent, resetValue]);

  if (!staticContent) {
    if (!contentIsChanging) {
      return <EmptyState showImage />;
    }

    return <Loading isLoading fullPage />;
  }

  return (
    <section className="contentarea" id="static-content-contentarea">
      <PageHeader
        title={
          <FormattedMessage
            id={
              match.params.id === 'new'
                ? 'static-content.create.title'
                : 'static-content.details.title'
            }
          />
        }
        backAction={
          new PageHeaderAction(
            (<FormattedMessage id="static-content.back-to-list" />),
            RoutePath.StaticContent,
          )
        }
      />
      {error && (
        <div className="alert alert--warning s-bottom--med">{error}</div>
      )}
      <div className="card">
        <div className="field">
          {staticContent.id ? (
            <h1 className="t-epsilon">{staticContent.id}</h1>
          ) : (
            <>
              <label htmlFor="title" className="field__lbl t-mandatory">
                <FormattedMessage id="form.title" />
              </label>
              <FormattedMessage id="form.title.placeholder">
                {placeholder => (
                  <input
                    id="title"
                    className="input input--med"
                    value={title}
                    type="text"
                    onChange={onTitleChange}
                    placeholder={placeholder as string}
                  />
                )}
              </FormattedMessage>
            </>
          )}
        </div>

        <div className="field">
          <label htmlFor="content" className="field__lbl t-mandatory">
            <FormattedMessage id="form.content" />
          </label>
          <SlateEditor
            content={content}
            onContentChange={onChange}
            modifiedHandler={setPageModified}
            location={location}
            galleryMedia={galleryMedia}
            mediaIsChanging={mediaIsChanging}
            uploadMedia={uploadMedia}
          />
        </div>

        <div className="card__footer">
          <div className="btn-group">
            <button
              className="btn btn--primary btn--med"
              onClick={saveContent}
              disabled={!pageModified}
            >
              <FormattedMessage
                id={staticContent.id ? 'button.save' : 'button.add'}
              />
            </button>
            {staticContent.id && (
              <button
                data-id={staticContent.id}
                className="btn btn--ghost btn--med btn--hasIcon"
                onClick={toggleDeleteModal}
              >
                <MdDelete size={16} />
              </button>
            )}
          </div>
        </div>
      </div>
      <DeleteModal />

      <PageGuard when={pageModified} />
    </section>
  );
};

export default connect<ReduxProps, DispatchProps, Props, ApplicationState>(
  (state, ownProps) => {
    const id = ownProps.match.params.id;

    return {
      staticContent:
        id === 'new'
          ? new StaticContent()
          : state.staticContent.items.filter(doc => doc.id === id)[0],
      contentIsChanging: state.staticContent.loading,
      galleryMedia: state.media.items,
      mediaIsChanging: state.media.loading,
    };
  },
  {
    getStaticContent: StaticContentThunks.getAllAsync,
    saveStaticContent: StaticContentThunks.saveStaticContent,
    removeStaticContent: StaticContentThunks.removeStaticContent,
    uploadMedia: MediaThunks.uploadMediaFromSlate as any,
    getAllMedia: MediaThunks.getAllAsync,
  },
)(StaticContentDetails);
