import { useState, useCallback } from 'react';
import moment from 'moment';

import { VirtualReward } from 'modules/rewards/models';
import { FilesError } from 'modules/media';

const initialState: VirtualReward = new VirtualReward();

export const useVirtualRewardValues = (
  rewardValues: VirtualReward,
  existingSlugs: string[],
  setPageModified: (state: boolean) => void,
) => {
  const [state, setValues] = useState<Partial<VirtualReward>>(rewardValues);
  const [focusesInput, setFocusedInput] = useState<
    'startDate' | 'endDate' | null
  >(null);
  const [mainImageError, setMainImageError] = useState<string>();

  function onInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value, dataset } = event.currentTarget;
    switch (dataset.type) {
      case 'amount':
      case 'points':
      case 'numberOfParts':
        setValues({
          ...state,
          [dataset.type]: parseInt(value),
        });
        break;
      case 'imageGroupPrefix':
      case 'imageGroupSeparator':
      case 'imageGroupOptionalSuffix':
      case 'title':
      case 'slug':
      case 'shortDescription':
      case 'learnMoreTitle':
        setValues({
          ...state,
          [dataset.type]: value,
        });
        break;

      default:
        break;
    }
    setPageModified(true);
  }

  const resetRewardValues = useCallback((reward?: VirtualReward) => {
    setValues(reward || initialState);
  }, []);

  function onFeaturedImageChange(featuredImageUrl: string) {
    setValues({
      ...state,
      featuredImageUrl,
    });
    setPageModified(true);
  }

  function onMainImageChange(files: File[]) {
    if (files.length === 0) {
      return;
    }
    setMainImageError(undefined);
    setValues({
      ...state,
      mainImageUrl: URL.createObjectURL(files[files.length - 1]),
      mainImageFile: files[files.length - 1],
    });
    setPageModified(true);
  }

  function onMainImageError(error: FilesError) {
    setMainImageError(error.message);
  }

  function onLearnMoreImageChange(learnMoreImageUrl: string) {
    setValues({
      ...state,
      learnMoreImageUrl,
    });
    setPageModified(true);
  }

  function removeFeaturedImage() {
    setValues({
      ...state,
      featuredImageUrl: '',
    });
    setPageModified(true);
  }

  function removeMainImage() {
    setValues({
      ...state,
      mainBlobImageUrl: undefined,
      mainImageFile: undefined,

      mainImageUrl: '',
      mainImageStorageReference: undefined,
    });
    setPageModified(true);
  }

  function removeLearnMoreImage() {
    setValues({
      ...state,
      learnMoreImageUrl: '',
    });
    setPageModified(true);
  }

  function onDatesChange(arg: {
    startDate: moment.Moment | null;
    endDate: moment.Moment | null;
  }) {
    setValues({
      ...state,
      validFrom: moment(arg.startDate || Date.now()).toDate(),
      validTo: moment(arg.endDate || Date.now()).toDate(),
    });
    setPageModified(true);
  }

  function updateSlug() {
    if (!state.title) {
      return;
    }

    const initialSlug = state.title
      .trim()
      .toLowerCase()
      .split(' ')
      .join('-');

    let slug = initialSlug;
    let appendix = 0;
    while (existingSlugs.includes(slug)) {
      slug = `${initialSlug}-${++appendix}`;
    }

    setValues({
      ...state,
      slug,
    });

    setPageModified(true);
  }

  function onFocusChange(arg: 'startDate' | 'endDate' | null) {
    setFocusedInput(arg);
  }

  return {
    state,
    setValues,
    onInputChange,
    resetRewardValues,

    onFeaturedImageChange,
    onMainImageChange,
    onLearnMoreImageChange,

    removeFeaturedImage,
    removeMainImage,
    removeLearnMoreImage,

    onMainImageError,
    mainImageError,

    onDatesChange,
    focusesInput,
    onFocusChange,
    updateSlug,
  };
};
