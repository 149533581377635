import React from 'react';
import { Link } from 'react-router-dom';
import { MdDelete } from 'react-icons/md';
import { FormattedMessage } from 'react-intl';

import { RoutePath } from 'modules/navigation';

import { StaticContent } from '../models';

interface Props {
  staticContent: StaticContent;
  toggleModal: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const StaticContentItem: React.FC<Props> = ({
  staticContent,
  toggleModal,
}) => {
  if (!staticContent.id) {
    return null;
  }

  return (
    <div className="itemlist--simple__item">
      <div className="itemlist--simple__item__content">
        <Link to={`${RoutePath.StaticContent}/${staticContent.id}`}>
          <h2 className="t-zeta">
            <strong>{staticContent.id}</strong>
          </h2>
        </Link>
      </div>

      <div className="itemlist--simple__item__action">
        <div className="btn-group">
          <Link
            className="btn btn--sml btn--secondary"
            to={`${RoutePath.StaticContent}/${staticContent.id}`}
          >
            <FormattedMessage id="button.edit" />
          </Link>

          <button
            data-id={staticContent.id}
            className="btn btn--ghost btn--sml btn--hasIcon"
            onClick={toggleModal}
          >
            <MdDelete size={16} />
          </button>
        </div>
      </div>
    </div>
  );
};
