import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ArticleList, ArticleDetails } from 'modules/articles';
import {
  FeedbackList,
  FeedbackCardsList,
  FeedbackThanksCardsList,
  FeedbackDetails,
} from 'modules/feedback';
import { LocalisationList, LocalisationDetails } from 'modules/localisation';
import { LocationList, LocationDetails } from 'modules/locations';
import { MediaDetails, MediaList } from 'modules/media';
import { PointHistoryDetails, PointHistoryList } from 'modules/gamification';
import { ProductList, ProductDetails } from 'modules/products';
import {
  RewardList,
  RedeemedRewardList,
  RedeemedRewardDetails,
  RewardDetails,
  VirtualRewardDetails,
} from 'modules/rewards';
import {
  SettingsLogo,
  SettingsModules,
  SettingsLocalisation,
} from 'modules/settings';
import { BadgeList, BadgeLevelsList, BadgeDetails } from 'modules/badges';
import {
  UserLevelsList,
  UserList,
  UserInvite,
  UserDetails,
} from 'modules/users';
import {
  StaticContentList,
  StaticContentDetails,
} from 'modules/static-content';
import { FAQDetails, FAQList } from 'modules/faq';
import { Summary } from 'modules/summary';

import { AppRoute, RoutePath } from '../models';

export const authenticatedRoutes: AppRoute[] = [
  {
    key: 'summary',
    title: 'Summary',
    accessbilityLevel: 'authenticated',
    component: Summary,
    exact: true,
    path: RoutePath.Summary,
  },
  {
    key: 'articles',
    title: 'Articles',
    accessbilityLevel: 'authenticated',
    exact: true,
    path: RoutePath.Articles,
    childLinks: [
      {
        title: <FormattedMessage id="articles.all" />,
        path: RoutePath.ArticlesAll,
      },
      {
        title: <FormattedMessage id="articles.draft" />,
        path: RoutePath.DraftArticles,
      },
      {
        title: <FormattedMessage id="articles.published" />,
        path: RoutePath.PublishedArticles,
      },
    ],
  },
  {
    key: 'articles-all',
    title: 'All Articles',
    accessbilityLevel: 'authenticated',
    component: ArticleList,
    exact: true,
    path: RoutePath.ArticlesAll,
  },
  {
    key: 'articles-draft',
    title: 'Draft Articles',
    accessbilityLevel: 'authenticated',
    component: ArticleList,
    exact: true,
    path: RoutePath.DraftArticles,
  },
  {
    key: 'articles-published',
    title: 'Published Articles',
    accessbilityLevel: 'authenticated',
    component: ArticleList,
    exact: true,
    path: RoutePath.PublishedArticles,
  },
  {
    key: 'rewards',
    title: 'Rewards',
    accessbilityLevel: 'authenticated',
    exact: true,
    path: RoutePath.Rewards,
    childLinks: [
      {
        title: <FormattedMessage id="rewards.all" />,
        path: RoutePath.RewardsAll,
      },
      {
        title: <FormattedMessage id="rewards.virtual" />,
        path: RoutePath.VirtualRewards,
      },
      {
        title: <FormattedMessage id="rewards.draft" />,
        path: RoutePath.DraftRewards,
      },
      {
        title: <FormattedMessage id="rewards.published" />,
        path: RoutePath.PublishedRewards,
      },
      {
        title: <FormattedMessage id="redeemed-rewards" />,
        path: RoutePath.RedeemedRewards,
      },
    ],
  },
  {
    key: 'rewards-all',
    title: 'All Rewards',
    accessbilityLevel: 'authenticated',
    component: RewardList,
    exact: true,
    path: RoutePath.RewardsAll,
  },
  {
    key: 'rewards-virtual',
    title: 'Virtual Rewards',
    accessbilityLevel: 'authenticated',
    component: RewardList,
    exact: true,
    path: RoutePath.VirtualRewards,
  },
  {
    key: 'rewards-draft',
    title: 'Draft Rewards',
    accessbilityLevel: 'authenticated',
    component: RewardList,
    exact: true,
    path: RoutePath.DraftRewards,
  },
  {
    key: 'rewards-published',
    title: 'Published Rewards',
    accessbilityLevel: 'authenticated',
    component: RewardList,
    exact: true,
    path: RoutePath.PublishedRewards,
  },
  {
    key: 'redeemed-rewards',
    title: 'Redeemed Rewards',
    accessbilityLevel: 'authenticated',
    component: RedeemedRewardList,
    exact: true,
    path: RoutePath.RedeemedRewards,
  },
  {
    key: 'products',
    title: 'Products',
    accessbilityLevel: 'authenticated',
    component: ProductList,
    exact: true,
    path: RoutePath.Products,
  },
  {
    key: 'locations',
    title: 'Locations',
    accessbilityLevel: 'authenticated',
    component: LocationList,
    exact: true,
    path: RoutePath.Locations,
  },
  {
    key: 'location-details',
    title: 'Locatioin Details',
    accessbilityLevel: 'authenticated',
    component: LocationDetails,
    exact: false,
    path: RoutePath.LocationDetails,
  },
  {
    key: 'gamification',
    title: 'Gamification',
    accessbilityLevel: 'authenticated',
    exact: true,
    path: RoutePath.Gamification,
    childLinks:
      process.env.REACT_APP_FIREBASE_ENV &&
      process.env.REACT_APP_FIREBASE_ENV.startsWith('loyalty')
        ? [
            {
              title: <FormattedMessage id="points-history" />,
              path: RoutePath.PointHistory,
            },
          ]
        : [
            {
              title: <FormattedMessage id="user-levels" />,
              path: RoutePath.UserLevels,
            },
            {
              title: <FormattedMessage id="badge-levels" />,
              path: RoutePath.BadgeLevels,
            },
            { title: <FormattedMessage id="badges" />, path: RoutePath.Badges },
            {
              title: <FormattedMessage id="points-history" />,
              path: RoutePath.PointHistory,
            },
          ],
  },
  {
    key: 'badges',
    title: 'Badges',
    accessbilityLevel: 'authenticated',
    component: BadgeList,
    exact: true,
    path: RoutePath.Badges,
  },
  {
    key: 'points-history',
    title: 'Points History',
    accessbilityLevel: 'authenticated',
    component: PointHistoryList,
    exact: true,
    path: RoutePath.PointHistory,
  },
  {
    key: 'user-levels',
    title: 'User Levels',
    accessbilityLevel: 'authenticated',
    component: UserLevelsList,
    exact: true,
    path: RoutePath.UserLevels,
  },
  {
    key: 'badge-levels',
    title: 'Badge Levels',
    accessbilityLevel: 'authenticated',
    component: BadgeLevelsList,
    exact: true,
    path: RoutePath.BadgeLevels,
  },
  {
    key: 'feedback',
    title: 'Feedback',
    accessbilityLevel: 'authenticated',
    exact: true,
    path: RoutePath.Feedback,
    childLinks: [
      {
        title: <FormattedMessage id="feedback.all" />,
        path: RoutePath.AllFeedbacks,
      },
      {
        title: <FormattedMessage id="feedback-cards" />,
        path: RoutePath.FeedbackCards,
      },
      {
        title: <FormattedMessage id="feedback-thanks-cards" />,
        path: RoutePath.FeedbackThanksCards,
      },
    ],
  },
  {
    key: 'feedbacks-all',
    title: 'All Feedbacks',
    accessbilityLevel: 'authenticated',
    component: FeedbackList,
    exact: true,
    path: RoutePath.AllFeedbacks,
  },
  {
    key: 'feedback-cards',
    title: 'Feedback Cards',
    accessbilityLevel: 'authenticated',
    component: FeedbackCardsList,
    exact: true,
    path: RoutePath.FeedbackCards,
  },
  {
    key: 'feedback-thanks-cards',
    title: 'Feedback Thanks Cards',
    accessbilityLevel: 'authenticated',
    component: FeedbackThanksCardsList,
    exact: true,
    path: RoutePath.FeedbackThanksCards,
  },
  {
    key: 'users',
    title: 'Users',
    accessbilityLevel: 'authenticated',
    component: UserList,
    exact: true,
    path: RoutePath.Users,
  },
  {
    key: 'static-content',
    title: 'Static content',
    accessbilityLevel: 'authenticated',
    component: StaticContentList,
    exact: true,
    path: RoutePath.StaticContent,
  },
  {
    key: 'faq',
    title: 'FAQ',
    accessbilityLevel: 'authenticated',
    component: FAQList,
    exact: true,
    path: RoutePath.FAQ,
  },
  {
    key: 'localisation',
    title: 'Localisation',
    accessbilityLevel: 'authenticated',
    exact: true,
    path: RoutePath.Localisation,
    childLinks: [
      {
        title: <FormattedMessage id="localisation.client" />,
        path: RoutePath.LocalisationClient,
      },
      {
        title: <FormattedMessage id="localisation.dashboard" />,
        path: RoutePath.LocalisationDashboard,
      },
    ],
  },
  {
    key: 'application',
    title: 'Application',
    accessbilityLevel: 'authenticated',
    component: LocalisationList,
    exact: true,
    path: RoutePath.LocalisationClient,
  },
  {
    key: 'dashboard',
    title: 'Dashboard',
    accessbilityLevel: 'authenticated',
    component: LocalisationList,
    exact: true,
    path: RoutePath.LocalisationDashboard,
  },
  {
    key: 'media',
    title: 'Media',
    accessbilityLevel: 'authenticated',
    component: MediaList,
    exact: true,
    path: RoutePath.Media,
  },
];

export const authenticatedDetailsRoutes: AppRoute[] = [
  {
    key: 'article-details',
    title: 'Article Details',
    accessbilityLevel: 'authenticated',
    component: ArticleDetails,
    exact: false,
    path: RoutePath.ArticleDetails,
  },
  {
    key: 'static-content-details',
    title: 'Static Content Details',
    accessbilityLevel: 'authenticated',
    component: StaticContentDetails,
    exact: false,
    path: RoutePath.StaticContentDetails,
  },
  {
    key: 'badge-details',
    title: 'Badge details',
    accessbilityLevel: 'authenticated',
    component: BadgeDetails,
    exact: false,
    path: RoutePath.BadgeDetails,
  },
  {
    key: 'product-details',
    title: 'Product Details',
    accessbilityLevel: 'authenticated',
    component: ProductDetails,
    exact: false,
    path: RoutePath.ProductDetails,
  },
  {
    key: 'localisation-client-details',
    title: 'Localisation Details',
    accessbilityLevel: 'authenticated',
    component: LocalisationDetails,
    exact: false,
    path: RoutePath.LocalisationClientDetails,
  },
  {
    key: 'localisation-dashboard-details',
    title: 'Localisation Details',
    accessbilityLevel: 'authenticated',
    component: LocalisationDetails,
    exact: false,
    path: RoutePath.LocalisationDashboardDetails,
  },
  {
    key: 'invite-users',
    title: 'Invite users',
    accessbilityLevel: 'authenticated',
    component: UserInvite,
    exact: true,
    path: RoutePath.UserInvite,
  },
  {
    key: 'user-details',
    title: 'User details',
    accessbilityLevel: 'authenticated',
    component: UserDetails,
    exact: false,
    path: RoutePath.UserDetails,
  },
  {
    key: 'faq-details',
    title: 'FAQ Details',
    accessbilityLevel: 'authenticated',
    component: FAQDetails,
    exact: false,
    path: RoutePath.FAQDetails,
  },
  {
    key: 'reward-details',
    title: 'Reward Details',
    accessbilityLevel: 'authenticated',
    component: RewardDetails,
    exact: false,
    path: RoutePath.RewardDetails,
  },
  {
    key: 'virtual-reward-details',
    title: 'Virtual Reward Details',
    accessbilityLevel: 'authenticated',
    component: VirtualRewardDetails,
    exact: false,
    path: RoutePath.VirtualRewardDetails,
  },
  {
    key: 'point-history-details',
    title: 'Point History Details',
    accessbilityLevel: 'authenticated',
    component: PointHistoryDetails,
    exact: false,
    path: RoutePath.PointHistoryDetails,
  },
  {
    key: 'redeemed-rewards-details',
    title: 'Redeem Rewards Details',
    accessbilityLevel: 'authenticated',
    component: RedeemedRewardDetails,
    exact: false,
    path: RoutePath.RedeemedRewardDetails,
  },
  {
    key: 'points-history-details',
    title: 'Point History Details',
    accessbilityLevel: 'authenticated',
    component: PointHistoryDetails,
    exact: false,
    path: RoutePath.PointHistoryDetails,
  },
  {
    key: 'feedback-details',
    title: 'Feedback Details',
    accessbilityLevel: 'authenticated',
    component: FeedbackDetails,
    exact: false,
    path: RoutePath.FeedbackDetails,
  },
  {
    key: 'media-details',
    title: 'Media Details',
    accessbilityLevel: 'authenticated',
    component: MediaDetails,
    exact: false,
    path: RoutePath.MediaDetails,
  },
];

export const adminOnlyRoutes: AppRoute[] = [
  {
    key: 'settings',
    title: 'Settings',
    accessbilityLevel: 'authenticated',
    exact: true,
    path: RoutePath.Settings,
    childLinks: [
      {
        title: <FormattedMessage id="settings.logo.title" />,
        path: RoutePath.SettingsLogo,
      },
      {
        title: <FormattedMessage id="settings.modules.title" />,
        path: RoutePath.SettingsModules,
      },
      {
        title: <FormattedMessage id="settings.localisation.title" />,
        path: RoutePath.SettingsLocalisation,
      },
    ],
  },
  {
    key: 'settings-logo',
    title: 'Logo',
    accessbilityLevel: 'authenticated',
    component: SettingsLogo,
    exact: true,
    path: RoutePath.SettingsLogo,
  },
  {
    key: 'settings-modules',
    title: 'Enabled Modules',
    accessbilityLevel: 'authenticated',
    component: SettingsModules,
    exact: true,
    path: RoutePath.SettingsModules,
  },
  {
    key: 'settings-localisation',
    title: 'Language',
    accessbilityLevel: 'authenticated',
    component: SettingsLocalisation,
    exact: true,
    path: RoutePath.SettingsLocalisation,
  },
];
