export const schema = {
  document: {
    nodes: [
      {
        match: [],
      },
    ],
  },
  blocks: {
    paragraph: {
      nodes: [
        {
          match: { object: 'text' },
        },
      ],
    },
    image: {
      isVoid: true,
    },
    img: {
      isVoid: true,
    },
    video: {
      isVoid: true,
    },
    gallery: {
      isVoid: true,
    },
  },
};
