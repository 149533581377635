import { Localisation } from '../models';

export const de: Localisation = {
  articles: 'Artikel',
  'articles.all': 'Alle Artikel',
  'articles.back-to-list': 'Zurück zur Artikelliste',
  'articles.btn.add': 'Neuen Artikel hinzufügen',
  'articles.create.title': 'Neuen Artikel erstellen',
  'articles.details.title': 'Artikeldetails',
  'articles.draft': 'Entwurf von Artikeln',
  'articles.published': 'Veröffentlichte Artikel',
  'badge-level': 'Abzeichen-Level',
  'badge-levels': 'Abzeichenebenen',
  'badge-levels.btn.add': 'Ebene hinzufügen',
  badges: 'Abzeichen',
  'badges.back-to-list': 'Zurück zur Abzeichenliste',
  'badges.create.new': 'Erstelle ein neues Abzeichen',
  'badges.details.title': 'Ausweisdetails',
  'badges.level': 'Niveau',
  'button.add': 'Hinzufügen',
  'button.cancel': 'Abbrechen',
  'button.change-image': 'Bild ändern',
  'button.confirm': 'Bestätigen',
  'button.delete-language': 'Sprache löschen',
  'button.edit': 'Bearbeiten',
  'button.invite': 'Einladen',
  'button.logout': 'Ausloggen',
  'button.publish': 'Veröffentlichen',
  'button.remove': 'Löschen',
  'button.remove-image': 'Entferne Bild',
  'button.save': 'Speichern',
  'button.save-draft': 'Entwurf speichern',
  'button.save-language': 'Sprache speichern',
  'button.send-pw-reset-mail': 'E-Mail zum Zurücksetzen des Kennworts senden',
  'button.unpublish': 'Veröffentlichung aufheben',
  'button.upload-image': 'Bild hochladen',
  'dashboard.version': 'Version',
  'error.invite-user': 'E-Mail ist leer oder falsch formatiert',
  'error.localisation':
    'Bitte definieren Sie Zeichenketten für leere Eigenschaften',
  'error.missing': 'Fehlende Information',
  'error.page-404': 'Die angeforderte Seite existiert nicht.',
  'error.page-404.message':
    'Etwas ist schief gelaufen und du bist in der Limbo gelandet. Klicken Sie auf den folgenden Link, um zur Sicherheit zurückzukehren.',
  'error.permissions':
    'Sie haben keine Berechtigung, auf diese Seite zuzugreifen.',
  'error.permissions.login':
    'Melden Sie sich an, um Zugriff auf die angeforderte Seite zu erhalten.',
  faq: 'FAQ',
  'faq.back-to-list': 'Zurück zur FAQ-Liste',
  'faq.btn.add': 'Neue FAQ hinzufügen',
  'faq.create.title': 'Neue FAQ erstellen',
  'faq.details.title': 'FAQ details',
  products: 'Produkte',
  'products.btn.add': 'Neues Produkt hinzufügen',
  'products.create.title': 'Neues Produkt erstellen',
  'products.details.title': 'Produktdetails',
  'products.back-to-list': 'Zurück zur Produktliste',
  feedback: 'Rückmeldungen',
  'feedback-cards': 'Feedback-Karten',
  'feedback-cards.active': 'Aktiv',
  'feedback-cards.btn.add': 'Feedback-Karte hinzufügen',
  'feedback-cards.direction': 'Richtung',
  'feedback-cards.error':
    'Max. Anzahl der aktiven Karten pro Richtungstyp ist 3',
  'feedback-cards.image-error':
    'Das Bildseitenverhältnis muss 16: 9 und max. Die unterstützte Breite beträgt 1280 px',
  'feedback-cards.image-error.size': 'Bildgröße',
  'feedback-cards.image-error.aspect': 'Seitenverhältnis',
  'feedback-thanks-cards': 'Feedback Dankeskarte',
  'feedback-thanks-cards.btn.add': 'Feedback hinzufügen Dankeskarte',
  'feedback.all': 'Alle Rückmeldungen',
  'feedback.giving': 'Feedback geben',
  'feedback.requesting': 'Feedback anfordern',
  'feedbacks.back-to-list': 'Zurück zur Liste aller Bewertungen',
  'feedbacks.status.read': 'Lesen',
  'feedbacks.status.unread': 'Ungelesen',
  'form.action-name': 'Aktionsname',
  'form.action-name.placeholder': 'Aktion auswählen',
  'form.action-type': 'Aktionstyp',
  'form.action-points': 'Aktionspunkte',
  'form.label': 'Etikette',
  'form.lead-text': 'Leittext',
  'form.url': 'URL',
  'form.barcode': 'Strichcode',
  'form.address': 'Adresse',
  'form.all': 'Alles',
  'form.amount': 'Menge',
  'form.city': 'Stadt',
  'form.company': 'Unternehmen',
  'form.company.placeholder': 'Firma eingeben',
  'form.content': 'Inhalt',
  'form.count': 'Anzahl',
  'form.cover-photo': 'Titelbild',
  'form.cover-photo.placeholder': 'Fügen Sie ein Titelbild hinzu',
  'form.date': 'Datum',
  'form.date-order': 'Terminbestellung',
  'form.date-order.asc': 'Aufsteigend',
  'form.date-order.desc': 'Absteigend',
  'form.department': 'Abteilung',
  'form.department.placeholder': 'Abteilung betreten',
  'form.description': 'Beschreibung',
  'form.direction-order': 'Richtung auftrag',
  'form.display-name': 'Anzeigename',
  'form.email': 'Email',
  'form.email.placeholder': 'Email eingeben',
  'form.featured-image': 'Vorgestelltes Bild',
  'form.first-name': 'Vorname',
  'form.first-name.placeholder': 'Bitte Vornamen eingeben',
  'form.from': 'Von',
  'form.interval': 'Gültige Zeit',
  'form.language': 'Sprache',
  'form.last-name': 'Nachname',
  'form.last-name.placeholder': 'Nachnamen eingeben',
  'form.level': 'Niveau',
  'form.not-shipped': 'Nicht ausgeliefert',
  'form.password': 'Passwort',
  'form.password.placeholder': 'Geben Sie Ihr Passwort ein',
  'form.phone': 'Telefon',
  'form.phone.placeholder': 'Telefonnummer eingeben',
  'form.points': 'Punkte',
  'form.points-required': 'Punkte erforderlich',
  'form.position': 'Position',
  'form.position.placeholder': 'Position eingeben',
  'form.post-code': 'Postleitzahl',
  'form.publish-date': 'Erscheinungsdatum',
  'form.read-articles': 'Artikel lesen',
  'form.remember-me': 'Erinnere dich an mich?',
  'form.role': 'Benutzer-Rolle',
  'form.shipped': 'Ausgeliefert',
  'form.slug': 'Slug',
  'form.slug.placeholder': 'Slug eingeben',
  'form.spent-points': 'Verbrauchte Punkte',
  'form.subtitle': 'Untertitel',
  'form.subtitle.placeholder': 'Geben Sie einen Untertitel ein',
  'form.team': 'Mannschaft',
  'form.team.placeholder': 'Team betreten',
  'form.title': 'Titel',
  'form.title.placeholder': 'Geben Sie einen Titel ein',
  'form.to': 'Zu',
  'form.total-points': 'Gesamtpunktzahl',
  'form.type': 'Art',
  'form.user-actions': 'Benutzeraktionen',
  'form.user-badges': 'Benutzerausweise',
  'form.user-filter': 'Nutzer',
  'form.user-filter.placeholder': 'Wähle den Benutzer',
  'form.user-id': 'Benutzeridentifikation',
  'form.user-id.placeholder': 'Benutzer-ID eingeben',
  'form.video-url': 'Video url',
  'form.video-url-input.placeholder': 'Gib die URL für dein Video ein',
  'form.video-url.placeholder': 'Fügen Sie ein Video hinzu',
  'form.image-gallery': 'Bildergalerie',
  'form.rating': 'Durchschnittliche Bewertung',
  'form.image.alt': 'Beim Laden des Bildes ist ein Problem aufgetreten',
  gamification: 'Gamification',
  localisation: 'Lokalisierung',
  'localisation.add-new-prop': 'Neue Eigenschaft hinzufügen',
  'localisation.back-to-list': 'Zurück zur Sprachenliste',
  'localisation.btn.add': 'Neue Sprache hinzufügen',
  'localisation.client': 'Applikation',
  'localisation.create.title': 'Neue Sprache erstellen',
  'localisation.dashboard': 'Dashboard',
  'localisation.details.title': 'Sprachdetails',
  'localisation.export': 'Sprache exportieren',
  'localisation.import': 'Sprache importieren',
  login: 'Anmeldung',
  'login.forgot-pw': 'Passwort vergessen?',
  'login.message':
    'Verwenden Sie Ihr Steiermark-Konto, um sich bei der Administratorkonsole anzumelden',
  'modal.are-you-sure': 'Bist du sicher?',
  'modal.image-select':
    'Ziehen Sie das Bild hierher, und legen Sie es dort ab, oder klicken Sie, um es auszuwählen',
  'modal.image-url': 'Bild-URL eingeben',
  'modal.insert-image': 'Bild einfügen',
  'modal.insert-video': 'Video einfügen',
  'modal.or': 'ODER',
  'modal.unsaved-changes':
    'Sie haben die Änderungen nicht gespeichert, verlassen Sie die Seite trotzdem?',
  'modal.video-url': 'Geben Sie die Video-URL ein',
  'points-history': 'Punkte Geschichte',
  'points-history.back-to-list': 'Zurück zur Punkteverlaufsliste',
  'points-history.btn.add': 'Punkt hinzufügen',
  'points-history.create.title':
    'Erstellen Sie einen neuen Punkt in der Geschichte',
  'points-history.details.title': 'Punkt Details',
  'redeemed-rewards': 'Eingelöste Belohnungen',
  'redeemed-rewards.back-to-list': 'Zurück zu eingelösten Prämien',
  'redeemed-rewards.details.title': 'Eingelöste Belohnungsdetails',
  'redeemed-rewards.public-info': 'Öffentliche Information',
  'redeemed-rewards.reward-info': 'Belohnungsinformationen',
  'redeemed-rewards.shipping-info': 'Versandinformationen',
  rewards: 'Belohnt',
  'rewards.all': 'Alle Belohnungen',
  'rewards.virtual': 'Virtuelle Belohnungen',
  'rewards.back-to-list': 'Zurück zur Belohnungsliste',
  'rewards.btn.add': 'Neue Belohnung hinzufügen',
  'rewards.btn.add-virtual': 'Neue Belohnung hinzufügen',
  'rewards.missing-user': '- Fehlende Benutzerinformationen -',
  'rewards.create.title': 'Neue Belohnung erstellen',
  'rewards.details.title': 'Belohnungsdetails',
  'rewards.draft': 'Belohnungsentwürfe',
  'rewards.not-shipped': 'Belohnung nicht versandt',
  'rewards.published': 'Veröffentlichte Belohnungen',
  'rewards.shipped': 'Belohnung versandt',
  search: 'Suche',
  settings: 'Einstellungen',
  'settings.btn.save': 'Speichern',
  'settings.localisation.select': 'Sprache auswählen',
  'settings.localisation.title': 'Sprache',
  'settings.logo.title': 'Instrumententafel Logo',
  'settings.modules.title': 'Aktivierte Module',
  'static-content': ' Statischer Inhalt',
  'static-content.back-to-list': 'Zurück zur statischen Inhaltsliste',
  'static-content.btn.add': 'Neuen statischen Inhalt hinzufügen',
  'static-content.create.title': 'Neuen statischen Inhalt erstellen',
  'static-content.details.title': 'Statische Inhaltsdetails',
  summary: 'Zusammenfassung',
  'summary.articles.title': 'Zuletzt veröffentlichte Artikel',
  'summary.btn.seemore': 'Mehr',
  'summary.feedback.title': 'Neueste Rückmeldungen',
  'summary.points.title': 'Neueste Punktaktionen',
  'summary.redeemed.title': 'Letzte eingelöste Prämien',
  'summary.stats.title': 'Statistiktitel',
  'toast.article.publish': 'Erfolgreich veröffentlichter {title} Artikel',
  'toast.article.save':
    'Der neue {title} Artikel wurde erfolgreich hinzugefügt',
  'toast.article.unpublish': 'Erfolgreich unveröffentlichter {title} Artikel',
  'toast.article.update': '{Title} Artikel erfolgreich aktualisiert',
  'toast.badge-levels.save': 'Abzeichenebenen erfolgreich gespeichert',
  'toast.badges.save': 'Neues {title} Abzeichen hinzugefügt',
  'toast.badges.update': '{title} Abzeichen erfolgreich aktualisiert',
  'toast.faq.save': 'Neue {title} FAQ erfolgreich hinzugefügt',
  'toast.faq.update': '{Title} FAQ erfolgreich aktualisiert',
  'toast.product.update': 'Erfolgreich aktualisiertes {title} Produkt',
  'toast.product.save': 'Neues {title} -Produkt erfolgreich hinzugefügt',
  'toast.localisation.update': 'Lokalisierung aktualisiert',
  'toast.modal.delete': 'Erfolgreich entfernt {title}',
  'toast.point.add': 'Neuer Punkt in der Geschichte hinzugefügt',
  'toast.redeemed.update': 'Erfolgreich bearbeitete eingelöste Belohnung',
  'toast.rewards.save': 'Neue {title} Belohnung hinzugefügt',
  'toast.rewards.update': '{title} Belohnung erfolgreich aktualisiert',
  'toast.settings-localisation.save':
    'Successfully updated dashboard localisation',
  'toast.settings-modules.save': 'Erfolgreich aktualisierte Dashboard-Module',
  'toast.settings.logo.removed': 'Dashboard-Logo entfernt',
  'toast.settings.logo.update': 'Dashboard-Logo aktualisiert',
  'toast.static-content.save': 'Neuer statischer Inhalt hinzugefügt {title}',
  'toast.static-content.update': 'Erfolgreich aktualisiert {title}',
  'toast.user-levels.save': 'Erfolgreich gespeicherte Benutzerstufen',
  'toast.users.invite': 'Erfolgreich eingeladener Benutzer mit E-Mail {title}',
  'toast.users.update': 'Erfolgreich aktualisierter Benutzer {title}',
  'toast.media.update': '{Title} Medien wurden erfolgreich aktualisiert',
  'toast.media.save': '{Title} - Datenträger wurde erfolgreich gespeichert',
  'toast.thanks-cards.save': 'Erfolgreich gespeicherte Feedback-Dankeskarten',
  'toast.feedback-cards.save': 'Erfolgreich gespeicherte Feedbackkarten',
  'toast.language.disabled':
    'Das Ändern der Sprache ist im Offline-Modus deaktiviert',
  'toast.logo.disabled':
    'Das Ändern des Logos ist im Offline-Modus deaktiviert',
  'toast.images.disabled':
    'Bilder können nicht im Offline-Modus aktualisiert werden',
  'toast.user.disabled':
    'Die Benutzerbearbeitung ist im Offline-Modus deaktiviert',
  'user-levels': 'Benutzerebenen',
  'user-levels.btn.add': 'Neue Benutzerebene hinzufügen',
  'user-levels.title.placeholder': 'Fügen Sie einen Ebenentitel hinzu',
  users: 'Benutzer',
  'users.back-to-list': 'Zurück zu den Benutzern',
  'users.details.private': 'Private Benutzerinformationen',
  'users.details.public': 'Öffentliche Benutzerinformationen',
  'users.details.title': 'Nutzerdetails',
  'users.invite': 'Benutzer einladen',
  'users.invite-email.placeholder': 'E-Mail der Person, die Sie einladen',
  media: 'Medien',
  'modal.insert-gallery.title': 'Bildergalerie einfügen',
  'modal.insert-gallery.placeholder': 'Galerie auswählen...',
  'media.btn.add': 'Fügen Sie neue Medien hinzu',
  'media.details.title': 'Mediendetails',
  'media.create.title': 'Neue Medien erstellen',
  'media.back-to-list': 'Geh zurück',
  gallery: 'Galerie',
  'alert.offline':
    'Es besteht keine Internetverbindung. Ihre Änderungen werden lokal gespeichert, bis die Verbindung hergestellt ist.',
  'alert.offline.features':
    'Einige Funktionen sind im Offline-Modus deaktiviert.',
};
